import auth from '@/plugins/auth'
import router, { constantRoutes, dynamicRoutes } from '@/router'
import { getRouters } from '@/api/menu'
import Layout from '@/layout/index'
import ParentView from '@/components/ParentView'
import InnerLink from '@/layout/components/InnerLink'

const permission = {
  state: {
    routes: [],
    addRoutes: [],
    defaultRoutes: [],
    topbarRouters: [],
    sidebarRouters: [],
    role: "",
    personType: '', // 登录角色 1平台 3学校
    dataView: '', // 学校数据大屏 是否收费
    subjectList: [] // 课程表列表
  },
  mutations: {
    SET_ROUTES: (state, routes) => {
      state.addRoutes = routes
      state.routes = constantRoutes.concat(routes)
    },
    SET_DEFAULT_ROUTES: (state, routes) => {
      state.defaultRoutes = constantRoutes.concat(routes)
    },
    SET_TOPBAR_ROUTES: (state, routes) => {
      state.topbarRouters = routes
    },
    SET_SIDEBAR_ROUTERS: (state, routes) => {
      state.sidebarRouters = routes
    },
    setRole(state, roles) {
      state.role = roles
    },
    setPersonType(state, personType) {
      state.personType = personType
      localStorage.setItem('personType', personType)
    },
    setDateView(state, dataView) {
      state.dataView = dataView
      localStorage.setItem('dataView', dataView)
    },
    setSubject(state, subjectList) {
      state.subjectList = subjectList
    }
  },
  actions: {
    // 生成路由
    GenerateRoutes({ commit }) {
      return new Promise(resolve => {
        // 向后端请求路由数据
        getRouters().then(res => {
          res.data.forEach(v => {
            if (v.path == "/shujudaping") {
              commit('setDateView', v.isViewAble)
            }
          })
          const sdata = JSON.parse(JSON.stringify(res.data))
          const rdata = JSON.parse(JSON.stringify(res.data))
          const sidebarRoutes = filterAsyncRouter(sdata)
          const rewriteRoutes = filterAsyncRouter(rdata, false, true)
          const asyncRoutes = filterDynamicRoutes(dynamicRoutes);
          rewriteRoutes.push({ path: '*', redirect: '/404', hidden: true })
          router.addRoutes(asyncRoutes);
          commit('SET_ROUTES', rewriteRoutes)
          commit('SET_SIDEBAR_ROUTERS', constantRoutes.concat(sidebarRoutes))
          commit('SET_DEFAULT_ROUTES', sidebarRoutes)
          commit('SET_TOPBAR_ROUTES', sidebarRoutes)
          resolve(rewriteRoutes)
        })
      })
    }
  }
}

// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap, lastRouter = false, type = false) {
  return asyncRouterMap.filter(route => {
    if (type && route.children) {
      // console.log(route,'打印了');
      if (route.children != null && route.children && route.children.length) {
        route.children.forEach((s, i) => {
          // console.log(s.isViewAble,'在学校');
          if (route.isViewAble == false) {
            s.isViewAble = route.isViewAble
            if (s.children != null && s.children && s.children.length) {
              s.children.forEach((z, x) => {
                if (s.isViewAble == false) {
                  z.isViewAble = s.isViewAble
                  if (z.children != null && z.children && z.children.length) {
                    z.children.forEach((q, e) => {
                      q.isViewAble = z.isViewAble
                    })
                  }
                }
              })
            }
          } else {
            if (s.children != null && s.children && s.children.length) {
              s.children.forEach((z, x) => {
                if (s.isViewAble == false) {
                  z.isViewAble = s.isViewAble
                  if (z.children != null && z.children && z.children.length) {
                    z.children.forEach((q, e) => {
                      q.isViewAble = z.isViewAble
                    })
                  }
                }
              })
            }
          }

        })
      } else {
        delete route['children']
        delete route['redirect']
      }

      route.children = filterChildren(route.children)
    }
    if (route.component) {
      // Layout ParentView 组件特殊处理
      if (route.component === 'Layout') {
        route.component = Layout
      } else if (route.component === 'ParentView') {
        route.component = ParentView
      } else if (route.component === 'InnerLink') {
        route.component = InnerLink
      } else {
        route.component = loadView(route.component, route)
      }
    }
    if (route.children != null && route.children && route.children.length) {

      route.children = filterAsyncRouter(route.children, route, type)
    } else {
      delete route['children']
      delete route['redirect']
    }
    return true
  })
}

function filterChildren(childrenMap, lastRouter = false) {
  var children = []
  childrenMap.forEach((el, index) => {
    // console.log(el,index,'打印');
    if (el.children && el.children.length) {
      if (el.component === 'ParentView' && !lastRouter) {
        el.children.forEach(c => {
          c.path = el.path + '/' + c.path
          if (c.children && c.children.length) {
            children = children.concat(filterChildren(c.children, c))
            return
          }
          children.push(c)
        })
        return
      }
    }
    if (lastRouter) {
      el.path = lastRouter.path + '/' + el.path
    }
    children = children.concat(el)
  })
  return children
}

// 动态路由遍历，验证是否具备权限
export function filterDynamicRoutes(routes) {
  const res = []
  routes.forEach(route => {
    if (route.permissions) {
      if (auth.hasPermiOr(route.permissions)) {
        res.push(route)
      }
    } else if (route.roles) {
      if (auth.hasRoleOr(route.roles)) {
        res.push(route)
      }
    }
  })
  return res
}

export const loadView = (view, route) => {
  if (process.env.NODE_ENV === 'development') {
    if (route.isViewAble == false) {
      if(route.defaultPageMode=='1'){
        return (resolve) => require([`@/views/schoolPages/defaultpage/defaultpageExpenditure`], resolve)
      }else if(route.defaultPageMode=='2'){
        return (resolve) => require([`@/views/schoolPages/defaultpage/defaultpageEducationFee`], resolve)
      }else if(route.defaultPageMode=='3'){
        return (resolve) => require([`@/views/schoolPages/defaultpage/dormitoryAccessControl`], resolve)
      }else if(route.defaultPageMode=='4'){
        return (resolve) => require([`@/views/schoolPages/defaultpage/librarian`], resolve)
      }else{
        return (resolve) => require([`@/views/schoolPages/defaultpage/defaultpage`], resolve)
      }
    } else {
      // console.log(`@/views/schoolPages/defaultpage/defaultpage`,'1111');
      return (resolve) => require([`@/views/${view}`], resolve)
    }




  } else {
    // 使用 import 实现生产环境的路由懒加载
    // return () => import(`@/views/${view}`)
    if (route.isViewAble == false) {
      // return (resolve) => require([`@/views/schoolPages/defaultpage/defaultpage`], resolve)
      if(route.defaultPageMode=='1'){
        return (resolve) => require([`@/views/schoolPages/defaultpage/defaultpageExpenditure`], resolve)
      }else if(route.defaultPageMode=='2'){
        return (resolve) => require([`@/views/schoolPages/defaultpage/defaultpageEducationFee`], resolve)
      }else if(route.defaultPageMode=='3'){
        return (resolve) => require([`@/views/schoolPages/defaultpage/dormitoryAccessControl`], resolve)
      }else if(route.defaultPageMode=='4'){
        return (resolve) => require([`@/views/schoolPages/defaultpage/librarian`], resolve)
      }else{
        return (resolve) => require([`@/views/schoolPages/defaultpage/defaultpage`], resolve)
      }
    } else {
      return (resolve) => require([`@/views/${view}`], resolve)

    }
  }
}

export default permission
