import request from '@/utils/request'

// 查列表    url: '/xyw-pay/xssAccount/getStudentRecordsByParent',
// '/xyw-miniApp/record/getMessageList'
export function getChat(data) {
    return request({
        url: '/xyw-miniApp/record/getMessageList',
        method: 'post',
        data,
        headers: {
            "fromApp": 'weChat'
        }
    })
}
export function getChatDD(data) {
    // 不传 2
    return request({
        url: '/xyw-miniApp/record/getMessageList',
        method: 'post',
        data,
    })
}
// 发送
export function saveVoice(data) {
    return request({
        url: '/xyw-miniApp/record/uploadVoice',
        method: 'post',
        data
    })
}
// 录音
export function uploadVideo(data) {
    return request({
        url: '/xyw-system/sysFile/upload',
        method: 'post',
        data,
        headers: { 'Content-Type': 'multipart/form-data' },
    })
}