
import Vue from 'vue'
Vue.filter('formatData', date => { // 年月日
    if (date != null) {
        const dateMat = new Date(date);
        const year = dateMat.getFullYear();
        const month = (dateMat.getMonth() + 1 < 10 ? "0" + (dateMat.getMonth() + 1) : dateMat.getMonth() + 1);
        const day = dateMat.getDate() < 10 ? "0" + dateMat.getDate() : dateMat.getDate();
        const timeFormat =
            year + '-' + month + '-' + day;
        return timeFormat;
    }
})
Vue.filter('formatTime', date => { // 年月日时分秒
    if (date != null) {
        const dateMat = new Date(date);
        const year = dateMat.getFullYear();
        const month = (dateMat.getMonth() + 1 < 10 ? "0" + (dateMat.getMonth() + 1) : dateMat.getMonth() + 1);
        const day = dateMat.getDate() < 10 ? "0" + dateMat.getDate() : dateMat.getDate();
        const HH = dateMat.getHours() < 10 ? "0" + dateMat.getHours() : dateMat.getHours();
        const mm = dateMat.getMinutes() < 10 ? "0" + dateMat.getMinutes() : dateMat.getMinutes();
        const ss = dateMat.getSeconds() < 10 ? "0" + dateMat.getSeconds() : dateMat.getSeconds();
        const timeFormat =
            year + '-' + month + '-' + day + ' ' + HH + ':' + mm + ':' + ss;
        return timeFormat;
    }
})
Vue.filter('filterTxt', val => { // 提取纯文本
    if (val != null && val != '') {
        var reg = /[\u4e00-\u9fa5]/g
        var names = val.match(reg)
        val = names.join('')
        return val
    } else return ''

})

Vue.filter('formatCountTime', timeLimit => { // 年月日时分
  let now = new Date()
  if (timeLimit != null) {
    now = new Date(now.getTime() + timeLimit * 24 * 60 * 60 * 1000);
  }
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  return `${year}-${month}-${day} ${hours}:${minutes}`;
})


