import request from '@/utils/request'
import { parseStrEmpty } from "@/utils/ruoyi";

// 查询用户列表
export function listUser(query) {
  return request({
    url: '/xyw-system/user/list',
    method: 'get',
    params: query
  })
}

// 查询用户详细
export function getUser(userId) {
  return request({
    url: '/xyw-system/user/' + parseStrEmpty(userId),
    method: 'get'
  })
}

// 新增用户
export function addUser(data) {
  return request({
    url: '/xyw-system/user',
    method: 'post',
    data: data
  })
}

// 修改用户
export function updateUser(data) {
  return request({
    url: '/xyw-system/user',
    method: 'put',
    data: data
  })
}

// 删除用户
export function delUser(userId) {
  return request({
    url: '/xyw-system/user/' + userId,
    method: 'delete'
  })
}

// 用户密码重置
export function resetUserPwd(userId, password) {
  const data = {
    userId,
    password
  }
  return request({
    url: '/xyw-system/user/resetPwd',
    method: 'put',
    data: data
  })
}
// 教师密码重置
export function resetTeacherPwd(data) {
  return request({
    url: '/xyw-system/sch/teacher/restPassWord',
    method: 'post',
    data
  })
}
// 用户状态修改
export function changeUserStatus(userId, status) {
  const data = {
    userId,
    status
  }
  return request({
    url: '/xyw-system/user/changeStatus',
    method: 'put',
    data: data
  })
}

// 查询用户个人信息
export function getUserProfile() {
  return request({
    url: '/xyw-system/user/profile',
    method: 'get'
  })
}

// 修改用户个人信息
export function updateUserProfile(data) {
  return request({
    url: '/xyw-system/user/profile',
    method: 'put',
    data: data
  })
}

// 用户密码重置
export function updateUserPwd(oldPassword, newPassword) {
  const data = {
    oldPassword,
    newPassword
  }
  return request({
    url: '/xyw-system/user/profile/updatePwd',
    method: 'put',
    params: data
  })
}

// 用户头像上传
export function uploadAvatar(data) {
  return request({
    url: '/xyw-system/user/profile/avatar',
    method: 'post',
    data: data
  })
}

// 查询授权角色
export function getAuthRole(userId) {
  return request({
    url: '/xyw-system/user/authRole/' + userId,
    method: 'get'
  })
}

// 保存授权角色
export function updateAuthRole(data) {
  return request({
    url: '/xyw-system/user/authRole',
    method: 'put',
    params: data
  })
}
// 根据类型获取用户列表
export function queryAllByUserType(userType) {
  return request({
    url: '/xyw-system/user/queryAllByUserType/' + userType,
    method: 'get'
  })
}
//  获取学校是否配置退款密码
export function getSchoolRefundPassword(data) {
  return request({
      url: '/xyw-system/refundPassWord/getSchoolRefundPassword',
      method: 'post',
      data
  })
}
// 学校配置退款密码
export function updateSchoolRefundPassword(data) {
  return request({
      url: '/xyw-system/refundPassWord/updateSchoolRefundPassword',
      method: 'post',
      data
  })
}