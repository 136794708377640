<template>
  <div class="app-container home">
    <!-- <div class="img-box">
      <img class="img" src="@/assets/images/home.png" alt="">
      <div class="btn">敬请期待...{{ personType == 1 ? "运营" : "学校" }}</div>
    </div> -->
    <!-- 运营端首页 -->
    <template v-if="personType == 1">

      <!-- 饼图 -->
      <div class="new-top" v-if="devInfo.agentCount">
        <div class="new-top-l">

          <div class="left-i">
            <img class="img" src="@/assets/images/school.png" alt="">
            <div>
              <div>学校</div>
              <div style="font-size: 22px;font-weight: 700;">{{
                devInfo.schoolCount ? devInfo.schoolCount : 0
              }}<span style="color: #4E5969;font-size: 14px;">所</span></div>
            </div>
          </div>

          <div class="left-i" >
            <img class="img" src="@/assets/images/agent.png" alt="">
            <div>
              <div>代理商</div>
              <div style="font-size: 22px;font-weight: 700;">{{ devInfo.agentCount }}<span
                  style="color: #4E5969;font-size: 14px;">家</span></div>
            </div>
          </div>

          <div class="left-i">
            <img class="img" src="@/assets/images/xss.png" alt="">
            <div>
              <!-- <div>{{ $store.state.user.userType != 2 ? '校收收已绑定学生' : '开通学生数' }} </div> -->
              <div>人脸采集用户 </div>
              <div style="font-size: 22px;font-weight: 700;">{{
                devInfo.studentFaceCount ?
                devInfo.studentFaceCount : 0
              }}<span style="color: #4E5969;font-size: 14px;">人</span></div>
            </div>
          </div>

        </div>
        <div class="xian">
       
        </div>
        <div class="new-c-r">
         
          <div class="right-l" >
            <div>
              <!-- <div >总人数</div>
              <div >{{ devInfo.allPeopleCount }}
              <span >人</span></div> -->

              <div>总人数 </div>
              <div style="font-size: 22px;font-weight: 700;">{{
                devInfo.allPeopleCount ?
                devInfo.allPeopleCount : 0
              }}<span style="color: #4E5969;font-size: 14px;">人</span></div>
            </div>
            
          </div>
          <div class="right-l" >
            <div>
              <!-- <div>教师人数</div>
              <div >{{ devInfo.teacherCount }}
              <span >人</span></div> -->

              <div>教师人数 </div>
              <div style="font-size: 22px;font-weight: 700;">{{
                devInfo.teacherCount ?
                devInfo.teacherCount : 0
              }}<span style="color: #4E5969;font-size: 14px;">人</span></div>
            </div>
          </div>
          <div class="right-l" >
            <div>
              <!-- <div>学生人数</div>
              <div >{{ devInfo.studentCount  }}
              <span >人</span></div> -->
              <div>学生人数 </div>
              <div style="font-size: 22px;font-weight: 700;">{{
                devInfo.studentCount ?
                devInfo.studentCount : 0
              }}<span style="color: #4E5969;font-size: 14px;">人</span></div>
            </div>
          </div>
          <div class="right-l" >
            <div>
              <!-- <div>家长人数</div>
              <div >{{ devInfo.parentCount }}
              <span >人</span></div> -->
              <div>家长人数 </div>
              <div style="font-size: 22px;font-weight: 700;">{{
                devInfo.parentCount ?
                devInfo.parentCount : 0
              }}<span style="color: #4E5969;font-size: 14px;">人</span></div>
            </div>
          </div>


          </div>
      
      </div>

      <div class="new-tops" v-if="!devInfo.agentCount">
        <div class="new-top-l">

          <div class="left-i">
            <img class="img" src="@/assets/images/school.png" alt="">
            <div>
              <div>学校</div>
              <div style="font-size: 22px;font-weight: 700;">{{
                devInfo.schoolCount ? devInfo.schoolCount : 0
              }}<span style="color: #4E5969;font-size: 14px;">所</span></div>
            </div>
          </div>

         

          <div class="left-i">
            <img class="img" src="@/assets/images/xss.png" alt="">
            <div>
              <!-- <div>{{ $store.state.user.userType != 2 ? '校收收已绑定学生' : '开通学生数' }} </div> -->
              <div>人脸采集用户 </div>
              <div style="font-size: 22px;font-weight: 700;">{{
                devInfo.studentFaceCount ?
                devInfo.studentFaceCount : 0
              }}<span style="color: #4E5969;font-size: 14px;">人</span></div>
            </div>
          </div>

        </div>
        <div class="xian">
       
        </div>
        <div class="new-c-r">
         
          <div class="right-l" >
            <div>
              <!-- <div >总人数</div>
              <div >{{ devInfo.allPeopleCount }}
              <span >人</span></div> -->

              <div>总人数 </div>
              <div style="font-size: 22px;font-weight: 700;">{{
                devInfo.allPeopleCount ?
                devInfo.allPeopleCount : 0
              }}<span style="color: #4E5969;font-size: 14px;">人</span></div>
            </div>
            
          </div>
          <div class="right-l" >
            <div>
              <!-- <div>教师人数</div>
              <div >{{ devInfo.teacherCount }}
              <span >人</span></div> -->

              <div>教师人数 </div>
              <div style="font-size: 22px;font-weight: 700;">{{
                devInfo.teacherCount ?
                devInfo.teacherCount : 0
              }}<span style="color: #4E5969;font-size: 14px;">人</span></div>
            </div>
          </div>
          <div class="right-l" >
            <div>
              <!-- <div>学生人数</div>
              <div >{{ devInfo.studentCount  }}
              <span >人</span></div> -->
              <div>学生人数 </div>
              <div style="font-size: 22px;font-weight: 700;">{{
                devInfo.studentCount ?
                devInfo.studentCount : 0
              }}<span style="color: #4E5969;font-size: 14px;">人</span></div>
            </div>
          </div>
          <div class="right-l" >
            <div>
              <!-- <div>家长人数</div>
              <div >{{ devInfo.parentCount }}
              <span >人</span></div> -->
              <div>家长人数 </div>
              <div style="font-size: 22px;font-weight: 700;">{{
                devInfo.parentCount ?
                devInfo.parentCount : 0
              }}<span style="color: #4E5969;font-size: 14px;">人</span></div>
            </div>
          </div>


          </div>
      
      </div>




      <div style="display: flex;width: 100%;justify-content: space-between;">
       
          <div class="school-boxs" >
            <div class="s-top">
              <span style="  font-weight: 700;">设备分布图</span>
              <span style="margin-left: 12px;font-size: 14px;color: #1D2129;">学校</span>
              <span style="font-size: 14px;color: #1D2129;">共部署{{
                deviceTotal ? deviceTotal : 0
              }}台设备</span>
            </div>
    
            <div id="eqEchart" style="width: 98%;height: 95%;padding: 0 5px 44px 0;box-sizing: border-box;"> </div>
          </div>
          <div class="school-box">
            <div class="s-top">学校分布图</div>
            <div id="schoolEchart" style="width: 100%;height: 100%;"></div>
          </div>
      </div>

      <!-- 智能公话 -->
      <div class="echarts-box">
        <div class="smart-talk" v-for="(item, index) in echartsBox" :key="index">
          <div class="talk-top">
            <div class="t-title">{{ item.name }}</div>
            <div style="display: flex;align-items: center;">
              <el-select v-model="item.talkVal" placeholder="请选择" style="width: 220px;"
                @change="schoolChange($event, index)" filterable>
                <!-- <el-option v-for="v in ((item.name == '校园团餐使用统计' || item.name == '校园团餐营收统计') ? schoolList : options)"
                  :key="v.schoolId" :label="v.schoolName" :value="v.schoolId"> -->
                <el-option v-for="v in  item.schoolList" :key="v.schoolId" :label="v.schoolName" :value="v.schoolId">
                </el-option>
              </el-select>
              <div class="month-box">
                <div class="month" :class="(item.talkMonKey && (index1 == item.talkMonIndex)) ? 'talk-ac' : ''"
                  v-for="(item1, index1) in monthsTalk" :key="index1" @click="monthTalkClick(index, index1)">{{
                    item1
                  }}</div>
              </div>
              <div style="width: 40px;text-align: center;cursor: pointer;" @click="zoomChart(index)">
                <svg-icon icon-class="fullscreen" />
              </div>
            </div>
          </div>
          <!-- 双折线 -->
          <div v-if="index == 0" id="talkEcharts" style="width: 100%;height:350px;"></div>
          <div v-if="index == 1" id="talkPayEcharts" style="width: 100%;height:350px;"></div>
          <div v-if="index == 2" id="groupEcharts" style="width: 100%;height:350px;"></div>
          <div v-if="index == 3" id="groupPayEcharts" style="width: 100%;height:350px;"></div>
          <div v-if="index == 4" id="bookEchart" style="width: 100%;height:350px;"></div>
          <div v-if="index == 5" id="washEchart" style="width: 100%;height:350px;"></div>
          <div v-if="index == 6" id="isLeaveSchool" style="width: 100%;height:350px;"></div>
          <div v-if="index == 7" id="isLeaveLife" style="width: 100%;height:350px;"></div>
          <div v-if="index == 8" id="vendingMachine" style="width: 100%;height:350px;"></div>
          <div v-if="index == 9" id="showerStatistics" style="width: 100%;height:350px;"></div>
          <div v-if="index == 10" id="hairDryer" style="width: 100%;height:350px;"></div>
        </div>
      </div>
    </template>
    <!-- 学校端 首页 -->
    <template v-else>
      <schoolIndex />
    </template>
    <!-- echarts弹窗 -->
    <el-dialog :title="titleChart" :visible.sync="openChart" width="82%" append-to-body @opened="switchChart"
      destroy-on-close>
      <div v-if="titleChart == '智能公话使用统计'" id="talkEchartsDialog" style="width: 100%;height:550px;"></div>
      <div v-if="titleChart == '智能公话充值统计'" id="talkPayEchartsDialog" style="width: 100%;height:550px;"></div>
      <div v-if="titleChart == '校园团餐使用统计'" id="groupEchartsDialog" style="width: 100%;height:550px;"></div>
      <div v-if="titleChart == '校园团餐营收统计'" id="groupPayEchartsDialog" style="width: 100%;height:550px;"></div>
      <div v-if="titleChart == '校园图书借阅统计'" id="bookEchartDialog" style="width: 100%;height:550px;"></div>
      <div v-if="titleChart == '共享洗衣使用统计'" id="washEchartDialog" style="width: 100%;height:550px;"></div>
      <div v-if="titleChart == '入离校门禁进出统计'" id="isLeaveSchoolDialog" style="width: 100%;height:550px;"></div>
      <div v-if="titleChart == '宿舍门禁进出统计'" id="isLeaveLifeDialog" style="width: 100%;height:550px;"></div>
      <div v-if="titleChart == '自助售货机使用统计'" id="isLeaveVendingMachine" style="width: 100%;height:550px;"></div>
      <div v-if="titleChart == '智慧淋浴使用统计'" id="takeShowerStatistics" style="width: 100%;height:550px;"></div>
      <div v-if="titleChart == '共享吹风机使用统计'" id="hairDryerDialog" style="width: 100%;height:550px;"></div>
    </el-dialog>
  </div>
</template>

<script>
import { getUserProfile } from "@/api/system/user";
import { getDevices, getMaps, getEchartsSchool, getVideoPhoneUseCount, getVideoPhoneTopUpCount, getMealMachineUseCount, getMealMachineRevenueCount, getBookSchoolBorrowCount, getWashingUseCount, getAccessSchoolCount, getAccessDormitoryCount, getBussinessSchoolList ,getVendingUseCount,getDeviceCount,getPeopleCount,getShowerUseCount} from "@/api/index";
import zhongguo from "@/assets/mapJson/data-city.json"
import * as echarts from "echarts";
import schoolIndex from '@/views/schoolIndex'
export default {
  name: "index",
  components: { schoolIndex },
  data() {
    return {
      openChart: false, // echarts弹窗
      titleChart: '',
      devInfo: {}, // 设备对象
      loading: '',
      user: {},
      timer: null,//定时器
      date: {
        year: null,
        month: null,
        date: null,
        day: null,
        hours: null,
        minutes: null,
        seconds: null
      },
      // 版本号
      version: "3.5.0",
      personType: '',
      infoList: [
        {
          name: '租户数（学校）',
          img: '@/assets/images/school.png',
          num: 1234
        },
        {
          name: '代理商',
          img: '@/assets/images/agent.png',
          num: 0
        }, {
          name: '校收收已绑定学生',
          img: '@/assets/images/xss.png',
          num: 521
        }
      ],
      monthsTalk: ['日', '月'],
      talkKey: '',
      agentNum: 0,
      options: [], // 有全部学校的学校列表
      schoolList: [], // 无全部学校的学校列表
      echartsBox: [
        { name: '智能公话使用统计', talkVal: '', schoolList: '', talkMonKey: true, talkMonIndex: '0' },
        { name: '智能公话充值统计', talkVal: '', schoolList: '', talkMonKey: true, talkMonIndex: '0' },
        { name: '校园团餐使用统计', talkVal: '', schoolList: '', talkMonKey: true, talkMonIndex: '0' },
        { name: '校园团餐营收统计', talkVal: '', schoolList: '', talkMonKey: true, talkMonIndex: '0' },
        { name: '校园图书借阅统计', talkVal: '', schoolList: '', talkMonKey: true, talkMonIndex: '0' },
        { name: '共享洗衣使用统计', talkVal: '', schoolList: '', talkMonKey: true, talkMonIndex: '0' },
        { name: '入离校门禁进出统计', talkVal: '', schoolList: '', talkMonKey: true, talkMonIndex: '0' },
        { name: '宿舍门禁进出统计', talkVal: '', schoolList: '', talkMonKey: true, talkMonIndex: '0' },
        { name: '自助售货机使用统计', talkVal: '', schoolList: '', talkMonKey: true, talkMonIndex: '0' },
        { name: '智慧淋浴使用统计', talkVal: '', schoolList: '', talkMonKey: true, talkMonIndex: '0' },
        { name: '共享吹风机使用统计', talkVal: '', schoolList: '', talkMonKey: true, talkMonIndex: '0' },
      ],
      deviceTotal:0,
    };
  },
  created() {
    this.personType = localStorage.getItem('personType')
    this.getUser()
  },
  mounted() {
    this.getSchool()
    this.timer = setInterval(() => {
      let now = new Date();
      this.date.year = now.getFullYear(); //  年份
      this.date.month = (now.getMonth() + 1) < 10 ? "0" + (now.getMonth() + 1) : (now.getMonth() + 1); //  月份，注意月份范围是0~11，5表示六月
      this.date.date = now.getDate() < 10 ? "0" + now.getDate() : now.getDate(); //  表示24号
      this.date.hours = now.getHours() < 10 ? "0" + now.getHours() : now.getHours(); // 19, 24小时制
      this.date.minutes = now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes(); // 49, 分钟
      this.date.seconds = now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds(); // 22, 秒
    }, 1000)
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
  methods: {
    // 个人信息
    getUser() {
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.8)",
      });
      getUserProfile().then(response => {
        this.user = response.data;
        this.loading.close();
      });
    },
    // 学校列表
    getSchool() {
      getBussinessSchoolList({}).then(res => {
        this.echartsBox.forEach(v => {

          switch (v.name) {
            case '智能公话使用统计':
              v.schoolList = res.data.phone?res.data.phone:[]
              v.talkVal = res.data.phone?res.data.phone[0].schoolId:''
              break;
            case '智能公话充值统计':
              v.schoolList = res.data.phone?res.data.phone:[]
              v.talkVal = res.data.phone?res.data.phone[0].schoolId:''
              break;
            case '校园团餐使用统计':
              v.schoolList = res.data.consumer?res.data.consumer:[]
              v.talkVal = res.data.consumer?res.data.consumer[0].schoolId:''
              break;
            case '校园团餐营收统计':
              v.schoolList = res.data.consumer?res.data.consumer:[]
              v.talkVal = res.data.consumer?res.data.consumer[0].schoolId:''
              break;
            case '校园图书借阅统计':
              v.schoolList = res.data.banpai?res.data.banpai:[]
              v.talkVal = res.data.banpai?res.data.banpai[0].schoolId:''
              break;
            case '共享洗衣使用统计':
              v.schoolList = res.data.washer?res.data.washer:[]
              v.talkVal = res.data.washer?res.data.washer[0].schoolId:''
              break;
            case '入离校门禁进出统计':
              v.schoolList = res.data.schoolGateAccess? res.data.schoolGateAccess:[]
              v.talkVal =res.data.schoolGateAccess?res.data.schoolGateAccess[0].schoolId:''
              break;
            case '宿舍门禁进出统计':
              v.schoolList = res.data.dormitoryAccess?res.data.dormitoryAccess:[]
              v.talkVal = res.data.dormitoryAccess?res.data.dormitoryAccess[0].schoolId:''
              break;
              case '自助售货机使用统计':
              v.schoolList = res.data.vending?res.data.vending:[]
              v.talkVal = res.data.vending?res.data.vending[0].schoolId:''
              break;
              case '智慧淋浴使用统计':
              v.schoolList = res.data.shower?res.data.shower:[]
              v.talkVal = res.data.shower?res.data.shower[0].schoolId:''
              break;
              case '共享吹风机使用统计':
              v.schoolList = res.data.airBlower?res.data.airBlower:[]
              v.talkVal = res.data.airBlower?res.data.airBlower[0].schoolId:''
              break;
            default:
              break;
          }
        })
        // this.schoolList = this.deepClone(res.data)
        // this.options = res.data
        // this.options.unshift({
        //   schoolId: '',
        //   schoolName: '全部学校'
        // })
        // this.echartsBox.forEach(v => {
        //   if (v.name == '校园团餐使用统计' || v.name == '校园团餐营收统计') {
        //     v.talkVal = this.schoolList[0].schoolId
        //   }
        // })
        this.getInit()
      })
    },
    // 深拷贝
    deepClone(obj) {
      if (typeof obj != "object") return obj;
      var temp = Array.isArray(obj) ? [] : {};
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (obj[key] && typeof obj[key] == "object") {
            // 如果obj[key]还是对象则执行递归
            temp[key] = this.deepClone(obj[key]); // 递归
          } else {
            temp[key] = obj[key];
          }
        }
      }
      return temp;
    },
    getInit() {
      this.getDevInfo()
      // 设备分布加载
      this.EqDistrEcharts()
      // 地图
      this.getSchoolEcharts()
      // 校园智能公话使用
      this.getTalkUse()
      // 校园智能公话充值
      this.getTalkPay()
      // 校园团餐使用统计
      this.getGroupEcharts()
      // 校园团餐营收统计
      this.getGroupPayEcharts()
      // 图书借阅
      this.getBooks()
      // 共享洗衣
      this.getWash()
      // 入离校
      this.getIsLeaveSchool()
      // 宿舍统计
      this.getIsLeaveLife()
      this.getVendingMachine()
      this.getShowerStatistics()
      this.getHairDryer()
    },
    getDevInfo(){
      getPeopleCount({}).then(res=>{
        console.log(res);
        this.devInfo = res.data
      })
    },
    // 选中学校
    schoolChange(e, i) {

      switch (i) {
        case 0:
          // console.log('查第一个图表的' + this.options[e].schoolName);
          this.getTalkUse()
          break;
        case 1:
          // console.log('查第二个图表的' + this.options[e].schoolName);
          this.getTalkPay()
          break;
        case 2:
          this.getGroupEcharts()
          break;
        case 3:
          this.getGroupPayEcharts()
          break;
        case 4:
          this.getBooks()
          break;
        case 5:
          this.getWash()
          break;
        case 6:
          this.getIsLeaveSchool()
          break;
        case 7:
          this.getIsLeaveLife()
          break;
        case 8:
          this.getVendingMachine()
          break;
          case 9:
          this.getShowerStatistics()
          break;
          case 10:
          this.getHairDryer()
          break;
        default:
          break;
      }
    },
    // 放大
    zoomChart(i) {
      this.openChart = true
      switch (i) {
        case 0:
          this.titleChart = '智能公话使用统计'
          break;
        case 1:
          this.titleChart = '智能公话充值统计'
          break;
        case 2:
          this.titleChart = '校园团餐使用统计'
          break;
        case 3:
          this.titleChart = '校园团餐营收统计'
          break;
        case 4:
          this.titleChart = '校园图书借阅统计'
          break;
        case 5:
          this.titleChart = '共享洗衣使用统计'
          break;
        case 6:
          this.titleChart = '入离校门禁进出统计'
          break;
        case 7:
          this.titleChart = '宿舍门禁进出统计'
          break;
            case 8:
          this.titleChart = '自助售货机使用统计'
          break;
          case 9:
          this.titleChart = '智慧淋浴使用统计'
          break; 
          case 10:
          this.titleChart = '共享吹风机使用统计'
          break;   
        default:
          break;
      }
    },
    // 弹窗展示echarts
    switchChart() {
      switch (this.titleChart) {
        case '智能公话使用统计':
          this.getTalkUseDialog()
          break;
        case '智能公话充值统计':
          this.getTalkPayDialog()
          break;
        case '校园团餐使用统计':
          this.getGroupEchartsDialog()
          break;
        case '校园团餐营收统计':
          this.getGroupPayEchartsDialog()
          break;
        case '校园图书借阅统计':
          this.getBooksDialog()
          break;
        case '共享洗衣使用统计':
          this.getWashDialog()
          break;
        case '入离校门禁进出统计':
          this.getIsLeaveSchoolDialog()
          break;
        case '宿舍门禁进出统计':
          this.getIsLeaveLifeDialog()
          break;
          case '自助售货机使用统计':
          this.getVendingMachineDialog()
          break;
          case '智慧淋浴使用统计':
          this.getShowerStatisticsDialog()
          break;
          case '共享吹风机使用统计':
          this.getHairDryerDialog()
          break;
          
        default:
          break;
      }
    },
    dialogClose() {

    },
    // 日月切换
    monthTalkClick(index, i) {
      // 判断当前是第几个图标
      this.echartsBox[index].talkMonKey = true
      // 选中的图标里 切换 日 or 月
      this.echartsBox[index].talkMonIndex = i + ''
      switch (index) {
        case 0:
          // 查对应图标
          console.log('查第一个图表的' + (i == 0 ? '日' : '月'));
          this.getTalkUse()
          break;
        case 1:
          console.log('查第二个图表' + (i == 0 ? '日' : '月'));
          this.getTalkPay()
          break;
        case 2:
          this.getGroupEcharts()
          break;
        case 3:
          this.getGroupPayEcharts()
          break;
        case 4:
          this.getBooks()
          break;
        case 5:
          this.getWash()
          break;
        case 6:
          this.getIsLeaveSchool()
          break;
        case 7:
          this.getIsLeaveLife()
          break;
          case 8:
          this.getVendingMachine()
          break;
          case 9:
          this.getShowerStatistics()
          break;
          case 10:
          this.getHairDryer()
          break;
          
        default:
          break;
      }
    },
    // 设备分布图
    EqDistrEcharts() {
      let datas = []
      let myChart = this.$echarts.init(
        document.getElementById("eqEchart")
      );
      /** 设备分布图*/
      getDeviceCount({}).then((res) => {
        this.deviceTotal = res.data.deviceTotal
        datas = res.data.deviceCount
        let option = {
          tooltip: {
            trigger: 'item',
            extraCssText: 'width: 145px;height: 70px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);',
            formatter: function (params) {
              // const str = `${params.marker}${params.data.name}<br/>${params.data.value}台设备`
              const str = `${params.marker}<span style='display:inline-block;font-size:13px;color:#333;font-weight:700;'>${params.data.name}</span><br/><span style='display:inline-block;font-size:12px;color:#767985;margin-left:12px;'>${params.data.value}台设备</span>`
              return str
            }
          },
          legend: {
            bottom: '10%',
            // left: 'center',
            orient: 'vertical',
            // // left: 'right',
            // top: 'bottom',
            // left: 'center',
            right: '5%',
            itemWidth: 14,
            itemHeight: 12,
            textStyle: {
              fontSize: 12,
              lineHeight: 20,
            
            }
          },
       
          series: [
            {
              // name: 'Access From',
              left: '-20%',
              type: 'pie',
              radius: ['30%', '60%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: false,
                  fontSize: 40,
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: false
              },
              itemStyle: {
                normal: {
                  color: function (colors) {
                    var colorList = [
                      '#0081FF',
                      '#FFBC55',
                      '#4BD2FF',
                      '#4CD6A6',
                      '#CC4BFF',
                      '#FF4BA2',
                      '#8A1BFF',
                      '#00998F',
                      '#6A4BFF',
                      
                    ];
                    return colorList[colors.dataIndex];
                  }
                }
              },
              data: datas
            }
          ]
        };
        myChart.setOption(option);
      });


    },
    // 地图
    getSchoolEcharts() {
      let myChart = this.$echarts.init(
        document.getElementById("schoolEchart")
      );
      getMaps({}).then((res) => {
        let datas = []
        res.data.forEach(v => {
          datas.push({
            name: v.schoolName,
            value: [v.locationLng, v.locationLat],
            agentName: v.agentName,
            deployDevice: v.deployDevice,
            xssStudentFaceCount: v.xssStudentFaceCount
          })
        });
        let option = {
          // 背景颜色
          // backgroundColor: "#404a59",
          // 提示浮窗样式

          tooltip: {
            show: true,
            extraCssText: 'max-width:220px;height:auto;background:#fff;box-shadow: 0px 0px 20px 0px rgba(92,90,120,0.33);padding:14px;',
            formatter(val) {
              console.log(val);
              return `${val.marker}<span style='display:inline-block;font-size:15px;color:#333;font-weight:700;white-space: pre-wrap;'>${val.data.name}</span><br/>` +
                `<span style='display:inline-block;font-size:14px;color:#666;margin:8px 0 0 12px;'>代理商：${val.data.agentName ? val.data.agentName : '无'}</span><br/>` +
                `<span style='display:inline-block;font-size:14px;color:#666;margin:8px 0 0 12px;'>校收收已绑定学生：${val.data.xssStudentFaceCount ? val.data.xssStudentFaceCount : 0}</span><br/>` +
                `<span style='display:inline-block;font-size:14px;color:#666;margin:8px 0 0 12px;white-space: pre-wrap;line-height: 22px;'>部署设备：${val.data.deployDevice ? val.data.deployDevice : ''}</span><br/>`
            },
            trigger: "item",
            alwaysShowContent: false,
            backgroundColor: "#0C121C",
            borderColor: "rgba(0, 0, 0, 0.16);",
            hideDelay: 100,
            triggerOn: "mousemove",
            enterable: true,
            textStyle: {
              color: "#DADADA",
              fontSize: "12",
              width: 20,
              height: 30,
              overflow: "break",
            },
            showDelay: 100
          },
          series: [
            {
              type: "effectScatter",
              coordinateSystem: "geo",
              effectType: "ripple",
              showEffectOn: "render",
              // 散点样式
              rippleEffect: {
                period: 1,
                scale: 1,
                brushType: "fill",
              },
              // 散点大小
              symbolSize: [10, 10],

              hoverAnimation: true,
              itemStyle: {
                normal: {
                  color: "#FFBD56",
                  shadowBlur: 3,
                  shadowColor: "#fff",
                },
              },
              zlevel: 1,
              data: datas
            },
          ],

          // 地图配置
          geo: {
            zoom: 1.5,
            roam: true, //支持拖拽缩放
            scaleLimit: {
              //滚轮缩放的极限控制
              min: 0.5, //缩放最小大小
              max: 100, //缩放最大大小
            },
            top: '20%', // 距离顶部
            map: "china",
            label: {
              // 通常状态下的样式
              normal: {
                show: false,
                textStyle: {
                  color: "#000",
                  fontSize: 10
                },
              },
              // 鼠标放上去的样式
              emphasis: {
                textStyle: {
                  color: "#fff",
                },
              },
            },
            // 地图区域的样式设置
            itemStyle: {
              normal: {
                borderColor: "#2262FF",
                borderWidth: 1.2,
                areaColor: {
                  type: "radial",
                  x: 0.5,
                  y: 0.5,
                  r: 0.8,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#DEEBFF", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#DEEBFF", // 100% 处的颜色
                    },
                  ],
                  globalCoord: false, // 缺省为 false
                },
                shadowColor: "#DEEBFF",
                shadowOffsetX: -2,
                shadowOffsetY: 2,
                shadowBlur: 10,
              },
              // 鼠标放上去高亮的样式
              emphasis: {
                areaColor: "#389BB7",
                borderWidth: 0,
              },
            },
          },
        }
        // 地图注册，第一个参数的名字必须和option.geo.map一致
        echarts.registerMap("china", zhongguo)
        myChart.setOption(option);
      })

    },
    // 智能公话使用
    getTalkUse() {
      let myChart = this.$echarts.init(
        document.getElementById("talkEcharts")
      );
      let data = {
        schoolId: this.echartsBox[0].talkVal,
        dayOrMonth: this.echartsBox[0].talkMonIndex
      }
      getVideoPhoneUseCount(data).then(res => {
        let xData = []
        res.data.dateList.forEach(v => {
          if (this.echartsBox[0].talkMonIndex == 0) {
            xData.push(
              v.substring(5, 10).replace(/-/g, "/")
            )
          } else {
            xData.push(
              v.substring(5) + '月'
            )
          }

        })
        // 指定图表的配置项和数据
        let option = {
          tooltip: {
            trigger: 'axis',
            extraCssText: 'width: 165px;height: 90px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
            axisPointer: {       //坐标轴指示器
              type: 'line',   //十字准星指示器
            },
            formatter: function (params) {
              console.log(params);
              // for (let i = 0; i < params.length; i++) {
              let res = `${params[0].marker}<span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name}</span><br/>`
              params.forEach(item => {
                res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName == '分钟' ? '通话分钟' : '使用人次'}&nbsp;&nbsp;&nbsp;${item.data}${item.seriesName == '分钟' ? '分钟' : '次'}</span><br/>`
              });
              return res
            }
          },
          legend: {
            icon: "circle",
            itemWidth: 12,  // 设置宽度
            itemHeight: 12, // 设置高度
            top: 20,
            data: ['分钟', '次数']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '10%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: true,
            axisLabel: {
              // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
              // interval: this.echartsBox[0].talkMonIndex == 0 ? 1 : 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
              interval: 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
              rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
              textStyle: {
                color: "#767985",
              }
            },
            data: xData,
            axisTick: {
              show: false // 不显示坐标轴刻度线
            },
            //  y轴刻度线
            axisLine: {
              show: true,
              lineStyle: {
                color: "#E5E6EB",
              }
            }
          },
          yAxis: [
            {
              type: 'value',
              name: '（分钟）',
              //坐标轴最大值、最小值、强制设置数据的步长间隔
              // interval: 5,
              axisLabel: {
                //y轴上带的单位
                formatter: '{value}',
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: { show: false },
              //分割线
              splitLine: {
                show: false,
                lineStyle: {
                  type: 'dashed', // 虚线
                  color: '#E5E6EB'
                }
              }
            },
            {
              minInterval: 1, // 最小单位是1
              type: 'value',
              name: '（次数）',
              axisLabel: {
                formatter: '{value}',
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: { show: false },
              //分割线
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed',
                  color: '#E5E6EB'
                }
              }
            }
          ],
          series: [
            {
              smooth: true,
              name: '分钟',
              type: 'line',
              symbolSize: 8, // 设置折线上圆点大小
              // symbol: 'circle', // 设置拐点为实心圆
              yAxisIndex: 0,
              data: res.data.durationList,
              itemStyle: {
                color: '#33D067',
              },
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      { offset: 0, color: " rgba(50,207,102,0.35)" },
                      // { offset: 0.5, color: "rgba(0,0,128,0.3)" },
                      { offset: 1, color: "rgba(50,207,102,0)" },
                    ]
                  )
                }
              },
            }, {
              smooth: true,
              name: '次数',
              type: 'line',
              symbolSize: 8, // 设置折线上圆点大小
              // symbol: 'circle', // 设置拐点为实心圆
              yAxisIndex: 1,
              data: res.data.numList,
              itemStyle: {
                color: '#FDBF5A',
              },
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      { offset: 0, color: "rgba(252,190,89,0.35)" },
                      // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                      { offset: 1, color: "rgba(252,190,89,0)" },
                    ]
                  )
                }
              },
            }
          ],
          dataZoom: [
            {
              show: true,
              height: 8, // 滚动条高度
              xAxisIndex: [0],
              bottom: 15, // 距离底部距离
              showDetail: false,
              showDataShadow: false,
              start: 0, //初始化时，滑动条宽度开始标度
              borderColor: "transparent",
              textStyle: {
                fontSize: 0
              },
              endValue: 10,//从0开始的相当于5个(x轴展示的数量)
              backgroundColor: "rgba(0,0,0,0)",
              borderWidth: 0,
              //                 handleIcon:
              //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
              //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
              //   handleSize: "0%",
              handleStyle: {
                color: "#d3dee5"
              }
            }
          ]
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
      })

    },
    // 智能公话使用-弹窗
    getTalkUseDialog() {
      let myChart = this.$echarts.init(
        document.getElementById("talkEchartsDialog")
      );
      let data = {
        schoolId: this.echartsBox[0].talkVal,
        dayOrMonth: this.echartsBox[0].talkMonIndex
      }
      getVideoPhoneUseCount(data).then(res => {
        let xData = []
        res.data.dateList.forEach(v => {
          if (this.echartsBox[0].talkMonIndex == 0) {
            xData.push(
              v.substring(5, 10).replace(/-/g, "/")
            )
          } else {
            xData.push(
              v.substring(5) + '月'
            )
          }

        })
        // 指定图表的配置项和数据
        let option = {
          tooltip: {
            trigger: 'axis',
            extraCssText: 'width: 165px;height: 90px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
            axisPointer: {       //坐标轴指示器
              type: 'line',   //十字准星指示器
            },
            formatter: function (params) {
              console.log(params);
              // for (let i = 0; i < params.length; i++) {
              let res = `${params[0].marker}<span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name}</span><br/>`
              params.forEach(item => {
                res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName == '分钟' ? '通话分钟' : '使用人次'}&nbsp;&nbsp;&nbsp;${item.data}${item.seriesName == '分钟' ? '分钟' : '次'}</span><br/>`
              });
              return res
            }
          },
          legend: {
            icon: "circle",
            itemWidth: 12,  // 设置宽度
            itemHeight: 12, // 设置高度
            top: 20,
            data: ['分钟', '次数']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '10%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: true,
            axisLabel: {
              // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
              // interval: this.echartsBox[0].talkMonIndex == 0 ? 1 : 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
              interval: 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
              rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
              textStyle: {
                color: "#767985",
              }
            },
            data: xData,
            axisTick: {
              show: false // 不显示坐标轴刻度线
            },
            //  y轴刻度线
            axisLine: {
              show: true,
              lineStyle: {
                color: "#E5E6EB",
              }
            }
          },
          yAxis: [
            {
              type: 'value',
              name: '（分钟）',
              //坐标轴最大值、最小值、强制设置数据的步长间隔
              // interval: 5,
              axisLabel: {
                //y轴上带的单位
                formatter: '{value}',
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: { show: false },
              //分割线
              splitLine: {
                show: false,
                lineStyle: {
                  type: 'dashed', // 虚线
                  color: '#E5E6EB'
                }
              }
            },
            {
              minInterval: 1, // 最小单位是1
              type: 'value',
              name: '（次数）',
              axisLabel: {
                formatter: '{value}',
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: { show: false },
              //分割线
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed',
                  color: '#E5E6EB'
                }
              }
            }
          ],
          series: [
            {
              smooth: true,
              name: '分钟',
              type: 'line',
              symbolSize: 8, // 设置折线上圆点大小
              // symbol: 'circle', // 设置拐点为实心圆
              yAxisIndex: 0,
              data: res.data.durationList,
              itemStyle: {
                color: '#33D067',
              },
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      { offset: 0, color: " rgba(50,207,102,0.35)" },
                      // { offset: 0.5, color: "rgba(0,0,128,0.3)" },
                      { offset: 1, color: "rgba(50,207,102,0)" },
                    ]
                  )
                }
              },
            }, {
              smooth: true,
              name: '次数',
              type: 'line',
              symbolSize: 8, // 设置折线上圆点大小
              // symbol: 'circle', // 设置拐点为实心圆
              yAxisIndex: 1,
              data: res.data.numList,
              itemStyle: {
                color: '#FDBF5A',
              },
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      { offset: 0, color: "rgba(252,190,89,0.35)" },
                      // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                      { offset: 1, color: "rgba(252,190,89,0)" },
                    ]
                  )
                }
              },
            }
          ],
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
      })

    },
    // 智能公话充值
    getTalkPay() {

      let myChart = this.$echarts.init(
        document.getElementById("talkPayEcharts")
      );
      let data = {
        schoolId: this.echartsBox[1].talkVal,
        dayOrMonth: this.echartsBox[1].talkMonIndex
      }
      getVideoPhoneTopUpCount(data).then(res => {
        let xData = []

        res.data.dateList.forEach(v => {
          if (this.echartsBox[1].talkMonIndex == 0) {
            xData.push(
              v.substring(5, 10).replace(/-/g, "/")
            )
          } else {
            xData.push(
              v.substring(5) + '月'
            )
          }

        })
        // 指定图表的配置项和数据
        let option = {
          tooltip: {
            trigger: 'axis',
            extraCssText: 'width: 188px;height: 90px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
            axisPointer: {       //坐标轴指示器
              type: 'line',   //十字准星指示器
            },
            formatter: function (params) {
              console.log(params);
              // for (let i = 0; i < params.length; i++) {
              let res = `${params[0].marker}<span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name}</span><br/>`
              params.forEach(item => {
                res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName == '金额' ? '充值总金额' : '充值人数'}&nbsp;&nbsp;&nbsp;${item.data}${item.seriesName == '金额' ? '元' : '人'}</span><br/>`
              });
              return res
            }
          },
          legend: {
            icon: "circle",
            itemWidth: 12,  // 设置宽度
            itemHeight: 12, // 设置高度
            top: 20,
            data: ['金额', '人数']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '10%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: true,
            axisLabel: {
              // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
              // interval: this.echartsBox[1].talkMonIndex == 0 ? 1 : 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
              interval: 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
              rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
              textStyle: {
                color: "#767985",
              }
            },
            data: xData,
            axisTick: {
              show: false // 不显示坐标轴刻度线
            },
            //  y轴刻度线
            axisLine: {
              show: true,
              lineStyle: {
                color: "#E5E6EB",
              }
            }
          },
          yAxis: [
            {

              type: 'value',
              name: '（金额）',
              //坐标轴最大值、最小值、强制设置数据的步长间隔
              // interval: 5,
              axisLabel: {
                //y轴上带的单位
                formatter: '{value}',
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: { show: false },
              //分割线
              splitLine: {
                show: false,
                lineStyle: {
                  type: 'dashed',
                  color: '#E5E6EB'
                }
              }
            },
            {
              minInterval: 1, // 最小单位是1
              type: 'value',
              name: '（人数）',
              axisLabel: {
                formatter: '{value}',
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: { show: false },
              //分割线
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed',
                  color: '#E5E6EB'
                }
              }
            }
          ],
          series: [
            {
              smooth: true,
              name: '金额',
              type: 'line',
              symbolSize: 8, // 设置折线上圆点大小
              // symbol: 'circle', // 设置拐点为实心圆
              yAxisIndex: 0,
              data: res.data.consumptionList,
              itemStyle: {
                color: '#33D067',
              },
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      { offset: 0, color: " rgba(50,207,102,0.35)" },
                      // { offset: 0.5, color: "rgba(0,0,128,0.3)" },
                      { offset: 1, color: "rgba(50,207,102,0)" },
                    ]
                  )
                }
              },
            }, {
              smooth: true,
              name: '人数',
              type: 'line',
              symbolSize: 8, // 设置折线上圆点大小
              // symbol: 'circle', // 设置拐点为实心圆
              yAxisIndex: 1,
              data: res.data.numList,
              itemStyle: {
                color: '#FDBF5A',
              },
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      { offset: 0, color: "rgba(252,190,89,0.35)" },
                      // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                      { offset: 1, color: "rgba(252,190,89,0)" },
                    ]
                  )
                }
              },
            }
          ],
          dataZoom: [
            {
              show: true,
              height: 8, // 滚动条高度
              xAxisIndex: [0],
              bottom: 15, // 距离底部距离
              showDetail: false,
              showDataShadow: false,
              start: 0, //初始化时，滑动条宽度开始标度
              borderColor: "transparent",
              textStyle: {
                fontSize: 0
              },
              endValue: 10,//从0开始的相当于5个(x轴展示的数量)
              backgroundColor: "rgba(0,0,0,0)",
              borderWidth: 0,
              //                 handleIcon:
              //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
              //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
              //   handleSize: "0%",
              handleStyle: {
                color: "#d3dee5"
              }
            }
          ]
        };

        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
      })

    },
    // 智能公话充值-弹窗
    getTalkPayDialog() {
      let myChart = this.$echarts.init(
        document.getElementById("talkPayEchartsDialog")
      );
      let data = {
        schoolId: this.echartsBox[1].talkVal,
        dayOrMonth: this.echartsBox[1].talkMonIndex
      }
      getVideoPhoneTopUpCount(data).then(res => {
        let xData = []

        res.data.dateList.forEach(v => {
          if (this.echartsBox[1].talkMonIndex == 0) {
            xData.push(
              v.substring(5, 10).replace(/-/g, "/")
            )
          } else {
            xData.push(
              v.substring(5) + '月'
            )
          }

        })
        // 指定图表的配置项和数据
        let option = {
          tooltip: {
            trigger: 'axis',
            extraCssText: 'width: 188px;height: 90px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
            axisPointer: {       //坐标轴指示器
              type: 'line',   //十字准星指示器
            },
            formatter: function (params) {
              console.log(params);
              // for (let i = 0; i < params.length; i++) {
              let res = `${params[0].marker}<span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name}</span><br/>`
              params.forEach(item => {
                res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName == '金额' ? '充值总金额' : '充值人数'}&nbsp;&nbsp;&nbsp;${item.data}${item.seriesName == '金额' ? '元' : '人'}</span><br/>`
              });
              return res
            }
          },
          legend: {
            icon: "circle",
            itemWidth: 12,  // 设置宽度
            itemHeight: 12, // 设置高度
            top: 20,
            data: ['金额', '人数']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '10%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: true,
            axisLabel: {
              // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
              // interval: this.echartsBox[1].talkMonIndex == 0 ? 1 : 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
              interval: 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
              rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
              textStyle: {
                color: "#767985",
              }
            },
            data: xData,
            axisTick: {
              show: false // 不显示坐标轴刻度线
            },
            //  y轴刻度线
            axisLine: {
              show: true,
              lineStyle: {
                color: "#E5E6EB",
              }
            }
          },
          yAxis: [
            {

              type: 'value',
              name: '（金额）',
              //坐标轴最大值、最小值、强制设置数据的步长间隔
              // interval: 5,
              axisLabel: {
                //y轴上带的单位
                formatter: '{value}',
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: { show: false },
              //分割线
              splitLine: {
                show: false,
                lineStyle: {
                  type: 'dashed',
                  color: '#E5E6EB'
                }
              }
            },
            {
              minInterval: 1, // 最小单位是1
              type: 'value',
              name: '（人数）',
              axisLabel: {
                formatter: '{value}',
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: { show: false },
              //分割线
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed',
                  color: '#E5E6EB'
                }
              }
            }
          ],
          series: [
            {
              smooth: true,
              name: '金额',
              type: 'line',
              symbolSize: 8, // 设置折线上圆点大小
              // symbol: 'circle', // 设置拐点为实心圆
              yAxisIndex: 0,
              data: res.data.consumptionList,
              itemStyle: {
                color: '#33D067',
              },
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      { offset: 0, color: " rgba(50,207,102,0.35)" },
                      // { offset: 0.5, color: "rgba(0,0,128,0.3)" },
                      { offset: 1, color: "rgba(50,207,102,0)" },
                    ]
                  )
                }
              },
            }, {
              smooth: true,
              name: '人数',
              type: 'line',
              symbolSize: 8, // 设置折线上圆点大小
              // symbol: 'circle', // 设置拐点为实心圆
              yAxisIndex: 1,
              data: res.data.numList,
              itemStyle: {
                color: '#FDBF5A',
              },
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      { offset: 0, color: "rgba(252,190,89,0.35)" },
                      // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                      { offset: 1, color: "rgba(252,190,89,0)" },
                    ]
                  )
                }
              },
            }
          ],
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
      })
    },
    // 校园团餐使用统计
    getGroupEcharts() {
      let myChart = this.$echarts.init(
        document.getElementById("groupEcharts")
      );
      let data = {
        schoolId: this.echartsBox[2].talkVal,
        dayOrMonth: this.echartsBox[2].talkMonIndex
      }
      getMealMachineUseCount(data).then(res => {
        let xData = []
        res.data.dateList.forEach(v => {

          if (this.echartsBox[2].talkMonIndex == 0) {
            xData.push(
              v.substring(5, 10).replace(/-/g, "/")
            )
          } else {
            xData.push(
              v.substring(5) + '月'
            )
          }
        })
        let option = {
          tooltip: {
            trigger: 'axis',
            extraCssText: 'width: 165px;height: 138px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
            axisPointer: {       //坐标轴指示器
              type: 'line',   //十字准星指示器
            },
            formatter: function (params) {
              let total = 0
              params.forEach(v => {
                total += (v.value - 0)
              })
              let res = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#4B93FF;" ></span ><span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name}</span><br/>` +
                `<span style="font-size:14px;color:#333;">刷脸次数 &nbsp;&nbsp; 共${total}次</span><br/>`
              params.reverse().forEach(item => {
                res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ${item.value}次</span><br/>`
              });
              return res
            }
          },
          legend: {
            icon: "circle",
            itemWidth: 12,  // 设置宽度
            itemHeight: 12, // 设置高度
            top: 20,
            data: ['早', '中', '晚']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '10%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: xData,
              axisLabel: {
                // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
                interval: 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
                rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#E5E6EB",
                }
              }
            }
          ],
          yAxis: [
            {
              minInterval: 1, // 最小单位是1
              name: '（次）',
              type: 'value',
              axisLabel: {
                formatter: '{value}',
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: { show: false },
              //分割线
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed',
                  color: '#E5E6EB'
                }
              }
            }
          ],
          series: [
            {
              name: '晚',
              type: 'bar',
              stack: 'Ad',
              barWidth: "16", // 设置柱子的宽度
              emphasis: {
                focus: 'series'
              },
              data: res.data.dinnerList,
              itemStyle: {
                // barBorderRadius: [0, 0, 12, 12],
                color: {
                  // type: "linear",
                  // x: 0, // 右
                  // y: 1, // 下
                  // x2: 0, // 左
                  // y2: 0, // 上
                  colorStops: [
                    {
                      offset: 0,
                      color: "#4B93FF", // 0% 处的颜色
                    },
                    {
                      offset: 0.9,
                      color: "#4B93FF", // 90% 处的颜色
                    },
                  ],
                },
              },
            },
            {
              name: '中',
              type: 'bar',
              barWidth: "16", // 设置柱子的宽度
              stack: 'Ad',
              emphasis: {
                focus: 'series'
              },
              data: res.data.lunchList,
              itemStyle: {
                // barBorderRadius: [12, 12, 12, 12],
                color: {
                  // type: "linear",
                  // x: 0, // 右
                  // y: 1, // 下
                  // x2: 0, // 左
                  // y2: 0, // 上
                  colorStops: [
                    {
                      offset: 0,
                      color: "#4CD6A6", // 0% 处的颜色
                    },
                    {
                      offset: 0.9,
                      color: "#4CD6A6", // 90% 处的颜色
                    },
                  ],
                },
              },
            },
            {
              name: '早',
              type: 'bar',
              stack: 'Ad',
              barWidth: "16", // 设置柱子的宽度
              emphasis: {
                focus: 'series'
              },
              data: res.data.breakfastList,
              itemStyle: {
                barBorderRadius: [12, 12, 0, 0],
                color: {
                  // type: "linear",
                  // x: 0, // 右
                  // y: 1, // 下
                  // x2: 0, // 左
                  // y2: 0, // 上
                  colorStops: [
                    {
                      offset: 0,
                      color: "#F8BC5B", // 0% 处的颜色
                    },
                    {
                      offset: 0.9,
                      color: "#F8BC5B", // 90% 处的颜色
                    },
                  ],
                },
              },
            },

          ],
          dataZoom: [
            {
              show: true,
              height: 8, // 滚动条高度
              xAxisIndex: [0],
              bottom: 15, // 距离底部距离
              showDetail: false,
              showDataShadow: false,
              start: 0, //初始化时，滑动条宽度开始标度
              borderColor: "transparent",
              textStyle: {
                fontSize: 0
              },
              endValue: 10,//从0开始的相当于5个(x轴展示的数量)
              backgroundColor: "rgba(0,0,0,0)",
              borderWidth: 0,
              //                 handleIcon:
              //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
              //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
              //   handleSize: "0%",
              handleStyle: {
                color: "#d3dee5"
              }
            }
          ]
        }
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
      })

    },
    // 校园团餐使用统计-弹窗
    getGroupEchartsDialog() {
      let myChart = this.$echarts.init(
        document.getElementById("groupEchartsDialog")
      );
      let data = {
        schoolId: this.echartsBox[2].talkVal,
        dayOrMonth: this.echartsBox[2].talkMonIndex
      }
      getMealMachineUseCount(data).then(res => {
        let xData = []
        res.data.dateList.forEach(v => {

          if (this.echartsBox[2].talkMonIndex == 0) {
            xData.push(
              v.substring(5, 10).replace(/-/g, "/")
            )
          } else {
            xData.push(
              v.substring(5) + '月'
            )
          }
        })
        let option = {
          tooltip: {
            trigger: 'axis',
            extraCssText: 'width: 165px;height: 138px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
            axisPointer: {       //坐标轴指示器
              type: 'line',   //十字准星指示器
            },
            formatter: function (params) {
              let total = 0
              params.forEach(v => {
                total += (v.value - 0)
              })
              let res = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#4B93FF;" ></span ><span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name}</span><br/>` +
                `<span style="font-size:14px;color:#333;">刷脸次数 &nbsp;&nbsp; 共${total}次</span><br/>`
              params.reverse().forEach(item => {
                res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ${item.value}次</span><br/>`
              });
              return res
            }
          },
          legend: {
            icon: "circle",
            itemWidth: 12,  // 设置宽度
            itemHeight: 12, // 设置高度
            top: 20,
            data: ['早', '中', '晚']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '10%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: xData,
              axisLabel: {
                // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
                interval: 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
                rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#E5E6EB",
                }
              }
            }
          ],
          yAxis: [
            {
              minInterval: 1, // 最小单位是1
              name: '（次）',
              type: 'value',
              axisLabel: {
                formatter: '{value}',
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: { show: false },
              //分割线
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed',
                  color: '#E5E6EB'
                }
              }
            }
          ],
          series: [
            {
              name: '晚',
              type: 'bar',
              stack: 'Ad',
              barWidth: "16", // 设置柱子的宽度
              emphasis: {
                focus: 'series'
              },
              data: res.data.dinnerList,
              itemStyle: {
                // barBorderRadius: [0, 0, 12, 12],
                color: {
                  // type: "linear",
                  // x: 0, // 右
                  // y: 1, // 下
                  // x2: 0, // 左
                  // y2: 0, // 上
                  colorStops: [
                    {
                      offset: 0,
                      color: "#4B93FF", // 0% 处的颜色
                    },
                    {
                      offset: 0.9,
                      color: "#4B93FF", // 90% 处的颜色
                    },
                  ],
                },
              },
            },
            {
              name: '中',
              type: 'bar',
              barWidth: "16", // 设置柱子的宽度
              stack: 'Ad',
              emphasis: {
                focus: 'series'
              },
              data: res.data.lunchList,
              itemStyle: {
                // barBorderRadius: [12, 12, 12, 12],
                color: {
                  // type: "linear",
                  // x: 0, // 右
                  // y: 1, // 下
                  // x2: 0, // 左
                  // y2: 0, // 上
                  colorStops: [
                    {
                      offset: 0,
                      color: "#4CD6A6", // 0% 处的颜色
                    },
                    {
                      offset: 0.9,
                      color: "#4CD6A6", // 90% 处的颜色
                    },
                  ],
                },
              },
            },
            {
              name: '早',
              type: 'bar',
              stack: 'Ad',
              barWidth: "16", // 设置柱子的宽度
              emphasis: {
                focus: 'series'
              },
              data: res.data.breakfastList,
              itemStyle: {
                barBorderRadius: [12, 12, 0, 0],
                color: {
                  // type: "linear",
                  // x: 0, // 右
                  // y: 1, // 下
                  // x2: 0, // 左
                  // y2: 0, // 上
                  colorStops: [
                    {
                      offset: 0,
                      color: "#F8BC5B", // 0% 处的颜色
                    },
                    {
                      offset: 0.9,
                      color: "#F8BC5B", // 90% 处的颜色
                    },
                  ],
                },
              },
            },

          ],
        }
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
      })

    },
    // 校园团餐营收统计
    getGroupPayEcharts() {
      let myChart = this.$echarts.init(
        document.getElementById("groupPayEcharts")
      );
      let data = {
        schoolId: this.echartsBox[3].talkVal,
        dayOrMonth: this.echartsBox[3].talkMonIndex
      }
      getMealMachineRevenueCount(data).then(res => {
        let xData = []
        res.data.dateList.forEach(v => {

          if (this.echartsBox[3].talkMonIndex == 0) {
            xData.push(
              v.substring(5, 10).replace(/-/g, "/")
            )
          } else {
            xData.push(
              v.substring(5) + '月'
            )
          }
        })

        let option = {
          tooltip: {
            trigger: 'axis',
            extraCssText: 'width: 165px;height: 138px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
            axisPointer: {       //坐标轴指示器
              type: 'line',   //十字准星指示器
            },
            formatter: function (params) {
              let total = 0
              params.forEach(v => {
                total += (v.value - 0)
              })
              let res = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#4B93FF;" ></span ><span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name}</span><br/>` +
                `<span style="font-size:14px;color:#333;">总消费 &nbsp;&nbsp;&nbsp;&nbsp; 共${params[0].data.allMachPrice ? params[0].data.allMachPrice : 0.00}元</span><br/>`
              params.reverse().forEach(item => {
                res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ${item.value}元</span><br/>`
              });
              return res
            }
          },
          legend: {
            icon: "circle",
            itemWidth: 12,  // 设置宽度
            itemHeight: 12, // 设置高度
            top: 20,
            data: ['早', '中', '晚']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '10%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: xData,
              axisLabel: {
                // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
                interval: 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
                rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#E5E6EB",
                }
              }
            }
          ],
          yAxis: [
            {
              name: '（元）',
              type: 'value',
              axisLabel: {
                formatter: '{value}',
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: { show: false },
              //分割线
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed',
                  color: '#E5E6EB'
                }
              }
            }
          ],
          series: [
            {
              name: '晚',
              type: 'bar',
              barWidth: "16", // 设置柱子的宽度
              stack: 'Ad',
              emphasis: {
                focus: 'series'
              },
              data: res.data.dinnerPriceList,
              itemStyle: {
                // barBorderRadius: [0, 0, 12, 12],
                color: {
                  // type: "linear",
                  // x: 0, // 右
                  // y: 1, // 下
                  // x2: 0, // 左
                  // y2: 0, // 上
                  colorStops: [
                    {
                      offset: 0,
                      color: "#4B93FF", // 0% 处的颜色
                    },
                    {
                      offset: 0.9,
                      color: "#4B93FF", // 90% 处的颜色
                    },
                  ],
                },
              },
            },
            {
              name: '中',
              type: 'bar',
              stack: 'Ad',
              barWidth: "16", // 设置柱子的宽度
              emphasis: {
                focus: 'series'
              },
              data: res.data.lunchPriceList,
              itemStyle: {
                // barBorderRadius: [12, 12, 12, 12],
                color: {
                  // type: "linear",
                  // x: 0, // 右
                  // y: 1, // 下
                  // x2: 0, // 左
                  // y2: 0, // 上
                  colorStops: [
                    {
                      offset: 0,
                      color: "#4CD6A6", // 0% 处的颜色
                    },
                    {
                      offset: 0.9,
                      color: "#4CD6A6", // 90% 处的颜色
                    },
                  ],
                },
              },
            },
            {
              name: '早',
              type: 'bar',
              stack: 'Ad',
              barWidth: "16", // 设置柱子的宽度
              emphasis: {
                focus: 'series'
              },
              data: res.data.breakfastPriceList,
              itemStyle: {
                barBorderRadius: [12, 12, 0, 0],
                color: {
                  // type: "linear",
                  // x: 0, // 右
                  // y: 1, // 下
                  // x2: 0, // 左
                  // y2: 0, // 上
                  colorStops: [
                    {
                      offset: 0,
                      color: "#F8BC5B", // 0% 处的颜色
                    },
                    {
                      offset: 0.9,
                      color: "#F8BC5B", // 90% 处的颜色
                    },
                  ],
                },
              },
            },
          ],
          dataZoom: [
            {
              show: true,
              height: 8, // 滚动条高度
              xAxisIndex: [0],
              bottom: 15, // 距离底部距离
              showDetail: false,
              showDataShadow: false,
              start: 0, //初始化时，滑动条宽度开始标度
              borderColor: "transparent",
              textStyle: {
                fontSize: 0
              },
              endValue: 10,//从0开始的相当于5个(x轴展示的数量)
              backgroundColor: "rgba(0,0,0,0)",
              borderWidth: 0,
              //                 handleIcon:
              //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
              //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
              //   handleSize: "0%",
              handleStyle: {
                color: "#d3dee5"
              }
            }
          ]
        }
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
      })
    },
    // 校园团餐营收统计-弹窗
    getGroupPayEchartsDialog() {
      let myChart = this.$echarts.init(
        document.getElementById("groupPayEchartsDialog")
      );
      let data = {
        schoolId: this.echartsBox[3].talkVal,
        dayOrMonth: this.echartsBox[3].talkMonIndex
      }
      getMealMachineRevenueCount(data).then(res => {
        let xData = []
        res.data.dateList.forEach(v => {

          if (this.echartsBox[3].talkMonIndex == 0) {
            xData.push(
              v.substring(5, 10).replace(/-/g, "/")
            )
          } else {
            xData.push(
              v.substring(5) + '月'
            )
          }
        })

        let option = {
          tooltip: {
            trigger: 'axis',
            extraCssText: 'width: 165px;height: 138px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
            axisPointer: {       //坐标轴指示器
              type: 'line',   //十字准星指示器
            },
            formatter: function (params) {
              let total = 0
              params.forEach(v => {
                total += (v.value - 0)
              })
              let res = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#4B93FF;" ></span ><span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name}</span><br/>` +
                `<span style="font-size:14px;color:#333;">总消费 &nbsp;&nbsp;&nbsp;&nbsp; 共${params[0].data.allMachPrice ? params[0].data.allMachPrice : 0.00}元</span><br/>`
              params.reverse().forEach(item => {
                res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ${item.value}元</span><br/>`
              });
              return res
            }
          },
          legend: {
            icon: "circle",
            itemWidth: 12,  // 设置宽度
            itemHeight: 12, // 设置高度
            top: 20,
            data: ['早', '中', '晚']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '10%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: xData,
              axisLabel: {
                // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
                interval: 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
                rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#E5E6EB",
                }
              }
            }
          ],
          yAxis: [
            {
              name: '（元）',
              type: 'value',
              axisLabel: {
                formatter: '{value}',
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: { show: false },
              //分割线
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed',
                  color: '#E5E6EB'
                }
              }
            }
          ],
          series: [
            {
              name: '晚',
              type: 'bar',
              barWidth: "16", // 设置柱子的宽度
              stack: 'Ad',
              emphasis: {
                focus: 'series'
              },
              data: res.data.dinnerPriceList,
              itemStyle: {
                // barBorderRadius: [0, 0, 12, 12],
                color: {
                  // type: "linear",
                  // x: 0, // 右
                  // y: 1, // 下
                  // x2: 0, // 左
                  // y2: 0, // 上
                  colorStops: [
                    {
                      offset: 0,
                      color: "#4B93FF", // 0% 处的颜色
                    },
                    {
                      offset: 0.9,
                      color: "#4B93FF", // 90% 处的颜色
                    },
                  ],
                },
              },
            },
            {
              name: '中',
              type: 'bar',
              stack: 'Ad',
              barWidth: "16", // 设置柱子的宽度
              emphasis: {
                focus: 'series'
              },
              data: res.data.lunchPriceList,
              itemStyle: {
                // barBorderRadius: [12, 12, 12, 12],
                color: {
                  // type: "linear",
                  // x: 0, // 右
                  // y: 1, // 下
                  // x2: 0, // 左
                  // y2: 0, // 上
                  colorStops: [
                    {
                      offset: 0,
                      color: "#4CD6A6", // 0% 处的颜色
                    },
                    {
                      offset: 0.9,
                      color: "#4CD6A6", // 90% 处的颜色
                    },
                  ],
                },
              },
            },
            {
              name: '早',
              type: 'bar',
              stack: 'Ad',
              barWidth: "16", // 设置柱子的宽度
              emphasis: {
                focus: 'series'
              },
              data: res.data.breakfastPriceList,
              itemStyle: {
                barBorderRadius: [12, 12, 0, 0],
                color: {
                  // type: "linear",
                  // x: 0, // 右
                  // y: 1, // 下
                  // x2: 0, // 左
                  // y2: 0, // 上
                  colorStops: [
                    {
                      offset: 0,
                      color: "#F8BC5B", // 0% 处的颜色
                    },
                    {
                      offset: 0.9,
                      color: "#F8BC5B", // 90% 处的颜色
                    },
                  ],
                },
              },
            },
          ]
        }
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
      })
    },
    // 图书借阅
    getBooks() {
      let myChart = this.$echarts.init(
        document.getElementById("bookEchart")
      );
      let data = {
        schoolId: this.echartsBox[4].talkVal,
        dayOrMonth: this.echartsBox[4].talkMonIndex
      }
      getBookSchoolBorrowCount(data).then(res => {
        let xData = []
        res.data.dateList.forEach(v => {

          if (this.echartsBox[4].talkMonIndex == 0) {
            xData.push(
              v.substring(5, 10).replace(/-/g, "/")
            )
          } else {
            xData.push(
              v.substring(5) + '月'
            )
          }
        })
        let option = {
          tooltip: {
            trigger: 'axis',
            extraCssText: 'width: 165px;height: 66px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
            axisPointer: {       //坐标轴指示器
              type: 'line',   //十字准星指示器
            },
            formatter: function (params) {
              return `${params[0].marker}<span style="font-size:12px;color:#767985;">${params[0].name}</span>` + '<br/>' +
                `<span style="color:#333;font-size:14px;margin-left:12px;">借阅图书 &nbsp;&nbsp; ${params[0].value}本</span>`
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: true,
            data: xData,
            axisLabel: {
              // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
              interval: 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
              rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
              textStyle: {
                color: "#767985",
              }
            },
            axisTick: {
              show: false // 不显示坐标轴刻度线
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#E5E6EB",
              }
            },
          },
          yAxis: {
            name: '（本）',
            type: 'value',
            minInterval: 1, // 最小单位是1
            axisLabel: {
              formatter: '{value}',
              textStyle: {
                color: "#767985",
              }
            },
            axisTick: {
              show: false // 不显示坐标轴刻度线
            },
            axisLine: {
              show: false, // 不显示坐标轴线
            },
            //分割线
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color: '#E5E6EB'
              }
            }
          },
          series: [
            {
              itemStyle: {
                normal: { // 设置线条的style等
                  color: '#0267FE',
                  lineStyle: {
                    color: '#0267FE', // 折线线条颜色
                  },
                  label: {
                    show: true
                  }
                }
              },
              data: res.data.borrowList, // this.yAxis[0,0,0,0,0,0,0,0,0,21]
              type: 'line',
              symbolSize: 10,   //设定中心点的大小
              areaStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                    offset: 0, color: 'rgba(2,103,254,0.3)' // 0% 处的颜色
                  }, {
                    offset: 1, color: 'rgba(2,103,254,0)' // 100% 处的颜色
                  }],
                  global: false // 缺省为 false
                }
              },
              smooth: true
            },
          ],
          dataZoom: [
            {
              show: true,
              height: 8, // 滚动条高度
              xAxisIndex: [0],
              bottom: 15, // 距离底部距离
              showDetail: false,
              start: 0, //初始化时，滑动条宽度开始标度
              showDataShadow: false,
              borderColor: "transparent",
              textStyle: {
                fontSize: 0
              },
              endValue: 10,//从0开始的相当于5个(x轴展示的数量)
              backgroundColor: "rgba(0,0,0,0)",
              borderWidth: 0,
              //                 handleIcon:
              //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
              //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
              //   handleSize: "0%",
              handleStyle: {
                color: "#d3dee5"
              }
            }
          ]
        };
        myChart.setOption(option);
      })
    },
    // 图书借阅-弹窗
    getBooksDialog() {
      let myChart = this.$echarts.init(
        document.getElementById("bookEchartDialog")
      );
      let data = {
        schoolId: this.echartsBox[4].talkVal,
        dayOrMonth: this.echartsBox[4].talkMonIndex
      }
      getBookSchoolBorrowCount(data).then(res => {
        let xData = []
        res.data.dateList.forEach(v => {

          if (this.echartsBox[4].talkMonIndex == 0) {
            xData.push(
              v.substring(5, 10).replace(/-/g, "/")
            )
          } else {
            xData.push(
              v.substring(5) + '月'
            )
          }
        })
        let option = {
          tooltip: {
            trigger: 'axis',
            extraCssText: 'width: 165px;height: 66px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
            axisPointer: {       //坐标轴指示器
              type: 'line',   //十字准星指示器
            },
            formatter: function (params) {
              return `${params[0].marker}<span style="font-size:12px;color:#767985;">${params[0].name}</span>` + '<br/>' +
                `<span style="color:#333;font-size:14px;margin-left:12px;">借阅图书 &nbsp;&nbsp; ${params[0].value}本</span>`
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: true,
            data: xData,
            axisLabel: {
              // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
              interval: 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
              rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
              textStyle: {
                color: "#767985",
              }
            },
            axisTick: {
              show: false // 不显示坐标轴刻度线
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#E5E6EB",
              }
            },
          },
          yAxis: {
            name: '（本）',
            type: 'value',
            minInterval: 1, // 最小单位是1
            axisLabel: {
              formatter: '{value}',
              textStyle: {
                color: "#767985",
              }
            },
            axisTick: {
              show: false // 不显示坐标轴刻度线
            },
            axisLine: {
              show: false, // 不显示坐标轴线
            },
            //分割线
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color: '#E5E6EB'
              }
            }
          },
          series: [
            {
              itemStyle: {
                normal: { // 设置线条的style等
                  color: '#0267FE',
                  lineStyle: {
                    color: '#0267FE', // 折线线条颜色
                  },
                  label: {
                    show: true
                  }
                }
              },
              data: res.data.borrowList, // this.yAxis[0,0,0,0,0,0,0,0,0,21]
              type: 'line',
              symbolSize: 10,   //设定中心点的大小
              areaStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                    offset: 0, color: 'rgba(2,103,254,0.3)' // 0% 处的颜色
                  }, {
                    offset: 1, color: 'rgba(2,103,254,0)' // 100% 处的颜色
                  }],
                  global: false // 缺省为 false
                }
              },
              smooth: true
            },
          ]
        };
        myChart.setOption(option);
      })
    },
    // 共享洗衣
    getWash() {
      let myChart = this.$echarts.init(
        document.getElementById("washEchart")
      );
      let data = {
        orderType: 1,
        schoolId: this.echartsBox[5].talkVal,
        dayOrMonth: this.echartsBox[5].talkMonIndex
      }
      getWashingUseCount(data).then(res => {
        let xData = []
        res.data.dateList.forEach(v => {

          if (this.echartsBox[5].talkMonIndex == 0) {
            xData.push(
              v.substring(5, 10).replace(/-/g, "/")
            )
          } else {
            xData.push(
              v.substring(5) + '月'
            )
          }
        })
        let option = {
          tooltip: {
            trigger: 'axis',
            extraCssText: 'width: 178px;height: 100px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
            axisPointer: {       //坐标轴指示器
              type: 'line',   //十字准星指示器
            },
            formatter: function (params) {
              let res = `${params[0].marker}<span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name}</span><br/>`
              params.forEach(item => {
                if (item.data.studentCount) { }
                res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName == '金额' ? '营收金额' : '使用次数'}&nbsp;&nbsp;&nbsp;${item.value}${item.seriesName == '金额' ? '元' : '次'}</span><br/>` +
                  `${item.data.studentCount ? `<span style="font-size:12px;color:#333;margin-left:12px;">教师：${item.data.teacherCount}次&nbsp;&nbsp;学生：${item.data.studentCount}次</span><br/>` : ''}`
              });
              return res
            }
          },
          legend: {
            icon: "circle",
            itemWidth: 12,  // 设置宽度
            itemHeight: 12, // 设置高度
            top: 20,
            data: ['金额', '次数']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '10%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: true,
            axisLabel: {
              // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
              interval: 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
              rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
              textStyle: {
                color: "#767985",
              }
            },
            data: xData,
            axisTick: {
              show: false // 不显示坐标轴刻度线
            },
            //  y轴刻度线
            axisLine: {
              show: true,
              lineStyle: {
                color: "#E5E6EB",
              }
            }
          },
          yAxis: [
            {
              type: 'value',
              name: '（金额）',
              //坐标轴最大值、最小值、强制设置数据的步长间隔
              // interval: 5,
              axisLabel: {
                //y轴上带的单位
                formatter: '{value}',
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: { show: false },
              //分割线
              splitLine: {
                show: false,
                lineStyle: {
                  type: 'dashed',
                  color: '#E5E6EB'
                }
              }
            },
            {
              minInterval: 1, // 最小单位是1
              type: 'value',
              name: '（次数）',
              axisLabel: {
                formatter: '{value}',
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: { show: false },
              //分割线
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed',
                  color: '#E5E6EB'
                }
              }
            }
          ],
          series: [
            {
              smooth: true,
              name: '金额',
              type: 'line',
              symbolSize: 8, // 设置折线上圆点大小
              // symbol: 'circle', // 设置拐点为实心圆
              yAxisIndex: 0,
              data: res.data.receiptAmountList,
              itemStyle: {
                color: '#DA3E40',
              },
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      { offset: 0, color: "rgba(225,60,60,0.35)" },
                      // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                      { offset: 1, color: "rgba(226,60,60,0)" },
                    ]
                  )
                }
              },
            }, {
              smooth: true,
              name: '次数',
              type: 'line',
              symbolSize: 8, // 设置折线上圆点大小
              // symbol: 'circle', // 设置拐点为实心圆
              yAxisIndex: 1,
              data: res.data.useList,
              itemStyle: {
                color: '#FDBF5A',
              },
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      { offset: 0, color: "rgba(252,190,89,0.35)" },
                      // { offset: 0.5, color: "rgba(0,0,128,0.3)" },
                      { offset: 1, color: "rgba(252,190,89,0)" },
                    ]
                  )
                }
              },
            }
          ],
          dataZoom: [
            {
              show: true,
              height: 8, // 滚动条高度
              xAxisIndex: [0],
              bottom: 15, // 距离底部距离
              showDetail: false,
              start: 0, //初始化时，滑动条宽度开始标度
              showDataShadow: false,
              borderColor: "transparent",
              textStyle: {
                fontSize: 0
              },
              endValue: 10,//从0开始的相当于5个(x轴展示的数量)
              backgroundColor: "rgba(0,0,0,0)",
              borderWidth: 0,
              //                 handleIcon:
              //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
              //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
              //   handleSize: "0%",
              handleStyle: {
                color: "#d3dee5"
              }
            }
          ]
        }
        myChart.setOption(option);
      })
    },
    // 共享洗衣-弹窗
    getWashDialog() {
      let myChart = this.$echarts.init(
        document.getElementById("washEchartDialog")
      );
      let data = {
        orderType: 1,
        schoolId: this.echartsBox[5].talkVal,
        dayOrMonth: this.echartsBox[5].talkMonIndex
      }
      getWashingUseCount(data).then(res => {
        let xData = []
        res.data.dateList.forEach(v => {

          if (this.echartsBox[5].talkMonIndex == 0) {
            xData.push(
              v.substring(5, 10).replace(/-/g, "/")
            )
          } else {
            xData.push(
              v.substring(5) + '月'
            )
          }
        })
        let option = {
          tooltip: {
            trigger: 'axis',
            extraCssText: 'width: 178px;height: 100px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
            axisPointer: {       //坐标轴指示器
              type: 'line',   //十字准星指示器
            },
            formatter: function (params) {
              let res = `${params[0].marker}<span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name}</span><br/>`
              params.forEach(item => {
                if (item.data.studentCount) { }
                res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName == '金额' ? '营收金额' : '使用次数'}&nbsp;&nbsp;&nbsp;${item.value}${item.seriesName == '金额' ? '元' : '次'}</span><br/>` +
                  `${item.data.studentCount ? `<span style="font-size:12px;color:#333;margin-left:12px;">教师：${item.data.teacherCount}次&nbsp;&nbsp;学生：${item.data.studentCount}次</span><br/>` : ''}`
              });
              return res
            }
          },
          legend: {
            icon: "circle",
            itemWidth: 12,  // 设置宽度
            itemHeight: 12, // 设置高度
            top: 20,
            data: ['金额', '次数']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '10%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: true,
            axisLabel: {
              // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
              interval: 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
              rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
              textStyle: {
                color: "#767985",
              }
            },
            data: xData,
            axisTick: {
              show: false // 不显示坐标轴刻度线
            },
            //  y轴刻度线
            axisLine: {
              show: true,
              lineStyle: {
                color: "#E5E6EB",
              }
            }
          },
          yAxis: [
            {
              type: 'value',
              name: '（金额）',
              //坐标轴最大值、最小值、强制设置数据的步长间隔
              // interval: 5,
              axisLabel: {
                //y轴上带的单位
                formatter: '{value}',
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: { show: false },
              //分割线
              splitLine: {
                show: false,
                lineStyle: {
                  type: 'dashed',
                  color: '#E5E6EB'
                }
              }
            },
            {
              minInterval: 1, // 最小单位是1
              type: 'value',
              name: '（次数）',
              axisLabel: {
                formatter: '{value}',
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: { show: false },
              //分割线
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed',
                  color: '#E5E6EB'
                }
              }
            }
          ],
          series: [
            {
              smooth: true,
              name: '金额',
              type: 'line',
              symbolSize: 8, // 设置折线上圆点大小
              // symbol: 'circle', // 设置拐点为实心圆
              yAxisIndex: 0,
              data: res.data.receiptAmountList,
              itemStyle: {
                color: '#DA3E40',
              },
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      { offset: 0, color: "rgba(225,60,60,0.35)" },
                      // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                      { offset: 1, color: "rgba(226,60,60,0)" },
                    ]
                  )
                }
              },
            }, {
              smooth: true,
              name: '次数',
              type: 'line',
              symbolSize: 8, // 设置折线上圆点大小
              // symbol: 'circle', // 设置拐点为实心圆
              yAxisIndex: 1,
              data: res.data.useList,
              itemStyle: {
                color: '#FDBF5A',
              },
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      { offset: 0, color: "rgba(252,190,89,0.35)" },
                      // { offset: 0.5, color: "rgba(0,0,128,0.3)" },
                      { offset: 1, color: "rgba(252,190,89,0)" },
                    ]
                  )
                }
              },
            }
          ]
        }
        myChart.setOption(option);
      })
    },
    // 入离校
    getIsLeaveSchool() {
      let myChart = this.$echarts.init(
        document.getElementById("isLeaveSchool")
      );
      let data = {
        schoolId: this.echartsBox[6].talkVal,
        dayOrMonth: this.echartsBox[6].talkMonIndex,
        deviceType: 1
      }
      getAccessSchoolCount(data).then(res => {
        let xData = []
        res.data.dateList.forEach(v => {

          if (this.echartsBox[6].talkMonIndex == 0) {
            xData.push(
              v.substring(5, 10).replace(/-/g, "/")
            )
          } else {
            xData.push(
              v.substring(5) + '月'
            )
          }
        })
        let option = {
          tooltip: {
            trigger: 'axis',
            extraCssText: 'width: 165px;height: 90px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
            axisPointer: {       //坐标轴指示器
              type: 'line',   //十字准星指示器
            },
            formatter: function (params) {
              // console.log(params);
              let res = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background: linear-gradient(180deg, rgba(2,102,253,0.65) 0%, rgba(2,102,253,0.3) 100%);" ></span ><span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name}</span><br/>`
              params.forEach(item => {
                res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName}人次&nbsp;&nbsp;&nbsp;${item.value}人</span> <span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.data.passCount}次</span><br/>`;
              });
              return res
            }
          },
          legend: {
            data: ['进入', '外出'],
            icon: "circle",
            itemWidth: 12,  // 设置宽度
            itemHeight: 12, // 设置高度
            top: 20,
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '10%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              // prettier-ignore
              data: xData,
              axisLabel: {
                // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
                // interval: this.echartsBox[6].talkMonIndex == 0 ? 1 : 0, //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
                interval: 0, //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
                rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#E5E6EB",
                }
              }
            }
          ],
          yAxis: [
            {
              minInterval: 1, // 最小单位是1
              name: '（人）',
              type: 'value',
              axisLabel: {
                formatter: '{value}',
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: { show: false },
              //分割线
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed',
                  color: '#E5E6EB'
                }
              }
            }
          ],
          series: [
            {
              name: '进入',
              type: 'bar',
              barWidth: "16", // 设置柱子的宽度
              data: res.data.personCountJrList,
              itemStyle: {
                barBorderRadius: [12, 12, 0, 0],
                color: new echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    { offset: 0, color: "rgba(2,102,253,0.65)" },
                    // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                    { offset: 1, color: "rgba(2,102,253,0.3)" },
                  ]
                )
                // color: {
                //   // type: "linear",
                //   // x: 0, // 右
                //   // y: 1, // 下
                //   // x2: 0, // 左
                //   // y2: 0, // 上
                //   colorStops: [
                //     {
                //       offset: 0,
                //       color: "rgba(2,102,253,0.65)", // 0% 处的颜色
                //     },
                //     {
                //       offset: 0.9,
                //       color: "rgba(2,102,253,0.3)", // 90% 处的颜色
                //     },
                //   ],
                // },
              }
            },
            {
              name: '外出',
              type: 'bar',
              barWidth: "16", // 设置柱子的宽度
              data: res.data.personCountWcList,
              itemStyle: {
                barBorderRadius: [12, 12, 0, 0],
                color: new echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    { offset: 0, color: "rgba(255,122,45,0.8)" },
                    // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                    { offset: 1, color: "rgba(255,122,45,0.4)" },
                  ]
                )
              }
            }
          ],
          dataZoom: [
            {
              show: true,
              height: 8, // 滚动条高度
              xAxisIndex: [0],
              bottom: 15, // 距离底部距离
              showDetail: false,
              start: 0, //初始化时，滑动条宽度开始标度
              showDataShadow: false,
              borderColor: "transparent",
              textStyle: {
                fontSize: 0
              },
              endValue: 10,//从0开始的相当于5个(x轴展示的数量)
              backgroundColor: "rgba(0,0,0,0)",
              borderWidth: 0,
              //                 handleIcon:
              //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
              //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
              //   handleSize: "0%",
              handleStyle: {
                color: "#d3dee5"
              }
            }
          ]
        }
        myChart.setOption(option);
      })
    },
    // 入离校-弹窗
    getIsLeaveSchoolDialog() {
      let myChart = this.$echarts.init(
        document.getElementById("isLeaveSchoolDialog")
      );
      let data = {
        schoolId: this.echartsBox[6].talkVal,
        dayOrMonth: this.echartsBox[6].talkMonIndex,
        deviceType: 1
      }
      getAccessSchoolCount(data).then(res => {
        let xData = []
        res.data.dateList.forEach(v => {

          if (this.echartsBox[6].talkMonIndex == 0) {
            xData.push(
              v.substring(5, 10).replace(/-/g, "/")
            )
          } else {
            xData.push(
              v.substring(5) + '月'
            )
          }
        })
        let option = {
          tooltip: {
            trigger: 'axis',
            extraCssText: 'width: 165px;height: 90px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
            axisPointer: {       //坐标轴指示器
              type: 'line',   //十字准星指示器
            },
            formatter: function (params) {
              console.log(params);
              let res = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background: linear-gradient(180deg, rgba(2,102,253,0.65) 0%, rgba(2,102,253,0.3) 100%);" ></span ><span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name}</span><br/>`
              params.forEach(item => {
                res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName}人次&nbsp;&nbsp;&nbsp;${item.value}人</span> <span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.data.passCount}次</span><br/>`;
              });
              return res
            }
          },
          legend: {
            data: ['进入', '外出'],
            icon: "circle",
            itemWidth: 12,  // 设置宽度
            itemHeight: 12, // 设置高度
            top: 20,
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '10%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              // prettier-ignore
              data: xData,
              axisLabel: {
                // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
                // interval: this.echartsBox[6].talkMonIndex == 0 ? 1 : 0, //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
                interval: 0, //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
                rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#E5E6EB",
                }
              }
            }
          ],
          yAxis: [
            {
              minInterval: 1, // 最小单位是1
              name: '（人）',
              type: 'value',
              axisLabel: {
                formatter: '{value}',
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: { show: false },
              //分割线
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed',
                  color: '#E5E6EB'
                }
              }
            }
          ],
          series: [
            {
              name: '进入',
              type: 'bar',
              barWidth: "16", // 设置柱子的宽度
              data: res.data.personCountJrList,
              itemStyle: {
                barBorderRadius: [12, 12, 0, 0],
                color: new echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    { offset: 0, color: "rgba(2,102,253,0.65)" },
                    // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                    { offset: 1, color: "rgba(2,102,253,0.3)" },
                  ]
                )
              }
            },
            {
              name: '外出',
              type: 'bar',
              barWidth: "16", // 设置柱子的宽度
              data: res.data.personCountWcList,
              itemStyle: {
                barBorderRadius: [12, 12, 0, 0],
                color: new echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    { offset: 0, color: "rgba(255,122,45,0.8)" },
                    // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                    { offset: 1, color: "rgba(255,122,45,0.4)" },
                  ]
                )
              }
            }
          ],
        }
        myChart.setOption(option);
      })
    },
    // 宿舍门禁
    getIsLeaveLife() {
      let myChart = this.$echarts.init(
        document.getElementById("isLeaveLife")
      );
      let data = {
        schoolId: this.echartsBox[7].talkVal,
        dayOrMonth: this.echartsBox[7].talkMonIndex,
        deviceType: 2
      }
      getAccessSchoolCount(data).then(res => {
        let xData = []
        res.data.dateList.forEach(v => {

          if (this.echartsBox[7].talkMonIndex == 0) {
            xData.push(
              v.substring(5, 10).replace(/-/g, "/")
            )
          } else {
            xData.push(
              v.substring(5) + '月'
            )
          }
        })
        let option = {
          tooltip: {
            trigger: 'axis',
            extraCssText: 'width: 165px;height: 90px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
            axisPointer: {       //坐标轴指示器
              type: 'line',   //十字准星指示器
            },
            formatter: function (params) {
              console.log(params);
              let res = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background: linear-gradient(180deg, rgba(2,102,253,0.65) 0%, rgba(2,102,253,0.3) 100%);" ></span ><span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name}</span><br/>`
              params.forEach(item => {
                res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName}人次&nbsp;&nbsp;&nbsp;${item.value}人</span> <span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.data.passCount}次</span><br/>`;
              });
              return res
            }
          },
          legend: {
            data: ['进入', '外出'],
            icon: "circle",
            itemWidth: 12,  // 设置宽度
            itemHeight: 12, // 设置高度
            top: 20,
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '10%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              // prettier-ignore
              data: xData,
              axisLabel: {
                // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
                interval: 0, //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
                rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#E5E6EB",
                }
              }
            }
          ],
          yAxis: [
            {
              minInterval: 1, // 最小单位是1
              name: '（人）',
              type: 'value',
              axisLabel: {
                formatter: '{value}',
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: { show: false },
              //分割线
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed',
                  color: '#E5E6EB'
                }
              }
            }
          ],
          series: [
            {
              name: '进入',
              type: 'bar',
              barWidth: "16", // 设置柱子的宽度
              data: res.data.personCountJrList,
              itemStyle: {
                barBorderRadius: [12, 12, 0, 0],
                color: new echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    { offset: 0, color: "rgba(2,102,253,0.65)" },
                    // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                    { offset: 1, color: "rgba(2,102,253,0.3)" },
                  ]
                )
              }
            },
            {
              name: '外出',
              type: 'bar',
              barWidth: "16", // 设置柱子的宽度
              data: res.data.personCountWcList,
              itemStyle: {
                barBorderRadius: [12, 12, 0, 0],
                color: new echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    { offset: 0, color: "rgba(255,122,45,0.8)" },
                    // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                    { offset: 1, color: "rgba(255,122,45,0.4)" },
                  ]
                )
              }
            }
          ],
          dataZoom: [
            {
              show: true,
              height: 8, // 滚动条高度
              xAxisIndex: [0],
              bottom: 15, // 距离底部距离
              showDetail: false,
              start: 0, //初始化时，滑动条宽度开始标度
              showDataShadow: false,
              borderColor: "transparent",
              textStyle: {
                fontSize: 0
              },
              endValue: 10,//从0开始的相当于5个(x轴展示的数量)
              backgroundColor: "rgba(0,0,0,0)",
              borderWidth: 0,
              //                 handleIcon:
              //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
              //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
              //   handleSize: "0%",
              handleStyle: {
                color: "#d3dee5"
              }
            }
          ]
        }
        myChart.setOption(option);
      })
    },
    // 宿舍门禁-弹窗
    getIsLeaveLifeDialog() {
      let myChart = this.$echarts.init(
        document.getElementById("isLeaveLifeDialog")
      );
      let data = {
        schoolId: this.echartsBox[7].talkVal,
        dayOrMonth: this.echartsBox[7].talkMonIndex,
        deviceType: 2
      }
      getAccessSchoolCount(data).then(res => {
        let xData = []
        res.data.dateList.forEach(v => {

          if (this.echartsBox[7].talkMonIndex == 0) {
            xData.push(
              v.substring(5, 10).replace(/-/g, "/")
            )
          } else {
            xData.push(
              v.substring(5) + '月'
            )
          }
        })
        let option = {
          tooltip: {
            trigger: 'axis',
            extraCssText: 'width: 165px;height: 90px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
            axisPointer: {       //坐标轴指示器
              type: 'line',   //十字准星指示器
            },
            formatter: function (params) {
              console.log(params);
              let res = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background: linear-gradient(180deg, rgba(2,102,253,0.65) 0%, rgba(2,102,253,0.3) 100%);" ></span ><span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name}</span><br/>`
              params.forEach(item => {
                res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName}人次&nbsp;&nbsp;&nbsp;${item.value}人</span> <span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.data.passCount}次</span><br/>`;
              });
              return res
            }
          },
          legend: {
            data: ['进入', '外出'],
            icon: "circle",
            itemWidth: 12,  // 设置宽度
            itemHeight: 12, // 设置高度
            top: 20,
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '10%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              // prettier-ignore
              data: xData,
              axisLabel: {
                // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
                interval: 0, //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
                rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#E5E6EB",
                }
              }
            }
          ],
          yAxis: [
            {
              minInterval: 1, // 最小单位是1
              name: '（人）',
              type: 'value',
              axisLabel: {
                formatter: '{value}',
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: { show: false },
              //分割线
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed',
                  color: '#E5E6EB'
                }
              }
            }
          ],
          series: [
            {
              name: '进入',
              type: 'bar',
              barWidth: "16", // 设置柱子的宽度
              data: res.data.personCountJrList,
              itemStyle: {
                barBorderRadius: [12, 12, 0, 0],
                color: new echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    { offset: 0, color: "rgba(2,102,253,0.65)" },
                    // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                    { offset: 1, color: "rgba(2,102,253,0.3)" },
                  ]
                )
              }
            },
            {
              name: '外出',
              type: 'bar',
              barWidth: "16", // 设置柱子的宽度
              data: res.data.personCountWcList,
              itemStyle: {
                barBorderRadius: [12, 12, 0, 0],
                color: new echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    { offset: 0, color: "rgba(255,122,45,0.8)" },
                    // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                    { offset: 1, color: "rgba(255,122,45,0.4)" },
                  ]
                )
              }
            }
          ]
        }
        myChart.setOption(option);
      })
    },
    // 售货机
    getVendingMachine() {
      let myChart = this.$echarts.init(
        document.getElementById("vendingMachine")
      );
      let data = {
        orderType: 1,
        schoolId: this.echartsBox[8].talkVal,
        dayOrMonth: this.echartsBox[8].talkMonIndex
      }
      getVendingUseCount(data).then(res => {
        let xData = []
        res.data.dateList.forEach(v => {

          if (this.echartsBox[8].talkMonIndex == 0) {
            xData.push(
              v.substring(5, 10).replace(/-/g, "/")
            )
          } else {
            xData.push(
              v.substring(5) + '月'
            )
          }
        })
        let option = {
          tooltip: {
            trigger: 'axis',
            extraCssText: 'width: 178px;height: 100px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
            axisPointer: {       //坐标轴指示器
              type: 'line',   //十字准星指示器
            },
            formatter: function (params) {
              let res = `${params[0].marker}<span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name}</span><br/>`
              params.forEach(item => {
                if (item.data.studentCount) { }
                res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName == '金额' ? '营收金额' : '使用次数'}&nbsp;&nbsp;&nbsp;${item.value}${item.seriesName == '金额' ? '元' : '次'}</span><br/>`
              });
              return res
            }
          },
          legend: {
            icon: "circle",
            itemWidth: 12,  // 设置宽度
            itemHeight: 12, // 设置高度
            top: 20,
            data: ['金额', '次数']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '10%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: true,
            axisLabel: {
              // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
              interval: 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
              rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
              textStyle: {
                color: "#767985",
              }
            },
            data: xData,
            axisTick: {
              show: false // 不显示坐标轴刻度线
            },
            //  y轴刻度线
            axisLine: {
              show: true,
              lineStyle: {
                color: "#E5E6EB",
              }
            }
          },
          yAxis: [
            {
              type: 'value',
              name: '（金额）',
              //坐标轴最大值、最小值、强制设置数据的步长间隔
              // interval: 5,
              axisLabel: {
                //y轴上带的单位
                formatter: '{value}',
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: { show: false },
              //分割线
              splitLine: {
                show: false,
                lineStyle: {
                  type: 'dashed',
                  color: '#E5E6EB'
                }
              }
            },
            {
              minInterval: 1, // 最小单位是1
              type: 'value',
              name: '（次数）',
              axisLabel: {
                formatter: '{value}',
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: { show: false },
              //分割线
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed',
                  color: '#E5E6EB'
                }
              }
            }
          ],
          series: [
            {
              smooth: true,
              name: '金额',
              type: 'line',
              symbolSize: 8, // 设置折线上圆点大小
              // symbol: 'circle', // 设置拐点为实心圆
              yAxisIndex: 0,
              data: res.data.receiptAmountList,
              itemStyle: {
                color: '#DA3E40',
              },
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      { offset: 0, color: "rgba(225,60,60,0.35)" },
                      // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                      { offset: 1, color: "rgba(226,60,60,0)" },
                    ]
                  )
                }
              },
            }, {
              smooth: true,
              name: '次数',
              type: 'line',
              symbolSize: 8, // 设置折线上圆点大小
              // symbol: 'circle', // 设置拐点为实心圆
              yAxisIndex: 1,
              data: res.data.useList,
              itemStyle: {
                color: '#FDBF5A',
              },
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      { offset: 0, color: "rgba(252,190,89,0.35)" },
                      // { offset: 0.5, color: "rgba(0,0,128,0.3)" },
                      { offset: 1, color: "rgba(252,190,89,0)" },
                    ]
                  )
                }
              },
            }
          ],
          dataZoom: [
            {
              show: true,
              height: 8, // 滚动条高度
              xAxisIndex: [0],
              bottom: 15, // 距离底部距离
              showDetail: false,
              start: 0, //初始化时，滑动条宽度开始标度
              showDataShadow: false,
              borderColor: "transparent",
              textStyle: {
                fontSize: 0
              },
              endValue: 10,//从0开始的相当于5个(x轴展示的数量)
              backgroundColor: "rgba(0,0,0,0)",
              borderWidth: 0,
              //                 handleIcon:
              //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
              //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
              //   handleSize: "0%",
              handleStyle: {
                color: "#d3dee5"
              }
            }
          ]
        }
        myChart.setOption(option);
      })
    },
    getVendingMachineDialog() {
      let myChart = this.$echarts.init(
        document.getElementById("isLeaveVendingMachine")
      );
      let data = {
        orderType: 1,
        schoolId: this.echartsBox[8].talkVal,
        dayOrMonth: this.echartsBox[8].talkMonIndex
      }
      getVendingUseCount(data).then(res => {
        let xData = []
        res.data.dateList.forEach(v => {

          if (this.echartsBox[8].talkMonIndex == 0) {
            xData.push(
              v.substring(5, 10).replace(/-/g, "/")
            )
          } else {
            xData.push(
              v.substring(5) + '月'
            )
          }
        })
        let option = {
          tooltip: {
            trigger: 'axis',
            extraCssText: 'width: 178px;height: 100px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
            axisPointer: {       //坐标轴指示器
              type: 'line',   //十字准星指示器
            },
            formatter: function (params) {
              console.log(params,'params');
              let res = `${params[0].marker}<span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name}</span><br/>`
              params.forEach(item => {
                if (item.data.studentCount) { }
                res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName == '金额' ? '营收金额' : '使用次数'}&nbsp;&nbsp;&nbsp;${item.value}${item.seriesName == '金额' ? '元' : '次'}</span><br/>` +
                  `${item.data.studentCount ? `<span style="font-size:12px;color:#333;margin-left:12px;">教师：${item.data.teacherCount}次&nbsp;&nbsp;学生：${item.data.studentCount}次</span><br/>` : ''}`
              });
              return res
            }
          },
          legend: {
            icon: "circle",
            itemWidth: 12,  // 设置宽度
            itemHeight: 12, // 设置高度
            top: 20,
            data: ['金额', '次数']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '10%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: true,
            axisLabel: {
              // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
              interval: 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
              rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
              textStyle: {
                color: "#767985",
              }
            },
            data: xData,
            axisTick: {
              show: false // 不显示坐标轴刻度线
            },
            //  y轴刻度线
            axisLine: {
              show: true,
              lineStyle: {
                color: "#E5E6EB",
              }
            }
          },
          yAxis: [
            {
              type: 'value',
              name: '（金额）',
              //坐标轴最大值、最小值、强制设置数据的步长间隔
              // interval: 5,
              axisLabel: {
                //y轴上带的单位
                formatter: '{value}',
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: { show: false },
              //分割线
              splitLine: {
                show: false,
                lineStyle: {
                  type: 'dashed',
                  color: '#E5E6EB'
                }
              }
            },
            {
              minInterval: 1, // 最小单位是1
              type: 'value',
              name: '（次数）',
              axisLabel: {
                formatter: '{value}',
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: { show: false },
              //分割线
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed',
                  color: '#E5E6EB'
                }
              }
            }
          ],
          series: [
            {
              smooth: true,
              name: '金额',
              type: 'line',
              symbolSize: 8, // 设置折线上圆点大小
              // symbol: 'circle', // 设置拐点为实心圆
              yAxisIndex: 0,
              data: res.data.receiptAmountList,
              itemStyle: {
                color: '#DA3E40',
              },
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      { offset: 0, color: "rgba(225,60,60,0.35)" },
                      // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                      { offset: 1, color: "rgba(226,60,60,0)" },
                    ]
                  )
                }
              },
            }, {
              smooth: true,
              name: '次数',
              type: 'line',
              symbolSize: 8, // 设置折线上圆点大小
              // symbol: 'circle', // 设置拐点为实心圆
              yAxisIndex: 1,
              data: res.data.useList,
              itemStyle: {
                color: '#FDBF5A',
              },
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      { offset: 0, color: "rgba(252,190,89,0.35)" },
                      // { offset: 0.5, color: "rgba(0,0,128,0.3)" },
                      { offset: 1, color: "rgba(252,190,89,0)" },
                    ]
                  )
                }
              },
            }
          ]
        }
        myChart.setOption(option);
      })
    },


    getShowerStatistics() {
      let myChart = this.$echarts.init(
        document.getElementById("showerStatistics")
      );
      let data = {
        orderType: 1,
        schoolId: this.echartsBox[9].talkVal,
        dayOrMonth: this.echartsBox[9].talkMonIndex
      }
      getShowerUseCount(data).then(res => {
        let xData = []
        res.data.dateList.forEach(v => {

          if (this.echartsBox[9].talkMonIndex == 0) {
            xData.push(
              v.substring(5, 10).replace(/-/g, "/")
            )
          } else {
            xData.push(
              v.substring(5) + '月'
            )
          }
        })
        let option = {
          tooltip: {
            trigger: 'axis',
            extraCssText: 'width: 178px;height: 100px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
            axisPointer: {       //坐标轴指示器
              type: 'line',   //十字准星指示器
            },
            formatter: function (params) {
              let res = `${params[0].marker}<span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name}</span><br/>`
              params.forEach(item => {
                if (item.data.studentCount) { }
                res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName == '金额' ? '营收金额' : '交易笔数'}&nbsp;&nbsp;&nbsp;${item.value}${item.seriesName == '金额' ? '元' : '笔'}</span><br/>` +
                  `${item.data.waterAmount ? `<span style="font-size:14px;color:#333;margin-left:12px;">用水量：${item.data.waterAmount}升<br/>` : ''}`
              });
              return res
            }
          },
          legend: {
            icon: "circle",
            itemWidth: 12,  // 设置宽度
            itemHeight: 12, // 设置高度
            top: 20,
            data: ['金额', '笔数']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '10%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: true,
            axisLabel: {
              // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
              interval: 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
              rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
              textStyle: {
                color: "#767985",
              }
            },
            data: xData,
            axisTick: {
              show: false // 不显示坐标轴刻度线
            },
            //  y轴刻度线
            axisLine: {
              show: true,
              lineStyle: {
                color: "#E5E6EB",
              }
            }
          },
          yAxis: [
            {
              type: 'value',
              name: '（金额）',
              //坐标轴最大值、最小值、强制设置数据的步长间隔
              // interval: 5,
              axisLabel: {
                //y轴上带的单位
                formatter: '{value}',
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: { show: false },
              //分割线
              splitLine: {
                show: false,
                lineStyle: {
                  type: 'dashed',
                  color: '#E5E6EB'
                }
              }
            },
            {
              minInterval: 1, // 最小单位是1
              type: 'value',
              name: '（笔数）',
              axisLabel: {
                formatter: '{value}',
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: { show: false },
              //分割线
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed',
                  color: '#E5E6EB'
                }
              }
            }
          ],
          series: [
            {
              smooth: true,
              name: '金额',
              type: 'line',
              symbolSize: 8, // 设置折线上圆点大小
              // symbol: 'circle', // 设置拐点为实心圆
              yAxisIndex: 0,
              data: res.data.receiptAmountList,
              itemStyle: {
                color: '#33D067',
              },
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      { offset: 0, color: " rgba(50,207,102,0.35)" },
                      // { offset: 0.5, color: "rgba(0,0,128,0.3)" },
                      { offset: 1, color: "rgba(50,207,102,0)" },
                    ]
                  )
                }
              },
            }, {
              smooth: true,
              name: '笔数',
              type: 'line',
              symbolSize: 8, // 设置折线上圆点大小
              // symbol: 'circle', // 设置拐点为实心圆
              yAxisIndex: 1,
              data: res.data.useList,
              itemStyle: {
                color: '#FDBF5A',
              },
                areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      { offset: 0, color: "rgba(252,190,89,0.35)" },
                      // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                      { offset: 1, color: "rgba(252,190,89,0)" },
                    ]
                  )
                }
              },
            }
          ],
          dataZoom: [
            {
              show: true,
              height: 8, // 滚动条高度
              xAxisIndex: [0],
              bottom: 15, // 距离底部距离
              showDetail: false,
              start: 0, //初始化时，滑动条宽度开始标度
              showDataShadow: false,
              borderColor: "transparent",
              textStyle: {
                fontSize: 0
              },
              endValue: 10,//从0开始的相当于5个(x轴展示的数量)
              backgroundColor: "rgba(0,0,0,0)",
              borderWidth: 0,
              //                 handleIcon:
              //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
              //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
              //   handleSize: "0%",
              handleStyle: {
                color: "#d3dee5"
              }
            }
          ]
        }
        myChart.setOption(option);
      })
    },
    getShowerStatisticsDialog() {
      let myChart = this.$echarts.init(
        document.getElementById("takeShowerStatistics")
      );
      let data = {
        orderType: 1,
        schoolId: this.echartsBox[9].talkVal,
        dayOrMonth: this.echartsBox[9].talkMonIndex
      }
      getShowerUseCount(data).then(res => {
        let xData = []
        res.data.dateList.forEach(v => {

          if (this.echartsBox[9].talkMonIndex == 0) {
            xData.push(
              v.substring(5, 10).replace(/-/g, "/")
            )
          } else {
            xData.push(
              v.substring(5) + '月'
            )
          }
        })

        let option = {
          tooltip: {
            trigger: 'axis',
            extraCssText: 'width: 178px;height: 100px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
            axisPointer: {       //坐标轴指示器
              type: 'line',   //十字准星指示器
            },
            formatter: function (params) {
              console.log(params,'params');
              let res = `${params[0].marker}<span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name}</span><br/>`
              params.forEach(item => {
                console.log(item);
                if (item.data.waterAmount) { }
                res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName == '金额' ? '营收金额' : '交易笔数'}&nbsp;&nbsp;&nbsp;${item.value}${item.seriesName == '金额' ? '元' : '笔'}</span><br/>` +
                  `${item.data.waterAmount ? `<span style="font-size:14px;color:#333;margin-left:12px;">用水量：${item.data.waterAmount}升<br/>` : ''}`
              });
              return res
            }
          },
          legend: {
            icon: "circle",
            itemWidth: 12,  // 设置宽度
            itemHeight: 12, // 设置高度
            top: 20,
            data: ['金额', '笔数']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '10%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: true,
            axisLabel: {
              // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
              interval: 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
              rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
              textStyle: {
                color: "#767985",
              }
            },
            data: xData,
            axisTick: {
              show: false // 不显示坐标轴刻度线
            },
            //  y轴刻度线
            axisLine: {
              show: true,
              lineStyle: {
                color: "#E5E6EB",
              }
            }
          },
          yAxis: [
            {
              type: 'value',
              name: '（金额）',
              //坐标轴最大值、最小值、强制设置数据的步长间隔
              // interval: 5,
              axisLabel: {
                //y轴上带的单位
                formatter: '{value}',
                textStyle: {
                  color: "#767985",
                }
              },
              // data: xData,
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: { show: false },
              //分割线
              splitLine: {
                show: false,
                lineStyle: {
                  type: 'dashed',
                  color: '#E5E6EB'
                }
              }
            },
            {
              minInterval: 1, // 最小单位是1
              type: 'value',
              name: '（笔数）',
              axisLabel: {
                formatter: '{value}',
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: { show: false },
              //分割线
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed',
                  color: '#E5E6EB'
                }
              }
            }
          ],
          series: [
            {
              smooth: true,
              name: '金额',
              type: 'line',
              symbolSize: 8, // 设置折线上圆点大小
              // symbol: 'circle', // 设置拐点为实心圆
              yAxisIndex: 0,
              data: res.data.receiptAmountList,
              itemStyle: {
                color: '#33D067',
              },
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      { offset: 0, color: " rgba(50,207,102,0.35)" },
                      // { offset: 0.5, color: "rgba(0,0,128,0.3)" },
                      { offset: 1, color: "rgba(50,207,102,0)" },
                    ]
                  )
                }
              },
            }, {
              smooth: true,
              name: '笔数',
              type: 'line',
              symbolSize: 8, // 设置折线上圆点大小
              // symbol: 'circle', // 设置拐点为实心圆
              yAxisIndex: 1,
              data: res.data.useList,
              itemStyle: {
                color: '#FDBF5A',
              },
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      { offset: 0, color: "rgba(252,190,89,0.35)" },
                      // { offset: 0.5, color: "rgba(0,0,128,0.3)" },
                      { offset: 1, color: "rgba(252,190,89,0)" },
                    ]
                  )
                }
              },
            }
          ]
        }
        myChart.setOption(option);
      })
    },



// 吹风机

    getHairDryer() {
      let myChart = this.$echarts.init(
        document.getElementById("hairDryer")
      );
      let data = {
        orderType: 9,
        schoolId: this.echartsBox[10].talkVal,
        dayOrMonth: this.echartsBox[10].talkMonIndex
      }
      getWashingUseCount(data).then(res => {
        let xData = []
        res.data.dateList.forEach(v => {

          if (this.echartsBox[10].talkMonIndex == 0) {
            xData.push(
              v.substring(5, 10).replace(/-/g, "/")
            )
          } else {
            xData.push(
              v.substring(5) + '月'
            )
          }
        })
        let option = {
          tooltip: {
            trigger: 'axis',
            extraCssText: 'width: 178px;height: 100px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
            axisPointer: {       //坐标轴指示器
              type: 'line',   //十字准星指示器
            },
            formatter: function (params) {
              let res = `${params[0].marker}<span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name}</span><br/>`
              params.forEach(item => {
                if (item.data.studentCount) { }
                res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName == '金额' ? '营收金额' : '使用次数'}&nbsp;&nbsp;&nbsp;${item.value}${item.seriesName == '金额' ? '元' : '次'}</span><br/>`
              });
              return res
            }
          },
          legend: {
            icon: "circle",
            itemWidth: 12,  // 设置宽度
            itemHeight: 12, // 设置高度
            top: 20,
            data: ['金额', '次数']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '10%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: true,
            axisLabel: {
              // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
              interval: 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
              rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
              textStyle: {
                color: "#767985",
              }
            },
            data: xData,
            axisTick: {
              show: false // 不显示坐标轴刻度线
            },
            //  y轴刻度线
            axisLine: {
              show: true,
              lineStyle: {
                color: "#E5E6EB",
              }
            }
          },
          yAxis: [
            {
              type: 'value',
              name: '（金额）',
              //坐标轴最大值、最小值、强制设置数据的步长间隔
              // interval: 5,
              axisLabel: {
                //y轴上带的单位
                formatter: '{value}',
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: { show: false },
              //分割线
              splitLine: {
                show: false,
                lineStyle: {
                  type: 'dashed',
                  color: '#f00'
                }
              }
            },
            {
              minInterval: 1, // 最小单位是1
              type: 'value',
              name: '（次数）',
              axisLabel: {
                formatter: '{value}',
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: { show: false },
              //分割线
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed',
                  color: '#E5E6EB'
                }
              }
            }
          ],
          series: [
            {
              smooth: true,
              name: '金额',
              type: 'line',
              symbolSize: 8, // 设置折线上圆点大小
              // symbol: 'circle', // 设置拐点为实心圆
              yAxisIndex: 0,
              data: res.data.receiptAmountList,
              itemStyle: {
                color: '#0267FE',
              },
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      { offset: 0, color: " rgba(28,123,255,0.35)" },
                      // { offset: 0.5, color: "rgba(0,0,128,0.3)" },
                      { offset: 1, color: "rgba(28,123,255,0)" },
                    ]
                  )
                }
              },
            }, {
              smooth: true,
              name: '次数',
              type: 'line',
              symbolSize: 8, // 设置折线上圆点大小
              // symbol: 'circle', // 设置拐点为实心圆
              yAxisIndex: 1,
              data: res.data.useList,
              itemStyle: {
                color: '#FDBF5A',
              },
                areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      { offset: 0, color: "rgba(252,190,89,0.35)" },
                      // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                      { offset: 1, color: "rgba(252,190,89,0)" },
                    ]
                  )
                }
              },
            }
          ],
          dataZoom: [
            {
              show: true,
              height: 8, // 滚动条高度
              xAxisIndex: [0],
              bottom: 15, // 距离底部距离
              showDetail: false,
              start: 0, //初始化时，滑动条宽度开始标度
              showDataShadow: false,
              borderColor: "transparent",
              textStyle: {
                fontSize: 0
              },
              endValue: 10,//从0开始的相当于5个(x轴展示的数量)
              backgroundColor: "rgba(0,0,0,0)",
              borderWidth: 0,
              //                 handleIcon:
              //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
              //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
              //   handleSize: "0%",
              handleStyle: {
                color: "#d3dee5"
              }
            }
          ]
        }
        myChart.setOption(option);
      })
    },
    getHairDryerDialog() {
      let myChart = this.$echarts.init(
        document.getElementById("hairDryerDialog")
      );
      let data = {
        orderType: 9,
        schoolId: this.echartsBox[10].talkVal,
        dayOrMonth: this.echartsBox[10].talkMonIndex
      }
      getWashingUseCount(data).then(res => {
        let xData = []
        res.data.dateList.forEach(v => {

          if (this.echartsBox[10].talkMonIndex == 0) {
            xData.push(
              v.substring(5, 10).replace(/-/g, "/")
            )
          } else {
            xData.push(
              v.substring(5) + '月'
            )
          }
        })

        let option = {
          tooltip: {
            trigger: 'axis',
            extraCssText: 'width: 178px;height: 100px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
            axisPointer: {       //坐标轴指示器
              type: 'line',   //十字准星指示器
            },
            formatter: function (params) {
              let res = `${params[0].marker}<span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name}</span><br/>`
              params.forEach(item => {
                if (item.data.studentCount) { }
                res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName == '金额' ? '营收金额' : '使用次数'}&nbsp;&nbsp;&nbsp;${item.value}${item.seriesName == '金额' ? '元' : '次'}</span><br/>`
              });
              return res
            }
          },
          legend: {
            icon: "circle",
            itemWidth: 12,  // 设置宽度
            itemHeight: 12, // 设置高度
            top: 20,
            data: ['金额', '次数']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '10%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            boundaryGap: true,
            axisLabel: {
              // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
              interval: 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
              rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
              textStyle: {
                color: "#767985",
              }
            },
            data: xData,
            axisTick: {
              show: false // 不显示坐标轴刻度线
            },
            //  y轴刻度线
            axisLine: {
              show: true,
              lineStyle: {
                color: "#E5E6EB",
              }
            }
          },
          yAxis: [
            {
              type: 'value',
              name: '（金额）',
              //坐标轴最大值、最小值、强制设置数据的步长间隔
              // interval: 5,
              axisLabel: {
                //y轴上带的单位
                formatter: '{value}',
                textStyle: {
                  color: "#767985",
                }
              },
              // data: xData,
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: { show: false },
              //分割线
              splitLine: {
                show: false,
                lineStyle: {
                  type: 'dashed',
                  color: '#E5E6EB'
                }
              }
            },
            {
              minInterval: 1, // 最小单位是1
              type: 'value',
              name: '（次数）',
              axisLabel: {
                formatter: '{value}',
                textStyle: {
                  color: "#767985",
                }
              },
              axisTick: {
                show: false // 不显示坐标轴刻度线
              },
              //  y轴刻度线
              axisLine: { show: false },
              //分割线
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed',
                  color: '#E5E6EB'
                }
              }
            }
          ],
          series: [
            {
              smooth: true,
              name: '金额',
              type: 'line',
              symbolSize: 8, // 设置折线上圆点大小
              // symbol: 'circle', // 设置拐点为实心圆
              yAxisIndex: 0,
              data: res.data.receiptAmountList,
              itemStyle: {
                color: '#0267FE',
              },
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      { offset: 0, color: " rgba(28,123,255,0.35)" },
                      // { offset: 0.5, color: "rgba(0,0,128,0.3)" },
                      { offset: 1, color: "rgba(28,123,255,0)" },
                    ]
                  )
                }
              },
            }, {
              smooth: true,
              name: '次数',
              type: 'line',
              symbolSize: 8, // 设置折线上圆点大小
              // symbol: 'circle', // 设置拐点为实心圆
              yAxisIndex: 1,
              data: res.data.useList,
              itemStyle: {
                color: '#FDBF5A',
              },
              areaStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      { offset: 0, color: "rgba(252,190,89,0.35)" },
                      // { offset: 0.5, color: "rgba(0,0,128,0.3)" },
                      { offset: 1, color: "rgba(252,190,89,0)" },
                    ]
                  )
                }
              },
            }
          ]
        }
        myChart.setOption(option);
      })
    },





  },
};
</script>

<style scoped lang="scss">
.home {
  min-height: calc(100vh - 84px);
  background-color: #F4F5F7;



  .school-box {
    width: 49.3%;
    height: 504px;
    background: #FFFFFF;
    border-radius: 10px;
    overflow: hidden;
    font-weight: 700;

    .s-top {
      font-size: 18px;
      color: #000;
      margin: 24px 0 0 36px;
    }
  }

  // 智能公话
  .echarts-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top:24px;

    .smart-talk {
      width: 49.3%;
      height: 394px;
      background: #FFFFFF;
      border-radius: 12px;
      overflow: hidden;
      flex-shrink: 0;
      margin-bottom: 24px;

      .talk-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 32px;
        margin: 24px 24px 0 24px;

        .t-title {
          font-size: 18px;
          color: #1D2129;
          font-weight: 700;
        }

        .month-box {
          display: flex;
          margin-left: 40px;
          height: 24px;

          .month {
            width: 30px;
            height: 24px;
            background-color: #DCDFE6;
            color: #fff;
            font-size: 14px;
            text-align: center;
            line-height: 24px;
            border-radius: 4px;
            cursor: pointer;
          }

          .talk-ac {
            background-color: #0267FE;
          }
        }
      }
    }

    .smart-talk:nth-child(even) {
      margin-right: 0;
      width: 49.3%;
    }


  }
}

::v-deep .el-input--medium .el-input__icon {
  line-height: 32px;
}

::v-deep .el-input--medium .el-input__inner {
  height: 32px;
  line-height: 32px;
}

::v-deep .el-dialog {
  left: 70px;
}
.school-boxs {
  width: 49.3%;
  height: 504px;
  background: #FFFFFF;
  border-radius: 10px;


  .s-top {
    font-size: 18px;
    color: #000;
    margin: 24px 0 0 36px;
  }
}
.new-top{
  width: 100%;
  height: 102px;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .new-top-l{
    width:47.3%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right:50px;
    .left-i {
      display: flex;
      align-items: center;
      width: 33%;
      height: 102px;
      background: #FFFFFF;
      border-radius: 10px;
      color: #1D2129;
      font-size: 14px;

      .img {
        width: 54px;
        height: 54px;
        margin: 0 12px 0 28px;
      }
    }
  }
  .xian{
    width:1px;
    height:60px;
    background: #D8D8D8;
    

  }
  .new-c-r{
    width:52.3%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .right-l{
      display: flex;
      align-items: center;
      justify-content:center;
      width: 24%;
      height: 102px;
      border-radius: 10px;
      color: #1D2129;
      font-size: 14px;
    
     
     
    }
  }
}


.new-tops{
  width: 100%;
  height: 102px;
  background: #fff;
  border-radius: 10px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .new-top-l{
    width:30.3%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right:50px;
    .left-i {
      display: flex;
      align-items: center;
      width: 49.3%;
      height: 102px;
      background: #FFFFFF;
      border-radius: 10px;
      color: #1D2129;
      font-size: 14px;

      .img {
        width: 54px;
        height: 54px;
        margin: 0 12px 0 28px;
      }
    }
  }
  .xian{
    width:1px;
    height:60px;
    background: #D8D8D8;
    

  }
  .new-c-r{
    width:60.3%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .right-l{
      display: flex;
      align-items: center;
      justify-content:center;
      width: 24%;
      height: 102px;
      border-radius: 10px;
      color: #1D2129;
      font-size: 14px;
    
     
     
    }
  }
}
</style>

