import request from '@/utils/request'
// 头部信息
export function getStudentAndTeacherCount(data) {
    return request({
        url: '/xyw-system/homePageApi/getStudentAndTeacherCount',
        method: 'post',
        data
    })
}
// 学生统计
export function getStudentCount(data) {
    return request({
        url: '/xyw-system/homePageApi/getStudentCount',
        method: 'post',
        data
    })
}
// 学校通知
export function getSchoolRelease(data) {
    return request({
        url: '/xyw-system/homePageApi/getSchoolRelease',
        method: 'post',
        data
    })
}
// 考勤统计
export function getStudentAttend(data) {
    return request({
        url: '/xyw-system/homePageApi/getStudentAttend',
        method: 'post',
        data
    })
}
// 校园团餐营收、次数
export function getSchoolMealCount(data) {
    return request({
        url: '/xyw-system/homePageApi/getSchoolMealCount',
        method: 'post',
        data
    })
}

// 列表
export function getBusinessType(data) {
    return request({
        url: '/xyw-system/homePage/getBusinessType',
        method: 'post',
        data
    })
}
// 人脸设备
export function getFaceDeviceCount(query) {
    return request({
        url: '/xyw-system/homePage/getFaceDeviceCount/' + query,
        method: 'get',
    })
}
// 请假列表
export function getMonthLeaveStatis(query) {
    return request({
        url: '/xyw-system/homePage/getMonthLeaveStatis/' + query,
        method: 'get',
    })
}
// 首页
export function schoolLargeScreen(data) {
    return request({
        url: '/xyw-system/homePage/schoolLargeScreen',
        method: 'post',
        data
    })
}
// 门禁
export function getAccessCount(data) {
    return request({
        url: '/xyw-system/homePageApi/getAccessCount',
        method: 'post',
        data
    })
}

export function getDormitoryType(data) {
    return request({
        url: '/xyw-system/homePage/getDormitoryType',
        method: 'post',
        data
    })
}