const entrance = {

    state: {
        traffic: [], // 宿舍门禁时段
        trafficDev: {}, // 宿舍门禁设备
    },
    mutations: {
        guard(state, value) {
            console.log(value);
            state.traffic = [...value];
        },
        guardDev(state, value) {
            console.log(value);
            state.trafficDev = value;
        },
    },
}

export default entrance





