<template>
    <div class="container">
        <div class="title">设备添加</div>
        <el-form :model="form" ref="form" size="small" :inline="true" label-width="120px">
            <div class="yyy">
                <div class="icon-meus">*</div>
                <el-form-item label="负责人手机号:" prop="">
                    <el-input ref="input" enterkeyhint="search" @keyup.enter.native="onBlur" maxlength="11"
                        v-model="form.phone" placeholder="请输入平台内已添加的学校负责人手机号" @blur="getSchool" />
                </el-form-item>
            </div>
            <el-form-item label="学校:" prop="">
                <el-input v-model="form.school" placeholder="生成学校" readonly />
                <!-- <el-select v-model="form.school" placeholder="请选择学校名称" filterable ref="select" @focus="pullKeybord"
                    @hook:mounted="pullKeybord" @visible-change="pullKeybord">
                    <el-option v-for="item in schoolOPtions" :key="item.id" :label="item.name" :value="item.id" />
                </el-select> -->
            </el-form-item>
            <div class="yyy">
                <div class="icon-meus dev-name">*</div>
                <el-form-item label="设备名称:" prop="">
                    <el-input v-model="form.deviceName" placeholder="请输入设备名称" />
                </el-form-item>
            </div>
            <el-form-item label="设备SN:" prop="deviceSn">
                <el-input v-model="form.deviceSn" placeholder="设备SN" readonly />
            </el-form-item>
            <el-form-item label="安装位置:" prop="">
                <el-input v-model="form.installationSite" placeholder="请输入安装位置" />
            </el-form-item>
            <el-button :loading="subFlag" class="submit" @click="submit" type="primary" size="mini">提交</el-button>
        </el-form>
    </div>
</template>
  
<script>
//   import errGif from '@/assets/401_images/401.gif'
import { debounce } from '@/utils/debounce'
import { getSchoolByMobile, bindPhoneDevice } from "@/api/system/fitMobile";
export default {
    name: 'fitMobile',
    data() {
        return {
            // errGif: errGif + '?' + +new Date()
            loading: '',
            subFlag: false,
            form: {
                phone: '',
                deviceName: '',
                deviceSn: '',
                school: '',
                schoolId: '',
                installationSite: '',

            },
            schoolOPtions: [{
                id: 1,
                name: '大学'
            },
            {
                id: 2,
                name: '小学'
            }],
            // rules: {
            //     phone: [{
            //         required: true,
            //         pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            //         message: "请输入正确的手机号码",
            //         trigger: "blur"
            //     }],
            //     deviceName: [{
            //         required: true,
            //         message: '请输入设备名称',
            //         trigger: 'blur'
            //     }]
            // }
        }
    },
    created() {
        let geturl = window.location.href
        let getqyinfo = geturl.split('?')[1] // 链接后的东西
        let getqys = new URLSearchParams('?' + getqyinfo)
        let getqycode = getqys.get('deviceSn')   //1001   
        this.form.deviceSn = getqycode
    },
    methods: {
        // 解决ios 下拉框 触焦拉不起键盘问题 （配合下面的 css-.el-scrollbar）
        pullKeybord(async) {
            this.$nextTick(() => {
                if (!async) {
                    // ios 手机有延迟问题
                    setTimeout(() => {
                        const { select } = this.$refs
                        const input = select.$el.querySelector('.el-input__inner')
                        input.removeAttribute('readonly')
                    }, 200)
                }
            })
        },
        // 搜索 防抖函数 默认600毫秒
        // searchInput: debounce(function () {
        //     this.loading = this.$loading({
        //         lock: true,
        //         text: "加载中",
        //         spinner: "el-icon-loading",
        //         background: "rgba(255, 255, 255, 0.8)",
        //     });
        // },800),
        // 回车 失焦
        onBlur() {
            this.$refs.input.blur()
        },
        //   查 学校
        getSchool() {
            let reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
            let phoneReg = reg.test(this.form.phone)
            if (this.form.phone.trim() == '') {
                this.$modal.msgError('手机号不能为空')
                return
            }
            if (!phoneReg && this.form.phone.trim() != '') {
                this.$modal.msgError('请输入正确的手机号')
                return
            }
            this.loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(255, 255, 255, 0.8)",
            });
            getSchoolByMobile(this.form.phone.trim()).then(res => {
                if (res.data) {
                    this.form.school = res.data.name
                    this.form.schoolId = res.data.id
                } else {
                    this.$modal.msgError('当前手机号未查询到学校')
                }
                this.loading.close()
            }).catch(err => {
                this.loading.close()
            })
            // setTimeout(() => { this.form.school = '本升专科技学院', this.loading.close() }, 1500)
        },
        // 提交
        submit() {

            if (this.form.phone.trim() == '') {
                this.$modal.msgError('学校负责人手机号不能为空')
                return
            }
            if (this.form.school.trim() == '') {
                this.$modal.msgError('学校不能为空')
                return
            }
            if (this.form.deviceName.trim() == '') {
                this.$modal.msgError('设备名称不能为空')
                return
            }
            if (this.form.deviceSn.trim() == '') {
                this.$modal.msgError('设备SN不能为空')
                return
            }
            this.loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(255, 255, 255, 0.8)",
            });
            this.subFlag = true
            bindPhoneDevice(this.form).then(res => {
                this.loading.close()
                this.$modal.msgSuccess(res.msg);
                this.subFlag = false
            }).catch(err => {
                this.loading.close()
                this.subFlag = false
            })
        }
    }
}
</script>
  
<style lang="scss" scoped>
.container {
    overflow: hidden;

    .title {
        font-size: 54px;
        color: #333;
        font-weight: 700;
        box-sizing: border-box;
        margin: 95px 35px 65px;
        padding-bottom: 50px;
        border-bottom: 1px solid #E8E8E8;
    }

    .yyy {
        position: relative;

        .icon-meus {
            color: red;
            position: absolute;
            left: 20px;
            transform: translateY(-50%);
            top: 28%;
        }

        .dev-name {
            left: 80px;
        }
    }




    .submit {
        width: 662px;
        height: 80px;
        background: #0267FE;
        border-radius: 125px;
        margin: 100px 44px 0;
    }
}

::v-deep .el-input__inner {
    width: 470px !important;
    height: 70px !important;
    line-height: 70px !important;

    &::placeholder {
        font-size: 18px;
        line-height: 70px !important;
        color: #888;
    }
}

::v-deep .el-message--error {
    min-width: unset !important;
    width: 240px !important;
    height: 40px !important;
}

::v-deep .el-scrollbar .el-scrollbar__bar {
    opacity: 1 !important;
}

::v-deep .el-form-item--small {
    margin-bottom: 69px;
}
</style>
  