import request from '@/utils/request'

// 查询设备通用管理列表
export function getSchoolByMobile(query) {
    return request({
        url: '/xyw-system/school/getSchoolByMobile/' + query,
        method: 'get'
    })
}
// 设备添加提交
export function bindPhoneDevice(data) {
    return request({
        url: '/xyw-system/deviceManage/bindPhoneDevice',
        method: 'post',
        data
    })
}