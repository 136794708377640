<template>
  <div style="height: 100%;">
    <div class="login" v-if="false">

      <!--  底部  -->
      <div class="el-login-footer">
        <span>Copyright © 2020 XinYuWei All Rights Reserved 济南新域伟网络科技有限公司 | 鲁ICP备20000246号-1 |
          山东省济南市历下区奥体西路中国铁建国际城B座703室</span>
      </div>
    </div>
    <!-- 学校端登录 -->
    <div class="login-wrap" v-if="personType == 3">
      <div class="left">
        <div class="bg-title">数智化管理平台学校端</div>
        <div class="english">DIGITAL INTELLIGENCE MANAGEMENT PLATFORM</div>
      </div>
      <div class="right">
        <div class="top">
          <div>登录</div>
          <img src="../assets/images/sign.png" alt="">
        </div>
        <div class="login-box">
          <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form">
            <!-- <h3 class="title">新域伟后台管理系统</h3> -->
            <el-form-item prop="username">
              <div class="item-box">
                <el-input v-model="loginForm.username" type="text" auto-complete="off" placeholder="账号">
                </el-input>
                <img class="icon" src="../assets/images/user.png" alt="" />
              </div>
            </el-form-item>
            <el-form-item prop="password" style="margin: 40px 0;">
              <div class="item-box">
                <el-input v-model="loginForm.password" :type="typeSwitch" auto-complete="off" placeholder="密码"
                  @keyup.enter.native="handleLogin">
                  <svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon" />
                </el-input>
                <img class="icon" src="../assets/images/psd.png" alt="" />
                <img @click="switchPsd" v-if="isPsd" class="icon last" src="../assets/images/y.png" alt="" />
                <img @click="switchPsd" v-else class="icon last" src="../assets/images/n.png" alt="" />
              </div>
            </el-form-item>
            <el-form-item prop="code" v-if="captchaOnOff">
              <div style="display: flex;line-height: 66px;">
                <div class="code">
                  <el-input v-model="loginForm.code" auto-complete="off" placeholder="请输入验证码"
                    @keyup.enter.native="handleLogin">
                    <svg-icon slot="prefix" icon-class="validCode" class="el-input__icon input-icon" />
                  </el-input>
                </div>
                <div class="login-code">
                  <el-image :src="codeUrl" @click="getCode" class="login-code-img" fit="cover" />
                </div>
              </div>
            </el-form-item>
            <el-checkbox v-model="loginForm.rememberMe" style="margin:0px 0px 25px 0px;">记住密码</el-checkbox>
            <el-form-item style="width:100%;">
              <el-button :loading="loading" size="medium" type="primary" style="width:100%;"
                @click.native.prevent="handleLogin">
                <span v-if="!loading">登 录</span>
                <span v-else>登 录 中...</span>
              </el-button>
              <div style="float: right;" v-if="register">
                <router-link class="link-type" :to="'/register'">立即注册</router-link>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <!-- 平台登录 -->
    <div class="login-platform" v-else>
      <div class="bg-title">数智化管理平台运营端</div>
      <div class="english">DIGITAL INTELLIGENCE MANAGEMENT PLATFORM</div>
      <div class="platform-box">
        <div class="top">
          <div>登录</div>
          <img src="../assets/images/sign.png" alt="">
        </div>
        <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form-plat">
          <!-- <h3 class="title">新域伟后台管理系统</h3> -->
          <el-form-item prop="username">
            <div class="item-box p-box">
              <el-input v-model="loginForm.username" type="text" auto-complete="off" placeholder="账号">
              </el-input>
              <img class="icon" src="../assets/images/user.png" alt="" />
            </div>
          </el-form-item>
          <el-form-item prop="password" style="margin: 40px 0;">
            <div class="item-box p-box">
              <el-input v-model="loginForm.password" :type="typeSwitch" auto-complete="off" placeholder="密码"
                @keyup.enter.native="handleLogin">
                <svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon" />
              </el-input>
              <img class="icon" src="../assets/images/psd.png" alt="" />
              <img @click="switchPsd" v-if="isPsd" class="icon last" src="../assets/images/y.png" alt="" />
              <img @click="switchPsd" v-else class="icon last" src="../assets/images/n.png" alt="" />
            </div>
          </el-form-item>
          <el-form-item prop="code" v-if="captchaOnOff">
            <div style="display: flex;line-height: 66px;">
              <div class="code">
                <el-input v-model="loginForm.code" auto-complete="off" placeholder="请输入验证码"
                  @keyup.enter.native="handleLogin">
                  <svg-icon slot="prefix" icon-class="validCode" class="el-input__icon input-icon" />
                </el-input>
              </div>
              <div class="login-code">
                <el-image :src="codeUrl" @click="getCode" class="login-code-img" fit="cover" />
              </div>
            </div>
          </el-form-item>
          <el-checkbox v-model="loginForm.rememberMe" style="margin:0px 0px 25px 0px;">记住密码</el-checkbox>
          <el-form-item style="width:100%;">
            <div class="plat">
              <el-button :loading="loading" size="medium" type="primary" style="width:100%;"
                @click.native.prevent="handleLogin">
                <span v-if="!loading">登 录</span>
                <span v-else>登 录 中...</span>
              </el-button>
            </div>
            <div style="float: right;" v-if="register">
              <router-link class="link-type" :to="'/register'">立即注册</router-link>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- <div v-if="permission.role == 1">学校登录页</div>
    <div v-if="permission.role == 2">登录页</div> -->
  </div>
</template>

<script>
import { getCodeImg } from "@/api/login";
import Cookies from "js-cookie";
import { encrypt, decrypt } from '@/utils/jsencrypt'
// import { mapState } from 'vuex'

export default {
  name: "Login",
  data() {
    return {
      isPsd: true,
      typeSwitch: 'password',
      codeUrl: "",
      loginForm: {
        username: "",
        password: "",
        rememberMe: false,
        code: "",
        uuid: ""
      },
      loginRules: {
        username: [
          { required: true, trigger: "blur", message: "请输入您的账号" }
        ],
        password: [
          { required: true, trigger: "blur", message: "请输入您的密码" }
        ],
        code: [{ required: true, trigger: "change", message: "请输入验证码" }]
      },
      loading: false,
      // 验证码开关
      captchaOnOff: true,
      // 注册开关
      register: false,
      redirect: undefined,
      personType: ''
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  created() {
    this.personType = localStorage.getItem('personType')
    this.getCode();
    this.getCookie();
  },
  computed: {
    // ...mapState(['permission'])
  },
  methods: {
    // 切换
    switchPsd() {
      this.isPsd = !this.isPsd
      if (this.isPsd) {
        this.typeSwitch = 'password'
      } else {
        this.typeSwitch = 'text'
      }
    },
    getCode() {
      getCodeImg().then(res => {
        this.captchaOnOff = res.captchaOnOff === undefined ? true : res.captchaOnOff;
        if (this.captchaOnOff) {
          this.codeUrl = "data:image/gif;base64," + res.img;
          this.loginForm.uuid = res.uuid;
        }
      });
    },
    getCookie() {
      const username = Cookies.get("username");
      const password = Cookies.get("password");
      const rememberMe = Cookies.get('rememberMe')
      this.loginForm = {
        username: username === undefined ? this.loginForm.username : username,
        password: password === undefined ? this.loginForm.password : decrypt(password),
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe)
      };
    },
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true;
          if (this.loginForm.rememberMe) {
            Cookies.set("username", this.loginForm.username, { expires: 30 });
            Cookies.set("password", encrypt(this.loginForm.password), { expires: 30 });
            Cookies.set('rememberMe', this.loginForm.rememberMe, { expires: 30 });
          } else {
            Cookies.remove("username");
            Cookies.remove("password");
            Cookies.remove('rememberMe');
          }
          this.$store.dispatch("Login", this.loginForm).then(() => {
            this.$router.push({ path: this.redirect || "/" }).catch(() => { });
          }).catch(() => {
            this.loading = false;
            if (this.captchaOnOff) {
              this.getCode();
            }
          });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
//  rel="stylesheet/scss"
// .login {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100%;
//   background-image: url("../assets/images/login-background.jpg");
//   background-size: cover;
// }


//学校端 
.login-wrap {
  display: flex;

  .left {
    position: relative;
    flex: 1;
    height: 100vh;
    width: 100vw;
    background-image: url("../assets/images/bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-radius: 20px 0 0 20px;

    .bg-title {
      position: absolute;
      margin-top: 40%;
      left: 10%;
      font-size: 44px;
      color: #fff;
      font-weight: 800;
      letter-spacing: 25px;
    }

    .english {
      position: absolute;
      left: 10%;
      bottom: 35%;
      font-size: 12px;
      color: #fff;
      font-weight: 500;
      letter-spacing: 3px;
    }
  }

  .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .top {
      width: 52%;
      height: 58px;
      margin: -100px 0 28px;
      font-size: 30px;
      color: #333;
      font-weight: 700;
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        width: 135px;
        height: 38px;
      }
    }

    .item-box {
      position: relative;
      width: 100%;
      height: 66px;

      .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 30px;
        width: 16px;
        height: 16px;
      }

      .last {
        left: unset;
        right: 43%;
      }
    }
  }
}

// 平台
.login-platform {
  width: 100vw;
  min-height: 100vh;
  background: url("../assets/images/bg-pl.jpg") no-repeat;
  background-size: cover;

  .login-form-plat {
    padding-left: 50px;
  }

  .bg-title {
    position: absolute;
    // top: 46%;
    // left: 10%;
    // top: 315px;
    // left: 214px;
    top: 33%;
    left: 11%;
    font-size: 60px;
    color: #fff;
    font-weight: 800;
    letter-spacing: 14px;
  }

  .english {
    position: absolute;
    // left:15%;
    // bottom: 60%;
    left: 11%;
    bottom: 270px;
    font-size: 12px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 3px;
  }

  .platform-box {
    position: absolute;
    // top:8%;
    // right: 5%;
    width: 500px;
    // height: 600px;
    // top: 240px;
    top: 20%;
    right: 5%;
    // right: 275px;
    // width: 500px;
    height: 600px;
    // width: 30%;
    // height: 77%;
    background-color: #fff;
    border-radius: 10px;

    .top {
      width: 410px;
      height: 58px;
      margin: 54px 0 45px 45px;
      font-size: 30px;
      color: #2262FF;
      font-weight: 700;
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        width: 135px;
        height: 52px;
      }
    }

    .item-box {
      position: relative;
      width: 100%;
      height: 66px;

      .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 30px;
        width: 16px;
        height: 16px;
      }

      .last {
        left: unset;
        right: 18%;
      }
    }

    .code {
      width: 55%;
      margin-right: 25px;
    }

    .login-code-img {
      width: 80%;
      height: 59px;
      border-radius: 86px;

      img {
        width: 170px;
        height: 61px;
        border-radius: 86px;

      }
    }
  }
}


.title {
  margin: 0px auto 30px auto;
  text-align: center;
  color: #707070;
}

.login-box {
  width: 100%;
  padding-left: 24%;
  box-sizing: border-box;

  // display: flex;
  // justify-content: center;
  .login-form {
    border-radius: 6px;
    background: #ffffff;
    width: 100%;
    // padding: 25px 25px 5px 25px;

    .el-input {
      height: 38px;

      input {
        height: 38px;
      }
    }

    .input-icon {
      height: 39px;
      width: 14px;
      margin-left: 2px;
    }
  }
}

.login-tip {
  font-size: 13px;
  text-align: center;
  color: #bfbfbf;
}

.login-code {
  width: 33%;
  height: 38px;
  float: right;
  cursor: pointer;

  img {
    // cursor: pointer;
    vertical-align: middle;
  }
}

.el-login-footer {
  height: 40px;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  font-family: Arial;
  font-size: 12px;
  letter-spacing: 1px;
}

.code {
  width: 48%;
  margin-right: 25px;
}

.login-code-img {
  width: 55%;
  height: 59px;
  border-radius: 86px;

  img {
    width: 170px;
    height: 61px;
    border-radius: 86px;

  }
}


::v-deep .el-button--medium {
  width: 70% !important;
  height: 67px;
  font-size: 24px;
  background-color: #2262FF;
  border-radius: 86px !important;
  box-shadow: 0px 8px 22px 0px rgba(74, 58, 255, 0.2600);
}

::v-deep .plat .el-button--medium {
  width: 88% !important;
}

::v-deep .el-input__inner {
  position: relative;
  width: 70%;
  height: 66px;
  padding-left: 50px;
  background: #FFFFFF;
  box-shadow: 0px 2px 6px 0px rgba(18, 17, 65, 0.0700);
  border-radius: 46px 46px 46px 46px;
  border: 1px solid #E0EAFF;

  .icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
  }
}

::v-deep .p-box .el-input__inner {
  width: 87%;
}

::v-deep .code .el-input__inner {
  width: 100%;
}
</style>
