var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "screenWrapper",
      staticClass: "screen-wrapper",
      style: _vm.wrapperStyle,
    },
    [
      _c("div", { staticClass: "top", attrs: { color: "transparent" } }, [
        _c("div", { staticClass: "week" }, [_vm._v(_vm._s(_vm.weeks))]),
        _c("div", { staticClass: "top-l" }, [
          _vm._v(
            " " +
              _vm._s(_vm.date.year) +
              "-" +
              _vm._s(_vm.date.month) +
              "-" +
              _vm._s(_vm.date.date) +
              " " +
              _vm._s(_vm.date.hours) +
              ":" +
              _vm._s(_vm.date.minutes) +
              " "
          ),
        ]),
        _c("div", { staticClass: "top-cot" }, [_vm._v(_vm._s(_vm.schoolName))]),
        _c("div", { staticClass: "top-r", on: { click: _vm.getInit } }, [
          _vm._m(0),
        ]),
      ]),
      _c("div", { staticClass: "box" }, [
        _c("div", { staticClass: "box-left" }, [
          _vm._m(1),
          _c("div", { staticClass: "box-left-personnel" }, [
            _c("div", { staticClass: "box-left-personnel-top" }, [
              _vm._v("全校请假人数统计"),
            ]),
            _c("div", { staticClass: "box-left-personnel-center" }, [
              _vm._m(2),
              _c("div", { staticClass: "box-left-personnel-center-right" }, [
                _c(
                  "div",
                  { staticClass: "box-left-personnel-center-right-one" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.NumberOfLeave ? _vm.NumberOfLeave : "0") +
                        " "
                    ),
                    _c("span", [_vm._v("人")]),
                  ]
                ),
                _vm._m(3),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "year-box" },
              _vm._l(_vm.dateList, function (v, i) {
                return _c(
                  "div",
                  {
                    key: i,
                    staticClass: "year-item",
                    class: v == _vm.activeDate ? "year-ac" : "",
                    on: {
                      click: function ($event) {
                        return _vm.dateClick(v, i)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(v) + " ")]
                )
              }),
              0
            ),
            _c("div", {
              staticStyle: {
                width: "98%",
                height: "300px",
                padding: "0 5px 44px 0",
                "box-sizing": "border-box",
              },
              attrs: { id: "leave" },
            }),
          ]),
        ]),
        _c("div", { staticClass: "box-cont" }, [
          _c("div", { staticClass: "box-cont-top" }, [
            _c("div", { staticClass: "box-cont-top-data" }, [
              _c("div", { staticClass: "box-cont-number" }, [
                _c("div", { staticClass: "numberBox" }, [
                  _c("div", { staticClass: "numberBoxImg" }, [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.studentCountVo.teacherCount
                              ? _vm.studentCountVo.teacherCount
                              : "0"
                          ) +
                          " "
                      ),
                      _c("br"),
                      _c("span", [_vm._v("人")]),
                    ]),
                  ]),
                  _c("div", { staticClass: "numberBoxText" }, [
                    _vm._v("教职工人数"),
                  ]),
                ]),
                _c("div", { staticClass: "numberBox" }, [
                  _c("div", { staticClass: "numberBoxImg1" }, [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.studentCountVo.classCount
                              ? _vm.studentCountVo.classCount
                              : "0"
                          ) +
                          " "
                      ),
                      _c("br"),
                      _c("span", [_vm._v("个")]),
                    ]),
                  ]),
                  _c("div", { staticClass: "numberBoxText" }, [
                    _vm._v("班级个数"),
                  ]),
                ]),
                _c("div", { staticClass: "numberBox" }, [
                  _c("div", { staticClass: "numberBoxImg2" }, [
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.studentCountVo.bindFaceCount
                              ? _vm.studentCountVo.bindFaceCount
                              : "0"
                          ) +
                          " "
                      ),
                      _c("br"),
                      _c("span", [_vm._v("人")]),
                    ]),
                  ]),
                  _c("div", { staticClass: "numberBoxText" }, [
                    _vm._v("已开通人脸"),
                  ]),
                ]),
                _c("div", { staticClass: "numberBox" }, [
                  _c("div", { staticClass: "numberBoxImg3" }, [
                    _c("div", [
                      _vm._v(" " + _vm._s(_vm.FaceDevice) + " "),
                      _c("br"),
                      _c("span", [_vm._v("台")]),
                    ]),
                  ]),
                  _c("div", { staticClass: "numberBoxText" }, [
                    _vm._v("人脸设备数量"),
                  ]),
                  _c("div", { staticClass: "equipment" }, [
                    _c("div", { on: { click: _vm.equipmentBoxShow } }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.equipmentData[_vm.equipmentIndex].typeName
                              ? _vm.equipmentData[_vm.equipmentIndex].typeName
                              : ""
                          ) + " "
                        ),
                      ]),
                      _c("img", {
                        attrs: {
                          src: require("../assets/DataSet/down.png"),
                          alt: "",
                        },
                      }),
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.equipmentShow,
                            expression: "equipmentShow",
                          },
                        ],
                        staticClass: "bottom-box-zhe",
                        attrs: { id: "equipments" },
                      },
                      _vm._l(_vm.equipmentData, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "li",
                            class: index == _vm.equipmentIndex ? "active" : "",
                            on: {
                              click: function ($event) {
                                return _vm.equipmentAdd(index)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item.typeName) + " ")]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "box-cont-list" }, [
              _c("div", { staticClass: "one" }, [
                _c("div", { staticClass: "one-number" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.studentCountVo.manCount
                          ? _vm.studentCountVo.manCount
                          : "0"
                      ) +
                      " "
                  ),
                  _c("span", [_vm._v("人")]),
                ]),
                _c("div", { staticClass: "one-headline" }, [
                  _vm._v("男生人数"),
                ]),
              ]),
              _c("div", { staticClass: "two" }, [
                _c("div", { staticClass: "two-number" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.studentCountVo.womanCount
                          ? _vm.studentCountVo.womanCount
                          : "0"
                      ) +
                      " "
                  ),
                  _c("span", [_vm._v("人")]),
                ]),
                _c("div", { staticClass: "two-headline" }, [
                  _vm._v("女生人数"),
                ]),
              ]),
              _c("div", { staticClass: "cont" }, [
                _c("div", { staticClass: "cont-number" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.studentCountVo.allStudentCount
                          ? _vm.studentCountVo.allStudentCount
                          : "0"
                      ) +
                      " "
                  ),
                  _c("br"),
                  _c("span", [_vm._v("人")]),
                ]),
                _c("div", { staticClass: "cont-headline" }, [
                  _vm._v("学生总人数"),
                ]),
              ]),
              _c("div", { staticClass: "one" }, [
                _c("div", { staticClass: "one-number" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.studentCountVo.attendCount
                          ? _vm.studentCountVo.attendCount
                          : "0"
                      ) +
                      " "
                  ),
                  _c("span", [_vm._v("人")]),
                ]),
                _c("div", { staticClass: "one-headline" }, [
                  _vm._v("走读生人数"),
                ]),
              ]),
              _c("div", { staticClass: "two" }, [
                _c("div", { staticClass: "two-number" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.studentCountVo.boardCount
                          ? _vm.studentCountVo.boardCount
                          : "0"
                      ) +
                      " "
                  ),
                  _c("span", [_vm._v("人")]),
                ]),
                _c("div", { staticClass: "two-headline" }, [
                  _vm._v("住宿生人数"),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "box-cont-bottom" }, [
            _c(
              "div",
              {
                staticClass: "bottom-box",
                class: _vm.flag == -1 ? "bottom-boxss" : "",
              },
              [
                _c("div", { staticClass: "bottom-box-top" }, [
                  _c("div", { on: { click: _vm.bottomBoxShows } }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.bottomBoxData[_vm.bottomBoxIndex]
                            ? _vm.bottomBoxData[_vm.bottomBoxIndex]
                                .statisticsName
                            : ""
                        ) + " "
                      ),
                    ]),
                    _c("img", {
                      attrs: {
                        src: require("../assets/DataSet/down.png"),
                        alt: "",
                      },
                    }),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.bottomBoxShow,
                          expression: "bottomBoxShow",
                        },
                      ],
                      staticClass: "bottom-box-zhe",
                      attrs: { id: "smart" },
                    },
                    _vm._l(_vm.bottomBoxData, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "li",
                          class: index == _vm.bottomBoxIndex ? "active" : "",
                          on: {
                            click: function ($event) {
                              return _vm.bottomBoxDataAdd(index)
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.statisticsName ? item.statisticsName : ""
                              ) +
                              " "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "year-box" },
                  _vm._l(_vm.dateList, function (v, i) {
                    return _c(
                      "div",
                      {
                        key: i,
                        staticClass: "year-item",
                        class: v == _vm.bottomBoxDataTime ? "year-ac" : "",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.dateClicks(v, i)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(v) + " ")]
                    )
                  }),
                  0
                ),
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.bottomBoxData[_vm.bottomBoxIndex].type == 1,
                      expression: "bottomBoxData[bottomBoxIndex].type == 1",
                    },
                  ],
                  class: _vm.bottomBoxShow ? "is-g" : "",
                  staticStyle: {
                    width: "100% !important",
                    height: "300px",
                    "box-sizing": "border-box",
                    "margin-top": "30px",
                  },
                  attrs: { id: "Phone" },
                }),
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.bottomBoxData[_vm.bottomBoxIndex].type == 2,
                      expression: "bottomBoxData[bottomBoxIndex].type == 2",
                    },
                  ],
                  class: _vm.bottomBoxShow ? "is-g" : "",
                  staticStyle: {
                    width: "100% !important",
                    height: "300px",
                    "box-sizing": "border-box",
                    "margin-top": "30px",
                  },
                  attrs: { id: "Books" },
                }),
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.bottomBoxData[_vm.bottomBoxIndex].type == 5 ||
                        _vm.bottomBoxData[_vm.bottomBoxIndex].type == 6,
                      expression:
                        "bottomBoxData[bottomBoxIndex].type == 5 ||\n              bottomBoxData[bottomBoxIndex].type == 6\n              ",
                    },
                  ],
                  class: _vm.bottomBoxShow ? "is-g" : "",
                  staticStyle: {
                    width: "100% !important",
                    height: "300px",
                    "box-sizing": "border-box",
                    "margin-top": "30px",
                  },
                  attrs: { id: "laundry" },
                }),
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.bottomBoxData[_vm.bottomBoxIndex].type == 4,
                      expression: "bottomBoxData[bottomBoxIndex].type == 4",
                    },
                  ],
                  class: _vm.bottomBoxShow ? "is-g" : "",
                  staticStyle: {
                    width: "100% !important",
                    height: "300px",
                    "box-sizing": "border-box",
                    "margin-top": "30px",
                  },
                  attrs: { id: "entrance" },
                }),
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.bottomBoxData[_vm.bottomBoxIndex].type == 3,
                      expression: "bottomBoxData[bottomBoxIndex].type == 3",
                    },
                  ],
                  class: _vm.bottomBoxShow ? "is-g" : "",
                  staticStyle: {
                    width: "100% !important",
                    height: "300px",
                    "box-sizing": "border-box",
                    "margin-top": "30px",
                  },
                  attrs: { id: "dinner" },
                }),
              ]
            ),
            _vm.flag != -1
              ? _c("div", { staticClass: "bottom-box-right" }, [
                  _c("div", { staticClass: "bottom-box-top" }, [
                    _c("div", { on: { click: _vm.checkBoxShow } }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.checkData[_vm.checkIndex].statisticsName) +
                            " "
                        ),
                      ]),
                      _c("img", {
                        attrs: {
                          src: require("../assets/DataSet/down.png"),
                          alt: "",
                        },
                      }),
                    ]),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.checkShow,
                            expression: "checkShow",
                          },
                        ],
                        staticClass: "bottom-box-zhe",
                        attrs: { id: "smarts" },
                      },
                      _vm._l(_vm.checkData, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "li",
                            class: index == _vm.checkIndex ? "active" : "",
                            on: {
                              click: function ($event) {
                                return _vm.checkIndexAdd(index)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item.statisticsName) + " ")]
                        )
                      }),
                      0
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "year-boxs" },
                    [
                      _c("el-date-picker", {
                        staticStyle: { color: "#18fefe" },
                        attrs: {
                          type: "date",
                          placeholder: "选择日期",
                          clearable: false,
                          "popper-class": "dark_form",
                        },
                        on: { change: _vm.changeTime },
                        model: {
                          value: _vm.timeVal,
                          callback: function ($$v) {
                            _vm.timeVal = $$v
                          },
                          expression: "timeVal",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", {
                    class: _vm.bottomBoxShow ? "is-g" : "",
                    staticStyle: {
                      width: "410px",
                      height: "305px",
                      "box-sizing": "border-box",
                      "margin-top": "30px",
                    },
                    attrs: { id: "leavings" },
                  }),
                ])
              : _vm._e(),
          ]),
        ]),
        _c("div", { staticClass: "box-right" }, [
          _vm._m(4),
          _c("div", { staticClass: "map-box" }, [
            _c("div", { staticClass: "bottom-box-top" }, [
              _c("div", { on: { click: _vm.informBoxShow } }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.informData[_vm.informIndex]) + " "),
                ]),
                _c("img", {
                  attrs: {
                    src: require("../assets/DataSet/down.png"),
                    alt: "",
                  },
                }),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.informShow,
                      expression: "informShow",
                    },
                  ],
                  staticClass: "bottom-box-zhe",
                  attrs: { id: "smartss" },
                },
                _vm._l(_vm.informData, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "li",
                      class: index == _vm.informIndex ? "active" : "",
                      on: {
                        click: function ($event) {
                          return _vm.informAdd(index)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(item) + " ")]
                  )
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "tip-box" }, [
              _c(
                "div",
                {
                  class: { anim: _vm.animate == true },
                  on: {
                    mouseenter: _vm.handleMouseEnter,
                    mouseleave: _vm.handleMouseLeave,
                  },
                },
                _vm._l(_vm.tabList, function (item, index) {
                  return _c(
                    "div",
                    {
                      staticClass: "i-list",
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "space-between",
                        "margin-bottom": "22px",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.openDetail(item)
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: { display: "flex", "font-size": "14px" },
                        },
                        [
                          _c("div", { staticClass: "title" }, [
                            _vm._v(_vm._s(item.title ? item.title : "")),
                          ]),
                        ]
                      ),
                      _c("div", { staticClass: "time" }, [
                        _vm._v(_vm._s(item.createTime ? item.createTime : "")),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.open,
              expression: "open",
            },
          ],
          staticClass: "dialog",
        },
        [
          _c("div", { staticClass: "dialog-box" }, [
            _c("div", { staticClass: "close", on: { click: _vm.close } }, [
              _c("img", {
                attrs: { src: require("../assets/DataSet/close.png"), alt: "" },
              }),
            ]),
            _c("div", { staticClass: "dialog-box-top" }, [
              _vm._v(" " + _vm._s(_vm.form.title) + " "),
            ]),
            _c("div", { staticClass: "dialog-box-subheading" }, [
              _c("div", { staticClass: "dialog-box-subheading-type" }, [
                _vm._v(" " + _vm._s(_vm.form.type) + " "),
              ]),
              _c("div", { staticClass: "dialog-box-subheading-issuer" }, [
                _vm._v(" 发布人: " + _vm._s(_vm.form.createByName) + " "),
              ]),
              _c("div", { staticClass: "dialog-box-subheading-time" }, [
                _vm._v(" 时间:" + _vm._s(_vm.form.createTime) + " "),
              ]),
            ]),
            _c("div", {
              staticClass: "dialog-box-cont",
              domProps: { innerHTML: _vm._s(_vm.form.content) },
            }),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "r-box" }, [
      _c("img", {
        attrs: { src: require("../assets/DataSet/refresh.png"), alt: "" },
      }),
      _vm._v(" "),
      _c("span", [_vm._v("刷新数据")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "box-left-teacher" }, [
      _c("div", { staticClass: "box-left-teacher-top" }, [_vm._v("师资数据")]),
      _c("div", {
        staticStyle: {
          width: "440px",
          height: "450px",
          padding: "0 5px 44px 0",
          "box-sizing": "border-box",
        },
        attrs: { id: "teacherData" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "box-img" }, [
      _c("img", {
        staticClass: "img-s",
        attrs: { src: require("../assets/DataSet/percentage.png"), alt: "" },
      }),
      _c("div", {
        staticClass: "box-left-personnel-center-left",
        attrs: { id: "percentage" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "box-left-personnel-center-right-Two" }, [
      _c("span"),
      _c("div", [_vm._v("今日请假人数")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "map-box" }, [
      _c("div", { staticClass: "map-box-top" }, [_vm._v("学生人数汇总")]),
      _c("div", {
        staticStyle: {
          width: "410px",
          height: "400px",
          "box-sizing": "border-box",
        },
        attrs: { id: "student" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }