import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken, setToken, setExpiresIn } from '@/utils/auth'
import { isRelogin } from '@/utils/request'

NProgress.configure({ showSpinner: false })
// 重定向 白名单
const whiteList = ['/login', '/auth-redirect', '/bind', '/register', '/fitMobile', '/zfbChat']

router.beforeEach((to, from, next) => {
  NProgress.start()
  let url = decodeURI(window.location.href);
  let getqyinfo = url.split('?')[1]   //   截取到参数部分
  let getqys = new URLSearchParams('?' + getqyinfo)  //将参数放在URLSearchParams函数中
  let gettoken = getqys.get('token')  // token
  let getexpires = getqys.get('expires')
  let isNo = (getexpires + '').indexOf('#');
  let expiresIn = (getexpires + '').substring(0, isNo) // expires

  // var aa = url.indexOf('=');
  // var isNo = url.indexOf('#');
  // console.log(aa, 'aa');
  // if (aa > -1) {
  //   url = url.substring(aa + 1, bb);

  //   console.log(url, 'getToken()');
  // }
  if (gettoken) {
    setToken(gettoken)
    store.commit('SET_TOKEN', gettoken)
    setExpiresIn(expiresIn)
    store.commit('SET_EXPIRES_IN', expiresIn)
  }

  if (getToken() || gettoken) {
    to.meta.title && store.dispatch('settings/setTitle', to.meta.title)
    /* has token*/
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      if (store.getters.roles.length === 0) {
        isRelogin.show = true
        // 判断当前用户是否已拉取完user_info信息
        store.dispatch('GetInfo').then(() => {
          isRelogin.show = false
          store.dispatch('GenerateRoutes').then(accessRoutes => {
            // 根据roles权限生成可访问的路由表
            router.addRoutes(accessRoutes) // 动态添加可访问路由表
            next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
          })
        }).catch(err => {
          store.dispatch('LogOut').then(() => {
            Message.error(err)
            // store.commit('setPersonType',3)
            console.log(123);
            next({ path: '/' })
          })
        })
      } else {
        next()
      }
    }
  } else {
    if (!localStorage.getItem('personType')) {
      let href = window.location.href
      console.log(window.location.href, 'href');
      if (href.indexOf('school') == -1) {
        store.commit('setPersonType', 1)
      } else {
        store.commit('setPersonType', 3)
      }
      store.commit('setRole', 2)
      console.log(store, 'personType');
    }
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      console.log(whiteList.indexOf(to.path));
      next()
    } else {
      console.log(to.path, 789);
      next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
      NProgress.done()
      // store.commit('setRole', 1)
      // console.log(store.state.permission.role, 'role');
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
