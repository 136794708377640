<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default  {
  name:  'App',
    metaInfo() {
        return {
            title: this.$store.state.settings.dynamicTitle && this.$store.state.settings.title,
            titleTemplate: title => {
                return title ? `${title} - ${process.env.VUE_APP_TITLE}` : process.env.VUE_APP_TITLE
            }
        }
    }
}
</script>
<style lang="scss">
.el-tooltip__popper {
  max-width: 40%;
}
.el-picker__popper.el-popper[role="tooltip"] {
  border-color: #24386000;
}
.el-popper.is-light .el-popper__arrow::before {
  background: #131e34;
  border: none !important;
}
.dark_form {
  background: #11234F !important;
  border: #131e34;
  .el-date-picker__header--bordered {
    border-bottom: solid 0.01rem #818ea9;
  }
  .el-picker__popper.el-popper[role="tooltip"] .el-popper__arrow::before {
    border: none !important;
  }
  .el-picker-panel__body {
    background: #11234F !important;
    color: #89beff !important;
    border: 1px solid #3E599C;
  }
  .el-date-picker__header-label {
    color: #fbfbfb;
    font-family: "HarmonyOS Sans";
  }
  .el-picker-panel__icon-btn {
    color: #fbfbfb;
    font-family: "HarmonyOS Sans";
  }
  .el-month-table td .cell {
    color: #fbfbfb;
    font-family: "HarmonyOS Sans";
  }
.el-year-table td .cell {
    color: #fbfbfb;
    font-family: "HarmonyOS Sans";
}
.el-date-table td.today span{
  color: #fff !important;
}
.el-date-table td.next-month, .el-date-table td.prev-month {
  color: #566B8F;
}
.el-date-table th {
  color: #566B8F;
  border-bottom: #566B8F 1px solid  !important; 
}

}
</style>
