var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      !_vm.topNav
        ? _c("breadcrumb", {
            staticClass: "breadcrumb-container",
            attrs: { id: "breadcrumb-container" },
          })
        : _vm._e(),
      _vm.topNav
        ? _c("top-nav", {
            staticClass: "topmenu-container",
            attrs: { id: "topmenu-container" },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.personType == 1
            ? [
                _vm.$store.state.user.userType != 2
                  ? _c("img", {
                      staticClass: "user-avatars",
                      attrs: {
                        src: require("../../assets/DataSet/largeIcon.png"),
                        title: "数据大屏",
                      },
                      on: { click: _vm.monitors },
                    })
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm.personType == 3
            ? [
                _c("img", {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["school:dataSet:view"],
                      expression: "['school:dataSet:view']",
                    },
                  ],
                  staticClass: "user-avatars",
                  attrs: {
                    src: require("../../assets/DataSet/largeIcon.png"),
                    title: "数据大屏",
                  },
                  on: { click: _vm.monitors },
                }),
              ]
            : _vm._e(),
          _vm.device !== "mobile"
            ? [
                _c("screenfull", {
                  staticClass: "right-menu-item hover-effect",
                  attrs: { id: "screenfull" },
                }),
              ]
            : _vm._e(),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" },
            },
            [
              _c(
                "div",
                { staticClass: "avatar-wrapper" },
                [
                  _c("img", {
                    staticClass: "user-avatar",
                    attrs: { src: _vm.avatar },
                  }),
                  _c("span", [_vm._v(_vm._s(_vm.name))]),
                  _c("i", { staticClass: "el-icon-arrow-down" }),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/user/profile" } },
                        [_c("el-dropdown-item", [_vm._v("个人中心")])],
                        1
                      ),
                      _c(
                        "el-dropdown-item",
                        {
                          nativeOn: {
                            click: function ($event) {
                              _vm.setting = true
                            },
                          },
                        },
                        [_c("span", [_vm._v("布局设置")])]
                      ),
                      _c(
                        "el-dropdown-item",
                        {
                          attrs: { divided: "" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.logout($event)
                            },
                          },
                        },
                        [_c("span", [_vm._v("退出登录")])]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }