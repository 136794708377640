import request from '@/utils/request'

// 学校列表
export function getEchartsSchool(data) {
    return request({
        url: '/xyw-system/largeScreen/admin/getSchoolList',
        method: 'post',
        data
    })
}

// 查询代理商管理列表
export function schoolOrBindStudentIncrease(data) {
    return request({
        url: '/xyw-system/largeScreen/admin/schoolOrBindStudentIncrease',
        method: 'post',
        data
    })
}
// 查询校收收访问
export function xssAccessRecord(data) {
    return request({
        url: '/xyw-system/largeScreen/admin/xssAccessRecord',
        method: 'post',
        data
    })
}
// 开通率
export function schoolUserAgent(data) {
    return request({
        url: '/xyw-system/largeScreen/admin/schoolUserAgent',
        method: 'post',
        data
    })
}
// 部署台数
export function countDevice(data) {
    return request({
        url: '/xyw-system/largeScreen/admin/countDevice',
        method: 'post',
        data
    })
}
// 地图
export function getSchoolInfoForMap(data) {
    return request({
        url: '/xyw-system/largeScreen/admin/getSchoolInfoForMap',
        method: 'post',
        data
    })
}
// 流水图表
export function currentAmountStatement(data) {
    return request({
        url: '/xyw-system/largeScreen/admin/currentAmountStatement',
        method: 'post',
        data
    })
}
// IMS/微信音视频阈值告警
export function getIms(data) {
    return request({
        url: '/xyw-system/largeScreen/admin/getImsOrWeChat',
        method: 'post',
        data
    })
}
// 使用记录
export function videoPhoneUseCount(data) {
    return request({
        url: '/xyw-system/largeScreen/admin/videoPhoneUseCount',
        method: 'post',
        data
    })
}
// 购买记录
export function buyPackageCount(data) {
    return request({
        url: '/xyw-system/largeScreen/admin/buyPackageCount',
        method: 'post',
        data
    })
}
// 全国·学校
export function getNationwideSchool(data) {
    return request({
        url: '/xyw-system/largeScreen/admin/getNationwideSchool',
        method: 'post',
        data
    })
}
// 实时消费记录
export function getRealTimeConsumption(data) {
    return request({
        url: '/xyw-system/largeScreen/admin/getRealTimeConsumption',
        method: 'post',
        data
    })
}
// 自助售货机
export function getVendingUseCount(data) {
    return request({
        url: '/xyw-system/homePageApi/getVendingUseCount',
        method: 'post',
        data
    })
}