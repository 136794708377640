import request from '@/utils/request'
// 设备图表
export function getDevices(data) {
    return request({
        url: '/xyw-system/homePageApi/getDeviceAndStudentCount',
        method: 'post',
        data
    })
}
// 地图
export function getMaps(data) {
    return request({
        url: '/xyw-system/homePageApi/getSchoolMap',
        method: 'post',
        data
    })
}
// 图表里的学校列表
export function getEchartsSchool(data) {
    return request({
        url: '/xyw-system/homePageApi/getSchoolList',
        method: 'post',
        data
    })
}
// 智能公话使用统计
export function getVideoPhoneUseCount(data) {
    return request({
        url: '/xyw-system/homePageApi/getVideoPhoneUseCount',
        method: 'post',
        data
    })
}
// 智能公话充值统计
export function getVideoPhoneTopUpCount(data) {
    return request({
        url: '/xyw-system/homePageApi/getVideoPhoneTopUpCount',
        method: 'post',
        data
    })
}
// 校园团餐使用统计
export function getMealMachineUseCount(data) {
    return request({
        url: '/xyw-system/homePageApi/getMealMachineUseCount',
        method: 'post',
        data
    })
}
// 校园团餐营收统计
export function getMealMachineRevenueCount(data) {
    return request({
        url: '/xyw-system/homePageApi/getMealMachineRevenueCount',
        method: 'post',
        data
    })
}
// 校园图书借阅统计
export function getBookSchoolBorrowCount(data) {
    return request({
        url: '/xyw-system/homePageApi/getBookSchoolBorrowCount',
        method: 'post',
        data
    })
}
// 共享洗衣使用统计
export function getWashingUseCount(data) {
    return request({
        url: '/xyw-system/homePageApi/getWashingUseCount',
        method: 'post',
        data
    })
}
// 入离校门禁进出统计
export function getAccessSchoolCount(data) {
    return request({
        url: '/xyw-system/homePageApi/getAccessSchoolCount',
        method: 'post',
        data
    })
}
// 宿舍门禁进出统计
export function getAccessDormitoryCount(data) {
    return request({
        url: '/xyw-system/homePageApi/getAccessDormitoryCount',
        method: 'post',
        data
    })
}
// 分类 查学校
export function getBussinessSchoolList(data) {
    return request({
        url: '/xyw-system/homePageApi/getBussinessSchoolList',
        method: 'post',
        data
    })
}
export function getVendingUseCount(data) {
    return request({
        url: '/xyw-system/homePageApi/getVendingUseCount',
        method: 'post',
        data
    })
}

export function getDeviceCount(data) {
    return request({
        url: '/xyw-system/homePageApi/getDeviceCount',
        method: 'post',
        data
    })
}

export function getPeopleCount(data) {
    return request({
        url: '/xyw-system/homePageApi/getPeopleCount',
        method: 'post',
        data
    })
}

export function getShowerUseCount(data) {
    return request({
        url: '/xyw-system/homePageApi/getShowerUseCount',
        method: 'post',
        data
    })
}