var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    { ref: "form", attrs: { model: _vm.user, "label-width": "120px" } },
    [
      _vm.isOld
        ? _c(
            "el-form-item",
            { attrs: { label: "旧退款密码", prop: "oldPassword" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: {
                  placeholder: "请输入旧退款密码",
                  oninput: "value=value.replace(/[\\W_]/g,'')",
                  maxlength: "6",
                  type: "password",
                  "show-password": "",
                },
                model: {
                  value: _vm.user.oldPassword,
                  callback: function ($$v) {
                    _vm.$set(_vm.user, "oldPassword", $$v)
                  },
                  expression: "user.oldPassword",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-form-item",
        {
          staticStyle: { position: "relative" },
          attrs: { label: "新退款密码", prop: "newPassword" },
        },
        [
          _c("el-tooltip", { attrs: { placement: "top", effect: "light" } }, [
            _c("div", { attrs: { slot: "content" }, slot: "content" }, [
              _vm._v(
                "学校的全部教职工在平台上为订单退款时，将会输入退款密码。"
              ),
              _c("br"),
              _vm._v("配置为空或不进行配置，视为关闭此功能"),
            ]),
            _c("i", {
              staticClass: "el-icon-question",
              staticStyle: {
                color: "rgb(34, 98, 255)",
                "font-size": "16px",
                cursor: "pointer",
                position: "absolute",
                top: "10px",
                left: "-102px",
              },
            }),
          ]),
          _c("el-input", {
            staticStyle: { width: "300px" },
            attrs: {
              placeholder: "请输入最长6位组合密码",
              oninput: "value=value.replace(/[\\W_]/g,'')",
              maxlength: "6",
              type: "password",
              "show-password": "",
            },
            model: {
              value: _vm.user.newPassword,
              callback: function ($$v) {
                _vm.$set(_vm.user, "newPassword", $$v)
              },
              expression: "user.newPassword",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "确认新退款密码", prop: "affirmPassword" } },
        [
          _c("el-input", {
            staticStyle: { width: "300px" },
            attrs: {
              placeholder: "请输入最长6位组合密码",
              type: "password",
              "show-password": "",
              oninput: "value=value.replace(/[\\W_]/g,'')",
              maxlength: "6",
            },
            model: {
              value: _vm.user.affirmPassword,
              callback: function ($$v) {
                _vm.$set(_vm.user, "affirmPassword", $$v)
              },
              expression: "user.affirmPassword",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.submit },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "mini" },
              on: { click: _vm.close },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }