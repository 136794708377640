var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container home" },
    [
      _vm.personType == 1
        ? [
            _vm.devInfo.agentCount
              ? _c("div", { staticClass: "new-top" }, [
                  _c("div", { staticClass: "new-top-l" }, [
                    _c("div", { staticClass: "left-i" }, [
                      _c("img", {
                        staticClass: "img",
                        attrs: {
                          src: require("@/assets/images/school.png"),
                          alt: "",
                        },
                      }),
                      _c("div", [
                        _c("div", [_vm._v("学校")]),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "22px",
                              "font-weight": "700",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.devInfo.schoolCount
                                  ? _vm.devInfo.schoolCount
                                  : 0
                              )
                            ),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4E5969",
                                  "font-size": "14px",
                                },
                              },
                              [_vm._v("所")]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "left-i" }, [
                      _c("img", {
                        staticClass: "img",
                        attrs: {
                          src: require("@/assets/images/agent.png"),
                          alt: "",
                        },
                      }),
                      _c("div", [
                        _c("div", [_vm._v("代理商")]),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "22px",
                              "font-weight": "700",
                            },
                          },
                          [
                            _vm._v(_vm._s(_vm.devInfo.agentCount)),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4E5969",
                                  "font-size": "14px",
                                },
                              },
                              [_vm._v("家")]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "left-i" }, [
                      _c("img", {
                        staticClass: "img",
                        attrs: {
                          src: require("@/assets/images/xss.png"),
                          alt: "",
                        },
                      }),
                      _c("div", [
                        _c("div", [_vm._v("人脸采集用户 ")]),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "22px",
                              "font-weight": "700",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.devInfo.studentFaceCount
                                  ? _vm.devInfo.studentFaceCount
                                  : 0
                              )
                            ),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4E5969",
                                  "font-size": "14px",
                                },
                              },
                              [_vm._v("人")]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "xian" }),
                  _c("div", { staticClass: "new-c-r" }, [
                    _c("div", { staticClass: "right-l" }, [
                      _c("div", [
                        _c("div", [_vm._v("总人数 ")]),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "22px",
                              "font-weight": "700",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.devInfo.allPeopleCount
                                  ? _vm.devInfo.allPeopleCount
                                  : 0
                              )
                            ),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4E5969",
                                  "font-size": "14px",
                                },
                              },
                              [_vm._v("人")]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "right-l" }, [
                      _c("div", [
                        _c("div", [_vm._v("教师人数 ")]),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "22px",
                              "font-weight": "700",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.devInfo.teacherCount
                                  ? _vm.devInfo.teacherCount
                                  : 0
                              )
                            ),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4E5969",
                                  "font-size": "14px",
                                },
                              },
                              [_vm._v("人")]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "right-l" }, [
                      _c("div", [
                        _c("div", [_vm._v("学生人数 ")]),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "22px",
                              "font-weight": "700",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.devInfo.studentCount
                                  ? _vm.devInfo.studentCount
                                  : 0
                              )
                            ),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4E5969",
                                  "font-size": "14px",
                                },
                              },
                              [_vm._v("人")]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "right-l" }, [
                      _c("div", [
                        _c("div", [_vm._v("家长人数 ")]),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "22px",
                              "font-weight": "700",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.devInfo.parentCount
                                  ? _vm.devInfo.parentCount
                                  : 0
                              )
                            ),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4E5969",
                                  "font-size": "14px",
                                },
                              },
                              [_vm._v("人")]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            !_vm.devInfo.agentCount
              ? _c("div", { staticClass: "new-tops" }, [
                  _c("div", { staticClass: "new-top-l" }, [
                    _c("div", { staticClass: "left-i" }, [
                      _c("img", {
                        staticClass: "img",
                        attrs: {
                          src: require("@/assets/images/school.png"),
                          alt: "",
                        },
                      }),
                      _c("div", [
                        _c("div", [_vm._v("学校")]),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "22px",
                              "font-weight": "700",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.devInfo.schoolCount
                                  ? _vm.devInfo.schoolCount
                                  : 0
                              )
                            ),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4E5969",
                                  "font-size": "14px",
                                },
                              },
                              [_vm._v("所")]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "left-i" }, [
                      _c("img", {
                        staticClass: "img",
                        attrs: {
                          src: require("@/assets/images/xss.png"),
                          alt: "",
                        },
                      }),
                      _c("div", [
                        _c("div", [_vm._v("人脸采集用户 ")]),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "22px",
                              "font-weight": "700",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.devInfo.studentFaceCount
                                  ? _vm.devInfo.studentFaceCount
                                  : 0
                              )
                            ),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4E5969",
                                  "font-size": "14px",
                                },
                              },
                              [_vm._v("人")]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "xian" }),
                  _c("div", { staticClass: "new-c-r" }, [
                    _c("div", { staticClass: "right-l" }, [
                      _c("div", [
                        _c("div", [_vm._v("总人数 ")]),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "22px",
                              "font-weight": "700",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.devInfo.allPeopleCount
                                  ? _vm.devInfo.allPeopleCount
                                  : 0
                              )
                            ),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4E5969",
                                  "font-size": "14px",
                                },
                              },
                              [_vm._v("人")]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "right-l" }, [
                      _c("div", [
                        _c("div", [_vm._v("教师人数 ")]),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "22px",
                              "font-weight": "700",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.devInfo.teacherCount
                                  ? _vm.devInfo.teacherCount
                                  : 0
                              )
                            ),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4E5969",
                                  "font-size": "14px",
                                },
                              },
                              [_vm._v("人")]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "right-l" }, [
                      _c("div", [
                        _c("div", [_vm._v("学生人数 ")]),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "22px",
                              "font-weight": "700",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.devInfo.studentCount
                                  ? _vm.devInfo.studentCount
                                  : 0
                              )
                            ),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4E5969",
                                  "font-size": "14px",
                                },
                              },
                              [_vm._v("人")]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "right-l" }, [
                      _c("div", [
                        _c("div", [_vm._v("家长人数 ")]),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "22px",
                              "font-weight": "700",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.devInfo.parentCount
                                  ? _vm.devInfo.parentCount
                                  : 0
                              )
                            ),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#4E5969",
                                  "font-size": "14px",
                                },
                              },
                              [_vm._v("人")]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  width: "100%",
                  "justify-content": "space-between",
                },
              },
              [
                _c("div", { staticClass: "school-boxs" }, [
                  _c("div", { staticClass: "s-top" }, [
                    _c("span", { staticStyle: { "font-weight": "700" } }, [
                      _vm._v("设备分布图"),
                    ]),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "margin-left": "12px",
                          "font-size": "14px",
                          color: "#1D2129",
                        },
                      },
                      [_vm._v("学校")]
                    ),
                    _c(
                      "span",
                      {
                        staticStyle: { "font-size": "14px", color: "#1D2129" },
                      },
                      [
                        _vm._v(
                          "共部署" +
                            _vm._s(_vm.deviceTotal ? _vm.deviceTotal : 0) +
                            "台设备"
                        ),
                      ]
                    ),
                  ]),
                  _c("div", {
                    staticStyle: {
                      width: "98%",
                      height: "95%",
                      padding: "0 5px 44px 0",
                      "box-sizing": "border-box",
                    },
                    attrs: { id: "eqEchart" },
                  }),
                ]),
                _vm._m(0),
              ]
            ),
            _c(
              "div",
              { staticClass: "echarts-box" },
              _vm._l(_vm.echartsBox, function (item, index) {
                return _c("div", { key: index, staticClass: "smart-talk" }, [
                  _c("div", { staticClass: "talk-top" }, [
                    _c("div", { staticClass: "t-title" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "220px" },
                            attrs: { placeholder: "请选择", filterable: "" },
                            on: {
                              change: function ($event) {
                                return _vm.schoolChange($event, index)
                              },
                            },
                            model: {
                              value: item.talkVal,
                              callback: function ($$v) {
                                _vm.$set(item, "talkVal", $$v)
                              },
                              expression: "item.talkVal",
                            },
                          },
                          _vm._l(item.schoolList, function (v) {
                            return _c("el-option", {
                              key: v.schoolId,
                              attrs: { label: v.schoolName, value: v.schoolId },
                            })
                          }),
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "month-box" },
                          _vm._l(_vm.monthsTalk, function (item1, index1) {
                            return _c(
                              "div",
                              {
                                key: index1,
                                staticClass: "month",
                                class:
                                  item.talkMonKey && index1 == item.talkMonIndex
                                    ? "talk-ac"
                                    : "",
                                on: {
                                  click: function ($event) {
                                    return _vm.monthTalkClick(index, index1)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item1))]
                            )
                          }),
                          0
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "40px",
                              "text-align": "center",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.zoomChart(index)
                              },
                            },
                          },
                          [
                            _c("svg-icon", {
                              attrs: { "icon-class": "fullscreen" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  index == 0
                    ? _c("div", {
                        staticStyle: { width: "100%", height: "350px" },
                        attrs: { id: "talkEcharts" },
                      })
                    : _vm._e(),
                  index == 1
                    ? _c("div", {
                        staticStyle: { width: "100%", height: "350px" },
                        attrs: { id: "talkPayEcharts" },
                      })
                    : _vm._e(),
                  index == 2
                    ? _c("div", {
                        staticStyle: { width: "100%", height: "350px" },
                        attrs: { id: "groupEcharts" },
                      })
                    : _vm._e(),
                  index == 3
                    ? _c("div", {
                        staticStyle: { width: "100%", height: "350px" },
                        attrs: { id: "groupPayEcharts" },
                      })
                    : _vm._e(),
                  index == 4
                    ? _c("div", {
                        staticStyle: { width: "100%", height: "350px" },
                        attrs: { id: "bookEchart" },
                      })
                    : _vm._e(),
                  index == 5
                    ? _c("div", {
                        staticStyle: { width: "100%", height: "350px" },
                        attrs: { id: "washEchart" },
                      })
                    : _vm._e(),
                  index == 6
                    ? _c("div", {
                        staticStyle: { width: "100%", height: "350px" },
                        attrs: { id: "isLeaveSchool" },
                      })
                    : _vm._e(),
                  index == 7
                    ? _c("div", {
                        staticStyle: { width: "100%", height: "350px" },
                        attrs: { id: "isLeaveLife" },
                      })
                    : _vm._e(),
                  index == 8
                    ? _c("div", {
                        staticStyle: { width: "100%", height: "350px" },
                        attrs: { id: "vendingMachine" },
                      })
                    : _vm._e(),
                  index == 9
                    ? _c("div", {
                        staticStyle: { width: "100%", height: "350px" },
                        attrs: { id: "showerStatistics" },
                      })
                    : _vm._e(),
                  index == 10
                    ? _c("div", {
                        staticStyle: { width: "100%", height: "350px" },
                        attrs: { id: "hairDryer" },
                      })
                    : _vm._e(),
                ])
              }),
              0
            ),
          ]
        : [_c("schoolIndex")],
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.titleChart,
            visible: _vm.openChart,
            width: "82%",
            "append-to-body": "",
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.openChart = $event
            },
            opened: _vm.switchChart,
          },
        },
        [
          _vm.titleChart == "智能公话使用统计"
            ? _c("div", {
                staticStyle: { width: "100%", height: "550px" },
                attrs: { id: "talkEchartsDialog" },
              })
            : _vm._e(),
          _vm.titleChart == "智能公话充值统计"
            ? _c("div", {
                staticStyle: { width: "100%", height: "550px" },
                attrs: { id: "talkPayEchartsDialog" },
              })
            : _vm._e(),
          _vm.titleChart == "校园团餐使用统计"
            ? _c("div", {
                staticStyle: { width: "100%", height: "550px" },
                attrs: { id: "groupEchartsDialog" },
              })
            : _vm._e(),
          _vm.titleChart == "校园团餐营收统计"
            ? _c("div", {
                staticStyle: { width: "100%", height: "550px" },
                attrs: { id: "groupPayEchartsDialog" },
              })
            : _vm._e(),
          _vm.titleChart == "校园图书借阅统计"
            ? _c("div", {
                staticStyle: { width: "100%", height: "550px" },
                attrs: { id: "bookEchartDialog" },
              })
            : _vm._e(),
          _vm.titleChart == "共享洗衣使用统计"
            ? _c("div", {
                staticStyle: { width: "100%", height: "550px" },
                attrs: { id: "washEchartDialog" },
              })
            : _vm._e(),
          _vm.titleChart == "入离校门禁进出统计"
            ? _c("div", {
                staticStyle: { width: "100%", height: "550px" },
                attrs: { id: "isLeaveSchoolDialog" },
              })
            : _vm._e(),
          _vm.titleChart == "宿舍门禁进出统计"
            ? _c("div", {
                staticStyle: { width: "100%", height: "550px" },
                attrs: { id: "isLeaveLifeDialog" },
              })
            : _vm._e(),
          _vm.titleChart == "自助售货机使用统计"
            ? _c("div", {
                staticStyle: { width: "100%", height: "550px" },
                attrs: { id: "isLeaveVendingMachine" },
              })
            : _vm._e(),
          _vm.titleChart == "智慧淋浴使用统计"
            ? _c("div", {
                staticStyle: { width: "100%", height: "550px" },
                attrs: { id: "takeShowerStatistics" },
              })
            : _vm._e(),
          _vm.titleChart == "共享吹风机使用统计"
            ? _c("div", {
                staticStyle: { width: "100%", height: "550px" },
                attrs: { id: "hairDryerDialog" },
              })
            : _vm._e(),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "school-box" }, [
      _c("div", { staticClass: "s-top" }, [_vm._v("学校分布图")]),
      _c("div", {
        staticStyle: { width: "100%", height: "100%" },
        attrs: { id: "schoolEchart" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }