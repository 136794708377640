<template>
    <div class="container">
        <div class="info">
            <div class="info-item">
                <img src="@/assets/images/stu.png" alt="">
                <div style="display: flex;align-items: center;">
                    <span style="font-size: 24px;color: #333;font-weight: 700;">{{
                        headInfo.studentCount ? headInfo.studentCount : 0
                    }}</span>
                    <span style="font-size: 14px;color: #86909C;margin-left: 8px;white-space: nowrap;">学生(人)</span>
                </div>
            </div>
            <div class="info-item">
                <img src="@/assets/images/tea.png" alt="">
                <div style="display: flex;align-items: center;">
                    <span style="font-size: 24px;color: #333;font-weight: 700;">{{
                        headInfo.teacherCount ? headInfo.teacherCount : 0
                    }}</span>
                    <span style="font-size: 14px;color: #86909C;margin-left: 8px;white-space: nowrap;">教职工(人)</span>
                </div>
            </div>
            <div class="line"></div>
            <div class="num-box">
                <div class="n-item" v-for="(item, index) in numList" :key="index">
                    <span class="num">{{ item.num }}</span>
                    <span class="name">{{ item.name }}</span>
                </div>
            </div>
        </div>
        <div class="echarts-box">
            <!-- 学生统计 -->
            <div class="smart-talk" :class="echartsBox.length == 0 ? 's-odd' : ''">
                <div class="talk-top">
                    <div class="t-title">学生统计</div>
                    <div style="display: flex;align-items: center;">
                        <el-select v-model="stuObj.talkVal" placeholder="请选择" style="width:120px;"
                            @change="schoolChange($event, stuObj)">
                            <el-option v-for="v in stuOptions" :key="v.value" :label="v.label" :value="v.value">
                            </el-option>
                        </el-select>
                        <div style="width: 40px;text-align: center;cursor: pointer;"
                            @click="zoomChart({ name: '学生统计' }, 0)">
                            <svg-icon icon-class="fullscreen" />
                        </div>
                    </div>
                </div>
                <!-- 双折线 -->
                <div id="stuEcharts" style="width: 100%;height:350px;"></div>
            </div>
            <!-- 可配置图表 -->
            <div class="smart-talk" :class="(index + 1) == echartsBox.length ? 's-odd' : ''"
                v-for="(item, index) in echartsBox" :key="index">
                <template v-if="item.name != '通知'">
                    <div class="talk-top">
                        <div class="t-title">{{ item.name }}</div>
                        <div style="display: flex;align-items: center;">
                            <el-select v-model="item.talkVal" placeholder="请选择" style="width:120px;"
                                @change="schoolChange($event, item)"
                                v-if="item.name == '学生统计' || item.name == '考勤统计' || item.name == '智能门禁进出统计'">
                                <template v-if="item.name == '学生统计'">
                                    <el-option v-for="v in stuOptions" :key="v.value" :label="v.label" :value="v.value">
                                    </el-option>
                                </template>
                                <template v-if="item.name == '考勤统计'">
                                    <el-option v-for="v in leaveOptions" :key="v.value" :label="v.label" :value="v.value">
                                    </el-option>
                                </template>
                                <template v-if="item.name == '智能门禁进出统计'">
                                    <el-option v-for="v in guardOptions" :key="v.value" :label="v.label" :value="v.value">
                                    </el-option>
                                </template>
                            </el-select>
                            <el-date-picker style="width: 140px;margin-left: 10px;" v-model="item.timeVal" type="date"
                                placeholder="选择日期" v-if="item.name == '考勤统计'" :clearable="false" @change="changeTime">
                            </el-date-picker>
                            <div class="month-box" v-if="item.name != '学生统计' && item.name != '考勤统计'">
                                <div class="month"
                                    :class="(item.talkMonKey && (index1 == item.talkMonIndex)) ? 'talk-ac' : ''"
                                    v-for="(item1, index1) in monthsTalk" :key="index1"
                                    @click="monthTalkClick(index, index1, item)">{{
                                        item1
                                    }}</div>
                            </div>
                            <div style="width: 40px;text-align: center;cursor: pointer;" @click="zoomChart(item, index)">
                                <svg-icon icon-class="fullscreen" />
                            </div>
                        </div>
                    </div>
                    <!-- 双折线 -->
                    <div v-if="item.name == '考勤统计'" id="attendEcharts" style="width: 100%;height:350px;"></div>
                    <div v-if="item.name == '智能公话使用统计'" id="talkEcharts" style="width: 100%;height:350px;"></div>
                    <div v-if="item.name == '校园团餐营收统计'" id="groupPayEcharts" style="width: 100%;height:350px;"></div>
                    <div v-if="item.name == '校园图书借阅统计'" id="bookEcharts" style="width: 100%;height:350px;"></div>
                    <div v-if="item.name == '共享洗衣使用统计'" id="washEcharts" style="width: 100%;height:350px;"></div>
                    <div v-if="item.name == '智能门禁进出统计'" id="accessEcharts" style="width: 100%;height:350px;"></div>
                    <div v-if="item.name == '智慧淋浴使用统计'" id="showerStatistics" style="width: 100%;height:350px;"></div>
                    <div v-if="item.name == '共享吹风机使用统计'" id="hairDryer" style="width: 100%;height:350px;"></div>
                </template>
                <template v-else>
                    <div class="inform-top">
                        <div class="inform" v-for="(v, i) in tabs" :key="i" @click="tabClick(i)">
                            <div class="inform-item" :class="i == tabIndex ? 'ac-i' : ''">
                                <span>{{ v }}</span>
                                <div v-if="i == tabIndex" class="bar"></div>
                            </div>
                        </div>
                    </div>
                    <div class="inform-content">
                        <div class="i-list" v-for="(item, index) in tabList" :key="index">
                            <div class="i-item" @click="openDetail(item)">
                                <div>{{ item.title ? item.title : '' }}</div>
                                <div class="time">{{ item.createTime ? item.createTime : '' }}</div>
                            </div>
                        </div>
                    </div>

                </template>
            </div>
        </div>
        <!-- 新闻通知弹窗 -->
        <el-dialog :title="title" :visible.sync="open" width="630px" append-to-body>
            <el-form ref="form" :model="form" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="内容：">
                            <div style="width: 200%;" v-html="form.content"></div>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>
        <!-- echarts弹窗 -->
        <el-dialog :title="titleChart" :visible.sync="openChart" width="82%" append-to-body @opened="switchChart"
            destroy-on-close>
            <div v-if="titleChart == '学生统计'" id="stuEchartsDialog" style="width: 100%;height:550px;"></div>
            <div v-if="titleChart == '考勤统计'" id="attendEchartsDialog" style="width: 100%;height:550px;"></div>
            <div v-if="titleChart == '智能公话使用统计'" id="talkEchartsDialog" style="width: 100%;height:550px;"></div>
            <div v-if="titleChart == '校园团餐营收统计'" id="groupPayEchartsDialog" style="width: 100%;height:550px;"></div>
            <div v-if="titleChart == '校园图书借阅统计'" id="bookEchartsDialog" style="width: 100%;height:550px;"></div>
            <div v-if="titleChart == '共享洗衣使用统计'" id="washEchartsDialog" style="width: 100%;height:550px;"></div>
            <div v-if="titleChart == '智能门禁进出统计'" id="accessEchartsDialog" style="width: 100%;height:550px;"></div>
            <div v-if="titleChart == '智慧淋浴使用统计'" id="takeShowerStatistics" style="width: 100%;height:550px;"></div>
            <div v-if="titleChart == '共享吹风机使用统计'" id="hairDryerDialog" style="width: 100%;height:550px;"></div>
        </el-dialog>
    </div>
</template>

<script>
import { mapState } from "vuex";
import * as echarts from "echarts";
import { getStudentAndTeacherCount, getStudentCount, getSchoolRelease, getStudentAttend, getSchoolMealCount } from '@/api/schoolIndex'
import { getVideoPhoneUseCount, getAccessDormitoryCount, getBookSchoolBorrowCount, getWashingUseCount, getAccessSchoolCount ,getShowerUseCount} from '@/api/index'
export default {
    name: 'schoolIndex',

    data() {
        return {
            openChart: false,
            titleChart: '',
            loading: '',
            open: false,
            title: '',
            form: {
                content: ''
            },
            authList: [],
            headInfo: {},
            tabIndex: '',
            echartsBox: [

                {
                    name: '通知', talkVal: '', talkMonKey: true, talkMonIndex: '0', key: 'inform', auth: 'school:chart:inform'
                },
                {
                    name: '考勤统计', timeVal: '', talkVal: '1', talkMonKey: true, talkMonIndex: '0', key: 'attend', auth: 'school:chart:attend'
                },
                {
                    name: '智能公话使用统计', talkVal: '', talkMonKey: true, talkMonIndex: '0', key: 'talkUse', auth: 'school:chart:talkUse'
                },
                {
                    name: '校园团餐营收统计', talkVal: '', talkMonKey: true, talkMonIndex: '0', key: 'income', auth: 'school:chart:income'
                },
                {
                    name: '校园图书借阅统计', talkVal: '', talkMonKey: true, talkMonIndex: '0', key: 'books', auth: 'school:chart:books'
                },
                {
                    name: '共享洗衣使用统计', talkVal: '', talkMonKey: true, talkMonIndex: '0', key: 'wash', auth: 'school:chart:wash'
                },
                {
                    name: '智能门禁进出统计', talkVal: '1', talkMonKey: true, talkMonIndex: '0', key: 'guard', auth: 'school:chart:guard'
                },
                {
                    name: '智慧淋浴使用统计', talkVal: '', talkMonKey: true, talkMonIndex: '0', key: 'shower', auth: 'school:chart:shower'
                },
                {
                    name: '共享吹风机使用统计', talkVal: '', talkMonKey: true, talkMonIndex: '0', key: 'hairDryer', auth: 'school:chart:hairDryer'
                }
            ],
            // echartsBox:[],
            monthsTalk: ['日', '月'],
            tabs: ['学校通知', '学校新闻'],
            tabList: [],
            stuObj: { name: '学生统计', talkVal: '0', talkMonKey: true, talkMonIndex: '0' },
            talkValOne: '',
            stuOptions: [{
                value: '0',
                label: '性别'
            }, {
                value: '1',
                label: '宿舍类型'
            }],
            leaveOptions: [{
                value: '1',
                label: '入离校考勤'
            }, {
                value: '2',
                label: '宿舍考勤'
            }],
            guardOptions: [{
                value: '1',
                label: '入离校门禁'
            }, {
                value: '2',
                label: '宿舍门禁'
            }],
            numList: [
                {
                    num: 0,
                    key: 1,
                    name: '男生(人)'
                },
                {
                    num: 0,
                    key: 2,
                    name: '女生(人)'
                },
                {
                    num: 0,
                    key: 3,
                    name: '走读生(人)'
                },
                {
                    num: 0,
                    key: 4,
                    name: '住宿生(人)'
                }
            ]
        }
    },
    created() {
        this.getNum()
        // 考勤统计 默认当前日期
        this.getData()
        // 查头部信息
        this.getHeadInfo()
    },
    computed: {
        ...mapState(['user']),
    },
    mounted() {
        // 学生统计
        this.getStuEcharts()
        // 学校通知
        this.getSchoolInform()

        // 考勤统计
        this.getAttendEcahrts()
        // 校园智能公话使用
        this.getTalkUse()
        // 校园团餐营收统计
        this.getGroupPayEcharts()
        // 校园图书借阅统计
        this.getBooks()
        // 共享洗衣
        this.getWash()
        // 门禁
        this.accesEcharts()
        this.getShowerStatistics()
        this.getHairDryer()

    },
    methods: {
        // 查图表数量
        getNum() {
            let list = []
            let result = []
            this.authList = this.user.permissions
            this.authList.forEach(item => {
                // console.log(item,'item');
                if (item && item.substring(0, 12) == 'school:chart') {
                    list.push(item)
                }
            })
            this.echartsBox.forEach(item => {
                list.forEach(v => {
                    if (v == item.auth) {
                        result.push(item)
                    }
                })
            })
            this.echartsBox = result
            console.log(this.echartsBox,'echartsBoxechartsBoxechartsBox');
        },
        // 获取数据
        getData() {
            let nowDay = this.$options.filters.formatData(new Date())
            this.echartsBox.forEach(v => {
                if (v.name == '考勤统计') {
                    v.timeVal = nowDay
                }
            });
        },
        // 头部信息
        getHeadInfo() {
            getStudentAndTeacherCount({}).then(res => {
                this.headInfo = res.data
                this.numList.forEach(v => {
                    switch (v.key) {
                        case 1:
                            v.num = res.data.manCount
                            break;
                        case 2:
                            v.num = res.data.womanCount
                            break;
                        case 3:
                            v.num = res.data.attendCount
                            break;
                        case 4:
                            v.num = res.data.boardCount
                            break;

                        default:
                            break;
                    }
                })
            })
        },
        // 通知、新闻
        tabClick(i) {
            this.tabIndex = i
            this.getSchoolInform()
        },
        // 时间选择
        changeTime(e) {
            this.echartsBox.forEach(v => {
                if (v.name == '考勤统计') {
                    v.timeVal = this.$options.filters.formatData(new Date(e))
                }
            })
            this.getAttendEcahrts()
        },
        // 学生统计-选类型
        schoolChange(e, val) {
            console.log(val, 'val');
            // let options = this.echartsBox[i].name == '学生统计' ? this.stuOptions : this.leaveOptions
            switch (val.name) {
                case '学生统计':
                    this.getStuEcharts()
                    break;
                case '考勤统计':
                    this.getAttendEcahrts()
                    break;
                case '智能门禁进出统计':
                    this.accesEcharts()
                    break;
                default:
                    break;
            }
        },
        // 日月切换
        monthTalkClick(index, i, val) {
            // 判断当前是第几个图标
            this.echartsBox[index].talkMonKey = true
            // 选中的图标里 切换 日 or 月
            this.echartsBox[index].talkMonIndex = i + ''
            switch (val.name) {
                case '智能公话使用统计':
                    this.getTalkUse()
                    break;
                case '校园团餐营收统计':
                    this.getGroupPayEcharts()
                    break;
                case '校园图书借阅统计':
                    this.getBooks()
                    break;
                case '共享洗衣使用统计':
                    this.getWash()
                    break;
                case '智能门禁进出统计':
                    this.accesEcharts()
                    break;
                    case '智慧淋浴使用统计':
                    this.getShowerStatistics()
                    break;
                case '共享吹风机使用统计':
                    this.getHairDryer()
                    break;     
                    
                default:
                    break;
            }
        },
        // 放大
        zoomChart(v, i) {
            this.openChart = true
            switch (v.name) {
                case '学生统计':
                    this.titleChart = '学生统计'
                    break;
                case '考勤统计':
                    this.titleChart = '考勤统计'
                    break;
                case '智能公话使用统计':
                    this.titleChart = '智能公话使用统计'
                    break;
                case '校园团餐营收统计':
                    this.titleChart = '校园团餐营收统计'
                    break;
                case '校园图书借阅统计':
                    this.titleChart = '校园图书借阅统计'
                    break;
                case '共享洗衣使用统计':
                    this.titleChart = '共享洗衣使用统计'
                    break;
                case '智能门禁进出统计':
                    this.titleChart = '智能门禁进出统计'
                    break;
                case '智慧淋浴使用统计':
                    this.titleChart = '智慧淋浴使用统计'
                    break;
                    case '共享吹风机使用统计':
                    this.titleChart = '共享吹风机使用统计'
                    break;
                default:
                    break;
            }
        },
        // 弹出层显示之前
        switchChart() {
            switch (this.titleChart) {
                case '学生统计':
                    this.getStuEchartsDialog()
                    break;
                case '考勤统计':
                    this.getAttendEcahrtsDialog()
                    break;
                case '智能公话使用统计':
                    this.getTalkUseDialog()
                    break;
                case '校园团餐营收统计':
                    this.getGroupPayEchartsDialog()
                    break;
                case '校园图书借阅统计':
                    this.getBooksDialog()
                    break;
                case '共享洗衣使用统计':
                    this.getWashDialog()
                    break;
                case '智能门禁进出统计':
                    this.accesEchartsDialog()
                    break;
                    case '智慧淋浴使用统计':
                    this.getShowerStatisticsDialog()
                    break;
                case '共享吹风机使用统计':
                    this.getHairDryerDialog()
                    break;
                    
                default:
                    break;
            }
        },
        // 学生统计-性别、住宿类型
        getStuEcharts() {
            let myChart = this.$echarts.init(
                document.getElementById("stuEcharts")
            );
            let data = {
                studentSearchType: this.stuObj.talkVal
            }
            let obj = this.stuObj
            getStudentCount(data).then(res => {
                let option = {
                    tooltip: {
                        trigger: 'axis',
                        extraCssText: 'width: 200px;height: 90px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
                        axisPointer: {       //坐标轴指示器
                            type: 'line',   //十字准星指示器
                        },
                        formatter: function (params) {
                            let res = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background: linear-gradient(180deg, rgba(2,102,253,0.65) 0%, rgba(2,102,253,0.3) 100%);" ></span ><span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name} &nbsp;&nbsp;共${params[0].data.allStudentCount}人</span><br/>`
                            params.forEach(item => {
                                res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName == '男' ? '男生' : item.seriesName == '女' ? '女生' : item.seriesName == '走读生' ? '走读生' : item.seriesName == '住宿生' ? '住宿生' : ''}&nbsp;&nbsp;&nbsp;${item.value}人</span><br/>`
                            });
                            return res
                        }
                    },
                    legend: {
                        data: this.stuObj.talkVal == 0 ? ['男', '女'] : ['住宿生', '走读生'],
                        icon: "circle",
                        itemWidth: 12,  // 设置宽度
                        itemHeight: 12, // 设置高度
                        top: 0,
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '10%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            // prettier-ignore
                            data: res.data.orgList,
                            axisLabel: {
                                // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
                                interval: 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
                                rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
                                textStyle: {
                                    color: "#767985",
                                }
                            },
                            axisTick: {
                                show: false // 不显示坐标轴刻度线
                            },
                            //  y轴刻度线
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: "#E5E6EB",
                                }
                            }
                        }
                    ],
                    yAxis: [
                        {
                            minInterval: 1, // 最小单位是1
                            name: '（人）',
                            type: 'value',
                            axisLabel: {
                                formatter: '{value}',
                                textStyle: {
                                    color: "#767985",
                                }
                            },
                            axisTick: {
                                show: false // 不显示坐标轴刻度线
                            },
                            //  y轴刻度线
                            axisLine: { show: false },
                            //分割线
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    type: 'dashed',
                                    color: '#E5E6EB'
                                }
                            }
                        }
                    ],
                    series: [
                        {
                            name: this.stuObj.talkVal == 0 ? '男' : '住宿生',
                            type: 'bar',
                            barWidth: "16", // 设置柱子的宽度
                            data: this.stuObj.talkVal == 0 ? res.data.manList : res.data.boardList,
                            itemStyle: {
                                barBorderRadius: [12, 12, 0, 0],
                                color: new echarts.graphic.LinearGradient(
                                    0, 0, 0, 1,
                                    [
                                        { offset: 0, color: "rgba(34,98,255,0.81)" },
                                        // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                                        { offset: 1, color: "rgba(34,98,255,0.43)" },
                                    ]
                                )
                            }
                        },
                        {
                            name: this.stuObj.talkVal == 0 ? '女' : '走读生',
                            type: 'bar',
                            barWidth: "16", // 设置柱子的宽度
                            data: this.stuObj.talkVal == 0 ? res.data.womanList : res.data.attendList,
                            itemStyle: {
                                barBorderRadius: [12, 12, 0, 0],
                                color: new echarts.graphic.LinearGradient(
                                    0, 0, 0, 1,
                                    [
                                        { offset: 0, color: "rgba(255,122,45,0.8)" },
                                        // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                                        { offset: 1, color: "rgba(255,122,45,0.4)" },
                                    ]
                                )
                            }
                        }
                    ],
                    dataZoom: [
                        {
                            show: true,
                            height: 8, // 滚动条高度
                            xAxisIndex: [0],
                            bottom: 15, // 距离底部距离
                            showDetail: false,
                            start: 0, //初始化时，滑动条宽度开始标度
                            showDataShadow: false,
                            borderColor: "transparent",
                            textStyle: {
                                fontSize: 0
                            },
                            endValue: 8,//从0开始的相当于5个(x轴展示的数量)
                            backgroundColor: "rgba(0,0,0,0)",
                            borderWidth: 0,
                            //                 handleIcon:
                            //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
                            //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
                            //   handleSize: "0%",
                            handleStyle: {
                                color: "#d3dee5"
                            }
                        }
                    ]
                }
                myChart.setOption(option);
            })
        },
        // 学生统计-性别、住宿类型 -弹窗
        getStuEchartsDialog() {
            let myChart = this.$echarts.init(
                document.getElementById("stuEchartsDialog")
            );
            let data = {
                studentSearchType: this.stuObj.talkVal
            }
            let obj = this.stuObj
            getStudentCount(data).then(res => {
                let option = {
                    tooltip: {
                        trigger: 'axis',
                        extraCssText: 'width: 200px;height: 90px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
                        axisPointer: {       //坐标轴指示器
                            type: 'line',   //十字准星指示器
                        },
                        formatter: function (params) {
                            let res = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background: linear-gradient(180deg, rgba(2,102,253,0.65) 0%, rgba(2,102,253,0.3) 100%);" ></span ><span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name} &nbsp;&nbsp;共${params[0].data.allStudentCount}人</span><br/>`
                            params.forEach(item => {
                                res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName == '男' ? '男生' : item.seriesName == '女' ? '女生' : item.seriesName == '走读生' ? '走读生' : item.seriesName == '住宿生' ? '住宿生' : ''}&nbsp;&nbsp;&nbsp;${item.value}人</span><br/>`
                            });
                            return res
                        }
                    },
                    legend: {
                        data: this.stuObj.talkVal == 0 ? ['男', '女'] : ['住宿生', '走读生'],
                        icon: "circle",
                        itemWidth: 12,  // 设置宽度
                        itemHeight: 12, // 设置高度
                        top: 0,
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '10%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            // prettier-ignore
                            data: res.data.orgList,
                            axisLabel: {
                                // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
                                interval: 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
                                rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
                                textStyle: {
                                    color: "#767985",
                                }
                            },
                            axisTick: {
                                show: false // 不显示坐标轴刻度线
                            },
                            //  y轴刻度线
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: "#E5E6EB",
                                }
                            }
                        }
                    ],
                    yAxis: [
                        {
                            minInterval: 1, // 最小单位是1
                            name: '（人）',
                            type: 'value',
                            axisLabel: {
                                formatter: '{value}',
                                textStyle: {
                                    color: "#767985",
                                }
                            },
                            axisTick: {
                                show: false // 不显示坐标轴刻度线
                            },
                            //  y轴刻度线
                            axisLine: { show: false },
                            //分割线
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    type: 'dashed',
                                    color: '#E5E6EB'
                                }
                            }
                        }
                    ],
                    series: [
                        {
                            name: this.stuObj.talkVal == 0 ? '男' : '住宿生',
                            type: 'bar',
                            barWidth: "16", // 设置柱子的宽度
                            data: this.stuObj.talkVal == 0 ? res.data.manList : res.data.boardList,
                            itemStyle: {
                                barBorderRadius: [12, 12, 0, 0],
                                color: new echarts.graphic.LinearGradient(
                                    0, 0, 0, 1,
                                    [
                                        { offset: 0, color: "rgba(34,98,255,0.81)" },
                                        // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                                        { offset: 1, color: "rgba(34,98,255,0.43)" },
                                    ]
                                )
                            }
                        },
                        {
                            name: this.stuObj.talkVal == 0 ? '女' : '走读生',
                            type: 'bar',
                            barWidth: "16", // 设置柱子的宽度
                            data: this.stuObj.talkVal == 0 ? res.data.womanList : res.data.attendList,
                            itemStyle: {
                                barBorderRadius: [12, 12, 0, 0],
                                color: new echarts.graphic.LinearGradient(
                                    0, 0, 0, 1,
                                    [
                                        { offset: 0, color: "rgba(255,122,45,0.8)" },
                                        // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                                        { offset: 1, color: "rgba(255,122,45,0.4)" },
                                    ]
                                )
                            }
                        }
                    ]
                }
                myChart.setOption(option);
            })
        },
        // 学校通知
        getSchoolInform() {
            this.loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(255, 255, 255, 0.8)",
            });
            let data = {
                functionType: this.tabIndex + 1
            }
            getSchoolRelease(data).then(res => {
                this.tabList = res.data
                this.loading.close()
            }).catch(err => {
                this.loading.close()
            })

        },
        // 学校通知、新闻详情
        openDetail(val) {
            this.open = true
            this.title = this.tabIndex == 0 ? '学校通知' : '学校新闻'
            this.form = {
                content: val.content.replace(/<img/g, "<img style='max-width:100%;height:auto;'")
            }
        },
        // 考勤统计
        getAttendEcahrts() {
            let flag = this.echartsBox.some(v => v.name == '考勤统计')
            if (flag) {
                let deviceType = ''
                let searchTime = ''
                let myChart = this.$echarts.init(
                    document.getElementById("attendEcharts")
                );
                this.echartsBox.forEach(v => {
                    if (v.name == '考勤统计') {
                        deviceType = v.talkVal
                        searchTime = v.timeVal
                    }
                })
                let data = {
                    deviceType: deviceType,
                    searchTime: searchTime
                }
                getStudentAttend(data).then(res => {
                    let option = {
                        tooltip: {
                            trigger: 'axis',
                            extraCssText: 'width: 200px;height: 111px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
                            axisPointer: {       //坐标轴指示器
                                type: 'line',   //十字准星指示器
                            },
                            formatter: function (params) {
                                console.log(params, 999);
                                let res = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background: linear-gradient(180deg, rgba(2,102,253,0.65) 0%, rgba(2,102,253,0.3) 100%);" ></span ><span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name} &nbsp;&nbsp;共${params[0].data.allPersonCount}人</span><br/>`
                                params.forEach(item => {
                                    res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName}&nbsp;&nbsp;&nbsp;${item.value}人</span><br/>`
                                });
                                return res
                            }
                        },
                        legend: {
                            data: ['正常', '异常', '请假'],
                            icon: "circle",
                            itemWidth: 12,  // 设置宽度
                            itemHeight: 12, // 设置高度
                            top: 0,
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '10%',
                            containLabel: true
                        },
                        xAxis: [
                            {
                                type: 'category',
                                // prettier-ignore
                                data: res.data.listOrg,
                                axisLabel: {
                                    // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
                                    interval: 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
                                    rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
                                    textStyle: {
                                        color: "#767985",
                                    }
                                },
                                axisTick: {
                                    show: false // 不显示坐标轴刻度线
                                },
                                //  y轴刻度线
                                axisLine: {
                                    show: true,
                                    lineStyle: {
                                        color: "#E5E6EB",
                                    }
                                }
                            }
                        ],
                        yAxis: [
                            {
                                name: '（人）',
                                type: 'value',
                                axisLabel: {
                                    formatter: '{value}',
                                    textStyle: {
                                        color: "#767985",
                                    }
                                },
                                axisTick: {
                                    show: false // 不显示坐标轴刻度线
                                },
                                //  y轴刻度线
                                axisLine: { show: false },
                                //分割线
                                splitLine: {
                                    show: true,
                                    lineStyle: {
                                        type: 'dashed',
                                        color: '#E5E6EB'
                                    }
                                }
                            }
                        ],
                        series: [
                            {
                                name: '正常',
                                type: 'bar',
                                barWidth: "16", // 设置柱子的宽度
                                data: res.data.listAll,
                                itemStyle: {
                                    barBorderRadius: [12, 12, 0, 0],
                                    color: new echarts.graphic.LinearGradient(
                                        0, 0, 0, 1,
                                        [
                                            { offset: 0, color: "rgba(2,102,253,0.65)" },
                                            // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                                            { offset: 1, color: "rgba(2,102,253,0.3)" },
                                        ]
                                    )
                                }
                            },
                            {
                                name: '异常',
                                type: 'bar',
                                barWidth: "16", // 设置柱子的宽度
                                data: res.data.ycPerson,
                                itemStyle: {
                                    barBorderRadius: [12, 12, 0, 0],
                                    color: new echarts.graphic.LinearGradient(
                                        0, 0, 0, 1,
                                        [
                                            { offset: 0, color: "rgba(228,60,60,0.8)" },
                                            // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                                            { offset: 1, color: "rgba(229,60,60,0.3)" },
                                        ]
                                    )
                                }
                            },
                            {
                                name: '请假',
                                type: 'bar',
                                barWidth: "16", // 设置柱子的宽度
                                data: res.data.qjPerson,
                                itemStyle: {
                                    barBorderRadius: [12, 12, 0, 0],
                                    color: new echarts.graphic.LinearGradient(
                                        0, 0, 0, 1,
                                        [
                                            { offset: 0, color: "rgba(255,122,45,0.8)" },
                                            // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                                            { offset: 1, color: "rgba(255,122,45,0.4)" },
                                        ]
                                    )
                                }
                            }
                        ],
                        dataZoom: [
                            {
                                show: true,
                                height: 8, // 滚动条高度
                                xAxisIndex: [0],
                                bottom: 15, // 距离底部距离
                                showDetail: false,
                                showDataShadow: false,
                                start: 0, //初始化时，滑动条宽度开始标度
                                borderColor: "transparent",
                                textStyle: {
                                    fontSize: 0
                                },
                                endValue: 8,//从0开始的相当于5个(x轴展示的数量)
                                backgroundColor: "rgba(0,0,0,0)",
                                borderWidth: 0,
                                //                 handleIcon:
                                //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
                                //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
                                //   handleSize: "0%",
                                handleStyle: {
                                    color: "#d3dee5"
                                }
                            }
                        ]
                    }
                    myChart.setOption(option);
                })
            }
        },
        // 考勤统计-弹窗
        getAttendEcahrtsDialog() {
            let flag = this.echartsBox.some(v => v.name == '考勤统计')
            if (flag) {
                let deviceType = ''
                let searchTime = ''
                let myChart = this.$echarts.init(
                    document.getElementById("attendEchartsDialog")
                );
                this.echartsBox.forEach(v => {
                    if (v.name == '考勤统计') {
                        deviceType = v.talkVal
                        searchTime = v.timeVal
                    }
                })
                let data = {
                    deviceType: deviceType,
                    searchTime: searchTime
                }
                getStudentAttend(data).then(res => {
                    let option = {
                        tooltip: {
                            trigger: 'axis',
                            extraCssText: 'width: 200px;height: 111px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
                            axisPointer: {       //坐标轴指示器
                                type: 'line',   //十字准星指示器
                            },
                            formatter: function (params) {
                                console.log(params, 999);
                                let res = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background: linear-gradient(180deg, rgba(2,102,253,0.65) 0%, rgba(2,102,253,0.3) 100%);" ></span ><span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name} &nbsp;&nbsp;共${params[0].data.allPersonCount}人</span><br/>`
                                params.forEach(item => {
                                    res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName}&nbsp;&nbsp;&nbsp;${item.value}人</span><br/>`
                                });
                                return res
                            }
                        },
                        legend: {
                            data: ['正常', '异常', '请假'],
                            icon: "circle",
                            itemWidth: 12,  // 设置宽度
                            itemHeight: 12, // 设置高度
                            top: 0,
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '10%',
                            containLabel: true
                        },
                        xAxis: [
                            {
                                type: 'category',
                                // prettier-ignore
                                data: res.data.listOrg,
                                axisLabel: {
                                    // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
                                    interval: 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
                                    rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
                                    textStyle: {
                                        color: "#767985",
                                    }
                                },
                                axisTick: {
                                    show: false // 不显示坐标轴刻度线
                                },
                                //  y轴刻度线
                                axisLine: {
                                    show: true,
                                    lineStyle: {
                                        color: "#E5E6EB",
                                    }
                                }
                            }
                        ],
                        yAxis: [
                            {
                                name: '（人）',
                                type: 'value',
                                axisLabel: {
                                    formatter: '{value}',
                                    textStyle: {
                                        color: "#767985",
                                    }
                                },
                                axisTick: {
                                    show: false // 不显示坐标轴刻度线
                                },
                                //  y轴刻度线
                                axisLine: { show: false },
                                //分割线
                                splitLine: {
                                    show: true,
                                    lineStyle: {
                                        type: 'dashed',
                                        color: '#E5E6EB'
                                    }
                                }
                            }
                        ],
                        series: [
                            {
                                name: '正常',
                                type: 'bar',
                                barWidth: "16", // 设置柱子的宽度
                                data: res.data.listAll,
                                itemStyle: {
                                    barBorderRadius: [12, 12, 0, 0],
                                    color: new echarts.graphic.LinearGradient(
                                        0, 0, 0, 1,
                                        [
                                            { offset: 0, color: "rgba(2,102,253,0.65)" },
                                            // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                                            { offset: 1, color: "rgba(2,102,253,0.3)" },
                                        ]
                                    )
                                }
                            },
                            {
                                name: '异常',
                                type: 'bar',
                                barWidth: "16", // 设置柱子的宽度
                                data: res.data.ycPerson,
                                itemStyle: {
                                    barBorderRadius: [12, 12, 0, 0],
                                    color: new echarts.graphic.LinearGradient(
                                        0, 0, 0, 1,
                                        [
                                            { offset: 0, color: "rgba(228,60,60,0.8)" },
                                            // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                                            { offset: 1, color: "rgba(229,60,60,0.3)" },
                                        ]
                                    )
                                }
                            },
                            {
                                name: '请假',
                                type: 'bar',
                                barWidth: "16", // 设置柱子的宽度
                                data: res.data.qjPerson,
                                itemStyle: {
                                    barBorderRadius: [12, 12, 0, 0],
                                    color: new echarts.graphic.LinearGradient(
                                        0, 0, 0, 1,
                                        [
                                            { offset: 0, color: "rgba(255,122,45,0.8)" },
                                            // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                                            { offset: 1, color: "rgba(255,122,45,0.4)" },
                                        ]
                                    )
                                }
                            }
                        ]
                    }
                    myChart.setOption(option);
                })
            }
        },
        // 智能公话使用统计
        getTalkUse() {
            let flag = this.echartsBox.some(v => v.name == '智能公话使用统计')
            if (flag) {
                let dayOrMonth = ''
                let myChart = this.$echarts.init(
                    document.getElementById("talkEcharts")
                );
                this.echartsBox.forEach(v => {
                    if (v.name == '智能公话使用统计') {
                        dayOrMonth = v.talkMonIndex
                    }
                })
                let data = {
                    dayOrMonth: dayOrMonth
                }
                getVideoPhoneUseCount(data).then(res => {
                    let xData = []
                    res.data.dateList.forEach(v => {
                        if (dayOrMonth == 0) {
                            xData.push(
                                v.substring(5, 10).replace(/-/g, "/")
                            )
                        } else {
                            xData.push(
                                v.substring(5) + '月'
                            )
                        }

                    })
                    // 指定图表的配置项和数据
                    let option = {
                        tooltip: {
                            trigger: 'axis',
                            extraCssText: 'width: 165px;height: 90px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
                            axisPointer: {       //坐标轴指示器
                                type: 'line',   //十字准星指示器
                            },
                            formatter: function (params) {
                                console.log(params);
                                // for (let i = 0; i < params.length; i++) {
                                let res = `${params[0].marker}<span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name}</span><br/>`
                                params.forEach(item => {
                                    res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName == '分钟' ? '通话分钟' : '使用人次'}&nbsp;&nbsp;&nbsp;${item.data}${item.seriesName == '分钟' ? '分钟' : '次'}</span><br/>`
                                });
                                return res
                            }
                        },
                        legend: {
                            icon: "circle",
                            itemWidth: 12,  // 设置宽度
                            itemHeight: 12, // 设置高度
                            top: 0,
                            data: ['分钟', '次数']
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '10%',
                            containLabel: true
                        },
                        xAxis: {
                            type: 'category',
                            boundaryGap: true,
                            axisLabel: {
                                // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
                                interval: 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
                                rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
                                textStyle: {
                                    color: "#767985",
                                }
                            },
                            data: xData,
                            axisTick: {
                                show: false // 不显示坐标轴刻度线
                            },
                            //  y轴刻度线
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: "#E5E6EB",
                                }
                            }
                        },
                        yAxis: [
                            {
                                type: 'value',
                                name: '（分钟）',
                                //坐标轴最大值、最小值、强制设置数据的步长间隔
                                // interval: 5,
                                axisLabel: {
                                    //y轴上带的单位
                                    formatter: '{value}',
                                    textStyle: {
                                        color: "#767985",
                                    }
                                },
                                axisTick: {
                                    show: false // 不显示坐标轴刻度线
                                },
                                //  y轴刻度线
                                axisLine: { show: false },
                                //分割线
                                splitLine: {
                                    show: false,
                                    lineStyle: {
                                        type: 'dashed', // 虚线
                                        color: '#E5E6EB'
                                    }
                                }
                            },
                            {
                                minInterval: 1, // 最小单位是1
                                type: 'value',
                                name: '（次数）',
                                axisLabel: {
                                    formatter: '{value}',
                                    textStyle: {
                                        color: "#767985",
                                    }
                                },
                                axisTick: {
                                    show: false // 不显示坐标轴刻度线
                                },
                                //  y轴刻度线
                                axisLine: { show: false },
                                //分割线
                                splitLine: {
                                    show: true,
                                    lineStyle: {
                                        type: 'dashed',
                                        color: '#E5E6EB'
                                    }
                                }
                            }
                        ],
                        series: [
                            {
                                smooth: true,
                                name: '分钟',
                                type: 'line',
                                symbolSize: 8, // 设置折线上圆点大小
                                // symbol: 'circle', // 设置拐点为实心圆
                                yAxisIndex: 0,
                                data: res.data.durationList,
                                itemStyle: {
                                    color: '#33D067',
                                },
                                areaStyle: {
                                    normal: {
                                        color: new echarts.graphic.LinearGradient(
                                            0, 0, 0, 1,
                                            [
                                                { offset: 0, color: " rgba(50,207,102,0.35)" },
                                                // { offset: 0.5, color: "rgba(0,0,128,0.3)" },
                                                { offset: 1, color: "rgba(50,207,102,0)" },
                                            ]
                                        )
                                    }
                                },
                            }, {
                                smooth: true,
                                name: '次数',
                                type: 'line',
                                symbolSize: 8, // 设置折线上圆点大小
                                // symbol: 'circle', // 设置拐点为实心圆
                                yAxisIndex: 1,
                                data: res.data.numList,
                                itemStyle: {
                                    color: '#FDBF5A',
                                },
                                areaStyle: {
                                    normal: {
                                        color: new echarts.graphic.LinearGradient(
                                            0, 0, 0, 1,
                                            [
                                                { offset: 0, color: "rgba(252,190,89,0.35)" },
                                                // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                                                { offset: 1, color: "rgba(252,190,89,0)" },
                                            ]
                                        )
                                    }
                                },
                            }
                        ],
                        dataZoom: [
                            {
                                show: true,
                                height: 8, // 滚动条高度
                                xAxisIndex: [0],
                                bottom: 15, // 距离底部距离
                                showDetail: false,
                                start: 0, //初始化时，滑动条宽度开始标度
                                showDataShadow: false,
                                borderColor: "transparent",
                                textStyle: {
                                    fontSize: 0
                                },
                                endValue: 8,//从0开始的相当于5个(x轴展示的数量)
                                backgroundColor: "rgba(0,0,0,0)",
                                borderWidth: 0,
                                //                 handleIcon:
                                //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
                                //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
                                //   handleSize: "0%",
                                handleStyle: {
                                    color: "#d3dee5"
                                }
                            }
                        ]
                    };
                    // 使用刚指定的配置项和数据显示图表。
                    myChart.setOption(option);
                })
            }
        },
        // 智能公话使用统计-弹窗
        getTalkUseDialog() {
            let flag = this.echartsBox.some(v => v.name == '智能公话使用统计')
            if (flag) {
                let dayOrMonth = ''
                let myChart = this.$echarts.init(
                    document.getElementById("talkEchartsDialog")
                );
                this.echartsBox.forEach(v => {
                    if (v.name == '智能公话使用统计') {
                        dayOrMonth = v.talkMonIndex
                    }
                })
                let data = {
                    dayOrMonth: dayOrMonth
                }
                getVideoPhoneUseCount(data).then(res => {
                    let xData = []
                    res.data.dateList.forEach(v => {
                        if (dayOrMonth == 0) {
                            xData.push(
                                v.substring(5, 10).replace(/-/g, "/")
                            )
                        } else {
                            xData.push(
                                v.substring(5) + '月'
                            )
                        }

                    })
                    // 指定图表的配置项和数据
                    let option = {
                        tooltip: {
                            trigger: 'axis',
                            extraCssText: 'width: 165px;height: 90px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
                            axisPointer: {       //坐标轴指示器
                                type: 'line',   //十字准星指示器
                            },
                            formatter: function (params) {
                                console.log(params);
                                // for (let i = 0; i < params.length; i++) {
                                let res = `${params[0].marker}<span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name}</span><br/>`
                                params.forEach(item => {
                                    res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName == '分钟' ? '通话分钟' : '使用人次'}&nbsp;&nbsp;&nbsp;${item.data}${item.seriesName == '分钟' ? '分钟' : '次'}</span><br/>`
                                });
                                return res
                            }
                        },
                        legend: {
                            icon: "circle",
                            itemWidth: 12,  // 设置宽度
                            itemHeight: 12, // 设置高度
                            top: 0,
                            data: ['分钟', '次数']
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '10%',
                            containLabel: true
                        },
                        xAxis: {
                            type: 'category',
                            boundaryGap: true,
                            axisLabel: {
                                // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
                                interval: 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
                                rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
                                textStyle: {
                                    color: "#767985",
                                }
                            },
                            data: xData,
                            axisTick: {
                                show: false // 不显示坐标轴刻度线
                            },
                            //  y轴刻度线
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: "#E5E6EB",
                                }
                            }
                        },
                        yAxis: [
                            {
                                type: 'value',
                                name: '（分钟）',
                                //坐标轴最大值、最小值、强制设置数据的步长间隔
                                // interval: 5,
                                axisLabel: {
                                    //y轴上带的单位
                                    formatter: '{value}',
                                    textStyle: {
                                        color: "#767985",
                                    }
                                },
                                axisTick: {
                                    show: false // 不显示坐标轴刻度线
                                },
                                //  y轴刻度线
                                axisLine: { show: false },
                                //分割线
                                splitLine: {
                                    show: false,
                                    lineStyle: {
                                        type: 'dashed', // 虚线
                                        color: '#E5E6EB'
                                    }
                                }
                            },
                            {
                                minInterval: 1, // 最小单位是1
                                type: 'value',
                                name: '（次数）',
                                axisLabel: {
                                    formatter: '{value}',
                                    textStyle: {
                                        color: "#767985",
                                    }
                                },
                                axisTick: {
                                    show: false // 不显示坐标轴刻度线
                                },
                                //  y轴刻度线
                                axisLine: { show: false },
                                //分割线
                                splitLine: {
                                    show: true,
                                    lineStyle: {
                                        type: 'dashed',
                                        color: '#E5E6EB'
                                    }
                                }
                            }
                        ],
                        series: [
                            {
                                smooth: true,
                                name: '分钟',
                                type: 'line',
                                symbolSize: 8, // 设置折线上圆点大小
                                // symbol: 'circle', // 设置拐点为实心圆
                                yAxisIndex: 0,
                                data: res.data.durationList,
                                itemStyle: {
                                    color: '#33D067',
                                },
                                areaStyle: {
                                    normal: {
                                        color: new echarts.graphic.LinearGradient(
                                            0, 0, 0, 1,
                                            [
                                                { offset: 0, color: " rgba(50,207,102,0.35)" },
                                                // { offset: 0.5, color: "rgba(0,0,128,0.3)" },
                                                { offset: 1, color: "rgba(50,207,102,0)" },
                                            ]
                                        )
                                    }
                                },
                            }, {
                                smooth: true,
                                name: '次数',
                                type: 'line',
                                symbolSize: 8, // 设置折线上圆点大小
                                // symbol: 'circle', // 设置拐点为实心圆
                                yAxisIndex: 1,
                                data: res.data.numList,
                                itemStyle: {
                                    color: '#FDBF5A',
                                },
                                areaStyle: {
                                    normal: {
                                        color: new echarts.graphic.LinearGradient(
                                            0, 0, 0, 1,
                                            [
                                                { offset: 0, color: "rgba(252,190,89,0.35)" },
                                                // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                                                { offset: 1, color: "rgba(252,190,89,0)" },
                                            ]
                                        )
                                    }
                                },
                            }
                        ]
                    };
                    // 使用刚指定的配置项和数据显示图表。
                    myChart.setOption(option);
                })
            }
        },
        // 校园团餐营收统计
        getGroupPayEcharts() {
            let flag = this.echartsBox.some(v => v.name == '校园团餐营收统计')
            if (flag) {
                let dayOrMonth = ''
                let myChart = this.$echarts.init(
                    document.getElementById("groupPayEcharts")
                );
                this.echartsBox.forEach(v => {
                    if (v.name == '校园团餐营收统计') {
                        dayOrMonth = v.talkMonIndex
                    }
                })
                let data = {
                    dayOrMonth: dayOrMonth
                }
                getSchoolMealCount(data).then(res => {
                    let xData = []
                    res.data.dateList.forEach(v => {

                        if (dayOrMonth == 0) {
                            xData.push(
                                v.substring(5, 10).replace(/-/g, "/")
                            )
                        } else {
                            xData.push(
                                v.substring(5) + '月'
                            )
                        }
                    })

                    let option = {
                        tooltip: {
                            trigger: 'axis',
                            extraCssText: 'width: 256px;height: 138px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
                            axisPointer: {       //坐标轴指示器
                                type: 'line',   //十字准星指示器
                            },
                            formatter: function (params) {
                                console.log(params);
                                let res = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#4B93FF;" ></span ><span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name} &nbsp;&nbsp;总营收&nbsp;${params[0].data.allUsePrice ? params[0].data.allUsePrice : 0.00}元  &nbsp;&nbsp;刷脸${params[0].data.allUseCount ? params[0].data.allUseCount : 0}次 </span><br/>`
                                params.reverse().forEach(item => {
                                    res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName}&nbsp;&nbsp;&nbsp;${item.value}元</span><br/>`
                                });
                                return res
                            }
                        },
                        legend: {
                            icon: "circle",
                            itemWidth: 12,  // 设置宽度
                            itemHeight: 12, // 设置高度
                            top: 0,
                            data: ['早', '中', '晚']
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '10%',
                            containLabel: true
                        },
                        xAxis: [
                            {
                                type: 'category',
                                data: xData,
                                axisLabel: {
                                    // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
                                    interval: dayOrMonth == 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
                                    rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
                                    textStyle: {
                                        color: "#767985",
                                    }
                                },
                                axisTick: {
                                    show: false // 不显示坐标轴刻度线
                                },
                                //  y轴刻度线
                                axisLine: {
                                    show: true,
                                    lineStyle: {
                                        color: "#E5E6EB",
                                    }
                                }
                            }
                        ],
                        yAxis: [
                            {
                                name: '（元）',
                                type: 'value',
                                axisLabel: {
                                    formatter: '{value}',
                                    textStyle: {
                                        color: "#767985",
                                    }
                                },
                                axisTick: {
                                    show: false // 不显示坐标轴刻度线
                                },
                                //  y轴刻度线
                                axisLine: { show: false },
                                //分割线
                                splitLine: {
                                    show: true,
                                    lineStyle: {
                                        type: 'dashed',
                                        color: '#E5E6EB'
                                    }
                                }
                            }
                        ],
                        series: [
                            {
                                name: '晚',
                                type: 'bar',
                                barWidth: "16", // 设置柱子的宽度
                                stack: 'Ad',
                                emphasis: {
                                    focus: 'series'
                                },
                                data: res.data.listLate,
                                itemStyle: {
                                    // barBorderRadius: [0, 0, 12, 12],
                                    color: {
                                        // type: "linear",
                                        // x: 0, // 右
                                        // y: 1, // 下
                                        // x2: 0, // 左
                                        // y2: 0, // 上
                                        colorStops: [
                                            {
                                                offset: 0,
                                                color: "#4B93FF", // 0% 处的颜色
                                            },
                                            {
                                                offset: 0.9,
                                                color: "#4B93FF", // 90% 处的颜色
                                            },
                                        ],
                                    },
                                },
                            },
                            {
                                name: '中',
                                type: 'bar',
                                stack: 'Ad',
                                barWidth: "16", // 设置柱子的宽度
                                emphasis: {
                                    focus: 'series'
                                },
                                data: res.data.listMiddle,
                                itemStyle: {
                                    // barBorderRadius: [12, 12, 12, 12],
                                    color: {
                                        // type: "linear",
                                        // x: 0, // 右
                                        // y: 1, // 下
                                        // x2: 0, // 左
                                        // y2: 0, // 上
                                        colorStops: [
                                            {
                                                offset: 0,
                                                color: "#4CD6A6", // 0% 处的颜色
                                            },
                                            {
                                                offset: 0.9,
                                                color: "#4CD6A6", // 90% 处的颜色
                                            },
                                        ],
                                    },
                                },
                            },
                            {
                                name: '早',
                                type: 'bar',
                                stack: 'Ad',
                                barWidth: "16", // 设置柱子的宽度
                                emphasis: {
                                    focus: 'series'
                                },
                                data: res.data.listEarly,
                                itemStyle: {
                                    barBorderRadius: [12, 12, 0, 0],
                                    color: {
                                        // type: "linear",
                                        // x: 0, // 右
                                        // y: 1, // 下
                                        // x2: 0, // 左
                                        // y2: 0, // 上
                                        colorStops: [
                                            {
                                                offset: 0,
                                                color: "#F8BC5B", // 0% 处的颜色
                                            },
                                            {
                                                offset: 0.9,
                                                color: "#F8BC5B", // 90% 处的颜色
                                            },
                                        ],
                                    },
                                },
                            },
                        ],
                        dataZoom: [
                            {
                                show: true,
                                height: 8, // 滚动条高度
                                xAxisIndex: [0],
                                bottom: 15, // 距离底部距离
                                showDetail: false,
                                showDataShadow: false,
                                start: 0, //初始化时，滑动条宽度开始标度
                                borderColor: "transparent",
                                textStyle: {
                                    fontSize: 0
                                },
                                endValue: 8,//从0开始的相当于5个(x轴展示的数量)
                                backgroundColor: "rgba(0,0,0,0)",
                                borderWidth: 0,
                                //                 handleIcon:
                                //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
                                //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
                                //   handleSize: "0%",
                                handleStyle: {
                                    color: "#d3dee5"
                                }
                            }
                        ]
                    }
                    // 使用刚指定的配置项和数据显示图表。
                    myChart.setOption(option);
                })
            }
        },
        // 校园团餐营收统计-弹窗
        getGroupPayEchartsDialog() {
            let flag = this.echartsBox.some(v => v.name == '校园团餐营收统计')
            if (flag) {
                let dayOrMonth = ''
                let myChart = this.$echarts.init(
                    document.getElementById("groupPayEchartsDialog")
                );
                this.echartsBox.forEach(v => {
                    if (v.name == '校园团餐营收统计') {
                        dayOrMonth = v.talkMonIndex
                    }
                })
                let data = {
                    dayOrMonth: dayOrMonth
                }
                getSchoolMealCount(data).then(res => {
                    let xData = []
                    res.data.dateList.forEach(v => {

                        if (dayOrMonth == 0) {
                            xData.push(
                                v.substring(5, 10).replace(/-/g, "/")
                            )
                        } else {
                            xData.push(
                                v.substring(5) + '月'
                            )
                        }
                    })

                    let option = {
                        tooltip: {
                            trigger: 'axis',
                            extraCssText: 'width: 256px;height: 138px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
                            axisPointer: {       //坐标轴指示器
                                type: 'line',   //十字准星指示器
                            },
                            formatter: function (params) {
                                console.log(params);
                                let res = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#4B93FF;" ></span ><span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name} &nbsp;&nbsp;总营收&nbsp;${params[0].data.allUsePrice ? params[0].data.allUsePrice : 0.00}元  &nbsp;&nbsp;刷脸${params[0].data.allUseCount ? params[0].data.allUseCount : 0}次 </span><br/>`
                                params.reverse().forEach(item => {
                                    res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName}&nbsp;&nbsp;&nbsp;${item.value}元</span><br/>`
                                });
                                return res
                            }
                        },
                        legend: {
                            icon: "circle",
                            itemWidth: 12,  // 设置宽度
                            itemHeight: 12, // 设置高度
                            top: 0,
                            data: ['早', '中', '晚']
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '10%',
                            containLabel: true
                        },
                        xAxis: [
                            {
                                type: 'category',
                                data: xData,
                                axisLabel: {
                                    // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
                                    interval: 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
                                    rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
                                    textStyle: {
                                        color: "#767985",
                                    }
                                },
                                axisTick: {
                                    show: false // 不显示坐标轴刻度线
                                },
                                //  y轴刻度线
                                axisLine: {
                                    show: true,
                                    lineStyle: {
                                        color: "#E5E6EB",
                                    }
                                }
                            }
                        ],
                        yAxis: [
                            {
                                name: '（元）',
                                type: 'value',
                                axisLabel: {
                                    formatter: '{value}',
                                    textStyle: {
                                        color: "#767985",
                                    }
                                },
                                axisTick: {
                                    show: false // 不显示坐标轴刻度线
                                },
                                //  y轴刻度线
                                axisLine: { show: false },
                                //分割线
                                splitLine: {
                                    show: true,
                                    lineStyle: {
                                        type: 'dashed',
                                        color: '#E5E6EB'
                                    }
                                }
                            }
                        ],
                        series: [
                            {
                                name: '晚',
                                type: 'bar',
                                barWidth: "16", // 设置柱子的宽度
                                stack: 'Ad',
                                emphasis: {
                                    focus: 'series'
                                },
                                data: res.data.listLate,
                                itemStyle: {
                                    // barBorderRadius: [0, 0, 12, 12],
                                    color: {
                                        // type: "linear",
                                        // x: 0, // 右
                                        // y: 1, // 下
                                        // x2: 0, // 左
                                        // y2: 0, // 上
                                        colorStops: [
                                            {
                                                offset: 0,
                                                color: "#4B93FF", // 0% 处的颜色
                                            },
                                            {
                                                offset: 0.9,
                                                color: "#4B93FF", // 90% 处的颜色
                                            },
                                        ],
                                    },
                                },
                            },
                            {
                                name: '中',
                                type: 'bar',
                                stack: 'Ad',
                                barWidth: "16", // 设置柱子的宽度
                                emphasis: {
                                    focus: 'series'
                                },
                                data: res.data.listMiddle,
                                itemStyle: {
                                    // barBorderRadius: [12, 12, 12, 12],
                                    color: {
                                        // type: "linear",
                                        // x: 0, // 右
                                        // y: 1, // 下
                                        // x2: 0, // 左
                                        // y2: 0, // 上
                                        colorStops: [
                                            {
                                                offset: 0,
                                                color: "#4CD6A6", // 0% 处的颜色
                                            },
                                            {
                                                offset: 0.9,
                                                color: "#4CD6A6", // 90% 处的颜色
                                            },
                                        ],
                                    },
                                },
                            },
                            {
                                name: '早',
                                type: 'bar',
                                stack: 'Ad',
                                barWidth: "16", // 设置柱子的宽度
                                emphasis: {
                                    focus: 'series'
                                },
                                data: res.data.listEarly,
                                itemStyle: {
                                    barBorderRadius: [12, 12, 0, 0],
                                    color: {
                                        // type: "linear",
                                        // x: 0, // 右
                                        // y: 1, // 下
                                        // x2: 0, // 左
                                        // y2: 0, // 上
                                        colorStops: [
                                            {
                                                offset: 0,
                                                color: "#F8BC5B", // 0% 处的颜色
                                            },
                                            {
                                                offset: 0.9,
                                                color: "#F8BC5B", // 90% 处的颜色
                                            },
                                        ],
                                    },
                                },
                            },
                        ]
                    }
                    // 使用刚指定的配置项和数据显示图表。
                    myChart.setOption(option);
                })
            }
        },
        // 图书借阅
        getBooks() {
            let flag = this.echartsBox.some(v => v.name == '校园图书借阅统计')
            if (flag) {
                let dayOrMonth = ''
                let myChart = this.$echarts.init(
                    document.getElementById("bookEcharts")
                );
                this.echartsBox.forEach(v => {
                    if (v.name == '校园图书借阅统计') {
                        dayOrMonth = v.talkMonIndex
                    }
                })
                let data = {
                    dayOrMonth: dayOrMonth
                }
                getBookSchoolBorrowCount(data).then(res => {
                    let xData = []
                    res.data.dateList.forEach(v => {

                        if (dayOrMonth == 0) {
                            xData.push(
                                v.substring(5, 10).replace(/-/g, "/")
                            )
                        } else {
                            xData.push(
                                v.substring(5) + '月'
                            )
                        }
                    })
                    let option = {
                        tooltip: {
                            trigger: 'axis',
                            extraCssText: 'width: 165px;height: 66px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
                            axisPointer: {       //坐标轴指示器
                                type: 'line',   //十字准星指示器
                            },
                            formatter: function (params) {
                                return `${params[0].marker}<span style="font-size:12px;color:#767985;">${params[0].name}</span>` + '<br/>' +
                                    `<span style="color:#333;font-size:14px;margin-left:12px;">借阅图书 &nbsp;&nbsp; ${params[0].value}本</span>`
                            }
                        },
                        xAxis: {
                            type: 'category',
                            boundaryGap: true,
                            data: xData,
                            axisLabel: {
                                // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
                                interval: 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
                                rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
                                textStyle: {
                                    color: "#767985",
                                }
                            },
                            axisTick: {
                                show: false // 不显示坐标轴刻度线
                            },
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: "#E5E6EB",
                                }
                            },
                        },
                        yAxis: {
                            name: '（本）',
                            type: 'value',
                            minInterval: 1, // 最小单位是1
                            axisLabel: {
                                formatter: '{value}',
                                textStyle: {
                                    color: "#767985",
                                }
                            },
                            axisTick: {
                                show: false // 不显示坐标轴刻度线
                            },
                            axisLine: {
                                show: false, // 不显示坐标轴线
                            },
                            //分割线
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    type: 'dashed',
                                    color: '#E5E6EB'
                                }
                            }
                        },
                        series: [
                            {
                                itemStyle: {
                                    normal: { // 设置线条的style等
                                        color: '#0267FE',
                                        lineStyle: {
                                            color: '#0267FE', // 折线线条颜色
                                        },
                                        label: {
                                            show: true
                                        }
                                    }
                                },
                                data: res.data.borrowList, // this.yAxis[0,0,0,0,0,0,0,0,0,21]
                                type: 'line',
                                symbolSize: 10,   //设定中心点的大小
                                areaStyle: {
                                    color: {
                                        type: 'linear',
                                        x: 0,
                                        y: 0,
                                        x2: 0,
                                        y2: 1,
                                        colorStops: [{
                                            offset: 0, color: 'rgba(2,103,254,0.3)' // 0% 处的颜色
                                        }, {
                                            offset: 1, color: 'rgba(2,103,254,0)' // 100% 处的颜色
                                        }],
                                        global: false // 缺省为 false
                                    }
                                },
                                smooth: true
                            },
                        ],
                        dataZoom: [
                            {
                                show: true,
                                height: 8, // 滚动条高度
                                xAxisIndex: [0],
                                bottom: 15, // 距离底部距离
                                showDetail: false,
                                start: 0, //初始化时，滑动条宽度开始标度
                                showDataShadow: false,
                                borderColor: "transparent",
                                textStyle: {
                                    fontSize: 0
                                },
                                endValue: 8,//从0开始的相当于5个(x轴展示的数量)
                                backgroundColor: "rgba(0,0,0,0)",
                                borderWidth: 0,
                                //                 handleIcon:
                                //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
                                //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
                                //   handleSize: "0%",
                                handleStyle: {
                                    color: "#d3dee5"
                                }
                            }
                        ]
                    };
                    myChart.setOption(option);
                })
            }
        },
        // 图书借阅
        getBooksDialog() {
            let flag = this.echartsBox.some(v => v.name == '校园图书借阅统计')
            if (flag) {
                let dayOrMonth = ''
                let myChart = this.$echarts.init(
                    document.getElementById("bookEchartsDialog")
                );
                this.echartsBox.forEach(v => {
                    if (v.name == '校园图书借阅统计') {
                        dayOrMonth = v.talkMonIndex
                    }
                })
                let data = {
                    dayOrMonth: dayOrMonth
                }
                getBookSchoolBorrowCount(data).then(res => {
                    let xData = []
                    res.data.dateList.forEach(v => {

                        if (dayOrMonth == 0) {
                            xData.push(
                                v.substring(5, 10).replace(/-/g, "/")
                            )
                        } else {
                            xData.push(
                                v.substring(5) + '月'
                            )
                        }
                    })
                    let option = {
                        tooltip: {
                            trigger: 'axis',
                            extraCssText: 'width: 165px;height: 66px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
                            axisPointer: {       //坐标轴指示器
                                type: 'line',   //十字准星指示器
                            },
                            formatter: function (params) {
                                return `${params[0].marker}<span style="font-size:12px;color:#767985;">${params[0].name}</span>` + '<br/>' +
                                    `<span style="color:#333;font-size:14px;margin-left:12px;">借阅图书 &nbsp;&nbsp; ${params[0].value}本</span>`
                            }
                        },
                        xAxis: {
                            type: 'category',
                            boundaryGap: true,
                            data: xData,
                            axisLabel: {
                                // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
                                interval: 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
                                rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
                                textStyle: {
                                    color: "#767985",
                                }
                            },
                            axisTick: {
                                show: false // 不显示坐标轴刻度线
                            },
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: "#E5E6EB",
                                }
                            },
                        },
                        yAxis: {
                            name: '（本）',
                            type: 'value',
                            minInterval: 1, // 最小单位是1
                            axisLabel: {
                                formatter: '{value}',
                                textStyle: {
                                    color: "#767985",
                                }
                            },
                            axisTick: {
                                show: false // 不显示坐标轴刻度线
                            },
                            axisLine: {
                                show: false, // 不显示坐标轴线
                            },
                            //分割线
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    type: 'dashed',
                                    color: '#E5E6EB'
                                }
                            }
                        },
                        series: [
                            {
                                itemStyle: {
                                    normal: { // 设置线条的style等
                                        color: '#0267FE',
                                        lineStyle: {
                                            color: '#0267FE', // 折线线条颜色
                                        },
                                        label: {
                                            show: true
                                        }
                                    }
                                },
                                data: res.data.borrowList, // this.yAxis[0,0,0,0,0,0,0,0,0,21]
                                type: 'line',
                                symbolSize: 10,   //设定中心点的大小
                                areaStyle: {
                                    color: {
                                        type: 'linear',
                                        x: 0,
                                        y: 0,
                                        x2: 0,
                                        y2: 1,
                                        colorStops: [{
                                            offset: 0, color: 'rgba(2,103,254,0.3)' // 0% 处的颜色
                                        }, {
                                            offset: 1, color: 'rgba(2,103,254,0)' // 100% 处的颜色
                                        }],
                                        global: false // 缺省为 false
                                    }
                                },
                                smooth: true
                            },
                        ]
                    };
                    myChart.setOption(option);
                })
            }
        },
        // 共享洗衣
        getWash() {
            let flag = this.echartsBox.some(v => v.name == '共享洗衣使用统计')
            if (flag) {
                let dayOrMonth = ''
                let myChart = this.$echarts.init(
                    document.getElementById("washEcharts")
                );
                this.echartsBox.forEach(v => {
                    if (v.name == '共享洗衣使用统计') {
                        dayOrMonth = v.talkMonIndex
                    }
                })
                let data = {
                    dayOrMonth: dayOrMonth,
                    orderType:1
                }
                getWashingUseCount(data).then(res => {
                    let xData = []
                    res.data.dateList.forEach(v => {

                        if (dayOrMonth == 0) {
                            xData.push(
                                v.substring(5, 10).replace(/-/g, "/")
                            )
                        } else {
                            xData.push(
                                v.substring(5) + '月'
                            )
                        }
                    })
                    let option = {
                        tooltip: {
                            trigger: 'axis',
                            extraCssText: 'width: 178px;height: 100px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
                            axisPointer: {       //坐标轴指示器
                                type: 'line',   //十字准星指示器
                            },
                            formatter: function (params) {
                                console.log(params);
                                let res = `${params[0].marker}<span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name}</span><br/>`
                                params.forEach(item => {
                                    if (item.data.studentCount) { }
                                    res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName == '金额' ? '营收金额' : '使用次数'}&nbsp;&nbsp;&nbsp;${item.value}${item.seriesName == '金额' ? '元' : '次'}</span><br/>` +
                                        `${item.data.studentCount ? `<span style="font-size:12px;color:#333;margin-left:12px;">教师：${item.data.teacherCount}次&nbsp;&nbsp;学生：${item.data.studentCount}次</span><br/>` : ''}`
                                });
                                return res
                                // console.log(params);
                                // const str = `${params.marker}<span style="font-size:12px;color:#767985;">${params.name}</span><br/><span style="font-size:14px;color:#333;margin:12px;">${params.seriesName == '金额' ? '营收金额：' : '使用次数：'} &nbsp;&nbsp; ${params.value}${params.seriesName == '金额' ? '元' : '次'}</span>${params.seriesName == '金额' ? '' : `<br/><span style="font-size:12px;color:#333;margin-left:12px;">教师：${params.data.teacherCount}次&nbsp;&nbsp;学生：${params.data.studentCount}次</span>`}`
                                // return str
                            }
                        },
                        legend: {
                            icon: "circle",
                            itemWidth: 12,  // 设置宽度
                            itemHeight: 12, // 设置高度
                            top: 0,
                            data: ['金额', '次数']
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '10%',
                            containLabel: true
                        },
                        xAxis: {
                            type: 'category',
                            boundaryGap: true,
                            axisLabel: {
                                // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
                                interval: 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
                                rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
                                textStyle: {
                                    color: "#767985",
                                }
                            },
                            data: xData,
                            axisTick: {
                                show: false // 不显示坐标轴刻度线
                            },
                            //  y轴刻度线
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: "#E5E6EB",
                                }
                            }
                        },
                        yAxis: [
                            {
                                type: 'value',
                                name: '（金额）',
                                //坐标轴最大值、最小值、强制设置数据的步长间隔
                                // interval: 5,
                                axisLabel: {
                                    //y轴上带的单位
                                    formatter: '{value}',
                                    textStyle: {
                                        color: "#767985",
                                    }
                                },
                                axisTick: {
                                    show: false // 不显示坐标轴刻度线
                                },
                                //  y轴刻度线
                                axisLine: { show: false },
                                //分割线
                                splitLine: {
                                    show: false,
                                    lineStyle: {
                                        type: 'dashed',
                                        color: '#E5E6EB'
                                    }
                                }
                            },
                            {
                                minInterval: 1, // 最小单位是1
                                type: 'value',
                                name: '（次数）',
                                axisLabel: {
                                    formatter: '{value}',
                                    textStyle: {
                                        color: "#767985",
                                    }
                                },
                                axisTick: {
                                    show: false // 不显示坐标轴刻度线
                                },
                                //  y轴刻度线
                                axisLine: { show: false },
                                //分割线
                                splitLine: {
                                    show: true,
                                    lineStyle: {
                                        type: 'dashed',
                                        color: '#E5E6EB'
                                    }
                                }
                            }
                        ],
                        series: [
                            {
                                smooth: true,
                                name: '金额',
                                type: 'line',
                                symbolSize: 8, // 设置折线上圆点大小
                                // symbol: 'circle', // 设置拐点为实心圆
                                yAxisIndex: 0,
                                data: res.data.receiptAmountList,
                                itemStyle: {
                                    color: '#DA3E40',
                                },
                                areaStyle: {
                                    normal: {
                                        color: new echarts.graphic.LinearGradient(
                                            0, 0, 0, 1,
                                            [
                                                { offset: 0, color: "rgba(225,60,60,0.35)" },
                                                // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                                                { offset: 1, color: "rgba(226,60,60,0)" },
                                            ]
                                        )
                                    }
                                },
                            }, {
                                smooth: true,
                                name: '次数',
                                type: 'line',
                                symbolSize: 8, // 设置折线上圆点大小
                                // symbol: 'circle', // 设置拐点为实心圆
                                yAxisIndex: 1,
                                data: res.data.useList,
                                itemStyle: {
                                    color: '#FDBF5A',
                                },
                                areaStyle: {
                                    normal: {
                                        color: new echarts.graphic.LinearGradient(
                                            0, 0, 0, 1,
                                            [
                                                { offset: 0, color: "rgba(252,190,89,0.35)" },
                                                // { offset: 0.5, color: "rgba(0,0,128,0.3)" },
                                                { offset: 1, color: "rgba(252,190,89,0)" },
                                            ]
                                        )
                                    }
                                },
                            }
                        ],
                        dataZoom: [
                            {
                                show: true,
                                height: 8, // 滚动条高度
                                xAxisIndex: [0],
                                bottom: 15, // 距离底部距离
                                start: 0, //初始化时，滑动条宽度开始标度
                                showDetail: false,
                                showDataShadow: false,
                                borderColor: "transparent",
                                textStyle: {
                                    fontSize: 0
                                },
                                endValue: 8,//从0开始的相当于5个(x轴展示的数量)
                                backgroundColor: "rgba(0,0,0,0)",
                                borderWidth: 0,
                                //                 handleIcon:
                                //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
                                //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
                                //   handleSize: "0%",
                                handleStyle: {
                                    color: "#d3dee5"
                                }
                            }
                        ]
                    }
                    myChart.setOption(option);
                })
            }
        },
        // 共享洗衣-弹窗
        getWashDialog() {
            let flag = this.echartsBox.some(v => v.name == '共享洗衣使用统计')
            if (flag) {
                let dayOrMonth = ''
                let myChart = this.$echarts.init(
                    document.getElementById("washEchartsDialog")
                );
                this.echartsBox.forEach(v => {
                    if (v.name == '共享洗衣使用统计') {
                        dayOrMonth = v.talkMonIndex
                    }
                })
                let data = {
                    dayOrMonth: dayOrMonth,
                    orderType:1,
                }
                getWashingUseCount(data).then(res => {
                    let xData = []
                    res.data.dateList.forEach(v => {

                        if (dayOrMonth == 0) {
                            xData.push(
                                v.substring(5, 10).replace(/-/g, "/")
                            )
                        } else {
                            xData.push(
                                v.substring(5) + '月'
                            )
                        }
                    })
                    let option = {
                        tooltip: {
                            trigger: 'axis',
                            extraCssText: 'width: 178px;height: 100px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
                            axisPointer: {       //坐标轴指示器
                                type: 'line',   //十字准星指示器
                            },
                            formatter: function (params) {
                                console.log(params);
                                let res = `${params[0].marker}<span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name}</span><br/>`
                                params.forEach(item => {
                                    if (item.data.studentCount) { }
                                    res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName == '金额' ? '营收金额' : '使用次数'}&nbsp;&nbsp;&nbsp;${item.value}${item.seriesName == '金额' ? '元' : '次'}</span><br/>` +
                                        `${item.data.studentCount ? `<span style="font-size:12px;color:#333;margin-left:12px;">教师：${item.data.teacherCount}次&nbsp;&nbsp;学生：${item.data.studentCount}次</span><br/>` : ''}`
                                });
                                return res
                                // console.log(params);
                                // const str = `${params.marker}<span style="font-size:12px;color:#767985;">${params.name}</span><br/><span style="font-size:14px;color:#333;margin:12px;">${params.seriesName == '金额' ? '营收金额：' : '使用次数：'} &nbsp;&nbsp; ${params.value}${params.seriesName == '金额' ? '元' : '次'}</span>${params.seriesName == '金额' ? '' : `<br/><span style="font-size:12px;color:#333;margin-left:12px;">教师：${params.data.teacherCount}次&nbsp;&nbsp;学生：${params.data.studentCount}次</span>`}`
                                // return str
                            }
                        },
                        legend: {
                            icon: "circle",
                            itemWidth: 12,  // 设置宽度
                            itemHeight: 12, // 设置高度
                            top: 0,
                            data: ['金额', '次数']
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '10%',
                            containLabel: true
                        },
                        xAxis: {
                            type: 'category',
                            boundaryGap: true,
                            axisLabel: {
                                // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
                                interval: 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
                                rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
                                textStyle: {
                                    color: "#767985",
                                }
                            },
                            data: xData,
                            axisTick: {
                                show: false // 不显示坐标轴刻度线
                            },
                            //  y轴刻度线
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: "#E5E6EB",
                                }
                            }
                        },
                        yAxis: [
                            {
                                type: 'value',
                                name: '（金额）',
                                //坐标轴最大值、最小值、强制设置数据的步长间隔
                                // interval: 5,
                                axisLabel: {
                                    //y轴上带的单位
                                    formatter: '{value}',
                                    textStyle: {
                                        color: "#767985",
                                    }
                                },
                                axisTick: {
                                    show: false // 不显示坐标轴刻度线
                                },
                                //  y轴刻度线
                                axisLine: { show: false },
                                //分割线
                                splitLine: {
                                    show: false,
                                    lineStyle: {
                                        type: 'dashed',
                                        color: '#E5E6EB'
                                    }
                                }
                            },
                            {
                                minInterval: 1, // 最小单位是1
                                type: 'value',
                                name: '（次数）',
                                axisLabel: {
                                    formatter: '{value}',
                                    textStyle: {
                                        color: "#767985",
                                    }
                                },
                                axisTick: {
                                    show: false // 不显示坐标轴刻度线
                                },
                                //  y轴刻度线
                                axisLine: { show: false },
                                //分割线
                                splitLine: {
                                    show: true,
                                    lineStyle: {
                                        type: 'dashed',
                                        color: '#E5E6EB'
                                    }
                                }
                            }
                        ],
                        series: [
                            {
                                smooth: true,
                                name: '金额',
                                type: 'line',
                                symbolSize: 8, // 设置折线上圆点大小
                                // symbol: 'circle', // 设置拐点为实心圆
                                yAxisIndex: 0,
                                data: res.data.receiptAmountList,
                                itemStyle: {
                                    color: '#DA3E40',
                                },
                                areaStyle: {
                                    normal: {
                                        color: new echarts.graphic.LinearGradient(
                                            0, 0, 0, 1,
                                            [
                                                { offset: 0, color: "rgba(225,60,60,0.35)" },
                                                // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                                                { offset: 1, color: "rgba(226,60,60,0)" },
                                            ]
                                        )
                                    }
                                },
                            }, {
                                smooth: true,
                                name: '次数',
                                type: 'line',
                                symbolSize: 8, // 设置折线上圆点大小
                                // symbol: 'circle', // 设置拐点为实心圆
                                yAxisIndex: 1,
                                data: res.data.useList,
                                itemStyle: {
                                    color: '#FDBF5A',
                                },
                                areaStyle: {
                                    normal: {
                                        color: new echarts.graphic.LinearGradient(
                                            0, 0, 0, 1,
                                            [
                                                { offset: 0, color: "rgba(252,190,89,0.35)" },
                                                // { offset: 0.5, color: "rgba(0,0,128,0.3)" },
                                                { offset: 1, color: "rgba(252,190,89,0)" },
                                            ]
                                        )
                                    }
                                },
                            }
                        ]
                    }
                    myChart.setOption(option);
                })
            }
        },
        // 智能门禁
        accesEcharts() {
            let flag = this.echartsBox.some(v => v.name == '智能门禁进出统计')
            if (flag) {
                let dayOrMonth = ''
                let talkVal = ''
                let myChart = this.$echarts.init(
                    document.getElementById("accessEcharts")
                );
                this.echartsBox.forEach(v => {
                    if (v.name == '智能门禁进出统计') {
                        dayOrMonth = v.talkMonIndex
                        talkVal = v.talkVal
                    }
                })
                let data = {
                    dayOrMonth: dayOrMonth,
                    deviceType:talkVal
                }
                // if (talkVal == 1) {
                    getAccessSchoolCount(data).then(res => {
                        let xData = []
                        res.data.dateList.forEach(v => {

                            if (dayOrMonth == 0) {
                                xData.push(
                                    v.substring(5, 10).replace(/-/g, "/")
                                )
                            } else {
                                xData.push(
                                    v.substring(5) + '月'
                                )
                            }
                        })
                        let option = {
                            tooltip: {
                                trigger: 'axis',
                                extraCssText: 'width: 165px;height: 90px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
                                axisPointer: {       //坐标轴指示器
                                    type: 'line',   //十字准星指示器
                                },
                                formatter: function (params) {
                                    console.log(params);
                                    let res = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background: linear-gradient(180deg, rgba(2,102,253,0.65) 0%, rgba(2,102,253,0.3) 100%);" ></span ><span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name}</span><br/>`
                                    params.forEach(item => {
                                        res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName}人次&nbsp;&nbsp;&nbsp;${item.value}人</span> <span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.data.passCount}次</span><br/>`;
                                    });
                                    return res
                                }
                            },
                            legend: {
                                data: ['进入', '外出'],
                                icon: "circle",
                                itemWidth: 12,  // 设置宽度
                                itemHeight: 12, // 设置高度
                                top: 0,
                            },
                            grid: {
                                left: '3%',
                                right: '4%',
                                bottom: '10%',
                                containLabel: true
                            },
                            xAxis: [
                                {
                                    type: 'category',
                                    // prettier-ignore
                                    data: xData,
                                    axisLabel: {
                                        // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
                                        interval: 0, //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
                                        rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
                                        textStyle: {
                                            color: "#767985",
                                        }
                                    },
                                    axisTick: {
                                        show: false // 不显示坐标轴刻度线
                                    },
                                    //  y轴刻度线
                                    axisLine: {
                                        show: true,
                                        lineStyle: {
                                            color: "#E5E6EB",
                                        }
                                    }
                                }
                            ],
                            yAxis: [
                                {
                                    minInterval: 1, // 最小单位是1
                                    name: '（人）',
                                    type: 'value',
                                    axisLabel: {
                                        formatter: '{value}',
                                        textStyle: {
                                            color: "#767985",
                                        }
                                    },
                                    axisTick: {
                                        show: false // 不显示坐标轴刻度线
                                    },
                                    //  y轴刻度线
                                    axisLine: { show: false },
                                    //分割线
                                    splitLine: {
                                        show: true,
                                        lineStyle: {
                                            type: 'dashed',
                                            color: '#E5E6EB'
                                        }
                                    }
                                }
                            ],
                            series: [
                                {
                                    name: '进入',
                                    type: 'bar',
                                    barWidth: "16", // 设置柱子的宽度
                                    data: res.data.personCountJrList,
                                    itemStyle: {
                                        barBorderRadius: [12, 12, 0, 0],
                                        color: new echarts.graphic.LinearGradient(
                                            0, 0, 0, 1,
                                            [
                                                { offset: 0, color: "rgba(2,102,253,0.65)" },
                                                // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                                                { offset: 1, color: "rgba(2,102,253,0.3)" },
                                            ]
                                        )
                                        // color: {
                                        //   // type: "linear",
                                        //   // x: 0, // 右
                                        //   // y: 1, // 下
                                        //   // x2: 0, // 左
                                        //   // y2: 0, // 上
                                        //   colorStops: [
                                        //     {
                                        //       offset: 0,
                                        //       color: "rgba(2,102,253,0.65)", // 0% 处的颜色
                                        //     },
                                        //     {
                                        //       offset: 0.9,
                                        //       color: "rgba(2,102,253,0.3)", // 90% 处的颜色
                                        //     },
                                        //   ],
                                        // },
                                    }
                                },
                                {
                                    name: '外出',
                                    type: 'bar',
                                    barWidth: "16", // 设置柱子的宽度
                                    data: res.data.personCountWcList,
                                    itemStyle: {
                                        barBorderRadius: [12, 12, 0, 0],
                                        color: new echarts.graphic.LinearGradient(
                                            0, 0, 0, 1,
                                            [
                                                { offset: 0, color: "rgba(228,60,60,0.8)" },
                                                // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                                                { offset: 1, color: "rgba(229,60,60,0.3)" },
                                            ]
                                        )
                                    }
                                }
                            ],
                            dataZoom: [
                                {
                                    show: true,
                                    height: 8, // 滚动条高度
                                    xAxisIndex: [0],
                                    bottom: 15, // 距离底部距离
                                    showDetail: false,
                                    showDataShadow: false,
                                    start: 0, //初始化时，滑动条宽度开始标度
                                    borderColor: "transparent",
                                    textStyle: {
                                        fontSize: 0
                                    },
                                    endValue: 8,//从0开始的相当于5个(x轴展示的数量)
                                    backgroundColor: "rgba(0,0,0,0)",
                                    borderWidth: 0,
                                    //                 handleIcon:
                                    //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
                                    //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
                                    //   handleSize: "0%",
                                    handleStyle: {
                                        color: "#d3dee5"
                                    }
                                }
                            ]
                        }
                        myChart.setOption(option);
                    })
                // } else {
                //     getAccessDormitoryCount(data).then(res => {
                //         let xData = []
                //         res.data.dateList.forEach(v => {

                //             if (dayOrMonth == 0) {
                //                 xData.push(
                //                     v.substring(5, 10).replace(/-/g, "/")
                //                 )
                //             } else {
                //                 xData.push(
                //                     v.substring(5) + '月'
                //                 )
                //             }
                //         })
                //         let option = {
                //             tooltip: {
                //                 trigger: 'axis',
                //                 extraCssText: 'width: 165px;height: 90px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
                //                 axisPointer: {       //坐标轴指示器
                //                     type: 'line',   //十字准星指示器
                //                 },
                //                 formatter: function (params) {
                //                     console.log(params);
                //                     return `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background: linear-gradient(180deg, rgba(2,102,253,0.65) 0%, rgba(2,102,253,0.3) 100%);" ></span ><span style="font-size:12px;color:#767985;">${params[0].name}</span><br/>` +
                //                         `<span style="font-size:14px;color:#333;margin-left:12px;">${params[0].seriesName}人次 &nbsp;&nbsp;&nbsp; ${params[0].data}人</span><br/>` +
                //                         `<span style="font-size:14px;color:#333;margin-left:12px;">${params[1].seriesName}人次 &nbsp;&nbsp;&nbsp; ${params[1].data}人</span><br/>`
                //                 }
                //             },
                //             legend: {
                //                 data: ['进入', '外出'],
                //                 icon: "circle",
                //                 itemWidth: 12,  // 设置宽度
                //                 itemHeight: 12, // 设置高度
                //                 top: 0,
                //             },
                //             grid: {
                //                 left: '3%',
                //                 right: '4%',
                //                 bottom: '10%',
                //                 containLabel: true
                //             },
                //             xAxis: [
                //                 {
                //                     type: 'category',
                //                     // prettier-ignore
                //                     data: xData,
                //                     axisLabel: {
                //                         // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
                //                         interval: dayOrMonth == 0 ? 1 : 0, //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
                //                         rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
                //                         textStyle: {
                //                             color: "#767985",
                //                         }
                //                     },
                //                     axisTick: {
                //                         show: false // 不显示坐标轴刻度线
                //                     },
                //                     //  y轴刻度线
                //                     axisLine: {
                //                         show: true,
                //                         lineStyle: {
                //                             color: "#E5E6EB",
                //                         }
                //                     }
                //                 }
                //             ],
                //             yAxis: [
                //                 {
                //                     minInterval: 1, // 最小单位是1
                //                     name: '（人）',
                //                     type: 'value',
                //                     axisLabel: {
                //                         formatter: '{value}',
                //                         textStyle: {
                //                             color: "#767985",
                //                         }
                //                     },
                //                     axisTick: {
                //                         show: false // 不显示坐标轴刻度线
                //                     },
                //                     //  y轴刻度线
                //                     axisLine: { show: false },
                //                     //分割线
                //                     splitLine: {
                //                         show: true,
                //                         lineStyle: {
                //                             type: 'dashed',
                //                             color: '#E5E6EB'
                //                         }
                //                     }
                //                 }
                //             ],
                //             series: [
                //                 {
                //                     name: '进入',
                //                     type: 'bar',
                //                     barWidth: "16", // 设置柱子的宽度
                //                     data: res.data.PersonCountJrList,
                //                     itemStyle: {
                //                         barBorderRadius: [12, 12, 0, 0],
                //                         color: new echarts.graphic.LinearGradient(
                //                             0, 0, 0, 1,
                //                             [
                //                                 { offset: 0, color: "rgba(2,102,253,0.65)" },
                //                                 // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                //                                 { offset: 1, color: "rgba(2,102,253,0.3)" },
                //                             ]
                //                         )
                //                         // color: {
                //                         //   // type: "linear",
                //                         //   // x: 0, // 右
                //                         //   // y: 1, // 下
                //                         //   // x2: 0, // 左
                //                         //   // y2: 0, // 上
                //                         //   colorStops: [
                //                         //     {
                //                         //       offset: 0,
                //                         //       color: "rgba(2,102,253,0.65)", // 0% 处的颜色
                //                         //     },
                //                         //     {
                //                         //       offset: 0.9,
                //                         //       color: "rgba(2,102,253,0.3)", // 90% 处的颜色
                //                         //     },
                //                         //   ],
                //                         // },
                //                     }
                //                 },
                //                 {
                //                     name: '外出',
                //                     type: 'bar',
                //                     barWidth: "16", // 设置柱子的宽度
                //                     data: res.data.PersonCountWcList,
                //                     itemStyle: {
                //                         barBorderRadius: [12, 12, 0, 0],
                //                         color: new echarts.graphic.LinearGradient(
                //                             0, 0, 0, 1,
                //                             [
                //                                 { offset: 0, color: "rgba(228,60,60,0.8)" },
                //                                 // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                //                                 { offset: 1, color: "rgba(229,60,60,0.3)" },
                //                             ]
                //                         )
                //                     }
                //                 }
                //             ],
                //             dataZoom: [
                //                 {
                //                     show: true,
                //                     height: 8, // 滚动条高度
                //                     xAxisIndex: [0],
                //                     bottom: 15, // 距离底部距离
                //                     showDetail: false,
                //                     showDataShadow: false,
                //                     start: 0, //初始化时，滑动条宽度开始标度
                //                     borderColor: "transparent",
                //                     textStyle: {
                //                         fontSize: 0
                //                     },
                //                     endValue: 8,//从0开始的相当于5个(x轴展示的数量)
                //                     backgroundColor: "rgba(0,0,0,0)",
                //                     borderWidth: 0,
                //                     //                 handleIcon:
                //                     //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
                //                     //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
                //                     //   handleSize: "0%",
                //                     handleStyle: {
                //                         color: "#d3dee5"
                //                     }
                //                 }
                //             ]
                //         }
                //         myChart.setOption(option);
                //     })
                // }
            }
        },
        // 智能门禁-弹窗
        accesEchartsDialog() {
            let flag = this.echartsBox.some(v => v.name == '智能门禁进出统计')
            if (flag) {
                let dayOrMonth = ''
                let talkVal = ''
                let myChart = this.$echarts.init(
                    document.getElementById("accessEchartsDialog")
                );
                this.echartsBox.forEach(v => {
                    if (v.name == '智能门禁进出统计') {
                        dayOrMonth = v.talkMonIndex
                        talkVal = v.talkVal
                    }
                })
                let data = {
                    dayOrMonth: dayOrMonth,
                    deviceType:talkVal
                }
                // if (talkVal == 1) {
                    getAccessSchoolCount(data).then(res => {
                        let xData = []
                        res.data.dateList.forEach(v => {

                            if (dayOrMonth == 0) {
                                xData.push(
                                    v.substring(5, 10).replace(/-/g, "/")
                                )
                            } else {
                                xData.push(
                                    v.substring(5) + '月'
                                )
                            }
                        })
                        let option = {
                            tooltip: {
                                trigger: 'axis',
                                extraCssText: 'width: 165px;height: 90px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
                                axisPointer: {       //坐标轴指示器
                                    type: 'line',   //十字准星指示器
                                },
                                formatter: function (params) {
                                    console.log(params);
                                    let res = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background: linear-gradient(180deg, rgba(2,102,253,0.65) 0%, rgba(2,102,253,0.3) 100%);" ></span ><span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name}</span><br/>`
                                    params.forEach(item => {
                                        res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName}人次&nbsp;&nbsp;&nbsp;${item.value}人</span> <span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.data.passCount}次</span><br/>`;
                                    });
                                    return res
                                }
                            },
                            legend: {
                                data: ['进入', '外出'],
                                icon: "circle",
                                itemWidth: 12,  // 设置宽度
                                itemHeight: 12, // 设置高度
                                top: 0,
                            },
                            grid: {
                                left: '3%',
                                right: '4%',
                                bottom: '10%',
                                containLabel: true
                            },
                            xAxis: [
                                {
                                    type: 'category',
                                    // prettier-ignore
                                    data: xData,
                                    axisLabel: {
                                        // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
                                        interval: 0, //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
                                        rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
                                        textStyle: {
                                            color: "#767985",
                                        }
                                    },
                                    axisTick: {
                                        show: false // 不显示坐标轴刻度线
                                    },
                                    //  y轴刻度线
                                    axisLine: {
                                        show: true,
                                        lineStyle: {
                                            color: "#E5E6EB",
                                        }
                                    }
                                }
                            ],
                            yAxis: [
                                {
                                    minInterval: 1, // 最小单位是1
                                    name: '（人）',
                                    type: 'value',
                                    axisLabel: {
                                        formatter: '{value}',
                                        textStyle: {
                                            color: "#767985",
                                        }
                                    },
                                    axisTick: {
                                        show: false // 不显示坐标轴刻度线
                                    },
                                    //  y轴刻度线
                                    axisLine: { show: false },
                                    //分割线
                                    splitLine: {
                                        show: true,
                                        lineStyle: {
                                            type: 'dashed',
                                            color: '#E5E6EB'
                                        }
                                    }
                                }
                            ],
                            series: [
                                {
                                    name: '进入',
                                    type: 'bar',
                                    barWidth: "16", // 设置柱子的宽度
                                    data: res.data.personCountJrList,
                                    itemStyle: {
                                        barBorderRadius: [12, 12, 0, 0],
                                        color: new echarts.graphic.LinearGradient(
                                            0, 0, 0, 1,
                                            [
                                                { offset: 0, color: "rgba(2,102,253,0.65)" },
                                                // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                                                { offset: 1, color: "rgba(2,102,253,0.3)" },
                                            ]
                                        )
                                        // color: {
                                        //   // type: "linear",
                                        //   // x: 0, // 右
                                        //   // y: 1, // 下
                                        //   // x2: 0, // 左
                                        //   // y2: 0, // 上
                                        //   colorStops: [
                                        //     {
                                        //       offset: 0,
                                        //       color: "rgba(2,102,253,0.65)", // 0% 处的颜色
                                        //     },
                                        //     {
                                        //       offset: 0.9,
                                        //       color: "rgba(2,102,253,0.3)", // 90% 处的颜色
                                        //     },
                                        //   ],
                                        // },
                                    }
                                },
                                {
                                    name: '外出',
                                    type: 'bar',
                                    barWidth: "16", // 设置柱子的宽度
                                    data: res.data.personCountWcList,
                                    itemStyle: {
                                        barBorderRadius: [12, 12, 0, 0],
                                        color: new echarts.graphic.LinearGradient(
                                            0, 0, 0, 1,
                                            [
                                                { offset: 0, color: "rgba(228,60,60,0.8)" },
                                                // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                                                { offset: 1, color: "rgba(229,60,60,0.3)" },
                                            ]
                                        )
                                    }
                                }
                            ]
                        }
                        myChart.setOption(option);
                    })
                // } else {
                //     getAccessDormitoryCount(data).then(res => {
                //         let xData = []
                //         res.data.dateList.forEach(v => {

                //             if (dayOrMonth == 0) {
                //                 xData.push(
                //                     v.substring(5, 10).replace(/-/g, "/")
                //                 )
                //             } else {
                //                 xData.push(
                //                     v.substring(5) + '月'
                //                 )
                //             }
                //         })
                //         let option = {
                //             tooltip: {
                //                 trigger: 'axis',
                //                 extraCssText: 'width: 165px;height: 90px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
                //                 axisPointer: {       //坐标轴指示器
                //                     type: 'line',   //十字准星指示器
                //                 },
                //                 formatter: function (params) {
                //                     console.log(params);
                //                     return `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background: linear-gradient(180deg, rgba(2,102,253,0.65) 0%, rgba(2,102,253,0.3) 100%);" ></span ><span style="font-size:12px;color:#767985;">${params[0].name}</span><br/>` +
                //                         `<span style="font-size:14px;color:#333;margin-left:12px;">${params[0].seriesName}人次 &nbsp;&nbsp;&nbsp; ${params[0].data}人</span><br/>` +
                //                         `<span style="font-size:14px;color:#333;margin-left:12px;">${params[1].seriesName}人次 &nbsp;&nbsp;&nbsp; ${params[1].data}人</span><br/>`
                //                 }
                //             },
                //             legend: {
                //                 data: ['进入', '外出'],
                //                 icon: "circle",
                //                 itemWidth: 12,  // 设置宽度
                //                 itemHeight: 12, // 设置高度
                //                 top: 0,
                //             },
                //             grid: {
                //                 left: '3%',
                //                 right: '4%',
                //                 bottom: '10%',
                //                 containLabel: true
                //             },
                //             xAxis: [
                //                 {
                //                     type: 'category',
                //                     // prettier-ignore
                //                     data: xData,
                //                     axisLabel: {
                //                         // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
                //                         interval: dayOrMonth == 0 ? 1 : 0, //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
                //                         rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
                //                         textStyle: {
                //                             color: "#767985",
                //                         }
                //                     },
                //                     axisTick: {
                //                         show: false // 不显示坐标轴刻度线
                //                     },
                //                     //  y轴刻度线
                //                     axisLine: {
                //                         show: true,
                //                         lineStyle: {
                //                             color: "#E5E6EB",
                //                         }
                //                     }
                //                 }
                //             ],
                //             yAxis: [
                //                 {
                //                     minInterval: 1, // 最小单位是1
                //                     name: '（人）',
                //                     type: 'value',
                //                     axisLabel: {
                //                         formatter: '{value}',
                //                         textStyle: {
                //                             color: "#767985",
                //                         }
                //                     },
                //                     axisTick: {
                //                         show: false // 不显示坐标轴刻度线
                //                     },
                //                     //  y轴刻度线
                //                     axisLine: { show: false },
                //                     //分割线
                //                     splitLine: {
                //                         show: true,
                //                         lineStyle: {
                //                             type: 'dashed',
                //                             color: '#E5E6EB'
                //                         }
                //                     }
                //                 }
                //             ],
                //             series: [
                //                 {
                //                     name: '进入',
                //                     type: 'bar',
                //                     barWidth: "16", // 设置柱子的宽度
                //                     data: res.data.PersonCountJrList,
                //                     itemStyle: {
                //                         barBorderRadius: [12, 12, 0, 0],
                //                         color: new echarts.graphic.LinearGradient(
                //                             0, 0, 0, 1,
                //                             [
                //                                 { offset: 0, color: "rgba(2,102,253,0.65)" },
                //                                 // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                //                                 { offset: 1, color: "rgba(2,102,253,0.3)" },
                //                             ]
                //                         )
                //                         // color: {
                //                         //   // type: "linear",
                //                         //   // x: 0, // 右
                //                         //   // y: 1, // 下
                //                         //   // x2: 0, // 左
                //                         //   // y2: 0, // 上
                //                         //   colorStops: [
                //                         //     {
                //                         //       offset: 0,
                //                         //       color: "rgba(2,102,253,0.65)", // 0% 处的颜色
                //                         //     },
                //                         //     {
                //                         //       offset: 0.9,
                //                         //       color: "rgba(2,102,253,0.3)", // 90% 处的颜色
                //                         //     },
                //                         //   ],
                //                         // },
                //                     }
                //                 },
                //                 {
                //                     name: '外出',
                //                     type: 'bar',
                //                     barWidth: "16", // 设置柱子的宽度
                //                     data: res.data.PersonCountWcList,
                //                     itemStyle: {
                //                         barBorderRadius: [12, 12, 0, 0],
                //                         color: new echarts.graphic.LinearGradient(
                //                             0, 0, 0, 1,
                //                             [
                //                                 { offset: 0, color: "rgba(228,60,60,0.8)" },
                //                                 // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                //                                 { offset: 1, color: "rgba(229,60,60,0.3)" },
                //                             ]
                //                         )
                //                     }
                //                 }
                //             ]
                //         }
                //         myChart.setOption(option);
                //     })
                // }
            }
        },



             // 共享洗衣
        getShowerStatistics() {
                console.log('智慧淋浴使用统计');
            let flag = this.echartsBox.some(v => v.name == '智慧淋浴使用统计')
            console.log(flag);
            if (flag) {
                let dayOrMonth = ''
                let myChart = this.$echarts.init(
                    document.getElementById("showerStatistics")
                );
                this.echartsBox.forEach(v => {
                    if (v.name == '智慧淋浴使用统计') {
                        dayOrMonth = v.talkMonIndex
                    }
                })

                let data = {
                    dayOrMonth: dayOrMonth,
                    orderType:1
                }
                getShowerUseCount(data).then(res => {
                    let xData = []
                    res.data.dateList.forEach(v => {

                        if (dayOrMonth == 0) {
                            xData.push(
                                v.substring(5, 10).replace(/-/g, "/")
                            )
                        } else {
                            xData.push(
                                v.substring(5) + '月'
                            )
                        }
                    })
                    let option = {
                        tooltip: {
                            trigger: 'axis',
                            extraCssText: 'width: 178px;height: 100px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
                            axisPointer: {       //坐标轴指示器
                                type: 'line',   //十字准星指示器
                            },
                            formatter: function (params) {
                            let res = `${params[0].marker}<span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name}</span><br/>`
                            params.forEach(item => {
                                if (item.data.studentCount) { }
                                res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName == '金额' ? '营收金额' : '交易笔数'}&nbsp;&nbsp;&nbsp;${item.value}${item.seriesName == '金额' ? '元' : '笔'}</span><br/>` +
                                `${item.data.waterAmount ? `<span style="font-size:14px;color:#333;margin-left:12px;">用水量：${item.data.waterAmount}升<br/>` : ''}`
                            });
                            return res
                            }
                        },
                        legend: {
                            icon: "circle",
                            itemWidth: 12,  // 设置宽度
                            itemHeight: 12, // 设置高度
                            top: 0,
                            data: ['金额', '笔数']
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '10%',
                            containLabel: true
                        },
                        xAxis: {
                        type: 'category',
                        boundaryGap: true,
                        axisLabel: {
                        // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
                        interval: 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
                        rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
                        textStyle: {
                            color: "#767985",
                        }
                        },
                        data: xData,
                        axisTick: {
                        show: false // 不显示坐标轴刻度线
                        },
                        //  y轴刻度线
                        axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#E5E6EB",
                        }
                        }
                    },
                        yAxis: [
                            {
                                type: 'value',
                                name: '（金额）',
                                //坐标轴最大值、最小值、强制设置数据的步长间隔
                                // interval: 5,
                                axisLabel: {
                                    //y轴上带的单位
                                    formatter: '{value}',
                                    textStyle: {
                                        color: "#767985",
                                    }
                                },
                                axisTick: {
                                    show: false // 不显示坐标轴刻度线
                                },
                                //  y轴刻度线
                                axisLine: { show: false },
                                //分割线
                                splitLine: {
                                    show: false,
                                    lineStyle: {
                                        type: 'dashed',
                                        color: '#E5E6EB'
                                    }
                                }
                            },
                            {
                                minInterval: 1, // 最小单位是1
                                type: 'value',
                                name: '（笔数）',
                                axisLabel: {
                                    formatter: '{value}',
                                    textStyle: {
                                        color: "#767985",
                                    }
                                },
                                axisTick: {
                                    show: false // 不显示坐标轴刻度线
                                },
                                //  y轴刻度线
                                axisLine: { show: false },
                                //分割线
                                splitLine: {
                                    show: true,
                                    lineStyle: {
                                        type: 'dashed',
                                        color: '#E5E6EB'
                                    }
                                }
                            }
                        ],
                        series: [
                        {
                        smooth: true,
                        name: '金额',
                        type: 'line',
                        symbolSize: 8, // 设置折线上圆点大小
                        // symbol: 'circle', // 设置拐点为实心圆
                        yAxisIndex: 0,
                        data: res.data.receiptAmountList,
                        itemStyle: {
                            color: '#33D067',
                        },
                        areaStyle: {
                            normal: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                { offset: 0, color: " rgba(50,207,102,0.35)" },
                                // { offset: 0.5, color: "rgba(0,0,128,0.3)" },
                                { offset: 1, color: "rgba(50,207,102,0)" },
                                ]
                            )
                            }
                        },
                        }, 
                        {
                        smooth: true,
                        name: '笔数',
                        type: 'line',
                        symbolSize: 8, // 设置折线上圆点大小
                        // symbol: 'circle', // 设置拐点为实心圆
                        yAxisIndex: 1,
                        data: res.data.useList,
                        itemStyle: {
                            color: '#FDBF5A',
                        },
                            areaStyle: {
                            normal: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                { offset: 0, color: "rgba(252,190,89,0.35)" },
                                // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                                { offset: 1, color: "rgba(252,190,89,0)" },
                                ]
                            )
                            }
                        },
                        }
                    ],
                        dataZoom: [
                            {
                                show: true,
                                height: 8, // 滚动条高度
                                xAxisIndex: [0],
                                bottom: 15, // 距离底部距离
                                start: 0, //初始化时，滑动条宽度开始标度
                                showDetail: false,
                                showDataShadow: false,
                                borderColor: "transparent",
                                textStyle: {
                                    fontSize: 0
                                },
                                endValue: 8,//从0开始的相当于5个(x轴展示的数量)
                                backgroundColor: "rgba(0,0,0,0)",
                                borderWidth: 0,
                                //                 handleIcon:
                                //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
                                //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
                                //   handleSize: "0%",
                                handleStyle: {
                                    color: "#d3dee5"
                                }
                            }
                        ]
                    }
                    myChart.setOption(option);
                })
            }
        },
        // 共享洗衣-弹窗
        getShowerStatisticsDialog() {
            let flag = this.echartsBox.some(v => v.name == '智慧淋浴使用统计')
            if (flag) {
                let dayOrMonth = ''
                let myChart = this.$echarts.init(
                    document.getElementById("takeShowerStatistics")
                );
                this.echartsBox.forEach(v => {
                    if (v.name == '智慧淋浴使用统计') {
                        dayOrMonth = v.talkMonIndex
                    }
                })
                let data = {
                    dayOrMonth: dayOrMonth,
                    orderType:1,
                }
                getShowerUseCount(data).then(res => {
                    let xData = []
                    res.data.dateList.forEach(v => {

                        if (dayOrMonth == 0) {
                            xData.push(
                                v.substring(5, 10).replace(/-/g, "/")
                            )
                        } else {
                            xData.push(
                                v.substring(5) + '月'
                            )
                        }
                    })
                    let option = {
                        tooltip: {
                            trigger: 'axis',
                            extraCssText: 'width: 178px;height: 100px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
                            axisPointer: {       //坐标轴指示器
                                type: 'line',   //十字准星指示器
                            },
                            formatter: function (params) {
                            let res = `${params[0].marker}<span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name}</span><br/>`
                            params.forEach(item => {
                                console.log(item);
                                if (item.data.waterAmount) { }
                                res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName == '金额' ? '营收金额' : '交易笔数'}&nbsp;&nbsp;&nbsp;${item.value}${item.seriesName == '金额' ? '元' : '笔'}</span><br/>` +
                                `${item.data.waterAmount ? `<span style="font-size:14px;color:#333;margin-left:12px;">用水量：${item.data.waterAmount}升<br/>` : ''}`
                            });
                            return res
                            }
                        },
                        legend: {
                            icon: "circle",
                            itemWidth: 12,  // 设置宽度
                            itemHeight: 12, // 设置高度
                            top: 0,
                            data: ['金额', '笔数']
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '10%',
                            containLabel: true
                        },
                        xAxis: {
                            type: 'category',
                            boundaryGap: true,
                            axisLabel: {
                                // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
                                interval: 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
                                rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
                                textStyle: {
                                    color: "#767985",
                                }
                            },
                            data: xData,
                            axisTick: {
                                show: false // 不显示坐标轴刻度线
                            },
                            //  y轴刻度线
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: "#E5E6EB",
                                }
                            }
                        },
                        yAxis: [
                            {
                                type: 'value',
                                name: '（金额）',
                                //坐标轴最大值、最小值、强制设置数据的步长间隔
                                // interval: 5,
                                axisLabel: {
                                    //y轴上带的单位
                                    formatter: '{value}',
                                    textStyle: {
                                        color: "#767985",
                                    }
                                },
                                axisTick: {
                                    show: false // 不显示坐标轴刻度线
                                },
                                //  y轴刻度线
                                axisLine: { show: false },
                                //分割线
                                splitLine: {
                                    show: false,
                                    lineStyle: {
                                        type: 'dashed',
                                        color: '#E5E6EB'
                                    }
                                }
                            },
                            {
                                minInterval: 1, // 最小单位是1
                                type: 'value',
                                name: '（笔数）',
                                axisLabel: {
                                    formatter: '{value}',
                                    textStyle: {
                                        color: "#767985",
                                    }
                                },
                                axisTick: {
                                    show: false // 不显示坐标轴刻度线
                                },
                                //  y轴刻度线
                                axisLine: { show: false },
                                //分割线
                                splitLine: {
                                    show: true,
                                    lineStyle: {
                                        type: 'dashed',
                                        color: '#E5E6EB'
                                    }
                                }
                            }
                        ],
                        series: [
                        {
                        smooth: true,
                        name: '金额',
                        type: 'line',
                        symbolSize: 8, // 设置折线上圆点大小
                        // symbol: 'circle', // 设置拐点为实心圆
                        yAxisIndex: 0,
                        data: res.data.receiptAmountList,
                        itemStyle: {
                            color: '#33D067',
                        },
                        areaStyle: {
                            normal: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                { offset: 0, color: " rgba(50,207,102,0.35)" },
                                // { offset: 0.5, color: "rgba(0,0,128,0.3)" },
                                { offset: 1, color: "rgba(50,207,102,0)" },
                                ]
                            )
                            }
                        },
                        }, {
                        smooth: true,
                        name: '笔数',
                        type: 'line',
                        symbolSize: 8, // 设置折线上圆点大小
                        // symbol: 'circle', // 设置拐点为实心圆
                        yAxisIndex: 1,
                        data: res.data.useList,
                        itemStyle: {
                            color: '#FDBF5A',
                        },
                        areaStyle: {
                            normal: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                { offset: 0, color: "rgba(252,190,89,0.35)" },
                                // { offset: 0.5, color: "rgba(0,0,128,0.3)" },
                                { offset: 1, color: "rgba(252,190,89,0)" },
                                ]
                            )
                            }
                        },
                        }
                        ]
                    }
                    myChart.setOption(option);
                })
            }
        },



                     // 吹风机
        getHairDryer() {
                console.log('123');
            let flag = this.echartsBox.some(v => v.name == '共享吹风机使用统计')
            console.log(flag);
            if (flag) {
                let dayOrMonth = ''
                let myChart = this.$echarts.init(
                    document.getElementById("hairDryer")
                );
                this.echartsBox.forEach(v => {
                    if (v.name == '共享吹风机使用统计') {
                        dayOrMonth = v.talkMonIndex
                    }
                })

                let data = {
                    orderType: 9,
                    dayOrMonth: dayOrMonth,
                    // orderType:1
                }
                getWashingUseCount(data).then(res => {
                    let xData = []
                    res.data.dateList.forEach(v => {

                        if (dayOrMonth == 0) {
                            xData.push(
                                v.substring(5, 10).replace(/-/g, "/")
                            )
                        } else {
                            xData.push(
                                v.substring(5) + '月'
                            )
                        }
                    })
                    let option = {
                        tooltip: {
                            trigger: 'axis',
                            extraCssText: 'width: 178px;height: 100px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
                            axisPointer: {       //坐标轴指示器
                                type: 'line',   //十字准星指示器
                            },
                            formatter: function (params) {
                            let res = `${params[0].marker}<span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name}</span><br/>`
                            params.forEach(item => {
                                if (item.data.studentCount) { }
                                res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName == '金额' ? '营收金额' : '使用次数'}&nbsp;&nbsp;&nbsp;${item.value}${item.seriesName == '金额' ? '元' : '次'}</span><br/>`
                            });
                            return res
                            }
                        },
                        legend: {
                            icon: "circle",
                            itemWidth: 12,  // 设置宽度
                            itemHeight: 12, // 设置高度
                            top: 0,
                            data: ['金额', '次数']
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '10%',
                            containLabel: true
                        },
                        xAxis: {
                        type: 'category',
                        boundaryGap: true,
                        axisLabel: {
                        // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
                        interval: 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
                        rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
                        textStyle: {
                            color: "#767985",
                        }
                        },
                        data: xData,
                        axisTick: {
                        show: false // 不显示坐标轴刻度线
                        },
                        //  y轴刻度线
                        axisLine: {
                        show: true,
                        lineStyle: {
                            color: "#E5E6EB",
                        }
                        }
                    },
                        yAxis: [
                            {
                                type: 'value',
                                name: '（金额）',
                                //坐标轴最大值、最小值、强制设置数据的步长间隔
                                // interval: 5,
                                axisLabel: {
                                    //y轴上带的单位
                                    formatter: '{value}',
                                    textStyle: {
                                        color: "#767985",
                                    }
                                },
                                axisTick: {
                                    show: false // 不显示坐标轴刻度线
                                },
                                //  y轴刻度线
                                axisLine: { show: false },
                                //分割线
                                splitLine: {
                                    show: false,
                                    lineStyle: {
                                        type: 'dashed',
                                        color: '#E5E6EB'
                                    }
                                }
                            },
                            {
                                minInterval: 1, // 最小单位是1
                                type: 'value',
                                name: '（次数）',
                                axisLabel: {
                                    formatter: '{value}',
                                    textStyle: {
                                        color: "#767985",
                                    }
                                },
                                axisTick: {
                                    show: false // 不显示坐标轴刻度线
                                },
                                //  y轴刻度线
                                axisLine: { show: false },
                                //分割线
                                splitLine: {
                                    show: true,
                                    lineStyle: {
                                        type: 'dashed',
                                        color: '#E5E6EB'
                                    }
                                }
                            }
                        ],
                        series: [
                        {
                        smooth: true,
                        name: '金额',
                        type: 'line',
                        symbolSize: 8, // 设置折线上圆点大小
                        // symbol: 'circle', // 设置拐点为实心圆
                        yAxisIndex: 0,
                        data: res.data.receiptAmountList,
                        itemStyle: {
                            color: '#0267FE',
                        },
                        areaStyle: {
                            normal: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    { offset: 0, color: " rgba(28,123,255,0.35)" },
                      // { offset: 0.5, color: "rgba(0,0,128,0.3)" },
                      { offset: 1, color: "rgba(28,123,255,0)" },
                                ]
                            )
                            }
                        },
                        }, 
                        {
                        smooth: true,
                        name: '次数',
                        type: 'line',
                        symbolSize: 8, // 设置折线上圆点大小
                        // symbol: 'circle', // 设置拐点为实心圆
                        yAxisIndex: 1,
                        data: res.data.useList,
                        itemStyle: {
                            color: '#FDBF5A',
                        },
                            areaStyle: {
                            normal: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                { offset: 0, color: "rgba(252,190,89,0.35)" },
                                // { offset: 0.5, color: "rgba(84,112,198,0.3)" },
                                { offset: 1, color: "rgba(252,190,89,0)" },
                                ]
                            )
                            }
                        },
                        }
                    ],
                        dataZoom: [
                            {
                                show: true,
                                height: 8, // 滚动条高度
                                xAxisIndex: [0],
                                bottom: 15, // 距离底部距离
                                start: 0, //初始化时，滑动条宽度开始标度
                                showDetail: false,
                                showDataShadow: false,
                                borderColor: "transparent",
                                textStyle: {
                                    fontSize: 0
                                },
                                endValue: 8,//从0开始的相当于5个(x轴展示的数量)
                                backgroundColor: "rgba(0,0,0,0)",
                                borderWidth: 0,
                                //                 handleIcon:
                                //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
                                //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
                                //   handleSize: "0%",
                                handleStyle: {
                                    color: "#d3dee5"
                                }
                            }
                        ]
                    }
                    myChart.setOption(option);
                })
            }
        },
        // 共享洗衣-弹窗
        getHairDryerDialog() {
            let flag = this.echartsBox.some(v => v.name == '共享吹风机使用统计')
            if (flag) {
                let dayOrMonth = ''
                let myChart = this.$echarts.init(
                    document.getElementById("hairDryerDialog")
                );
                this.echartsBox.forEach(v => {
                    if (v.name == '共享吹风机使用统计') {
                        dayOrMonth = v.talkMonIndex
                    }
                })
                let data = {
                    orderType: 9,
                    dayOrMonth: dayOrMonth,
                    // orderType:1,
                }
                getWashingUseCount(data).then(res => {
                    let xData = []
                    res.data.dateList.forEach(v => {

                        if (dayOrMonth == 0) {
                            xData.push(
                                v.substring(5, 10).replace(/-/g, "/")
                            )
                        } else {
                            xData.push(
                                v.substring(5) + '月'
                            )
                        }
                    })
                    let option = {
                        tooltip: {
                            trigger: 'axis',
                            extraCssText: 'width: 178px;height: 100px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
                            axisPointer: {       //坐标轴指示器
                                type: 'line',   //十字准星指示器
                            },
                            formatter: function (params) {
                            let res = `${params[0].marker}<span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name}</span><br/>`
                            params.forEach(item => {
                                if (item.data.studentCount) { }
                                res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName == '金额' ? '营收金额' : '使用次数'}&nbsp;&nbsp;&nbsp;${item.value}${item.seriesName == '金额' ? '元' : '次'}</span><br/>`
                            });
                            return res
                            }
                        },
                        legend: {
                            icon: "circle",
                            itemWidth: 12,  // 设置宽度
                            itemHeight: 12, // 设置高度
                            top: 0,
                            data: ['金额', '次数']
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '10%',
                            containLabel: true
                        },
                        xAxis: {
                            type: 'category',
                            boundaryGap: true,
                            axisLabel: {
                                // formatter: this.showTxtDate == '本年' ? '{value} 月' : '{value} 日' ,
                                interval: 0,  //坐标轴刻度标签的显示间隔(在类目轴中有效) 0:显示所有  1：隔一个显示一个 :3：隔三个显示一个...
                                rotate: 0,  //标签倾斜的角度，显示不全时可以通过旋转防止标签重叠（-90到90）
                                textStyle: {
                                    color: "#767985",
                                }
                            },
                            data: xData,
                            axisTick: {
                                show: false // 不显示坐标轴刻度线
                            },
                            //  y轴刻度线
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: "#E5E6EB",
                                }
                            }
                        },
                        yAxis: [
                            {
                                type: 'value',
                                name: '（金额）',
                                //坐标轴最大值、最小值、强制设置数据的步长间隔
                                // interval: 5,
                                axisLabel: {
                                    //y轴上带的单位
                                    formatter: '{value}',
                                    textStyle: {
                                        color: "#767985",
                                    }
                                },
                                axisTick: {
                                    show: false // 不显示坐标轴刻度线
                                },
                                //  y轴刻度线
                                axisLine: { show: false },
                                //分割线
                                splitLine: {
                                    show: false,
                                    lineStyle: {
                                        type: 'dashed',
                                        color: '#E5E6EB'
                                    }
                                }
                            },
                            {
                                minInterval: 1, // 最小单位是1
                                type: 'value',
                                name: '（次数）',
                                axisLabel: {
                                    formatter: '{value}',
                                    textStyle: {
                                        color: "#767985",
                                    }
                                },
                                axisTick: {
                                    show: false // 不显示坐标轴刻度线
                                },
                                //  y轴刻度线
                                axisLine: { show: false },
                                //分割线
                                splitLine: {
                                    show: true,
                                    lineStyle: {
                                        type: 'dashed',
                                        color: '#E5E6EB'
                                    }
                                }
                            }
                        ],
                        series: [
                        {
                        smooth: true,
                        name: '金额',
                        type: 'line',
                        symbolSize: 8, // 设置折线上圆点大小
                        // symbol: 'circle', // 设置拐点为实心圆
                        yAxisIndex: 0,
                        data: res.data.receiptAmountList,
                        itemStyle: {
                            color: '#0267FE',
                        },
                        areaStyle: {
                            normal: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    { offset: 0, color: " rgba(28,123,255,0.35)" },
                                    // { offset: 0.5, color: "rgba(0,0,128,0.3)" },
                                    { offset: 1, color: "rgba(28,123,255,0)" },
                                ]
                            )
                            }
                        },
                        }, {
                        smooth: true,
                        name: '次数',
                        type: 'line',
                        symbolSize: 8, // 设置折线上圆点大小
                        // symbol: 'circle', // 设置拐点为实心圆
                        yAxisIndex: 1,
                        data: res.data.useList,
                        itemStyle: {
                            color: '#FDBF5A',
                        },
                        areaStyle: {
                            normal: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                { offset: 0, color: "rgba(252,190,89,0.35)" },
                                // { offset: 0.5, color: "rgba(0,0,128,0.3)" },
                                { offset: 1, color: "rgba(252,190,89,0)" },
                                ]
                            )
                            }
                        },
                        }
                        ]
                    }
                    myChart.setOption(option);
                })
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.container {
    min-height: calc(100vh - 84px);
    background-color: #F4F5F7;
}

.info {
    height: 110px;
    background: #FFFFFF;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 0 87px 0 48px;
    box-sizing: border-box;
    margin-bottom: 24px;

    .info-item {
        display: flex;
        align-items: center;
        margin-right: 90px;

        img {
            width: 54px;
            height: 54px;
            margin-right: 16px;
        }
    }

    .line {
        width: 1px;
        height: 60px;
        background: #D8D8D8;
    }

    .num-box {
        width: 70%;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .n-item {
            display: flex;
            align-items: center;
            // margin-left: 100px;

            .num {
                font-size: 24px;
                color: #333333;
                font-weight: 700;
                margin-right: 8px;
            }

            .name {
                white-space: nowrap;
                font-size: 14px;
                color: #86909C;
            }
        }
    }
}

.echarts-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;


    .smart-talk {
        width: 49.3%;
        height: 394px;
        background: #FFFFFF;
        border-radius: 12px;
        overflow: hidden;
        flex-shrink: 0;
        margin-bottom: 24px;

        .talk-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 32px;
            margin: 24px 24px 0 24px;

            .t-title {
                font-size: 18px;
                color: #1D2129;
                font-weight: 700;
            }

            .month-box {
                display: flex;
                margin-left: 40px;
                height: 24px;

                .month {
                    width: 30px;
                    height: 24px;
                    background-color: #DCDFE6;
                    color: #fff;
                    font-size: 14px;
                    text-align: center;
                    line-height: 24px;
                    border-radius: 4px;
                    cursor: pointer;
                }

                .talk-ac {
                    background-color: #0267FE;
                }
            }
        }

        // 通知 头
        .inform-top {
            display: flex;
            align-items: center;
            height: 57px;
            border-bottom: 1px solid #F4F5F7;
            margin: 0 24px 10px;

            .inform {
                height: 100%;
                line-height: 57px;

                .inform-item {
                    position: relative;
                    font-size: 14px;
                    color: #86909C;
                    margin-right: 22px;
                    cursor: pointer;

                    .bar {
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 30px;
                        height: 3px;
                        background: #2262FF;
                        border-radius: 10px;
                    }
                }

                .ac-i {
                    font-size: 18px;
                    color: #1D2129;
                }
            }
        }

        .inform-content {
            font-size: 14px;
            color: #1D2129;
            height: 336px;
            overflow-y: auto;

            .i-list {
                margin: 0 28px;

                .i-item {
                    height: 45px;
                    line-height: 45px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    cursor: pointer;

                    .time {
                        font-size: 12px;
                        color: #86909C;
                    }
                }
            }
        }
    }

    .smart-talk:nth-child(even) {
        margin-right: 0;
        width: 49.3%;
    }

    .s-odd {
        width: 100%;
    }


}

::v-deep .el-dialog {
    left: 70px;
}
</style>
