<template>
  <el-form ref="form" :model="user" label-width="120px">
    <el-form-item label="旧退款密码" prop="oldPassword" v-if="isOld">
      <el-input v-model="user.oldPassword" placeholder="请输入旧退款密码" oninput="value=value.replace(/[\W_]/g,'')" maxlength="6"
        type="password" show-password style="width: 300px;" />
    </el-form-item>
    <el-form-item label="新退款密码" prop="newPassword" style="position: relative;">
      <el-tooltip placement="top" effect="light">
        <div slot="content">学校的全部教职工在平台上为订单退款时，将会输入退款密码。<br />配置为空或不进行配置，视为关闭此功能</div>
        <i style="color:rgb(34, 98, 255) ;font-size: 16px;cursor: pointer;position: absolute;top: 10px;left: -102px;"
          class="el-icon-question"></i>
      </el-tooltip>
      <el-input v-model="user.newPassword" placeholder="请输入最长6位组合密码" oninput="value=value.replace(/[\W_]/g,'')" maxlength="6"
        type="password" show-password style="width: 300px;" />
    </el-form-item>

    <el-form-item label="确认新退款密码" prop="affirmPassword">
      <el-input v-model="user.affirmPassword" placeholder="请输入最长6位组合密码" type="password" show-password
        oninput="value=value.replace(/[\W_]/g,'')" maxlength="6" style="width: 300px;" />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" size="mini" @click="submit">保存</el-button>
      <el-button type="danger" size="mini" @click="close">关闭</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { getSchoolRefundPassword, updateSchoolRefundPassword } from "@/api/system/user";

export default {
  data() {
    const equalToPassword = (rule, value, callback) => {
      if (this.user.newPassword !== value) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    };
    return {
      user: {
        oldPassword: '',
        newPassword: '',
        affirmPassword: ''
      },
      isOld: true,
      // 表单校验
      rules: {
        oldPassword: [
          { required: true, message: "旧密码不能为空", trigger: "blur" }
        ],
        newPassword: [
          // { required: true, message: "新密码不能为空", trigger: "blur" },
          { min: 6, max: 20, message: "长度在 6 到 20 个字符", trigger: "blur" }
        ],
        affirmPassword: [
          { required: true, message: "确认密码不能为空", trigger: "blur" },
          { required: true, validator: equalToPassword, trigger: "blur" }
        ]
      }
    };
  },
  created() {
    this.getRefPsd()
  },
  methods: {
    // 查是否设密码
    getRefPsd() {
      getSchoolRefundPassword({}).then(res => {
        this.isOld = res.data == 1 ? true : false
      })
    },
    submit() {
      // this.$refs["form"].validate(valid => {
      //   if (!valid) {
      //     // updateUserPwd(this.user.oldPassword, this.user.newPassword).then(response => {
      //     //   this.$modal.msgSuccess("修改成功");
      //     // });
      //     return
      //   }
      // });
      let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]*$/;
      if (!this.isOld && !this.user.newPassword && !this.user.affirmPassword) {
        this.$modal.msgError('您未设置过本校的退款密码，无需保存')
        return
      }
      if (!this.user.oldPassword && this.isOld) {
        this.$modal.msgError('旧退款密码不能为空')
        return
      }
      if ((this.user.newPassword.length < 6 || this.user.affirmPassword.length < 6) && !this.isOld) {
        this.$modal.msgError('密码长度应为 6位')
        return
      }
      if (((0 < this.user.newPassword.length && this.user.newPassword.length < 6) || (0 < this.user.affirmPassword.length && this.user.affirmPassword.length < 6)) && this.isOld) {
        this.$modal.msgError('密码长度应为 6位')
        return
      }
      if (this.user.newPassword != this.user.affirmPassword) {
        this.$modal.msgError('两次密码输入的不一致')
        return
      }
      // if (!reg.test(this.user.newPassword)) {
      //   this.$modal.msgError('密码应为 字母和数字组合')
      //   return
      // }
      console.log(this.user);
      updateSchoolRefundPassword(this.user).then(res => {
        this.$modal.msgSuccess(res.msg)
        this.$refs.form.resetFields()
        this.getRefPsd()
      })
    },
    close() {
      this.$tab.closePage();
    }
  }
};
</script>
