var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("设备添加")]),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            size: "small",
            inline: true,
            "label-width": "120px",
          },
        },
        [
          _c(
            "div",
            { staticClass: "yyy" },
            [
              _c("div", { staticClass: "icon-meus" }, [_vm._v("*")]),
              _c(
                "el-form-item",
                { attrs: { label: "负责人手机号:", prop: "" } },
                [
                  _c("el-input", {
                    ref: "input",
                    attrs: {
                      enterkeyhint: "search",
                      maxlength: "11",
                      placeholder: "请输入平台内已添加的学校负责人手机号",
                    },
                    on: { blur: _vm.getSchool },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.onBlur($event)
                      },
                    },
                    model: {
                      value: _vm.form.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "学校:", prop: "" } },
            [
              _c("el-input", {
                attrs: { placeholder: "生成学校", readonly: "" },
                model: {
                  value: _vm.form.school,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "school", $$v)
                  },
                  expression: "form.school",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "yyy" },
            [
              _c("div", { staticClass: "icon-meus dev-name" }, [_vm._v("*")]),
              _c(
                "el-form-item",
                { attrs: { label: "设备名称:", prop: "" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入设备名称" },
                    model: {
                      value: _vm.form.deviceName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "deviceName", $$v)
                      },
                      expression: "form.deviceName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "设备SN:", prop: "deviceSn" } },
            [
              _c("el-input", {
                attrs: { placeholder: "设备SN", readonly: "" },
                model: {
                  value: _vm.form.deviceSn,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "deviceSn", $$v)
                  },
                  expression: "form.deviceSn",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "安装位置:", prop: "" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入安装位置" },
                model: {
                  value: _vm.form.installationSite,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "installationSite", $$v)
                  },
                  expression: "form.installationSite",
                },
              }),
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "submit",
              attrs: { loading: _vm.subFlag, type: "primary", size: "mini" },
              on: { click: _vm.submit },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }