<template>
  <div class="app-container">
    <el-row :gutter="20">
      <el-col :span="6" :xs="24">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>个人信息</span>
          </div>
          <div>
            <div class="text-center">
              <userAvatar :user="user" @getUserInfo="getUser" />
            </div>
            <ul class="list-group list-group-striped">
              <li class="list-group-item">
                <svg-icon icon-class="" />用户名称
                <div class="pull-right">{{ user.userName }}</div>
              </li>
              <li class="list-group-item">
                <svg-icon icon-class="" />手机号码
                <div class="pull-right">{{ user.phonenumber }}</div>
              </li>
              <li class="list-group-item">
                <svg-icon icon-class="" />用户邮箱
                <div class="pull-right">{{ user.email }}</div>
              </li>
              <li class="list-group-item">
                <svg-icon icon-class="tree" />部门
                <div class="pull-right" v-if="user.dept">{{ user.dept.deptName }} / {{ postGroup }}</div>
                <div class="pull-right" v-else>{{ postGroup }}</div>
              </li>
              <li class="list-group-item">
                <svg-icon icon-class="peoples" />角色
                <div class="pull-right">{{ roleGroup }}</div>
              </li>
              <li class="list-group-item">
                <svg-icon icon-class="" />创建日期
                <div class="pull-right">{{ user.createTime }}</div>
              </li>
            </ul>
          </div>
        </el-card>
      </el-col>
      <el-col :span="18" :xs="24">
        <el-card>
          <div slot="header" class="clearfix">
            <span>基本资料</span>
          </div>
          <el-tabs v-model="activeTab">
            <el-tab-pane label="基本资料" name="userinfo">
              <userInfo :user="user" @getUserInfo="getUser" />
            </el-tab-pane>
            <el-tab-pane label="登录密码" name="resetPwd">
              <resetPwd :user="user" />
            </el-tab-pane>
            <!-- auth -->
            <el-tab-pane label="本校退款密码" name="schoolPwd" v-if="auth && personType == 3">
              <schoolPwd />
            </el-tab-pane>
          </el-tabs>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import userAvatar from "./userAvatar";
import userInfo from "./userInfo";
import resetPwd from "./resetPwd";
import schoolPwd from "./schoolPwd";
import { getUserProfile } from "@/api/system/user";

export default {
  name: "Profile",
  components: { userAvatar, userInfo, resetPwd, schoolPwd },
  data() {
    return {
      user: {},
      roleGroup: {},
      postGroup: {},
      activeTab: "userinfo",
      loading: '',
      personType: '',
      auth: null
    };
  },
  created() {
    this.personType = localStorage.getItem('personType')
    this.getUser();
    console.log(this.$store.getters.roles);
    if (this.$store.getters.roles.indexOf('admin') != -1) {
      this.auth = true
    } else {
      this.auth = false
    }
  },
  methods: {
    //     userEmit(){
    //  this.getUser();
    //   },
    getUser() {
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.8)",
      });
      getUserProfile().then(response => {
        console.log(response);
        this.user = response.data;
        this.roleGroup = response.roleGroup;
        this.postGroup = response.postGroup;
        this.loading.close();
      });
    },

  }
};
</script>
<style scoped lang="scss">
.app-container {
  min-height: calc(100vh - 84px);
  background-color: #F4F5F7;
  padding: 14px;

}

::v-deep .el-card.is-always-shadow {
  box-shadow: none
}

::v-deep .el-card {
  border: none;
}

::v-deep .el-tabs__nav-wrap::after {
  border-bottom: 1px solid #F4F5F7;
  background-color: #fff;
}

::v-deep .el-tabs__header {
  margin: 0 0 24px;
}

::v-deep .el-form-item__label {
  font-weight: 400;
}

.list-group-item {
  border-bottom: 1px solid #F4F5F7;
  border-top: 1px solid #F4F5F7;
  background-color: #fff;
  padding: 16px 0;
  box-sizing: border-box;
}
</style>
