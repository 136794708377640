var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { height: "100%" } }, [
    false ? _c("div", { staticClass: "login" }, [_vm._m(0)]) : _vm._e(),
    _vm.personType == 3
      ? _c("div", { staticClass: "login-wrap" }, [
          _vm._m(1),
          _c("div", { staticClass: "right" }, [
            _vm._m(2),
            _c(
              "div",
              { staticClass: "login-box" },
              [
                _c(
                  "el-form",
                  {
                    ref: "loginForm",
                    staticClass: "login-form",
                    attrs: { model: _vm.loginForm, rules: _vm.loginRules },
                  },
                  [
                    _c("el-form-item", { attrs: { prop: "username" } }, [
                      _c(
                        "div",
                        { staticClass: "item-box" },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              "auto-complete": "off",
                              placeholder: "账号",
                            },
                            model: {
                              value: _vm.loginForm.username,
                              callback: function ($$v) {
                                _vm.$set(_vm.loginForm, "username", $$v)
                              },
                              expression: "loginForm.username",
                            },
                          }),
                          _c("img", {
                            staticClass: "icon",
                            attrs: {
                              src: require("../assets/images/user.png"),
                              alt: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { margin: "40px 0" },
                        attrs: { prop: "password" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "item-box" },
                          [
                            _c(
                              "el-input",
                              {
                                attrs: {
                                  type: _vm.typeSwitch,
                                  "auto-complete": "off",
                                  placeholder: "密码",
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.handleLogin($event)
                                  },
                                },
                                model: {
                                  value: _vm.loginForm.password,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.loginForm, "password", $$v)
                                  },
                                  expression: "loginForm.password",
                                },
                              },
                              [
                                _c("svg-icon", {
                                  staticClass: "el-input__icon input-icon",
                                  attrs: {
                                    slot: "prefix",
                                    "icon-class": "password",
                                  },
                                  slot: "prefix",
                                }),
                              ],
                              1
                            ),
                            _c("img", {
                              staticClass: "icon",
                              attrs: {
                                src: require("../assets/images/psd.png"),
                                alt: "",
                              },
                            }),
                            _vm.isPsd
                              ? _c("img", {
                                  staticClass: "icon last",
                                  attrs: {
                                    src: require("../assets/images/y.png"),
                                    alt: "",
                                  },
                                  on: { click: _vm.switchPsd },
                                })
                              : _c("img", {
                                  staticClass: "icon last",
                                  attrs: {
                                    src: require("../assets/images/n.png"),
                                    alt: "",
                                  },
                                  on: { click: _vm.switchPsd },
                                }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm.captchaOnOff
                      ? _c("el-form-item", { attrs: { prop: "code" } }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "line-height": "66px",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "code" },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: {
                                        "auto-complete": "off",
                                        placeholder: "请输入验证码",
                                      },
                                      nativeOn: {
                                        keyup: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.handleLogin($event)
                                        },
                                      },
                                      model: {
                                        value: _vm.loginForm.code,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.loginForm, "code", $$v)
                                        },
                                        expression: "loginForm.code",
                                      },
                                    },
                                    [
                                      _c("svg-icon", {
                                        staticClass:
                                          "el-input__icon input-icon",
                                        attrs: {
                                          slot: "prefix",
                                          "icon-class": "validCode",
                                        },
                                        slot: "prefix",
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "login-code" },
                                [
                                  _c("el-image", {
                                    staticClass: "login-code-img",
                                    attrs: { src: _vm.codeUrl, fit: "cover" },
                                    on: { click: _vm.getCode },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _c(
                      "el-checkbox",
                      {
                        staticStyle: { margin: "0px 0px 25px 0px" },
                        model: {
                          value: _vm.loginForm.rememberMe,
                          callback: function ($$v) {
                            _vm.$set(_vm.loginForm, "rememberMe", $$v)
                          },
                          expression: "loginForm.rememberMe",
                        },
                      },
                      [_vm._v("记住密码")]
                    ),
                    _c(
                      "el-form-item",
                      { staticStyle: { width: "100%" } },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              loading: _vm.loading,
                              size: "medium",
                              type: "primary",
                            },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handleLogin($event)
                              },
                            },
                          },
                          [
                            !_vm.loading
                              ? _c("span", [_vm._v("登 录")])
                              : _c("span", [_vm._v("登 录 中...")]),
                          ]
                        ),
                        _vm.register
                          ? _c(
                              "div",
                              { staticStyle: { float: "right" } },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "link-type",
                                    attrs: { to: "/register" },
                                  },
                                  [_vm._v("立即注册")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ])
      : _c("div", { staticClass: "login-platform" }, [
          _c("div", { staticClass: "bg-title" }, [
            _vm._v("数智化管理平台运营端"),
          ]),
          _c("div", { staticClass: "english" }, [
            _vm._v("DIGITAL INTELLIGENCE MANAGEMENT PLATFORM"),
          ]),
          _c(
            "div",
            { staticClass: "platform-box" },
            [
              _vm._m(3),
              _c(
                "el-form",
                {
                  ref: "loginForm",
                  staticClass: "login-form-plat",
                  attrs: { model: _vm.loginForm, rules: _vm.loginRules },
                },
                [
                  _c("el-form-item", { attrs: { prop: "username" } }, [
                    _c(
                      "div",
                      { staticClass: "item-box p-box" },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "text",
                            "auto-complete": "off",
                            placeholder: "账号",
                          },
                          model: {
                            value: _vm.loginForm.username,
                            callback: function ($$v) {
                              _vm.$set(_vm.loginForm, "username", $$v)
                            },
                            expression: "loginForm.username",
                          },
                        }),
                        _c("img", {
                          staticClass: "icon",
                          attrs: {
                            src: require("../assets/images/user.png"),
                            alt: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { margin: "40px 0" },
                      attrs: { prop: "password" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "item-box p-box" },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                type: _vm.typeSwitch,
                                "auto-complete": "off",
                                placeholder: "密码",
                              },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.handleLogin($event)
                                },
                              },
                              model: {
                                value: _vm.loginForm.password,
                                callback: function ($$v) {
                                  _vm.$set(_vm.loginForm, "password", $$v)
                                },
                                expression: "loginForm.password",
                              },
                            },
                            [
                              _c("svg-icon", {
                                staticClass: "el-input__icon input-icon",
                                attrs: {
                                  slot: "prefix",
                                  "icon-class": "password",
                                },
                                slot: "prefix",
                              }),
                            ],
                            1
                          ),
                          _c("img", {
                            staticClass: "icon",
                            attrs: {
                              src: require("../assets/images/psd.png"),
                              alt: "",
                            },
                          }),
                          _vm.isPsd
                            ? _c("img", {
                                staticClass: "icon last",
                                attrs: {
                                  src: require("../assets/images/y.png"),
                                  alt: "",
                                },
                                on: { click: _vm.switchPsd },
                              })
                            : _c("img", {
                                staticClass: "icon last",
                                attrs: {
                                  src: require("../assets/images/n.png"),
                                  alt: "",
                                },
                                on: { click: _vm.switchPsd },
                              }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm.captchaOnOff
                    ? _c("el-form-item", { attrs: { prop: "code" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "line-height": "66px",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "code" },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: {
                                      "auto-complete": "off",
                                      placeholder: "请输入验证码",
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.handleLogin($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.loginForm.code,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.loginForm, "code", $$v)
                                      },
                                      expression: "loginForm.code",
                                    },
                                  },
                                  [
                                    _c("svg-icon", {
                                      staticClass: "el-input__icon input-icon",
                                      attrs: {
                                        slot: "prefix",
                                        "icon-class": "validCode",
                                      },
                                      slot: "prefix",
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "login-code" },
                              [
                                _c("el-image", {
                                  staticClass: "login-code-img",
                                  attrs: { src: _vm.codeUrl, fit: "cover" },
                                  on: { click: _vm.getCode },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "el-checkbox",
                    {
                      staticStyle: { margin: "0px 0px 25px 0px" },
                      model: {
                        value: _vm.loginForm.rememberMe,
                        callback: function ($$v) {
                          _vm.$set(_vm.loginForm, "rememberMe", $$v)
                        },
                        expression: "loginForm.rememberMe",
                      },
                    },
                    [_vm._v("记住密码")]
                  ),
                  _c("el-form-item", { staticStyle: { width: "100%" } }, [
                    _c(
                      "div",
                      { staticClass: "plat" },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              loading: _vm.loading,
                              size: "medium",
                              type: "primary",
                            },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.handleLogin($event)
                              },
                            },
                          },
                          [
                            !_vm.loading
                              ? _c("span", [_vm._v("登 录")])
                              : _c("span", [_vm._v("登 录 中...")]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm.register
                      ? _c(
                          "div",
                          { staticStyle: { float: "right" } },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "link-type",
                                attrs: { to: "/register" },
                              },
                              [_vm._v("立即注册")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "el-login-footer" }, [
      _c("span", [
        _vm._v(
          "Copyright © 2020 XinYuWei All Rights Reserved 济南新域伟网络科技有限公司 | 鲁ICP备20000246号-1 | 山东省济南市历下区奥体西路中国铁建国际城B座703室"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left" }, [
      _c("div", { staticClass: "bg-title" }, [_vm._v("数智化管理平台学校端")]),
      _c("div", { staticClass: "english" }, [
        _vm._v("DIGITAL INTELLIGENCE MANAGEMENT PLATFORM"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top" }, [
      _c("div", [_vm._v("登录")]),
      _c("img", {
        attrs: { src: require("../assets/images/sign.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top" }, [
      _c("div", [_vm._v("登录")]),
      _c("img", {
        attrs: { src: require("../assets/images/sign.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }