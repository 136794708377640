var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "info" }, [
        _c("div", { staticClass: "info-item" }, [
          _c("img", {
            attrs: { src: require("@/assets/images/stu.png"), alt: "" },
          }),
          _c(
            "div",
            { staticStyle: { display: "flex", "align-items": "center" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "font-size": "24px",
                    color: "#333",
                    "font-weight": "700",
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.headInfo.studentCount ? _vm.headInfo.studentCount : 0
                    )
                  ),
                ]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "font-size": "14px",
                    color: "#86909C",
                    "margin-left": "8px",
                    "white-space": "nowrap",
                  },
                },
                [_vm._v("学生(人)")]
              ),
            ]
          ),
        ]),
        _c("div", { staticClass: "info-item" }, [
          _c("img", {
            attrs: { src: require("@/assets/images/tea.png"), alt: "" },
          }),
          _c(
            "div",
            { staticStyle: { display: "flex", "align-items": "center" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "font-size": "24px",
                    color: "#333",
                    "font-weight": "700",
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.headInfo.teacherCount ? _vm.headInfo.teacherCount : 0
                    )
                  ),
                ]
              ),
              _c(
                "span",
                {
                  staticStyle: {
                    "font-size": "14px",
                    color: "#86909C",
                    "margin-left": "8px",
                    "white-space": "nowrap",
                  },
                },
                [_vm._v("教职工(人)")]
              ),
            ]
          ),
        ]),
        _c("div", { staticClass: "line" }),
        _c(
          "div",
          { staticClass: "num-box" },
          _vm._l(_vm.numList, function (item, index) {
            return _c("div", { key: index, staticClass: "n-item" }, [
              _c("span", { staticClass: "num" }, [_vm._v(_vm._s(item.num))]),
              _c("span", { staticClass: "name" }, [_vm._v(_vm._s(item.name))]),
            ])
          }),
          0
        ),
      ]),
      _c(
        "div",
        { staticClass: "echarts-box" },
        [
          _c(
            "div",
            {
              staticClass: "smart-talk",
              class: _vm.echartsBox.length == 0 ? "s-odd" : "",
            },
            [
              _c("div", { staticClass: "talk-top" }, [
                _c("div", { staticClass: "t-title" }, [_vm._v("学生统计")]),
                _c(
                  "div",
                  { staticStyle: { display: "flex", "align-items": "center" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "120px" },
                        attrs: { placeholder: "请选择" },
                        on: {
                          change: function ($event) {
                            return _vm.schoolChange($event, _vm.stuObj)
                          },
                        },
                        model: {
                          value: _vm.stuObj.talkVal,
                          callback: function ($$v) {
                            _vm.$set(_vm.stuObj, "talkVal", $$v)
                          },
                          expression: "stuObj.talkVal",
                        },
                      },
                      _vm._l(_vm.stuOptions, function (v) {
                        return _c("el-option", {
                          key: v.value,
                          attrs: { label: v.label, value: v.value },
                        })
                      }),
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "40px",
                          "text-align": "center",
                          cursor: "pointer",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.zoomChart({ name: "学生统计" }, 0)
                          },
                        },
                      },
                      [
                        _c("svg-icon", {
                          attrs: { "icon-class": "fullscreen" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", {
                staticStyle: { width: "100%", height: "350px" },
                attrs: { id: "stuEcharts" },
              }),
            ]
          ),
          _vm._l(_vm.echartsBox, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "smart-talk",
                class: index + 1 == _vm.echartsBox.length ? "s-odd" : "",
              },
              [
                item.name != "通知"
                  ? [
                      _c("div", { staticClass: "talk-top" }, [
                        _c("div", { staticClass: "t-title" }, [
                          _vm._v(_vm._s(item.name)),
                        ]),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            item.name == "学生统计" ||
                            item.name == "考勤统计" ||
                            item.name == "智能门禁进出统计"
                              ? _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "120px" },
                                    attrs: { placeholder: "请选择" },
                                    on: {
                                      change: function ($event) {
                                        return _vm.schoolChange($event, item)
                                      },
                                    },
                                    model: {
                                      value: item.talkVal,
                                      callback: function ($$v) {
                                        _vm.$set(item, "talkVal", $$v)
                                      },
                                      expression: "item.talkVal",
                                    },
                                  },
                                  [
                                    item.name == "学生统计"
                                      ? _vm._l(_vm.stuOptions, function (v) {
                                          return _c("el-option", {
                                            key: v.value,
                                            attrs: {
                                              label: v.label,
                                              value: v.value,
                                            },
                                          })
                                        })
                                      : _vm._e(),
                                    item.name == "考勤统计"
                                      ? _vm._l(_vm.leaveOptions, function (v) {
                                          return _c("el-option", {
                                            key: v.value,
                                            attrs: {
                                              label: v.label,
                                              value: v.value,
                                            },
                                          })
                                        })
                                      : _vm._e(),
                                    item.name == "智能门禁进出统计"
                                      ? _vm._l(_vm.guardOptions, function (v) {
                                          return _c("el-option", {
                                            key: v.value,
                                            attrs: {
                                              label: v.label,
                                              value: v.value,
                                            },
                                          })
                                        })
                                      : _vm._e(),
                                  ],
                                  2
                                )
                              : _vm._e(),
                            item.name == "考勤统计"
                              ? _c("el-date-picker", {
                                  staticStyle: {
                                    width: "140px",
                                    "margin-left": "10px",
                                  },
                                  attrs: {
                                    type: "date",
                                    placeholder: "选择日期",
                                    clearable: false,
                                  },
                                  on: { change: _vm.changeTime },
                                  model: {
                                    value: item.timeVal,
                                    callback: function ($$v) {
                                      _vm.$set(item, "timeVal", $$v)
                                    },
                                    expression: "item.timeVal",
                                  },
                                })
                              : _vm._e(),
                            item.name != "学生统计" && item.name != "考勤统计"
                              ? _c(
                                  "div",
                                  { staticClass: "month-box" },
                                  _vm._l(
                                    _vm.monthsTalk,
                                    function (item1, index1) {
                                      return _c(
                                        "div",
                                        {
                                          key: index1,
                                          staticClass: "month",
                                          class:
                                            item.talkMonKey &&
                                            index1 == item.talkMonIndex
                                              ? "talk-ac"
                                              : "",
                                          on: {
                                            click: function ($event) {
                                              return _vm.monthTalkClick(
                                                index,
                                                index1,
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(item1))]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "40px",
                                  "text-align": "center",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.zoomChart(item, index)
                                  },
                                },
                              },
                              [
                                _c("svg-icon", {
                                  attrs: { "icon-class": "fullscreen" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      item.name == "考勤统计"
                        ? _c("div", {
                            staticStyle: { width: "100%", height: "350px" },
                            attrs: { id: "attendEcharts" },
                          })
                        : _vm._e(),
                      item.name == "智能公话使用统计"
                        ? _c("div", {
                            staticStyle: { width: "100%", height: "350px" },
                            attrs: { id: "talkEcharts" },
                          })
                        : _vm._e(),
                      item.name == "校园团餐营收统计"
                        ? _c("div", {
                            staticStyle: { width: "100%", height: "350px" },
                            attrs: { id: "groupPayEcharts" },
                          })
                        : _vm._e(),
                      item.name == "校园图书借阅统计"
                        ? _c("div", {
                            staticStyle: { width: "100%", height: "350px" },
                            attrs: { id: "bookEcharts" },
                          })
                        : _vm._e(),
                      item.name == "共享洗衣使用统计"
                        ? _c("div", {
                            staticStyle: { width: "100%", height: "350px" },
                            attrs: { id: "washEcharts" },
                          })
                        : _vm._e(),
                      item.name == "智能门禁进出统计"
                        ? _c("div", {
                            staticStyle: { width: "100%", height: "350px" },
                            attrs: { id: "accessEcharts" },
                          })
                        : _vm._e(),
                      item.name == "智慧淋浴使用统计"
                        ? _c("div", {
                            staticStyle: { width: "100%", height: "350px" },
                            attrs: { id: "showerStatistics" },
                          })
                        : _vm._e(),
                      item.name == "共享吹风机使用统计"
                        ? _c("div", {
                            staticStyle: { width: "100%", height: "350px" },
                            attrs: { id: "hairDryer" },
                          })
                        : _vm._e(),
                    ]
                  : [
                      _c(
                        "div",
                        { staticClass: "inform-top" },
                        _vm._l(_vm.tabs, function (v, i) {
                          return _c(
                            "div",
                            {
                              key: i,
                              staticClass: "inform",
                              on: {
                                click: function ($event) {
                                  return _vm.tabClick(i)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "inform-item",
                                  class: i == _vm.tabIndex ? "ac-i" : "",
                                },
                                [
                                  _c("span", [_vm._v(_vm._s(v))]),
                                  i == _vm.tabIndex
                                    ? _c("div", { staticClass: "bar" })
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                      _c(
                        "div",
                        { staticClass: "inform-content" },
                        _vm._l(_vm.tabList, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "i-list" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "i-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.openDetail(item)
                                    },
                                  },
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(item.title ? item.title : "")
                                    ),
                                  ]),
                                  _c("div", { staticClass: "time" }, [
                                    _vm._v(
                                      _vm._s(
                                        item.createTime ? item.createTime : ""
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ],
              ],
              2
            )
          }),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.open,
            width: "630px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.open = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, "label-width": "100px" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c("el-form-item", { attrs: { label: "内容：" } }, [
                        _c("div", {
                          staticStyle: { width: "200%" },
                          domProps: { innerHTML: _vm._s(_vm.form.content) },
                        }),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.titleChart,
            visible: _vm.openChart,
            width: "82%",
            "append-to-body": "",
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.openChart = $event
            },
            opened: _vm.switchChart,
          },
        },
        [
          _vm.titleChart == "学生统计"
            ? _c("div", {
                staticStyle: { width: "100%", height: "550px" },
                attrs: { id: "stuEchartsDialog" },
              })
            : _vm._e(),
          _vm.titleChart == "考勤统计"
            ? _c("div", {
                staticStyle: { width: "100%", height: "550px" },
                attrs: { id: "attendEchartsDialog" },
              })
            : _vm._e(),
          _vm.titleChart == "智能公话使用统计"
            ? _c("div", {
                staticStyle: { width: "100%", height: "550px" },
                attrs: { id: "talkEchartsDialog" },
              })
            : _vm._e(),
          _vm.titleChart == "校园团餐营收统计"
            ? _c("div", {
                staticStyle: { width: "100%", height: "550px" },
                attrs: { id: "groupPayEchartsDialog" },
              })
            : _vm._e(),
          _vm.titleChart == "校园图书借阅统计"
            ? _c("div", {
                staticStyle: { width: "100%", height: "550px" },
                attrs: { id: "bookEchartsDialog" },
              })
            : _vm._e(),
          _vm.titleChart == "共享洗衣使用统计"
            ? _c("div", {
                staticStyle: { width: "100%", height: "550px" },
                attrs: { id: "washEchartsDialog" },
              })
            : _vm._e(),
          _vm.titleChart == "智能门禁进出统计"
            ? _c("div", {
                staticStyle: { width: "100%", height: "550px" },
                attrs: { id: "accessEchartsDialog" },
              })
            : _vm._e(),
          _vm.titleChart == "智慧淋浴使用统计"
            ? _c("div", {
                staticStyle: { width: "100%", height: "550px" },
                attrs: { id: "takeShowerStatistics" },
              })
            : _vm._e(),
          _vm.titleChart == "共享吹风机使用统计"
            ? _c("div", {
                staticStyle: { width: "100%", height: "550px" },
                attrs: { id: "hairDryerDialog" },
              })
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }