<template>
    <div class="box">
        <div v-if="personType == 1">
            <div class="content-index">
                <!-- <dv-loading v-if="isLoading" class="loading-box">加载中...</dv-loading> -->
                <!-- 自适应容器 -->
                <ScaleScreen :width="1920" :height="1080" class="scale-wrap" :selfAdaption="selfAdaption">
                    <div class="top">
                        <div class="top-l"> {{ date.year }}-{{ date.month }}-{{ date.date }} {{ date.hours }}:{{
                            date.minutes }}</div>
                        <!-- @click="click" -->
                        <div class="week">{{ weeks }}</div>
                        <div class="top-cot">新域伟科技数据可视化系统</div>
                        <div class="top-r" @click="getInit(1)">
                            <div class="r-box">
                                <img src="@/assets/DataSet/refresh.png" alt="">
                                <span>刷新数据</span>
                            </div>
                        </div>
                    </div>
                    <div class="content">
                        <div class="content-left">
                            <!-- 增长数据 -->
                            <div class="grow-box" style="">
                                <!-- 标题+弹窗 -->
                                <div class="school-grow">
                                    <!--  @click="openSchoolGrow" -->
                                    <div id="diaDateTit"
                                        style="height: 100%;display: flex;align-items: center;font-weight: 700;">
                                        <div>{{ isSchoolGName }}</div>
                                        <!-- <div class="s-g-bar"></div> -->
                                    </div>
                                    <!-- 中间弹窗 -->
                                    <div class="g-content" v-if="isSchoolG" id="diaDate">
                                        <div class="g-c-i" :class="isSchoolGAc == 1 ? 'item-ac' : ''"
                                            @click="schoolGrowClick(1)">
                                            用户增长数据</div>
                                        <div class="g-c-i" :class="isSchoolGAc == 2 ? 'item-ac' : ''"
                                            @click="schoolGrowClick(2)">人脸绑定增长数据</div>
                                    </div>

                                </div>
                                <!-- 日月切换 -->
                                <div class="year-box" v-if="isSchoolGAc == 2">
                                    <div @click="stuDateClickPay(i)" class="year-item"
                                        :class="i == stuActiveDate ? 'year-ac' : ''" v-for="(v, i) in stuMouList" :key="i">
                                        {{
                                            v }}</div>
                                </div>
                                <div v-show="isSchoolGAc == 1" id="schoolGrowEchart"
                                    style="width: 396px;height: 240px;margin-top: 30px;" :class="isSchoolG ? 'is-g' : ''">
                                </div>
                                <div v-show="isSchoolGAc == 2" id="studentEchart"
                                    style="width: 430px;height: 240px;margin-top: 30px;" :class="isSchoolG ? 'is-g' : ''">
                                </div>
                            </div>
                            <!-- 访问记录 -->
                            <div class="grow-box" v-for="(item, index) in recList" :key="index">
                                <div class="xss-grow">
                                    <!-- 统计标题 -->
                                    <div class="school-select" @click="schoolSelect(index)">
                                        <div>{{ item.schoolName }}</div>
                                        <div class="s-g-bar"></div>
                                    </div>
                                    <!-- 添加弹窗 -->
                                    <div class="school-alert" v-if="item.schoolAc" id="diaDate">
                                        <div class="a-item" :class="i == item.schoolIndex ? 'a-item-ac' : ''"
                                            @click="schoolItemClick(i, v, index)"
                                            v-for="(v, i) in ((index == 1 && isuseAc == 2) ? schoolPayList : schoolList)"
                                            :key="i">
                                            {{
                                                v.schoolName
                                            }}
                                        </div>
                                    </div>
                                    <!-- 活跃率  -->
                                    <div class="xss-name" @click.stop="openUse(index)">
                                        <div>{{ index == 0 ? item.name : isuseName }}</div>
                                        <!-- <div>{{ item.name }}</div> -->
                                        <div v-if="item.name != '小程序活跃率统计'" class="s-g-bar"></div>
                                        <!-- 标题弹窗 -->
                                        <div class="xss-name-tit" v-if="isUseVis && index == 1" id="diaDate">
                                            <div class="g-c-i" v-for="(v, i) in useList" :key="i"
                                                :class="isuseAc == i ? 'item-ac' : ''" @click.stop="useClick(v, i)">
                                                {{ v }}</div>
                                        </div>
                                    </div>
                                    <div class="mini-app" v-if="index == 0" @click.stop="miniTitClick">
                                        <div style="white-space: nowrap;">{{ miniTypeName }}</div>
                                        <div class="s-g-bar"></div>

                                        <!-- 弹窗  -->
                                        <div class="mini-alert" v-if="miniOpen">
                                            <div @click="miniClick(typeIndex)" class="a-item"
                                                :class="miniTypeIndex == typeIndex ? 'a-item-ac' : ''"
                                                v-for="(typeItem, typeIndex) in miniType">{{ typeItem }}</div>
                                        </div>
                                    </div>
                                    <div class="year-box" :class="index == 0 ? 'year-box-hy' : ''">
                                        <div @click="dateClick(i, index)" class="year-item"
                                            :class="i == item.activeDate ? 'year-ac' : ''" v-for="(v, i) in item.dateList"
                                            :key="i">{{
                                                v }}</div>
                                    </div>
                                </div>
                                <!-- 统计图表 -->
                                <div v-if="index == 0" id="xssEchart" style="width: 100%;height: 205px;margin-top: 60px;"
                                    :class="(item.schoolAc) ? 'is-g' : ''">
                                </div>
                                <div v-if="index == 1">
                                    <div v-show="isuseAc == 0" id="phonePayUse"
                                        style="width: 440px;height: 240px;margin-top: 40px;"
                                        :class="(item.schoolAc || isUseVis) ? 'is-g' : ''">
                                    </div>
                                    <div v-show="isuseAc == 1" id="schoolBooks"
                                        style="width: 440px;height: 240px;margin-top: 40px;"
                                        :class="(item.schoolAc || isUseVis) ? 'is-g' : ''">
                                    </div>
                                    <div v-show="isuseAc == 2" id="schoolIncome"
                                        style="width: 440px;height: 240px;margin-top: 40px;"
                                        :class="(item.schoolAc || isUseVis) ? 'is-g' : ''">
                                    </div>
                                    <div v-show="isuseAc == 3" id="entrance"
                                        style="width: 440px;height: 240px;margin-top: 40px;"
                                        :class="(item.schoolAc || isUseVis) ? 'is-g' : ''">
                                    </div>
                                    <div v-show="(isuseAc == 4 || isuseAc == 5)" id="laundry"
                                        style="width: 440px;height: 240px;margin-top: 40px;"
                                        :class="(item.schoolAc || isUseVis) ? 'is-g' : ''">
                                    </div>
                                    <div v-show="(isuseAc == 6)" id="vending"
                                        style="width: 440px;height: 240px;margin-top: 40px;"
                                        :class="(item.schoolAc || isUseVis) ? 'is-g' : ''">
                                    </div>
                                    <!-- <div v-show="isuseAc == 5" id="laundry"
                                        style="width: 440px;height: 240px;margin-top: 40px;"
                                        :class="(item.schoolAc || isUseVis) ? 'is-g' : ''">
                                    </div> -->
                                </div>

                                <!-- <div v-if="index == 1" id="phoneEchart" style="width: 100%;height: 80%;margin-top: 40px;"
                                    :class="(item.schoolAc) ? 'is-g' : ''">
                                </div> -->
                                <!-- <div class="grow-box"></div> -->
                            </div>
                        </div>
                        <div class="content-center">
                            <div class="c-top">
                                <!-- 学生、代理商.. -->
                                <div class="c-t-box">
                                    <div class="c-t-rho" v-for="(item, index) in rhoList" :style="{
                                        'background-image': 'url(' + item.img + ')',
                                        'background-repeat': 'no-repeat', 'background-size': 'cover'
                                    }">
                                        <div class="rho-num" :style="{
                                            'color': item.colorTxt, 'font-size': '24px'
                                        }">
                                            <div v-if="item.name == '每日消费笔数'">
                                                <template v-if="(item.num - 0)">
                                                    <animate-number duration="500" from="0" :to="item.num"
                                                        :key="item.num"></animate-number>
                                                    <!-- <span style="font-size: 20px;">%</span> -->
                                                </template>

                                                <div v-else>0
                                                    <!-- <span style="font-size: 20px;">%</span>  -->
                                                </div>
                                            </div>
                                            <div v-else-if="item.name == '每日消费金额'">
                                                <animate-number v-if="(item.num - 0)" duration="500" from="0" :to="item.num"
                                                    :formatter="formatterRate" :key="item.num"></animate-number>
                                                <div v-else>0</div>
                                            </div>
                                            <div v-else>
                                                <animate-number v-if="item.num" duration="500" from="0" :to="item.num"
                                                    :key="item.num"></animate-number>
                                                <div v-else>0</div>
                                            </div>
                                            <div style="font-size: 20px;">{{ item.dw }}</div>
                                        </div>
                                        <div class="rho-name">
                                            <div>{{ item.name }}</div>
                                            <div class="rho-tip" @click="tipClick(index)">
                                                <div v-if="(index == 3 || index == 4)">{{ (index ==
                                                    3 ? schoolTipName : schoolTipNameMon) ? (index ==
                                                        3 ? schoolTipName : schoolTipNameMon) :
                                                    item.tip }}</div>
                                                <div v-else>{{ item.tip }}</div>
                                                <div v-if="item.name == '每日消费笔数' || item.name == '每日消费金额'" class="s-g-bar">
                                                </div>
                                                <!-- 添加弹窗 每日笔数 -->
                                                <div class="school-alert" v-if="index == 3 && schoolTipAc" id="diaDate">
                                                    <div class="a-item" :class="i == schoolTipIndex ? 'a-item-ac' : ''"
                                                        @click.stop="schoolItemClickTip(i, v, index)"
                                                        v-for="(v, i) in schoolPayList" :key="i">{{ v.schoolName
                                                        }}
                                                    </div>
                                                </div>
                                                <!-- 添加弹窗 每日金额 -->
                                                <div class="school-alert" v-if="index == 4 && schoolTipAcMon" id="diaDate">
                                                    <div class="a-item" :class="i == schoolTipIndexMon ? 'a-item-ac' : ''"
                                                        @click.stop="schoolItemClickTipMon(i, v, index)"
                                                        v-for="(v, i) in schoolPayList" :key="i">{{ v.schoolName
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- 开通业务列表 -->
                                <div class="work-list">
                                    <div class="work-item" style="flex: 2.5;">
                                        <div class="work">

                                            <div style="color: #01E0D9;margin-bottom: 5px;"> <span style="font-size: 28px;">
                                                    <animate-number duration="1500" v-if="deviceObj.jxsx" from="0"
                                                        :to="deviceObj.jxsx" :key="deviceObj.jxsx"></animate-number>
                                                    <span v-else>0</span>
                                                </span>台</div>
                                            <div>校园数智公话</div>
                                        </div>
                                        <div class="work">
                                            <div style="color: #FEDB65;margin-bottom: 5px;"> <span style="font-size: 28px;">
                                                    <animate-number duration="1500" v-if="deviceObj.bp" from="0"
                                                        :to="deviceObj.bp" :key="deviceObj.bp"></animate-number>
                                                    <span v-else>0</span>
                                                </span>台</div>
                                            <div>校园数智班牌</div>
                                        </div>
                                        <div class="work">
                                            <div style="color: #FEDB65;margin-bottom: 5px;"> <span style="font-size: 28px;">
                                                    <animate-number duration="1500" v-if="deviceObj.mj" from="0"
                                                        :to="deviceObj.mj" :key="deviceObj.mj"></animate-number>
                                                    <span v-else>0</span>
                                                </span>台</div>
                                            <div>校园刷脸门禁</div>
                                        </div>
                                        <div class="work">
                                            <div style="color: #01E0D9;margin-bottom: 5px;"> <span style="font-size: 28px;">
                                                    <animate-number duration="1500" v-if="deviceObj.cfj" from="0"
                                                        :to="deviceObj.cfj" :key="deviceObj.cfj"></animate-number>
                                                    <span v-else>0</span>
                                                </span>台</div>
                                            <div>校园吹风机</div>
                                        </div>
                                    </div>
                                    <div class="work-item" style="position: relative;">
                                        <img style="width: 214px;height: 285px;margin-bottom: 50px;"
                                            src="@/assets/DataSet/center.png" />
                                        <div class="work-txt">已部署设备台数</div>
                                    </div>
                                    <div class="work-item" style="flex: 2.5;">
                                        <div class="work">
                                            <div style="color: #01E0D9;margin-bottom: 5px;"> <span style="font-size: 28px;">
                                                    <animate-number duration="1500" v-if="deviceObj.xfj" from="0"
                                                        :to="deviceObj.xfj" :key="deviceObj.xfj"></animate-number>
                                                    <span v-else>0</span>
                                                </span>台</div>
                                            <div>校园团餐机</div>
                                        </div>
                                        <div class="work">
                                            <div style="color: #FEDB65;margin-bottom: 5px;"> <span style="font-size: 28px;">
                                                    <animate-number duration="1500" v-if="deviceObj.xyj" from="0"
                                                        :to="deviceObj.xyj" :key="deviceObj.xyj"></animate-number>
                                                    <span v-else>0</span>
                                                </span>台</div>
                                            <div>校园洗衣机</div>
                                        </div>
                                        <div class="work">
                                            <div style="color: #FEDB65;margin-bottom: 5px;"> <span style="font-size: 28px;">
                                                    <animate-number duration="1500" v-if="deviceObj.ysj" from="0"
                                                        :to="deviceObj.ysj" :key="deviceObj.ysj"></animate-number>
                                                    <span v-else>0</span>
                                                </span>台</div>
                                            <div>校园饮水机</div>
                                        </div>
                                        <div class="work">
                                            <div style="color: #01E0D9;margin-bottom: 5px;"> <span style="font-size: 28px;">
                                                    <animate-number duration="1500" v-if="deviceObj.shj" from="0"
                                                        :to="deviceObj.shj" :key="deviceObj.shj"></animate-number>
                                                    <span v-else>0</span>
                                                </span>台</div>
                                            <div>校园售货机</div>
                                        </div>
                                    </div>
                                    <!-- <div :style="{
                                        'background-image': 'url(' + item.img + ')',
                                        'background-repeat': 'no-repeat', 'background-size': '100% 100%'
                                    }" class="work-item" :class="index == 3 ? 'w-center' : ''"
                                        v-for="(item, index) in workList" :key="index">
                                        <div :style="{
                                            'color': item.colorTxt, 'font-size': '28px', 'font-weigt': '800'
                                        }">
                                            <animate-number duration="1500" v-if="(item.num - 0)" from="0" :to="item.num"
                                                :key="item.num"></animate-number>
                                            <span v-else>
                                                <span v-if="item.name">0</span>
                                            </span>
                                             {{ item.num?item.num:'' }} 
                                            <span v-if="index != 3" style="font-size: 14px;">台</span>
                                            <div v-if="index == 3" class="work-txt">设备已部署台数</div>
                                        </div>
                                        <div style="font-size: 14px;color:#fff;">{{ item.name }}</div>
                                    </div> -->
                                </div>
                            </div>
                            <!-- 购买、支付 -->
                            <div class="c-bottom">
                                <div class="bottom-box" v-for="(item, index) in payList" :key="index">
                                    <div class="pay-box">
                                        <!-- 头部 -->
                                        <div class="pay-top" @click="payClick(item, index)">
                                            <div class="pay-tit">
                                                <div>{{ index == 0 ? item.name : isPayTypeMouName }}</div>
                                                <div v-if="index == 1" class="s-g-bar"></div>
                                            </div>
                                            <img style="display: block;width: 323px;height: 43px;margin: 12px auto 0;"
                                                src="@/assets/DataSet/pay-top.png" alt="">
                                            <!-- 流水类型的日月切换 -->
                                            <div class="g-content" v-if="isPayTypeMou && index == 1" id="diaDate">
                                                <div class="g-c-i" v-for="(v, i) in payTypeMouList" :key="i"
                                                    :class="isPayTypeMouAc == i ? 'item-ac' : ''"
                                                    @click.stop="schoolGrowMouClick(i)">
                                                    {{ v }}</div>
                                            </div>

                                        </div>
                                        <!-- pay标题 -->
                                        <div v-if="index == 1" class="school-select" @click="schoolSelectPay(index)">
                                            <div>{{ item.schoolName }}</div>
                                            <div class="s-g-bar"></div>
                                        </div>
                                        <!-- pay弹窗 -->
                                        <div class="school-alert" v-if="item.schoolAc" id="diaDate">
                                            <div class="a-item" :class="i == item.schoolIndex ? 'a-item-ac' : ''"
                                                @click="schoolItemClickPay(i, v, index)" v-for="(v, i) in schoolPayList"
                                                :key="i">
                                                {{ v.schoolName }}
                                            </div>
                                        </div>
                                        <!-- pay-年月日 -->
                                        <div class="year-box" v-if="index == 0">
                                            <div @click="dateClickPay(i, index)" class="year-item"
                                                :class="i == item.activeDate ? 'year-ac' : ''"
                                                v-for="(v, i) in item.dateList" :key="i">{{
                                                    v }}</div>
                                        </div>
                                        <!-- 金额流水类型 -->
                                        <div v-else>
                                            <!-- pay标题 -->
                                            <div class="school-select" style="left: unset;right: 24px;"
                                                @click="schoolSelectPayType(index)">
                                                <div>{{ item.payTypeName }}</div>
                                                <div class="s-g-bar"></div>
                                            </div>
                                            <!-- pay弹窗 -->
                                            <div class="school-alert" style="left: unset;right: 24px;" v-if="item.payTypeAc"
                                                id="diaDate">
                                                <div class="a-item" :class="i == item.payTypeIndex ? 'a-item-ac' : ''"
                                                    @click="schoolItemClickPayType(i, v, index)"
                                                    v-for="(v, i) in item.payTypeList" :key="i">
                                                    {{ v
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 公话购买统计 -->
                                    <!-- <div v-if="index == 0" id="phonePayEchart"
                                        style="width: 400px;height: 76%;margin-top: 40px;"
                                        :class="(item.schoolAc) ? 'is-g' : ''">
                                    </div> -->
                                    <!-- 购买记录 -->
                                    <div v-if="index == 0" class="tip-box">
                                        <div :class="{ anim: animate == true }" @mouseenter="handleMouseEnter"
                                            @mouseleave="handleMouseLeave">
                                            <div v-for="(item, index) in payRecord"
                                                style="display: flex;align-items: center;justify-content: space-between;margin-bottom: 22px;">
                                                <div style="display: flex;font-size: 14px;">
                                                    <div class="tip"
                                                        :class="item.deviceType == 0 ? '' : item.deviceType == 1 ? 'tip-szgh' : item.deviceType == 2 ? 'tip-cfj' : item.deviceType == 3 ? 'tip-xyj' : item.deviceType == 4 ? 'tip-shj' : item.deviceType == 5 ? 'tip-ysj' : ''">
                                                        {{
                                                            item.deviceType == 0 ? '校园团餐' : item.deviceType == 1 ? '数智公话' :
                                                            item.deviceType ==
                                                                2 ?
                                                                '吹风机' : item.deviceType == 3 ? '洗衣机' : item.deviceType == 4 ? '售货机'
                                                                    :
                                                                    item.deviceType
                                                                        ==
                                                                        5 ? '饮水机' : ''
                                                        }}</div>
                                                    <div
                                                        style="width: 240px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">
                                                        {{ item.userName ? item.userName : '' }} 消费<span
                                                            style="color: #FFB82E;">{{
                                                                item.consumptionMoney ? item.consumptionMoney : '0.00'
                                                            }}元</span>
                                                    </div>
                                                </div>
                                                <div style="font-size: 12px;margin-right: 5px;">{{
                                                    item.consumptionTime ? item.consumptionTime : '' }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 流水概况 -->
                                    <div v-if="index == 1" id="payNumEchart"
                                        style="width: 430px;height: 75%;margin-top: 40px;"
                                        :class="(item.schoolAc || item.payTypeAc || isPayTypeMou) ? 'is-g' : ''">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content-right">
                            <div class="map-box">
                                <div class="map-tit">已合作学校分布图</div>
                                <div id="mapEchart" style="width: 100%;height: 100%;"></div>
                                <div class="map-info">
                                    <div class="info-area">
                                        <div style="color:#66FFFF;font-size: 16px;">全国 · {{ allSchool }}所</div>

                                        <div @click="mapAlertClick"
                                            style="height:20px;display: flex;align-items: center;cursor: pointer;position: relative;margin: 15px 0;">
                                            <div>{{ cityName }} · {{ citySchoolList.length }}所</div>
                                            <div style="margin:0 18px;" v-if="this.citySchoolList.length != 0">{{
                                                citySchoolName }}</div>
                                            <div class="s-g-bar" v-if="this.citySchoolList.length != 0"></div>
                                            <!-- 添加弹窗  :class="i == item.schoolIndex ? 'a-item-ac' : ''-->
                                            <div class="school-alert" v-if="mapVis" id="diaDate">
                                                <div class="a-item" :class="i == citySchoolIndex ? 'a-item-ac' : ''"
                                                    @click.stop="schoolAreaClick(i, v)" v-for="(v, i) in citySchoolList"
                                                    :key="i">
                                                    {{ v.schoolName
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="display: none;color: #FEDB65;margin-top: 8px;">
                                        <div style="margin-right:48px;color: #66FFFF;">代理商：{{
                                            mapObj.agentName ? mapObj.agentName : '无' }}</div>
                                        <div>校收收已绑定人数：{{
                                            mapObj.xssBindUserCount ? mapObj.xssBindUserCount : '0' }}</div>
                                    </div>
                                    <div style="margin-top: 8px;color: #fff;">
                                        <div style="font-size: 14px;margin-top: 5px;"><span
                                                style="font-size: 16px;font-weight: 700;">设备部署：</span>{{
                                                    mapObj.deviceInfo ? mapObj.deviceInfo : '无' }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="ims-box">
                                <div class="ims-tit">IMS/微信音视频阈值告警</div>
                                <div class="ims-wrap">
                                    <div class="ims-top-box" v-for="(item, index) in imsTop" :key="index">
                                        <div>
                                            <div class="ims">{{ item.name }}</div>
                                            <div class="ims-num"> <span v-if="index == 2"
                                                    style="font-size: 12px;color: #fff;">前</span> <span v-if="index == 2"
                                                    style="color: #FEDB65;">
                                                    <animate-number duration="1500" v-if="item.num" from="0" :to="item.num"
                                                        :key="item.num"></animate-number>
                                                </span><span v-if="index != 2" style="color: #129BFF;">
                                                    <animate-number duration="1500" v-if="item.num" from="0" :to="item.num"
                                                        :key="item.num"></animate-number>
                                                </span>
                                                <span v-if="index != 2" style="font-size: 12px;color: #fff;">分钟</span>
                                                <span v-if="index == 2" style="font-size: 12px;color: #fff;">天</span>
                                            </div>
                                        </div>
                                        <div class="i-bar"></div>
                                    </div>

                                </div>
                                <div class="ims-bottom">
                                    <div class="i-b-t">近三日报警信息</div>
                                    <div style="padding: 15px 0;">
                                        <dv-scroll-board :config="config"
                                            style="width:100%;height:160px;padding:5px 10px 0;" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ScaleScreen>
            </div>
        </div>
        <div v-else class="content-index ">

            <DataSetSchool :width="1920" :height="1080" class="scale-wrap" :selfAdaption="selfAdaption" />
        </div>
    </div>
</template>
<script>
import DataSetSchool from './DataSetSchool.vue';
import ScaleScreen from "@/components/scale-screen/scale-screen.vue";
import zhongguo from "@/assets/mapJson/data-city.json"
// import "echarts-gl" //3D地图插件
import { schoolOrBindStudentIncrease, xssAccessRecord, getEchartsSchool, schoolUserAgent, getIms, currentAmountStatement, countDevice, getSchoolInfoForMap, videoPhoneUseCount, buyPackageCount, getNationwideSchool, getRealTimeConsumption, getVendingUseCount } from "@/api/system/DataSet"
import { getVideoPhoneUseCount, getBookSchoolBorrowCount, getAccessSchoolCount, getWashingUseCount } from "@/api/index"
import { getSchoolMealCount } from "@/api/schoolIndex"
import * as echarts from "echarts";
export default {
    components: { DataSetSchool, ScaleScreen },
    data() {

        return {
            //控制动效是否开启
            intervalHandle: null,
            setTimeHandle: null,
            animate: false,

            weeks: '',
            timer: null,
            echartsTimer: null,
            date: {
                year: null,
                month: null,
                date: null,
                day: null,
                hours: null,
                minutes: null,
                seconds: null
            },

            cityIndex: [], // 所有省的键名
            citySchoolList: [], // 地图，省下的所有学校
            cityName: '', //省名
            citySchoolName: '', //选择的学校名
            citySchoolIndex: '',//选择的学校index
            citySchoolId: '',//选择的学校id

            isLoading: false,
            config: {},
            personType: '',
            selfAdaption: true,
            isSchoolG: false, // 学校增长弹窗
            isSchoolGAc: 1, //学校增长弹窗选择index
            isSchoolGName: '用户增长数据', //学校增长弹窗选择name
            result: [],

            stuMouList: ['日', '月'], //学生增长日月
            stuActiveDate: 0,//学生增长日月选中

            payTypeMouList: ['近日金额流水概况', '近月金额流水概况'],
            isPayTypeMou: false, // 流水日月弹窗
            isPayTypeMouAc: 0, //流水日月弹窗选择index
            isPayTypeMouName: '近日金额流水概况', //流水日月弹窗选择name


            // 访问记录
            recList: [{
                name: '小程序活跃率统计',
                dateList: ['日', '月', '年'],
                activeDate: '0', //选择年月日
                schoolAc: false,
                schoolIndex: 0,
                schoolName: '全部学校',
                schoolId: ''
            },
            {
                name: '智能公话使用统计',
                dateList: ['日', '月'],
                activeDate: '0', //选择年月日
                schoolAc: false,
                schoolIndex: 0,
                schoolName: '全部学校',
                schoolId: ''
            }],
            allSchool: 0,
            // 使用弹窗展示
            isUseVis: false,
            // 使用列表
            useList: ['智能公话使用统计', '校园图书借阅统计', '校园团餐营收统计', '智能门禁进出统计', '智慧洗衣使用统计', '智慧吹风使用统计', '自助售货机使用统计'],
            isuseAc: 0, //使用选择index
            isuseName: '智能公话使用统计', //使用选择名字
            // 购买、金额记录
            // 访问记录  
            payList: [{
                name: '实时消费记录',
                // dateList: ['日', '月', '年'],
                activeDate: '0', //选择年月日
                schoolAc: false,
                schoolIndex: 0,
                schoolName: '',
                schoolId: ''
            },
            {
                name: '近日金额流水概况',
                payTypeList: ['所有设备', '校园数智公话', '校园团餐机', '校园洗衣机', '校园吹风机', '校园售货机'],
                activeDate: '0', //选择年月日
                schoolAc: false,
                payTypeAc: false,
                payTypeIndex: 0,
                payTypeName: '所有设备',
                schoolIndex: 0,
                schoolName: '',
                schoolId: ''
            }],
            // 活跃率类型
            miniType: ['总活跃率', '新校收收活跃率', '家校之音活跃率', '悦教云活跃率'],
            miniTypeIndex: 0, // 选中index
            miniTypeName: '总活跃率', // 选中name
            miniOpen: false,
            // 消费记录
            payRecord: [],
            recIndex: 0,
            schoolList: [],//学校列表
            schoolPayList: [],//实时消费记录学校列表
            mapName: '', // 地图上 省份名点击
            mapObj: {},
            mapVis: false, // 地图学校
            imsTop: [{
                name: 'IMS最低阈值',
                num: 0
            },
            {
                name: '微信音视频最低阈值',
                num: 0
            },
            {
                name: '微信音视频过期告警',
                num: 0
            }],
            // src: require('../assets/DataSet/center.png'),
            rhoList: [
                {
                    name: '学校数',
                    img: require('../assets/DataSet/xxBorder.png'),
                    num: 0,
                    colorTxt: '#42A3FF',
                    dw: '',
                    tip: ''
                },
                {
                    name: '合作商',
                    img: require('../assets/DataSet/dlsBorder.png'),
                    num: 0,
                    colorTxt: '#8AB2FF',
                    dw: '',
                    tip: ''
                },
                {
                    name: '学生数',
                    img: require('../assets/DataSet/xsBorder.png'),
                    num: 0,
                    colorTxt: '#94FFB2',
                    dw: '',
                    tip: ''
                },
                {
                    name: '每日消费笔数',
                    img: require('../assets/DataSet/ktBorder.png'),
                    num: '',
                    colorTxt: '#FFB82E',
                    dw: '',
                    tip: ''
                },
                {
                    name: '每日消费金额',
                    img: require('../assets/DataSet/xxBorder.png'),
                    num: 0,
                    colorTxt: '#42A3FF',
                    dw: '',
                    tip: ''
                }
            ],
            schoolTipIndex: 0, // tip学校选中索引
            schoolTipAc: false,// tip学校显示隐藏
            schoolTipName: '', // tip学校名
            schoolTipId: '', // tip学校Id

            schoolTipIndexMon: 0, // tip金额学校选中索引
            schoolTipAcMon: false,// tip金额学校显示隐藏
            schoolTipNameMon: '', // tip金额学校名
            schoolTipIdMon: '', // tip金额学校Id

            workList: [
                {
                    name: '校园数智公话',
                    num: 0,
                    img: require('../assets/DataSet/c-bor.png'),
                    colorTxt: '#01E0D9'
                },
                {
                    name: '校园数智班牌',
                    num: 0,
                    img: require('../assets/DataSet/c-bor.png'),
                    colorTxt: '#FEDB65'
                },
                {
                    name: '校园刷脸门禁',
                    num: 0,
                    img: require('../assets/DataSet/c-bor.png'),
                    colorTxt: '#01E0D9'
                },
                {
                    name: '',
                    img: require('../assets/DataSet/center.png'),
                    num: 0
                },
                {
                    name: '校园团餐机',
                    num: 0,
                    img: require('../assets/DataSet/c-bor.png'),
                    colorTxt: '#01E0D9'
                },
                {
                    name: '校园洗衣机',
                    num: 0,
                    img: require('../assets/DataSet/c-bor.png'),
                    colorTxt: '#FEDB65'
                },
                {
                    name: '校园吹风机',
                    num: 0,
                    img: require('../assets/DataSet/c-bor.png'),
                    colorTxt: '#01E0D9'
                }
            ],
            deviceObj: {}
        }
    },
    created() {
        this.initHandle()
        this.personType = localStorage.getItem('personType')
    },
    mounted() {
        this.getInit()
        this.echartsTimer = setInterval(() => {
            this.getInit()
        }, 600000)
        // this.getMapEcharts()
        //给页面添加鼠标抬起事件
        // document.addEventListener('mouseup', (e) => {
        //     //获取弹窗对象
        //     const userCon = document.getElementById('diaDate')
        //     const userConTit = document.getElementById('diaDateTit')
        //     if (userCon && !userCon.contains(e.target) || (userCon && userConTit.contains(e.target))) {
        //         this.isMask = true
        //         console.log(7777);
        //         this.isSchoolG = false
        //         this.recList.forEach(v => {
        //             v.schoolAc = false
        //         })
        //         this.payList.forEach(v => {
        //             // console.log(v);
        //             v.schoolAc = false
        //             if (v.payTypeName) {
        //                 v.payTypeAc = false
        //             }
        //         })
        //         this.isPayTypeMou = false
        //         this.mapVis = false
        //         this.schoolTipAc = false
        //     } else {
        //         this.isMask = false
        //     }
        // })
    },
    beforeDestroy() {
        if (this.timer || this.echartsTimer) {
            clearInterval(this.timer);
            clearInterval(this.echartsTimer);
            this.timer = null;
            this.echartsTimer = null;
        }
        clearTimeout(this.intervalHandle)
        clearTimeout(this.setTimeHandle)
        this.intervalHandle = null
        this.setTimeHandle = null
    },
    methods: {
        getInit(key) {
            this.isShow = true
            this.isSchoolGAc = 1
            // 学校列表
            this.getSchool()

            // 中间业务使用情况
            this.getSchoolWork()
            // 获取ims
            this.getImsInfo()
            // this.click()
            // 学校增长数据
            this.getSchoolGrowEcharts()
            // 学生增长
            // this.getStudentEcharts()
            // 统计图表-校收收
            this.getXss()
            // 智能公话
            // this.getPhone()
            // 智能公话购买
            // this.getPhonePay()
            this.getPhoneUse()
            this.getPayRecord()

            // 地图
            this.getMapEcharts()
            this.getMapList('山东省')
        },
        initHandle() {
            this.timer = setInterval(() => {
                let now = new Date();
                this.date.year = now.getFullYear(); //  年份
                this.date.month = (now.getMonth() + 1) < 10 ? "0" + (now.getMonth() + 1) : (now.getMonth() + 1); //  月份，注意月份范围是0~11，5表示六月
                this.date.date = now.getDate() < 10 ? "0" + now.getDate() : now.getDate(); //  表示24号
                this.date.hours = now.getHours() < 10 ? "0" + now.getHours() : now.getHours(); // 19, 24小时制
                this.date.minutes = now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes(); // 49, 分钟
                this.date.seconds = now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds(); // 22, 秒
            }, 1000)
            let week = new Date().getDay();
            if (week == 0) {
                this.weeks = "星期日";
            } else if (week == 1) {
                this.weeks = "星期一";
            } else if (week == 2) {
                this.weeks = "星期二";
            } else if (week == 3) {
                this.weeks = "星期三";
            } else if (week == 4) {
                this.weeks = "星期四";
            } else if (week == 5) {
                this.weeks = "星期五";
            } else if (week == 6) {
                this.weeks = "星期六";
            }
        },
        formatter: function (num) {
            return num.toFixed(0)
        },
        formatterRate: function (num) {
            return num.toFixed(0)
        },

        // 滚动
        scroll() {
            this.animate = true;    // 因为在消息向上滚动的时候需要添加css3过渡动画，所以这里需要设置true
            this.setTimeHandle = setTimeout(() => {      //  这里直接使用了es6的箭头函数，省去了处理this指向偏移问题，代码也比之前简化了很多
                this.payRecord.push(this.payRecord[0]);  // 将数组的第一个元素添加到数组的
                this.payRecord.shift();               //删除数组的第一个元素
                this.animate = false;  // margin-top 为0 的时候取消过渡动画，实现无缝滚动
            }, 700)
        },
        // 移入
        handleMouseEnter() {
            clearTimeout(this.intervalHandle)
            clearTimeout(this.setTimeHandle)
            this.intervalHandle = null
            this.setTimeHandle = null
            console.log('来了');
        },
        // 移出
        handleMouseLeave() {
            console.log('走了');
            if (this.payRecord.length > 7) {
                this.countDown()
            }
        },
        countDown() {
            clearTimeout(this.intervalHandle)
            this.intervalHandle = null
            this.scroll()
            this.intervalHandle = setTimeout(this.countDown, 1500)
        },
        // 学校列表
        getSchool() {
            getEchartsSchool({}).then(res => {
                console.log(res.data);
                this.schoolPayList = this.deepClone(res.data)
                this.payList[1].schoolName = this.schoolPayList[0].schoolName
                this.payList[1].schoolId = this.schoolPayList[0].schoolId
                this.schoolTipName = this.schoolPayList[0].schoolName
                this.schoolTipId = this.schoolPayList[0].schoolId
                this.schoolTipNameMon = this.schoolPayList[0].schoolName
                this.schoolTipIdMon = this.schoolPayList[0].schoolId

                this.schoolList = res.data
                this.schoolList.unshift({
                    schoolName: '全部学校',
                    schoolId: ''
                })
                // 中间使用情况
                this.getSchoolUserAgent()
                // 流水概况
                this.getPayNum()
            })
        },
        // 深拷贝
        deepClone(obj) {
            if (typeof obj != "object") return obj;
            var temp = Array.isArray(obj) ? [] : {};
            for (let key in obj) {
                if (obj.hasOwnProperty(key)) {
                    if (obj[key] && typeof obj[key] == "object") {
                        // 如果obj[key]还是对象则执行递归
                        temp[key] = this.deepClone(obj[key]); // 递归
                    } else {
                        temp[key] = obj[key];
                    }
                }
            }
            return temp;
        },
        // 中间使用
        getSchoolUserAgent(id, key) {
            console.log(this.schoolTipId);
            console.log(key == 1 ? this.schoolTipId : key == 2 ? this.schoolTipIdMon : this.schoolTipId, '+++++++');
            this.isLoading = true
            schoolUserAgent({ distinguish: key ? key : '', searchType: key ? 1 : 2, schoolId: key == 1 ? this.schoolTipId : key == 2 ? this.schoolTipIdMon : this.schoolTipId }).then(res => {
                this.isLoading = false
                this.rhoList.forEach(v => {
                    switch (v.name) {
                        case '学校数':
                            v.num = res.data.hasOwnProperty('countSchool') ? res.data.countSchool : v.num
                            break;
                        case '合作商':
                            v.num = res.data.hasOwnProperty('countAgent') ? res.data.countAgent : v.num
                            break;
                        case '学生数':
                            v.num = res.data.hasOwnProperty('countXss') ? res.data.countXss : v.num
                            break;
                        case '每日消费笔数':
                            v.num = res.data.hasOwnProperty('sumCount') ? (res.data.sumCount - 0) : (v.num - 0)
                            break;
                        case '每日消费金额':
                            v.num = res.data.hasOwnProperty('sumPrice') ? (res.data.sumPrice - 0) : (v.num - 0)
                            break;
                        default:
                            break;
                    }
                })
                console.log(this.rhoList, 'rohlist');
            }).catch(err => {
                this.isLoading = false
            })
        },
        // 部署台数
        getSchoolWork() {
            this.isLoading = true
            countDevice({}).then(res => {
                this.isLoading = false
                this.deviceObj = res.data
                this.deviceObj.ysj = 0
            }).catch(err => {
                this.isLoading = false
            })
        },
        // 获取ims
        getImsInfo() {
            this.isLoading = true
            getIms({}).then(res => {
                this.isLoading = false
                this.config = {
                    // indexHeader: "序号",
                    oddRowBGC: "transparent", // 奇数行背景色
                    evenRowBGC: "transparent", // 偶数行背景色
                    // headerBGC: "#eb5a6d", // 表头背景色
                    // header: ["单位名称", "本月督办次数"],
                    rowNum: 4, // 表行数
                    index: true,
                    // headerHeight: 40,
                    columnWidth: [60, 400, 150],
                    waitTime: 1500, // 轮播时间间隔(ms)
                    // align: ["center", "center"],
                    data: res.data.taskRemindImsWechats
                    // data: scolldata,
                }
                this.imsTop.forEach(v => {
                    switch (v.name) {
                        case 'IMS最低阈值':
                            v.num = res.data.sysAllMinutePool.reminderThreshold
                            break;
                        case '微信音视频最低阈值':
                            v.num = res.data.sysAllMinutePool.wxReminderThreshold
                            break;
                        case '微信音视频过期告警':
                            v.num = res.data.sysAllMinutePool.wxMinuteExpireBeforeDay
                            break;

                        default:
                            break;
                    }
                })
            }).catch(err => {
                this.isLoading = false
            })
        },
        // 打开学校增长弹窗
        openSchoolGrow() {
            if (this.isSchoolGName == '用户增长数据') {
                this.isSchoolGAc = 1
            } else {
                this.isSchoolGAc = 2
            }
            this.isSchoolG = !this.isSchoolG
        },
        // 选择增长图表
        schoolGrowClick(i) {
            this.isSchoolGAc = i
            if (this.isSchoolGAc == 1) {
                this.getSchoolGrowEcharts()
                this.isSchoolGName = '用户增长数据'
            } else {
                this.getStudentEcharts()
                this.isSchoolGName = '人脸绑定增长数据'
            }
            this.isSchoolG = false
        },
        // 流水的类型日月切换
        schoolGrowMouClick(i) {
            this.isPayTypeMouAc = i
            if (this.isPayTypeMouAc == 0) {
                this.isPayTypeMouName = '近日金额流水概况'
                this.isPayTypeMou = false
                this.getPayNum()
            } else {
                this.isPayTypeMouName = '近月金额流水概况'
                this.isPayTypeMou = false
                this.getPayNum()
            }
            console.log(this.isPayTypeMou, 'isPayTypeMou');
        },
        // 活跃率弹窗
        miniTitClick() {
            this.miniOpen = !this.miniOpen
        },
        // 活跃率类型点击
        miniClick(i) {
            this.miniTypeIndex = i
            this.miniTypeName = this.miniType[i]
            console.log(this.miniTypeIndex);
            console.log(i);
            this.getXss()
        },
        // 选年月日
        dateClick(i, index) {
            this.recList[index].activeDate = i
            if (index == 0) {
                this.getXss()
            } else {
                switch (this.isuseAc) {
                    case 0:
                        this.getPhoneUse()
                        break;
                    case 1:
                        this.getSchoolBooks()
                        break;
                    case 2:
                        this.getSchoolMeal()
                        break;
                    case 3:
                        this.getEntrance()
                        break;
                    case 4:
                        this.getLaundry()
                        break;
                    case 5:
                        this.getLaundry()
                        break;
                    case 6:
                        this.getVending()
                        break;
                    default:
                        break;
                }
                // this.getPhone()
            }

        },
        // 使用统计弹窗
        openUse(i) {
            if (i == 1) {
                this.isUseVis = !this.isUseVis
            }
        },
        // 使用弹窗选择
        useClick(v, i) {
            this.isuseAc = i
            this.isuseName = v
            this.isUseVis = false
            // this.recList[1].schoolName = this.schoolList[0].schoolName
            // this.recList[1].schoolId = this.schoolList[0].schoolId
            switch (i) {
                case 0:
                    this.getPhoneUse()
                    break;
                case 1:
                    this.getSchoolBooks()
                    break;
                case 2:
                    this.recList[1].schoolName = this.schoolPayList[0].schoolName
                    this.recList[1].schoolId = this.schoolPayList[0].schoolId
                    this.getSchoolMeal()
                    break;
                case 3:
                    this.getEntrance()
                    break;
                case 4:
                    this.getLaundry()
                    break;
                case 5:
                    this.getLaundry()
                    break;
                case 6:
                    this.getVending()
                    break;
                default:
                    break;
            }
        },
        // pay-选年月日
        dateClickPay(i, index) {
            this.payList[index].activeDate = i
            this.getPhonePay()
        },
        // 学生增长日月
        stuDateClickPay(i) {
            this.stuActiveDate = i
            this.getStudentEcharts()
        },
        // 学校弹窗
        schoolSelect(index) {
            // if(!this.isMask && !this.recList[index].schoolAc) {
            this.recList[index].schoolAc = !this.recList[index].schoolAc
            // }

        },
        // 流水日月title
        payClick(v, i) {
            if (i == 1) {
                this.isPayTypeMou = !this.isPayTypeMou
            }

        },
        // pay-学校弹窗
        schoolSelectPay(index) {
            this.payList[index].schoolAc = !this.payList[index].schoolAc
        },
        // pay-流水类型点击
        schoolSelectPayType(index) {
            this.payList[index].payTypeAc = !this.payList[index].payTypeAc
            // this.getPayNum()
        },
        // 学校列表点击
        schoolItemClick(i, v, index) {
            this.recList[index].schoolIndex = i
            this.recList[index].schoolName = v.schoolName
            this.recList[index].schoolId = v.schoolId
            this.recList[index].schoolAc = false
            if (index == 0) {
                this.getXss()
            } else {
                switch (this.isuseAc) {
                    case 0:
                        this.getPhoneUse()
                        break;
                    case 1:
                        this.getSchoolBooks()
                        break;
                    case 2:
                        this.getSchoolMeal()
                        break;
                    case 3:
                        this.getEntrance()
                        break;
                    case 4:
                        this.getLaundry()
                        break;
                    case 5:
                        this.getLaundry()
                        break;
                    case 6:
                        this.getVending()
                        break;
                    default:
                        break;
                }
                // this.getPhone()
            }

        },
        // pay-学校点击
        schoolItemClickPay(i, v, index) {
            this.payList[index].schoolIndex = i
            this.payList[index].schoolName = v.schoolName
            this.payList[index].schoolId = v.schoolId
            this.payList[index].schoolAc = false
            if (index == 0) {
                // this.getPhonePay()
                this.getPayRecord()
            } else {
                this.getPayNum()
            }

        },
        // pay类型点击
        schoolItemClickPayType(i, v, index) {
            console.log(8888);
            this.payList[index].payTypeIndex = i
            this.payList[index].payTypeName = v
            this.payList[index].payTypeAc = false
            this.getPayNum()
        },
        // 地图-学校 弹窗
        mapAlertClick() {
            if (this.citySchoolList.length != 0) {
                this.mapVis = !this.mapVis
            }

        },
        // 地图-学校点击选择
        schoolAreaClick(i, v) {
            console.log(i, v);
            this.citySchoolIndex = i
            this.citySchoolName = v.schoolName
            this.mapVis = false
            // console.log(this.citySchoolList.filter(v => v.schoolName == this.citySchoolName)[0]);
            this.mapObj = this.citySchoolList.filter(v => v.schoolName == this.citySchoolName)[0]
        },
        // 中间tip点击
        tipClick(i) {
            if (i == 3) {
                this.schoolTipAc = !this.schoolTipAc
            } else {
                this.schoolTipAcMon = !this.schoolTipAcMon
            }
        },
        // 学校列表点击 每日笔数
        schoolItemClickTip(i, v, index) {
            this.schoolTipIndex = i
            this.schoolTipId = this.schoolPayList[i].schoolId
            this.schoolTipAc = false
            this.schoolTipName = this.schoolPayList[i].schoolName
            if (this.schoolTipName) {
                this.getSchoolUserAgent(this.schoolTipId, 1)
            }
        },
        // 学校列表点击 每日金额
        schoolItemClickTipMon(i, v, index) {
            this.schoolTipIndexMon = i
            this.schoolTipIdMon = this.schoolPayList[i].schoolId
            this.schoolTipAcMon = false
            this.schoolTipNameMon = this.schoolPayList[i].schoolName
            if (this.schoolTipNameMon) {
                this.getSchoolUserAgent(this.schoolTipIdMon, 2)
            }
        },
        // 学校增长
        getSchoolGrowEcharts() {
            this.isLoading = true
            let myChart = this.$echarts.init(
                document.getElementById("schoolGrowEchart")
            );
            // let result = []
            schoolOrBindStudentIncrease({ searchType: 2, searchMonthOfDay: 2 }).then(res => {
                this.isLoading = false
                let xData = []
                let yDate = res.data.yData
                res.data.xData.forEach(v => {
                    if (this.isSchoolGAc == 0) {
                        xData.push(
                            v.substring(5, 10).replace(/-/g, "/")
                        )
                    } else {
                        xData.push(
                            v.length == 2 ? v : v.substring(5) + '月'
                        )
                    }
                })
                console.log(xData, 'xData');
                console.log(yDate, 'yDate');
                this.result = xData.map((name, i) => ({ name, value: yDate[i] }))
                let option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {       //坐标轴指示器
                            type: 'line',   //十字准星指示器
                        },
                        extraCssText: 'width: 165px;height: 66px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
                        formatter: function (params) {
                            console.log(params);
                            return `<span style="font-size:12px;color:#3D3D3D;margin-left:12px;">${params[0].name}</span>` + '<br/>' +
                                `<span style="color:#3D3D3D;font-size:12px;margin-left:12px;">用户人数 &nbsp;&nbsp; ${params[0].value ? params[0].value : 0}人</span>`
                        }
                    },
                    xAxis: {
                        max: 50000,
                        splitLine: {
                            show: false
                        },
                        axisLine: {
                            show: false
                        },
                        axisLabel: {
                            show: false,
                            fontSize: 12
                        },
                        axisTick: {
                            show: false
                        }
                    },
                    grid: {
                        left: '13%',
                        top: '5%', // 设置条形图的边距
                        right: 50,
                        bottom: 0
                    },
                    yAxis: [{
                        type: "category",
                        inverse: true,
                        data: this.result,
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            show: false,
                            fontSize: 12
                        }
                    }],
                    dataZoom: [

                        // {
                        //     type: "inside",
                        //     startValue: 0,
                        //     endValue: 6,
                        //     minValueSpan: 2,
                        //     maxValueSpan: 2,
                        //     yAxisIndex: [0],
                        //     zoomOnMouseWheel: false,  // 关闭滚轮缩放
                        //     moveOnMouseWheel: true, // 开启滚轮平移
                        //     moveOnMouseMove: true  // 鼠标移动能触发数据窗口平移 
                        // },
                        {
                            type: 'slider',
                            // zoomLock:true,
                            realtime: true,
                            startValue: 0,
                            endValue: 5,
                            width: '3.5',
                            height: '80%',
                            yAxisIndex: [0], // 控制y轴滚动
                            // // 设置背景颜色
                            // backgroundColor: "red",
                            //   fillerColor: "red", // 滚动条颜色
                            borderColor: "rgba(17, 100, 210, 0.1)",
                            //   backgroundColor: '#cfcfcf',//两边未选中的滑动条区域的颜色
                            handleSize: 0, // 两边手柄尺寸
                            showDataShadow: false,//是否显示数据阴影 默认auto
                            showDetail: true, // 拖拽时是否展示滚动条两侧的文字
                            top: '1%',
                            right: '5',
                        }
                    ],
                    series: [{
                        type: "bar",
                        barWidth: 10,
                        legendHoverLink: false,
                        silent: true,
                        itemStyle: {
                            normal: {
                                color: function (params) {
                                    var color;
                                    color = {
                                        type: "linear",
                                        x: 0,
                                        y: 0,
                                        x2: 1,
                                        y2: 0,
                                        colorStops: [{
                                            offset: 0,
                                            color: "#047CFF" // 0% 处的颜色
                                        }, {
                                            offset: 1,
                                            color: "#047CFF" // 100% 处的颜色
                                        }]
                                    }
                                    return color;
                                },
                            }
                        },
                        label: {
                            normal: {
                                show: true,
                                position: "left",
                                formatter: "{b}",
                                textStyle: {
                                    color: "#fff",
                                    fontSize: 14
                                }
                            }
                        },
                        data: this.result,
                        z: 1,
                        animationEasing: "elasticOut"
                    },
                    {
                        type: "pictorialBar",
                        itemStyle: {
                            normal: {
                                color: "#061348"
                            }
                        },
                        symbolRepeat: "fixed",
                        symbolMargin: 2, // 小格子的宽
                        symbol: "rect",
                        symbolClip: true,
                        symbolSize: [1, 21],
                        symbolPosition: "start",
                        symbolOffset: [1, -1],
                        // symbolBoundingData: 3000,
                        label: {
                            normal: {
                                formatter: (params) => {
                                    var text = '{f| ' + params.data.value + '人' + '}'
                                    // return text
                                    // console.log(params.data);
                                    // params.data.forEach(v=>{
                                    //     return params.data.value
                                    // })
                                    // var text = '{f| ' + params.data.value + '所' + '}'
                                    return params.data.value != 0 ? text : '';
                                },
                                rich: {
                                    a: {
                                        color: 'red'
                                    },
                                    b: {
                                        color: 'blue'
                                    },
                                    c: {
                                        color: 'yellow'
                                    },
                                    d: {
                                        color: "green"
                                    },
                                    f: {
                                        color: "#ffffff"
                                    }
                                },
                                position: 'right',
                                show: false
                            }
                        },
                        data: this.result,
                        z: 2,
                        animationEasing: "elasticOut"
                    },
                    {
                        name: "外框",
                        type: "bar",
                        barGap: "-120%", // 设置外框粗细
                        data: this.result,
                        barWidth: 10,
                        itemStyle: {
                            normal: {
                                color: "transparent", // 填充色
                                barBorderWidth: 1, // 边框宽度
                                label: {
                                    // 标签显示位置
                                    show: false,
                                    position: "top" // insideTop 或者横向的 insideLeft
                                }
                            }
                        },
                        z: 0
                    }
                    ]
                }
                // 使用刚指定的配置项和数据显示图表。
                myChart.clear();
                myChart.setOption(option);
            }).catch(err => {
                this.isLoading = false
            })
            console.log(this.result, 'result');

        },
        // 学生增长
        getStudentEcharts() {
            this.isLoading = true
            let myChart = this.$echarts.init(
                document.getElementById("studentEchart")
            );
            schoolOrBindStudentIncrease({ searchType: this.isSchoolGAc, searchMonthOfDay: (this.stuActiveDate - 0) + 1 }).then(res => {
                this.isLoading = false
                let xData = []
                let yData = res.data.yData
                res.data.xData.forEach(v => {
                    if (this.stuActiveDate == 0) {
                        xData.push(
                            v.length == 2 ? v : v.substring(5, 10).replace(/-/g, "/")
                        )
                    } else {
                        xData.push(
                            v.length == 2 ? v : v.substring(5) + '月'
                        )

                    }
                })
                let option = {
                    grid: {
                        left: '5%',
                        right: '5%',
                        top: '12%',
                        bottom: '15%',
                        // containLabel: true
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {       //坐标轴指示器
                            type: 'line',   //十字准星指示器
                        },
                        extraCssText: 'width: 165px;height: 66px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
                        formatter: function (params) {
                            return `<span style="font-size:12px;color:#3D3D3D;margin-left:12px;">${params[0].name}</span>` + '<br/>' +
                                `<span style="color:#3D3D3D;font-size:14px;margin-left:12px;">增长人数 &nbsp;&nbsp; ${params[0].value ? params[0].value : 0}人</span>`
                        }
                    },
                    xAxis: {
                        type: 'category', // category(坐标轴类型)
                        data: xData,
                        axisTick: { // 坐标轴刻度相关配置
                            show: false // 是否显示坐标轴刻度
                        },
                        axisLine: { // 坐标轴轴线相关配置
                            lineStyle: { // 坐标轴轴线样式
                                color: '#2C6CA3' // 坐标轴轴线颜色
                            }
                        },
                        axisLabel: { // 坐标轴刻度标签相关配置
                            color: '#ffffff',
                            fontSize: 10,
                            margin: 20
                        }
                    },
                    yAxis: {
                        // name: '人',
                        minInterval: 1, // 最小单位是1
                        nameTextStyle: {
                            color: '#fff'
                        },
                        type: 'value', // value(数值轴,适用于连续数据)
                        axisTick: { // 坐标轴刻度相关配置
                            show: false  // 是否显示坐标轴刻度
                        },
                        axisLine: { // 坐标轴轴线相关配置
                            show: false,
                            lineStyle: { // 坐标轴轴线样式
                                color: '#2C6CA3' // 坐标轴轴线颜色
                            }
                        },
                        axisLabel: { // 坐标轴刻度标签相关配置
                            show: false,
                            color: '#ffffff',
                            fontSize: 10,
                        },
                        splitLine: { // 坐标轴在 grid 区域中的分隔线.
                            show: false,
                            lineStyle: { // 分割线配置
                                color: '#2C6CA3', // 分割线颜色
                                type: 'dashed' //虚线
                            }
                        }
                    },
                    series: [
                        // 中间的长方形柱状图(柱状图):bar
                        {
                            type: 'bar', // 柱状图
                            barWidth: 18, // 柱条的宽度,不设时自适应
                            barGap: '0%', // 柱子与柱子之间的距离
                            itemStyle: { // 图形样式
                                // color支持(rgb(255,255,255)、rgba(255,255,255,1)、#fff,也支持渐变色和纹理填充)
                                // 下面就是使用线性渐变
                                color: {
                                    "x": 0,
                                    "y": 0,
                                    "x2": 0,
                                    "y2": 1,
                                    "type": "linear",
                                    "global": false,
                                    "colorStops": [{
                                        "offset": 0, // 0%处的颜色
                                        "color": "#1B81F7"
                                    }, {
                                        "offset": 1, // 100%处的颜色
                                        "color": "#18AAF8"
                                    }]
                                }
                            },

                            label: { // 图形上的文本标签,可用于说明图像的一些数据信息,比如值,名称等
                                show: true, //是否显示标签
                                position: 'top', // 标签的位置(可以是绝对的像素值或者百分比['50%','50%',也可以是top,left等])
                                color: '#fff',
                                fontSize: 12,
                                // formatter: '{c}元'
                                formatter: (params) => {
                                    return params.value != 0 ? (params.value + '人') : ''
                                }
                            },
                            data: yData
                        },
                    ],
                    dataZoom: [
                        {
                            show: true,
                            height: 6, // 滚动条高度
                            xAxisIndex: [0],
                            bottom: 0, // 距离底部距离
                            showDetail: false,
                            showDataShadow: false,
                            start: 0, //初始化时，滑动条宽度开始标度
                            borderColor: "transparent",
                            textStyle: {
                                fontSize: 0,
                            },
                            endValue: 5, //从0开始的相当于5个(x轴展示的数量)
                            fillerColor: "#3E599C", // 滚动条颜色
                            borderColor: "rgba(17, 100, 210, 0.12)",
                            backgroundColor: '#142746',//两边未选中的滑动条区域的颜色

                            borderWidth: 0,
                            //                 handleIcon:
                            //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
                            //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
                            //   handleSize: "0%",
                            handleStyle: {
                                color: "#d3dee5",
                            },
                        },
                        {
                            type: "inside",  // 支持内部鼠标滚动平移
                            start: 0,
                            // end: 20,
                            startValue: 0, // 从头开始。
                            endValue: 5,  // 最多5个
                            zoomOnMouseWheel: false,  // 关闭滚轮缩放
                            moveOnMouseWheel: true, // 开启滚轮平移
                            moveOnMouseMove: true  // 鼠标移动能触发数据窗口平移
                        }
                    ],
                }
                // 使用刚指定的配置项和数据显示图表。
                myChart.clear();
                myChart.setOption(option);
                console.log(xData);
            }).catch(err => {
                this.isLoading = false
            })
        },
        // 校收收统计
        getXss() {
            this.isLoading = true
            let myChart = this.$echarts.init(
                document.getElementById("xssEchart")
            );
            // let yData = [300, 498, 778, 382, 299, 372];
            xssAccessRecord({ schoolId: this.recList[0].schoolId, searchMonthOfDay: (this.recList[0].activeDate - 0) + 1, accessType: this.miniTypeIndex == 0 ? '' : this.miniTypeIndex }).then(res => {
                this.isLoading = false
                let xData = []
                let yData = []
                res.data.yData.forEach(ret => {

                    if (ret == '0') {

                        ret = ''
                    }
                    yData.push(ret)
                })
                // let yData = res.data.yData
                console.log(yData, 898989);
                res.data.xData.forEach(v => {
                    if (this.recList[0].activeDate == 0) {
                        xData.push(
                            v.length == 2 ? v : v.substring(5, 10).replace(/-/g, "/")
                        )
                    } else if (this.recList[0].activeDate == 1) {
                        xData.push(
                            v.length == 2 ? v : v.substring(5) + '月'
                        )
                    } else {
                        xData.push(v)
                    }
                    console.log(xData, '7777777777');
                    let option = {
                        grid: {
                            left: '10%',
                            right: '5%',
                            top: '15%',
                            bottom: '17%',
                            // containLabel: true
                        },
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {       //坐标轴指示器
                                type: 'line',   //十字准星指示器
                            },
                            extraCssText: 'width: 165px;height: 66px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
                            formatter: function (params) {
                                console.log(params);
                                return `<span style="font-size:12px;color:#3D3D3D;margin-left:12px;">${params[0].name}</span>` + '<br/>' +
                                    `<span style="color:#3D3D3D;font-size:12px;margin-left:12px;">访问人数 &nbsp;&nbsp; ${params[0].value ? params[0].value : 0}人</span>`
                            }
                        },
                        xAxis: {
                            type: 'category', // category(坐标轴类型)
                            data: xData,
                            axisTick: { // 坐标轴刻度相关配置
                                show: false // 是否显示坐标轴刻度
                            },
                            axisLine: { // 坐标轴轴线相关配置
                                lineStyle: { // 坐标轴轴线样式
                                    color: '#2C6CA3' // 坐标轴轴线颜色
                                }
                            },
                            axisLabel: { // 坐标轴刻度标签相关配置
                                color: '#ffffff',
                                fontSize: 10,
                                margin: 20
                            }
                        },
                        yAxis: {
                            name: '人',
                            minInterval: 1, // 最小单位是1
                            nameTextStyle: {
                                color: '#fff'
                            },
                            type: 'value', // value(数值轴,适用于连续数据)
                            axisTick: { // 坐标轴刻度相关配置
                                show: false  // 是否显示坐标轴刻度
                            },
                            axisLine: { // 坐标轴轴线相关配置
                                show: true,
                                lineStyle: { // 坐标轴轴线样式
                                    color: '#2C6CA3' // 坐标轴轴线颜色
                                }
                            },
                            axisLabel: { // 坐标轴刻度标签相关配置
                                color: '#ffffff',
                                fontSize: 10,
                            },
                            splitLine: { // 坐标轴在 grid 区域中的分隔线
                                lineStyle: { // 分割线配置
                                    color: '#2C6CA3', // 分割线颜色
                                    type: 'dashed' //虚线
                                }
                            }
                        },
                        series: [
                            // 底部的椭圆形(象形柱图):pictorialBar
                            {
                                type: "pictorialBar", // pictorialBar(象形柱图)
                                label: { // 图形上的文本标签,可用于说明图像的一些数据信息,比如值,名称等
                                    show: true, //是否显示标签
                                    position: 'top', // 标签的位置(可以是绝对的像素值或者百分比['50%','50%',也可以是top,left等])
                                    color: '#fff',
                                    fontSize: 14,
                                    formatter: function (params) {
                                        return params.value != 0 ? (params.value + '人') : ''
                                    }
                                },
                                symbolSize: [18, 5], // 图形的大小用数组分别比表示宽和高,也乐意设置成10相当于[10,10]
                                symbolOffset: [0, 3], // 图形相对于原本位置的偏移
                                z: 12, // 象形柱状图组件的所有图形的 z 值.控制图形的前后顺序.z 值小的图形会被 z 值大的图形覆盖.
                                itemStyle: { // 图形样式
                                    // echarts.graphic.LinearGradient(echarts内置的渐变色生成器)
                                    // 4个参数用于配置渐变色的起止位置,这4个参数依次对应右 下 左 上
                                    color: "#0C5DEB"
                                },
                                data: yData
                            },
                            // 中间的长方形柱状图(柱状图):bar
                            {
                                type: 'bar', // 柱状图
                                barWidth: 18, // 柱条的宽度,不设时自适应
                                barGap: '0%', // 柱子与柱子之间的距离
                                itemStyle: { // 图形样式
                                    // color支持(rgb(255,255,255)、rgba(255,255,255,1)、#fff,也支持渐变色和纹理填充)
                                    // 下面就是使用线性渐变
                                    color: {
                                        "x": 0,
                                        "y": 0,
                                        "x2": 0,
                                        "y2": 1,
                                        "type": "linear",
                                        "global": false,
                                        "colorStops": [{
                                            "offset": 0, // 0%处的颜色
                                            "color": "#1B81F7"
                                        }, {
                                            "offset": 1, // 100%处的颜色
                                            "color": "#18AAF8"
                                        }]
                                    }
                                },
                                data: yData
                            },
                            // 顶部的椭圆形(象形柱图):pictorialBar
                            {
                                type: "pictorialBar",
                                symbolSize: [18, 6],
                                symbolOffset: [0, -3],
                                z: 12,
                                symbolPosition: "end",
                                itemStyle: {
                                    color: '#02E6EB'
                                },
                                data: yData
                            }
                        ],
                        dataZoom: [
                            {
                                show: true,
                                height: 6, // 滚动条高度
                                xAxisIndex: [0],
                                bottom: 0, // 距离底部距离
                                showDetail: false,
                                showDataShadow: false,
                                start: 0, //初始化时，滑动条宽度开始标度
                                borderColor: "transparent",
                                textStyle: {
                                    fontSize: 0,
                                },
                                endValue: 5, //从0开始的相当于5个(x轴展示的数量)
                                fillerColor: "#3E599C", // 滚动条颜色
                                borderColor: "rgba(17, 100, 210, 0.12)",
                                backgroundColor: '#142746',//两边未选中的滑动条区域的颜色

                                borderWidth: 0,
                                //                 handleIcon:
                                //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
                                //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
                                //   handleSize: "0%",
                                handleStyle: {
                                    color: "#d3dee5",
                                },
                            },
                            {
                                type: "inside",  // 支持内部鼠标滚动平移
                                start: 0,
                                // end: 20,
                                startValue: 0, // 从头开始。
                                endValue: 5,  // 最多5个
                                zoomOnMouseWheel: false,  // 关闭滚轮缩放
                                moveOnMouseWheel: true, // 开启滚轮平移
                                moveOnMouseMove: true  // 鼠标移动能触发数据窗口平移
                            }

                        ],
                    }
                    // 使用刚指定的配置项和数据显示图表。
                    myChart.clear();
                    myChart.setOption(option);
                })
            }).catch(err => {
                this.isLoading = false
            })


        },
        // 智能公话
        getPhone() {
            this.isLoading = true
            let myChart = this.$echarts.init(
                document.getElementById("phoneEchart")
            );
            videoPhoneUseCount({ searchMonthOfDay: this.recList[1].activeDate, schoolId: this.recList[1].schoolId }).then(res => {
                this.isLoading = false
                let by = new Date().getMonth() + 1 //本月
                let jr = new Date().getDate() //今日
                let xDataOld = []
                let xData = []
                let xDataResult = []
                let yData = []
                res.data.forEach(v => {
                    xDataOld.push(v.recordTime)
                    yData.push(v.listByTime)
                })
                xDataOld.forEach(v => {
                    if (this.recList[1].activeDate == 0) {
                        xData.push(
                            v.substring(5, 10).replace(/-/g, "/")
                        )
                    } else {
                        xData.push(
                            v.length == 2 ? v : v.substring(5) + '月'
                        )
                    }
                })
                if (this.recList[1].activeDate == 0) {
                    xData.forEach(item => {
                        if (item.substring(2) == jr) {
                            item = '今日'
                        }
                        xDataResult.push(item)
                    })
                } else {
                    xData.forEach(item => {
                        if (item.substring(0, 2) == by) {
                            item = '本月'
                        }
                        xDataResult.push(item)
                    })
                }
                console.log(xData);
                console.log(yData);
                let option = {
                    grid: {
                        left: '10%',
                        right: '5%',
                        top: '12%',
                        bottom: '15%',
                        // containLabel: true
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {       //坐标轴指示器
                            type: 'line',   //十字准星指示器
                        },
                        extraCssText: 'width: 165px;height: 66px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
                        formatter: function (params) {
                            console.log(params);
                            return `<span style="font-size:12px;color:#3D3D3D;margin-left:12px;">${params[0].name} 使用设备  ${params[0].value ? params[0].value : 0}人</span>` + '<br/>' +
                                `<span style="color:#3D3D3D;font-size:14px;margin-left:12px;">使用率为  ${(params[0].data.usageRate) ? (params[0].data.usageRate) : 0}%</span>`
                        }

                        // formatter: function (parms) {
                        //     return parms.marker + " " + parms.name + "：" + parms.value + "次";
                        // }
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: xDataResult,
                        splitLine: {
                            show: true,
                            lineStyle: { // 坐标轴轴线样式
                                color: '#2C6CA3' // 坐标轴轴线颜色
                            }
                        },
                        axisLine: {
                            lineStyle: { // 坐标轴轴线样式
                                color: '#2C6CA3' // 坐标轴轴线颜色
                            }
                        },//坐标轴轴线
                        axisTick: { show: false },//坐标轴刻度
                        axisLabel: {    // 坐标轴标签
                            // show: true,  // 是否显示
                            // inside: false, // 是否朝内
                            // rotate: 0, // 旋转角度
                            // margin: 5, // 刻度标签与轴线之间的距离
                            lineStyle: { // 坐标轴轴线样式
                                color: '#2C6CA3' // 坐标轴轴线颜色
                            },
                            color: '#ffffff',
                            fontSize: 10,
                        },
                    },
                    yAxis: {
                        minInterval: 1, // 最小单位是1
                        name: '人',
                        nameTextStyle: {
                            color: '#fff'
                        },
                        type: 'value',
                        splitLine: { show: false },//坐标轴在 grid 区域中的分隔线
                        axisTick: { show: false },//坐标轴刻度
                        axisLine: { // 坐标轴轴线相关配置
                            lineStyle: { // 坐标轴轴线样式
                                color: '#2C6CA3' // 坐标轴轴线颜色
                            }
                        },
                        axisLabel: { // 坐标轴刻度标签相关配置
                            color: '#ffffff',
                            fontSize: 10,
                        },
                    },
                    series: [
                        {
                            itemStyle: {
                                normal: { // 设置线条的style等
                                    color: '#0267FE',
                                    lineStyle: {
                                        color: '#0267FE', // 折线线条颜色
                                    },
                                    label: {
                                        show: false
                                    }
                                }
                            },
                            data: yData,
                            type: 'line',
                            symbolSize: 8,   //设定中心点的大小
                            areaStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [{
                                        offset: 0, color: '#00348F' // 0% 处的颜色
                                    }, {
                                        offset: 1, color: 'rgba(0,52,143,0)' // 100% 处的颜色
                                    }],
                                    global: false // 缺省为 false
                                }
                            },
                            smooth: true
                        },
                    ],
                    dataZoom: [
                        {
                            show: true,
                            height: 6, // 滚动条高度
                            xAxisIndex: [0],
                            bottom: 0, // 距离底部距离
                            showDetail: false,
                            showDataShadow: false,
                            start: 0, //初始化时，滑动条宽度开始标度
                            borderColor: "transparent",
                            textStyle: {
                                fontSize: 0,
                            },
                            endValue: 5, //从0开始的相当于5个(x轴展示的数量)
                            fillerColor: "#3E599C", // 滚动条颜色
                            borderColor: "rgba(17, 100, 210, 0.12)",
                            backgroundColor: '#142746',//两边未选中的滑动条区域的颜色

                            borderWidth: 0,
                            //                 handleIcon:
                            //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
                            //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
                            //   handleSize: "0%",
                            handleStyle: {
                                color: "#d3dee5",
                            },
                        },
                        {
                            type: "inside",  // 支持内部鼠标滚动平移
                            start: 0,
                            // end: 20,
                            startValue: 0, // 从头开始。
                            endValue: 5,  // 最多5个
                            zoomOnMouseWheel: false,  // 关闭滚轮缩放
                            moveOnMouseWheel: true, // 开启滚轮平移
                            moveOnMouseMove: true  // 鼠标移动能触发数据窗口平移
                        }

                    ],
                };
                // 使用刚指定的配置项和数据显示图表。
                myChart.setOption(option);
            }).catch(err => {
                this.isLoading = false
            })
        },
        // 智能公话购买
        getPhonePay() {
            this.isLoading = true
            let myChart = this.$echarts.init(
                document.getElementById("phonePayEchart")
            );
            buyPackageCount({ schoolId: this.payList[0].schoolId, searchMonthOfDay: (this.payList[0].activeDate - 0) + 1 }).then(res => {
                this.isLoading = false
                let xData = []
                let yData = res.data.yDate
                res.data.xDate.forEach(v => {
                    if (this.payList[0].activeDate == 0) {
                        xData.push(
                            v.length == 2 ? v : v.substring(5, 10).replace(/-/g, "/")
                        )
                    } else if (this.recList[0].activeDate == 1) {
                        xData.push(
                            v.length == 2 ? v : v.substring(5) + '月'
                        )
                    } else {
                        xData.push(v)
                    }
                })
                let option = {
                    grid: {
                        left: '10%',
                        right: '5%',
                        top: '12%',
                        bottom: '15%',
                        // containLabel: true
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {       //坐标轴指示器
                            type: 'line',   //十字准星指示器
                        },
                        extraCssText: 'width: 265px;height: 66px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
                        formatter: function (params) {
                            console.log(params);
                            return `<span style="font-size:12px;color:#3D3D3D;margin-left:12px;">${params[0].name} 已购买套餐人数共  ${params[0].value ? params[0].value : 0}人</span>` + '<br/>' +
                                `<span style="color:#3D3D3D;font-size:14px;margin-left:12px;">购买率为  ${params[0].data.purchase ? params[0].data.purchase : 0}%</span>`
                        }

                        // formatter: function (parms) {
                        //     return parms.marker + " " + parms.name + "：" + parms.value + "次";
                        // }
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: xData,
                        splitLine: {
                            show: false,
                            lineStyle: { // 坐标轴轴线样式
                                color: '#2C6CA3' // 坐标轴轴线颜色
                            }
                        },
                        axisLine: {
                            lineStyle: { // 坐标轴轴线样式
                                color: '#2C6CA3' // 坐标轴轴线颜色
                            }
                        },//坐标轴轴线
                        axisTick: { show: false },//坐标轴刻度
                        axisLabel: {    // 坐标轴标签
                            // show: true,  // 是否显示
                            // inside: false, // 是否朝内
                            // rotate: 0, // 旋转角度
                            // margin: 5, // 刻度标签与轴线之间的距离
                            lineStyle: { // 坐标轴轴线样式
                                color: '#2C6CA3' // 坐标轴轴线颜色
                            },
                            color: '#ffffff',
                            fontSize: 10,
                        },
                    },
                    yAxis: {
                        name: '人',
                        minInterval: 1, // 最小单位是1
                        nameTextStyle: {
                            color: '#fff'
                        },
                        type: 'value',
                        splitLine: { // 坐标轴在 grid 区域中的分隔线
                            lineStyle: { // 分割线配置
                                color: '#2C6CA3', // 分割线颜色
                                type: 'dashed' //虚线
                            }
                        },
                        axisTick: { show: false },//坐标轴刻度
                        axisLine: { // 坐标轴轴线相关配置
                            show: true,
                            lineStyle: { // 坐标轴轴线样式
                                color: '#2C6CA3' // 坐标轴轴线颜色
                            }
                        },
                        axisLabel: { // 坐标轴刻度标签相关配置
                            color: '#ffffff',
                            fontSize: 10,
                        },
                    },
                    series: [
                        {
                            itemStyle: {
                                normal: { // 设置线条的style等
                                    color: '#F2C900',
                                    lineStyle: {
                                        color: '#FF5A0E', // 折线线条颜色
                                    },
                                    label: {
                                        show: false
                                    }
                                }
                            },
                            data: yData,
                            type: 'line',
                            symbolSize: 8,   //设定中心点的大小
                            symbol: 'circle',
                            areaStyle: {
                                color: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 0,
                                    y2: 1,
                                    colorStops: [{
                                        offset: 0, color: '#FFBF60' // 0% 处的颜色
                                    }, {
                                        offset: 1, color: 'rgba(107,91,60,0)' // 100% 处的颜色
                                    }],
                                    global: false // 缺省为 false
                                }
                            },
                            smooth: true
                        },
                    ],
                    dataZoom: [
                        {
                            show: true,
                            height: 6, // 滚动条高度
                            xAxisIndex: [0],
                            bottom: 0, // 距离底部距离
                            showDetail: false,
                            showDataShadow: false,
                            start: 0, //初始化时，滑动条宽度开始标度
                            borderColor: "transparent",
                            textStyle: {
                                fontSize: 0,
                            },
                            endValue: 5, //从0开始的相当于5个(x轴展示的数量)
                            fillerColor: "#3E599C", // 滚动条颜色
                            borderColor: "rgba(17, 100, 210, 0.12)",
                            backgroundColor: '#142746',//两边未选中的滑动条区域的颜色

                            borderWidth: 0,
                            //                 handleIcon:
                            //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
                            //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
                            //   handleSize: "0%",
                            handleStyle: {
                                color: "#d3dee5",
                            },
                        },
                        {
                            type: "inside",  // 支持内部鼠标滚动平移
                            start: 0,
                            // end: 20,
                            startValue: 0, // 从头开始。
                            endValue: 5,  // 最多5个
                            zoomOnMouseWheel: false,  // 关闭滚轮缩放
                            moveOnMouseWheel: true, // 开启滚轮平移
                            moveOnMouseMove: true  // 鼠标移动能触发数据窗口平移
                        }

                    ],
                };
                // 使用刚指定的配置项和数据显示图表。
                myChart.setOption(option);
            }).catch(err => {
                this.isLoading = false
            })
        },

        //   智能公话使用统计 ---+++
        getPhoneUse() {
            this.isLoading = true
            let myChart = this.$echarts.init(
                document.getElementById("phonePayUse")
            );
            let dayOrMonth = this.recList[1].activeDate
            getVideoPhoneUseCount({ schoolId: this.recList[1].schoolId, dayOrMonth: dayOrMonth }).then(res => {
                this.isLoading = false
                let by = new Date().getMonth() + 1; //本月
                let jr = new Date().getDate(); //今日
                let xData = [];
                let xDataResult = [];
                res.data.dateList.forEach((v) => {
                    if (dayOrMonth == 0) {
                        xData.push(v.substring(5, 10).replace(/-/g, "/"));
                    } else {
                        xData.push(v.length == 2 ? v : v.substring(5) + "月");
                    }
                });
                if (dayOrMonth == 0) {
                    xData.forEach((item) => {
                        if (item.substring(2) == jr) {
                            item = "今日";
                        }
                        xDataResult.push(item);
                    });
                } else {
                    xData.forEach((item) => {
                        if (item.substring(0, 2) == by) {
                            item = "本月";
                        }
                        xDataResult.push(item);
                    });
                }
                let option = {
                    color: ["#A0FF9B", "#3BDEFF"],
                    // tooltip: {
                    //   trigger: "axis",
                    //   axisPointer: {
                    //     type: "cross",
                    //     label: {
                    //       backgroundColor: "#6a7985",
                    //     },
                    //   },
                    // },
                    tooltip: {
                        trigger: "axis",
                        extraCssText:
                            "background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;color:#000",
                        axisPointer: {
                            //坐标轴指示器
                            type: "line", //十字准星指示器
                        },
                    },
                    legend: {
                        left: "5%",

                        data: ["次数", "分钟"],
                        textStyle: {
                            color: "#ffffff",
                        },
                    },

                    grid: {
                        left: "50",
                        right: "40",
                        bottom: "40",
                        top: "40",
                    },
                    xAxis: [
                        {
                            type: "category",
                            boundaryGap: false,
                            data: xDataResult,
                            axisLine: {
                                // 坐标轴轴线相关配置

                                lineStyle: {
                                    // 坐标轴轴线样式
                                    color: "#2C6CA3", // 坐标轴轴线颜色
                                },
                            },
                            axisLabel: {
                                color: "#fff",
                                fontSize: 12,
                                interval: 0,
                                formatter: function (params) {
                                    let newParamsName = "";
                                    let paramsNameNumber = params.length;
                                    let provideNumber = 5;
                                    let rowNumber = Math.ceil(paramsNameNumber / provideNumber);
                                    if (paramsNameNumber > provideNumber) {
                                        for (let p = 0; p < rowNumber; p++) {
                                            let tempStr = "";
                                            let start = p * provideNumber;
                                            let end = start + provideNumber;
                                            if (p === rowNumber - 1) {
                                                tempStr = params.substring(start, paramsNameNumber);
                                            } else {
                                                if (p > 2) {
                                                    tempStr = "...";
                                                    newParamsName += tempStr;
                                                    break;
                                                } else {
                                                    tempStr = params.substring(start, end);
                                                    if (p < 2) {
                                                        tempStr += "\n";
                                                    }
                                                }
                                            }
                                            newParamsName += tempStr;
                                        }
                                    } else {
                                        newParamsName = params;
                                    }
                                    return newParamsName;
                                },
                            },
                        },
                    ],
                    yAxis: [
                        {
                            type: "value",

                            axisLabel: {
                                // 坐标轴刻度标签相关配置
                                color: "#ffffff",
                                fontSize: 10,
                                margin: 20,
                            },
                            splitLine: {
                                // 坐标轴在 grid 区域中的分隔线

                                lineStyle: {
                                    // 分割线配置
                                    color: "#2C6CA3", // 分割线颜色
                                    type: "dashed", //虚线
                                },
                            },
                        },
                        {
                            type: "value",

                            axisLabel: {
                                // 坐标轴刻度标签相关配置
                                color: "#ffffff",
                                fontSize: 10,
                                margin: 20,
                            },
                            splitLine: {
                                // 坐标轴在 grid 区域中的分隔线

                                lineStyle: {
                                    // 分割线配置
                                    color: "#2C6CA3", // 分割线颜色
                                    type: "dashed", //虚线
                                },
                            },
                        },
                    ],
                    series: [
                        {
                            name: "次数",
                            type: "line",
                            stack: "Total",
                            smooth: true,
                            lineStyle: {
                                width: 0,
                            },
                            itemStyle: {
                                normal: {
                                    // 设置线条的style等
                                    color: "#0267FE",
                                    lineStyle: {
                                        color: "rgba(59,222,255,1)",
                                    },
                                    label: {
                                        show: false,
                                    },
                                },
                            },
                            showSymbol: false,
                            areaStyle: {
                                opacity: 0.8,
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: "rgba(59,222,255,1)",
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(59,222,255,0.2)",
                                    },
                                ]),
                            },
                            emphasis: {
                                focus: "series",
                            },
                            data: res.data.numList,
                        },
                        {
                            name: "分钟",
                            type: "line",
                            stack: "Total",

                            itemStyle: {
                                normal: {
                                    // 设置线条的style等
                                    color: "#0267FE",
                                    lineStyle: {
                                        color: "rgba(204,255,201,1)",
                                    },
                                    label: {
                                        show: false,
                                    },
                                },
                            },
                            smooth: true,
                            lineStyle: {
                                width: 0,
                            },
                            showSymbol: false,
                            areaStyle: {
                                opacity: 0.8,
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: "rgba(204,255,201,1)",
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(204,255,201,0.2)",
                                    },
                                ]),
                            },
                            emphasis: {
                                focus: "series",
                            },
                            data: res.data.durationList,
                        },
                    ],
                    dataZoom: [
                        {
                            show: true,
                            height: 6, // 滚动条高度
                            xAxisIndex: [0],
                            bottom: 0, // 距离底部距离
                            showDetail: false,
                            showDataShadow: false,
                            start: 0, //初始化时，滑动条宽度开始标度
                            borderColor: "transparent",
                            textStyle: {
                                fontSize: 0,
                            },
                            endValue: 5, //从0开始的相当于5个(x轴展示的数量)
                            fillerColor: "#3E599C", // 滚动条颜色
                            borderColor: "rgba(17, 100, 210, 0.12)",
                            backgroundColor: "#142746", //两边未选中的滑动条区域的颜色

                            borderWidth: 0,
                            //                 handleIcon:
                            //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
                            //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
                            //   handleSize: "0%",
                            handleStyle: {
                                color: "#d3dee5",
                            },
                        },
                        {
                            type: "inside", // 支持内部鼠标滚动平移
                            start: 0,
                            // end: 20,
                            startValue: 0, // 从头开始。
                            endValue: 5, // 最多5个
                            zoomOnMouseWheel: false, // 关闭滚轮缩放
                            moveOnMouseWheel: true, // 开启滚轮平移
                            moveOnMouseMove: true, // 鼠标移动能触发数据窗口平移
                        },
                    ],
                };

                console.log(myChart);
                // 使用刚指定的配置项和数据显示图表。
                myChart.clear();
                myChart.setOption(option);
            }).catch(err => {
                this.isLoading = false
            })
        },
        // 校园图书借阅统计
        getSchoolBooks() {

            let myChart = this.$echarts.init(document.getElementById("schoolBooks"));

            let dayOrMonth = this.recList[1].activeDate
            getBookSchoolBorrowCount({ schoolId: this.recList[1].schoolId, dayOrMonth: dayOrMonth }).then((res) => {
                let by = new Date().getMonth() + 1; //本月
                let jr = new Date().getDate(); //今日
                let xData = [];
                let xDataResult = [];
                res.data.dateList.forEach((v) => {
                    if (dayOrMonth == 0) {
                        xData.push(v.substring(5, 10).replace(/-/g, "/"));
                    } else {
                        xData.push(v.length == 2 ? v : v.substring(5) + "月");
                    }
                });
                if (dayOrMonth == 0) {
                    xData.forEach((item) => {
                        if (item.substring(2) == jr) {
                            item = "今日";
                        }
                        xDataResult.push(item);
                    });
                } else {
                    xData.forEach((item) => {
                        if (item.substring(0, 2) == by) {
                            item = "本月";
                        }
                        xDataResult.push(item);
                    });
                }

                let option = {
                    grid: {
                        left: "50",
                        right: "10",
                        bottom: "40",
                        top: "40",
                    },
                    tooltip: {
                        trigger: "axis",
                        axisPointer: {
                            //坐标轴指示器
                            type: "line", //十字准星指示器
                        },
                        extraCssText:
                            "width: 165px;height: 66px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;",
                        formatter: function (params) {
                            return (
                                `<span style="font-size:12px;color:#3D3D3D;">${params[0].name}</span>` +
                                "<br/>" +
                                `<span style="color:#3D3D3D;font-size:14px;margin-left:12px;">借阅图书 &nbsp;&nbsp; ${params[0].value}本</span>`
                            );
                        },

                        // formatter: function (parms) {
                        //     return parms.marker + " " + parms.name + "：" + parms.value + "次";
                        // }
                    },
                    xAxis: {
                        type: "category",
                        boundaryGap: false,
                        data: xDataResult,
                        splitLine: {
                            show: true,
                            lineStyle: {
                                // 坐标轴轴线样式
                                color: "#2C6CA3", // 坐标轴轴线颜色
                            },
                        },
                        axisLine: {
                            lineStyle: {
                                // 坐标轴轴线样式
                                color: "#2C6CA3", // 坐标轴轴线颜色
                            },
                        }, //坐标轴轴线
                        axisLabel: {
                            color: "#fff",
                            fontSize: 12,
                            interval: 0,
                            formatter: function (params) {
                                let newParamsName = "";
                                let paramsNameNumber = params.length;
                                let provideNumber = 5;
                                let rowNumber = Math.ceil(paramsNameNumber / provideNumber);
                                if (paramsNameNumber > provideNumber) {
                                    for (let p = 0; p < rowNumber; p++) {
                                        let tempStr = "";
                                        let start = p * provideNumber;
                                        let end = start + provideNumber;
                                        if (p === rowNumber - 1) {
                                            tempStr = params.substring(start, paramsNameNumber);
                                        } else {
                                            if (p > 2) {
                                                tempStr = "...";
                                                newParamsName += tempStr;
                                                break;
                                            } else {
                                                tempStr = params.substring(start, end);
                                                if (p < 2) {
                                                    tempStr += "\n";
                                                }
                                            }
                                        }
                                        newParamsName += tempStr;
                                    }
                                } else {
                                    newParamsName = params;
                                }
                                return newParamsName;
                            },
                        },
                        axisTick: { show: false }, //坐标轴刻度
                    },

                    yAxis: {
                        name: "本",
                        nameTextStyle: {
                            color: "#fff",
                        },
                        type: "value",
                        splitLine: { show: false }, //坐标轴在 grid 区域中的分隔线
                        axisTick: { show: false }, //坐标轴刻度
                        axisLine: {
                            // 坐标轴轴线相关配置
                            lineStyle: {
                                // 坐标轴轴线样式
                                color: "#2C6CA3", // 坐标轴轴线颜色
                            },
                        },
                        axisLabel: {
                            // 坐标轴刻度标签相关配置
                            color: "#ffffff",
                            fontSize: 10,
                        },
                    },
                    series: [
                        {
                            type: "line",
                            stack: "Total",
                            smooth: true,
                            lineStyle: {
                                width: 0,
                            },
                            itemStyle: {
                                normal: {
                                    // 设置线条的style等
                                    color: "#0267FE",
                                    lineStyle: {
                                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                            {
                                                offset: 0,
                                                color: "#FFBF60",
                                            },
                                            {
                                                offset: 1,
                                                color: "#FFBF60",
                                            },
                                        ]),
                                    },
                                    label: {
                                        show: false,
                                    },
                                },
                            },
                            showSymbol: false,
                            areaStyle: {
                                opacity: 0.8,
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: "rgba(255,191,96,1) ",
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(107,91,60,0.2) ",
                                    },
                                ]),
                            },
                            emphasis: {
                                focus: "series",
                            },
                            data: res.data.borrowList,
                        },
                    ],
                    dataZoom: [
                        {
                            show: true,
                            height: 6, // 滚动条高度
                            xAxisIndex: [0],
                            bottom: 0, // 距离底部距离
                            showDetail: false,
                            showDataShadow: false,
                            start: 0, //初始化时，滑动条宽度开始标度
                            borderColor: "transparent",
                            textStyle: {
                                fontSize: 0,
                            },
                            endValue: 5, //从0开始的相当于5个(x轴展示的数量)
                            fillerColor: "#3E599C", // 滚动条颜色
                            borderColor: "rgba(17, 100, 210, 0.12)",
                            backgroundColor: "#142746", //两边未选中的滑动条区域的颜色

                            borderWidth: 0,
                            //                 handleIcon:
                            //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
                            //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
                            //   handleSize: "0%",
                            handleStyle: {
                                color: "#d3dee5",
                            },
                        },
                        {
                            type: "inside", // 支持内部鼠标滚动平移
                            start: 0,
                            // end: 20,
                            startValue: 0, // 从头开始。
                            endValue: 5, // 最多5个
                            zoomOnMouseWheel: false, // 关闭滚轮缩放
                            moveOnMouseWheel: true, // 开启滚轮平移
                            moveOnMouseMove: true, // 鼠标移动能触发数据窗口平移
                        },
                    ],
                };
                myChart.clear();
                myChart.setOption(option);
            });
        },
        // 团餐管理
        getSchoolMeal() {
            // 基于准备好的dom，初始化echarts实例

            let chartDom = document.getElementById("schoolIncome");
            let myChart = this.$echarts.init(chartDom);

            let dayOrMonth = this.recList[1].activeDate
            getSchoolMealCount({ schoolId: this.recList[1].schoolId, dayOrMonth: dayOrMonth }).then((res) => {
                let by = new Date().getMonth() + 1; //本月
                let jr = new Date().getDate(); //今日
                let xData = [];
                let xDataResult = [];
                res.data.dateList.forEach((v) => {
                    if (dayOrMonth == 0) {
                        xData.push(v.substring(5, 10).replace(/-/g, "/"));
                    } else {
                        xData.push(v.length == 2 ? v : v.substring(5) + "月");
                    }
                });
                if (dayOrMonth == 0) {
                    xData.forEach((item) => {
                        if (item.substring(2) == jr) {
                            item = "今日";
                        }
                        xDataResult.push(item);
                    });
                } else {
                    xData.forEach((item) => {
                        if (item.substring(0, 2) == by) {
                            item = "本月";
                        }
                        xDataResult.push(item);
                    });
                }
                let dinnerData = {
                    //  backgroundColor: '#00265f',

                    legend: {
                        left: "5%",

                        data: ["早", "中", "晚"],
                        textStyle: {
                            color: "#ffffff",
                        },
                    },
                    tooltip: {
                        trigger: "axis",
                        extraCssText:
                            "background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;",
                        axisPointer: {
                            //坐标轴指示器
                            type: "line", //十字准星指示器
                        },
                        formatter: function (params) {
                            console.log(params);
                            let res = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#4B93FF;" ></span ><span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name
                                } &nbsp;&nbsp;总营收&nbsp;${params[0].data.allUsePrice ? params[0].data.allUsePrice : 0.0
                                }元  &nbsp;&nbsp;刷脸${params[0].data.allUseCount ? params[0].data.allUseCount : 0
                                }次 </span><br/>`;
                            params.reverse().forEach((item) => {
                                res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName}&nbsp;&nbsp;&nbsp;${item.value}元</span><br/>`;
                            });
                            return res;
                        },
                    },
                    grid: {
                        left: "5%",
                        right: "4%",
                        bottom: "3%",
                        containLabel: true,
                    },
                    xAxis: [
                        {
                            type: "category",
                            // data: data.titleList,
                            data: xDataResult,
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: "rgba(255,255,255,.1)",
                                    width: 1,
                                    type: "solid",
                                },
                            },

                            axisTick: {
                                show: false,
                            },
                            axisLabel: {
                                color: "#fff",
                                fontSize: 12,
                                interval: 0,
                                formatter: function (params) {
                                    let newParamsName = "";
                                    let paramsNameNumber = params.length;
                                    let provideNumber = 4;
                                    let rowNumber = Math.ceil(paramsNameNumber / provideNumber);
                                    if (paramsNameNumber > provideNumber) {
                                        for (let p = 0; p < rowNumber; p++) {
                                            let tempStr = "";
                                            let start = p * provideNumber;
                                            let end = start + provideNumber;
                                            if (p === rowNumber - 1) {
                                                tempStr = params.substring(start, paramsNameNumber);
                                            } else {
                                                if (p > 2) {
                                                    tempStr = "...";
                                                    newParamsName += tempStr;
                                                    break;
                                                } else {
                                                    tempStr = params.substring(start, end);
                                                    if (p < 2) {
                                                        tempStr += "\n";
                                                    }
                                                }
                                            }
                                            newParamsName += tempStr;
                                        }
                                    } else {
                                        newParamsName = params;
                                    }
                                    return newParamsName;
                                },
                            },
                        },
                    ],
                    yAxis: [
                        {
                            type: "value",
                            axisLabel: {
                                //formatter: '{value} %'
                                show: true,
                                textStyle: {
                                    color: "rgba(255,255,255,.6)",
                                    fontSize: "12",
                                },
                            },
                            axisTick: {
                                show: false,
                            },
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: "rgba(255,255,255,.1	)",
                                    width: 1,
                                    type: "solid",
                                },
                            },
                            splitLine: {
                                lineStyle: {
                                    color: "rgba(255,255,255,.1)",
                                },
                            },
                        },
                    ],
                    series: [
                        {
                            type: "bar",
                            name: "早",
                            stack: "堆叠", //设置堆叠柱状图的属性，需堆叠的柱子名称需相同
                            // data: data.oneList,
                            data: res.data.listEarly,
                            barWidth: "20%", //柱子宽度
                            // barGap: 1, //柱子之间间距
                            itemStyle: {
                                normal: {
                                    color: "#4CD6A6", //柱子颜色
                                    opacity: 1,
                                    barBorderRadius: 1,
                                },
                            },
                        },
                        {
                            type: "bar",
                            name: "中",
                            stack: "堆叠", //设置堆叠柱状图的属性，需堆叠的柱子名称需相同
                            // data: data.twoList,
                            data: res.data.listMiddle,
                            barWidth: "20%", //柱子宽度
                            // barGap: 1, //柱子之间间距
                            itemStyle: {
                                normal: {
                                    color: "#469DFA", //柱子颜色
                                    opacity: 1,
                                    barBorderRadius: 1, //柱子菱角
                                },
                            },
                        },
                        {
                            type: "bar",
                            name: "晚",
                            stack: "堆叠", //设置堆叠柱状图的属性，需堆叠的柱子名称需相同
                            // data: data.twoList,
                            data: res.data.listLate,
                            barWidth: "20%", //柱子宽度
                            // barGap: 1, //柱子之间间距
                            itemStyle: {
                                normal: {
                                    color: "#F8BC5B", //柱子颜色
                                    opacity: 1,
                                    barBorderRadius: 1, //柱子菱角
                                },
                            },
                        },
                    ],
                    dataZoom: [
                        {
                            show: true,
                            height: 6, // 滚动条高度
                            xAxisIndex: [0],
                            bottom: 0, // 距离底部距离
                            showDetail: false,
                            showDataShadow: false,
                            start: 0, //初始化时，滑动条宽度开始标度
                            borderColor: "transparent",
                            textStyle: {
                                fontSize: 0,
                            },
                            endValue: 5, //从0开始的相当于5个(x轴展示的数量)
                            fillerColor: "#3E599C", // 滚动条颜色
                            borderColor: "rgba(17, 100, 210, 0.12)",
                            backgroundColor: "#142746", //两边未选中的滑动条区域的颜色

                            borderWidth: 0,
                            //                 handleIcon:
                            //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
                            //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
                            //   handleSize: "0%",
                            handleStyle: {
                                color: "#d3dee5",
                            },
                        },
                        {
                            type: "inside", // 支持内部鼠标滚动平移
                            start: 0,
                            // end: 20,
                            startValue: 0, // 从头开始。
                            endValue: 5, // 最多5个
                            zoomOnMouseWheel: false, // 关闭滚轮缩放
                            moveOnMouseWheel: true, // 开启滚轮平移
                            moveOnMouseMove: true, // 鼠标移动能触发数据窗口平移
                        },
                    ],
                };
                myChart.clear();
                // 使用刚指定的配置项和数据显示图表。
                myChart.setOption(dinnerData);
            });
        },
        //  门禁
        getEntrance() {
            let chartDom = document.getElementById("entrance");
            let myChart = this.$echarts.init(chartDom);

            let dayOrMonth = this.recList[1].activeDate
            getAccessSchoolCount({ schoolId: this.recList[1].schoolId, dayOrMonth: dayOrMonth, deviceType: "" }).then((res) => {
                let by = new Date().getMonth() + 1; //本月
                let jr = new Date().getDate(); //今日
                let xData = [];
                let xDataResult = [];
                res.data.dateList.forEach((v) => {
                    if (dayOrMonth == 0) {
                        xData.push(v.substring(5, 10).replace(/-/g, "/"));
                    } else {
                        xData.push(v.length == 2 ? v : v.substring(5) + "月");
                    }
                });
                if (dayOrMonth == 0) {
                    xData.forEach((item) => {
                        if (item.substring(2) == jr) {
                            item = "今日";
                        }
                        xDataResult.push(item);
                    });
                } else {
                    xData.forEach((item) => {
                        if (item.substring(0, 2) == by) {
                            item = "本月";
                        }
                        xDataResult.push(item);
                    });
                }
                let entranceData = {
                    backgroundColor: "transparent",
                    tooltip: {
                        trigger: "axis",
                        extraCssText:
                            "background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;color:#000",
                        formatter: function (params) {
                            console.log(params);
                            let res = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background: linear-gradient(180deg, rgba(2,102,253,0.65) 0%, rgba(2,102,253,0.3) 100%);" ></span ><span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name}</span><br/>`
                            params.forEach(item => {
                                res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName}人次&nbsp;&nbsp;&nbsp;${item.value}人</span> <span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.data.passCount}次</span><br/>`;
                            });
                            return res;
                        },
                        axisPointer: {
                            //坐标轴指示器
                            type: "shadow", //十字准星指示器
                        },
                    },
                    legend: {
                        left: "5%",
                        data: ["进入", "外出"],
                        textStyle: {
                            color: "#fff",
                        },
                        itemWidth: 15,
                        itemHeight: 11,
                        itemGap: 35,
                    },
                    grid: {
                        left: "50",
                        right: "10",
                        bottom: "40",
                        top: "40",
                    },
                    xAxis: [
                        {
                            type: "category",
                            axisLine: {
                                lineStyle: {
                                    color: "#2C6CA3",
                                },
                            },
                            axisLabel: {
                                color: "#fff",
                                fontSize: 14,
                                interval: 0,
                                formatter: function (params) {
                                    let newParamsName = "";
                                    let paramsNameNumber = params.length;
                                    let provideNumber = 4;
                                    let rowNumber = Math.ceil(paramsNameNumber / provideNumber);
                                    if (paramsNameNumber > provideNumber) {
                                        for (let p = 0; p < rowNumber; p++) {
                                            let tempStr = "";
                                            let start = p * provideNumber;
                                            let end = start + provideNumber;
                                            if (p === rowNumber - 1) {
                                                tempStr = params.substring(start, paramsNameNumber);
                                            } else {
                                                if (p > 2) {
                                                    tempStr = "...";
                                                    newParamsName += tempStr;
                                                    break;
                                                } else {
                                                    tempStr = params.substring(start, end);
                                                    if (p < 2) {
                                                        tempStr += "\n";
                                                    }
                                                }
                                            }
                                            newParamsName += tempStr;
                                        }
                                    } else {
                                        newParamsName = params;
                                    }
                                    return newParamsName;
                                },
                            },
                            splitLine: {
                                lineStyle: {
                                    color: "#00314a",
                                },
                            },
                            // axisTick: {
                            //   show: false
                            // },

                            data: xDataResult,
                        },
                    ],
                    yAxis: [
                        {
                            type: "value",

                            nameTextStyle: {
                                color: "#fff",
                                fontSize: 14,
                            },
                            axisLine: {
                                lineStyle: {
                                    color: "#2C6CA3",
                                },
                            },
                            axisTick: {
                                show: false,
                            },

                            splitLine: {
                                // 坐标轴在 grid 区域中的分隔线

                                lineStyle: {
                                    // 分割线配置
                                    color: "#2C6CA3", // 分割线颜色
                                    type: "dashed", //虚线
                                },
                            },
                            axisLabel: {
                                color: "#fff",
                                fontSize: 14,
                                formatter: "{value}",
                            },
                        },
                    ],
                    series: [
                        {
                            name: "进入",
                            type: "bar",
                            data: res.data.personCountJrList,
                            barWidth: 10,
                            barGap: "0%" /*多个并排柱子设置柱子之间的间距*/,
                            barCategoryGap: "100%" /*多个并排柱子设置柱子之间的间距*/,

                            itemStyle: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    { offset: 0, color: "rgba(244,94,94,1)" },
                                    { offset: 1, color: "rgba(255,205,205,1)" },
                                ]),
                            },
                        },
                        {
                            name: "外出",
                            type: "bar",
                            data: res.data.personCountWcList,
                            barWidth: 10,
                            barGap: "0%" /*多个并排柱子设置柱子之间的间距*/,
                            barCategoryGap: "100%" /*多个并排柱子设置柱子之间的间距*/,

                            itemStyle: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    { offset: 0, color: "rgba(70,157,250,1)" },
                                    { offset: 1, color: "rgba(0,31,64,1)" },
                                ]),
                            },
                        },
                    ],
                    dataZoom: [
                        {
                            show: true,
                            height: 6, // 滚动条高度
                            xAxisIndex: [0],
                            bottom: 0, // 距离底部距离
                            showDetail: false,
                            showDataShadow: false,
                            start: 0, //初始化时，滑动条宽度开始标度
                            borderColor: "transparent",
                            textStyle: {
                                fontSize: 0,
                            },
                            endValue: 5, //从0开始的相当于5个(x轴展示的数量)
                            fillerColor: "#3E599C", // 滚动条颜色
                            borderColor: "rgba(17, 100, 210, 0.12)",
                            backgroundColor: "#142746", //两边未选中的滑动条区域的颜色

                            borderWidth: 0,
                            //                 handleIcon:
                            //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
                            //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
                            //   handleSize: "0%",
                            handleStyle: {
                                color: "#d3dee5",
                            },
                        },
                        {
                            type: "inside", // 支持内部鼠标滚动平移
                            start: 0,
                            // end: 20,
                            startValue: 0, // 从头开始。
                            endValue: 5, // 最多5个
                            zoomOnMouseWheel: false, // 关闭滚轮缩放
                            moveOnMouseWheel: true, // 开启滚轮平移
                            moveOnMouseMove: true, // 鼠标移动能触发数据窗口平移
                        },
                    ],
                };
                myChart.clear();
                myChart.setOption(entranceData);
            });
        },
        // 共享洗衣使用统计
        getLaundry() {
            let chartDom = document.getElementById("laundry");
            let myChart = this.$echarts.init(chartDom);

            let type = "";
            if (this.isuseAc == 4) {
                type = 1;
            } else {
                type = 9;
            }
            let dayOrMonth = this.recList[1].activeDate
            getWashingUseCount({ schoolId: this.recList[1].schoolId, dayOrMonth: dayOrMonth, orderType: type }).then(
                (res) => {
                    console.log(res);
                    let by = new Date().getMonth() + 1; //本月
                    let jr = new Date().getDate(); //今日
                    let xData = [];
                    let xDataResult = [];
                    res.data.dateList.forEach((v) => {
                        if (dayOrMonth == 0) {
                            xData.push(v.substring(5, 10).replace(/-/g, "/"));
                        } else {
                            xData.push(v.length == 2 ? v : v.substring(5) + "月");
                        }
                    });
                    if (dayOrMonth == 0) {
                        xData.forEach((item) => {
                            if (item.substring(2) == jr) {
                                item = "今日";
                            }
                            xDataResult.push(item);
                        });
                    } else {
                        xData.forEach((item) => {
                            if (item.substring(0, 2) == by) {
                                item = "本月";
                            }
                            xDataResult.push(item);
                        });
                    }
                    let laundryData = {
                        color: ["#3BDEFF", "#A0FF9B"],

                        tooltip: {
                            trigger: "axis",
                            extraCssText:
                                "width: 178px;height: 100px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;",
                            axisPointer: {
                                //坐标轴指示器
                                type: "line", //十字准星指示器
                            },
                            formatter: function (params) {
                                console.log(params);
                                let res = `${params[0].marker}<span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name}</span><br/>`;
                                params.forEach((item) => {
                                    if (item.data.studentCount) {
                                    }
                                    res +=
                                        `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName == "金额" ? "营收金额" : "使用次数"
                                        }&nbsp;&nbsp;&nbsp;${item.value}${item.seriesName == "金额" ? "元" : "次"
                                        }</span><br/>` +
                                        `${item.data.studentCount
                                            ? `<span style="font-size:12px;color:#333;margin-left:12px;">教师：${item.data.teacherCount}次&nbsp;&nbsp;学生：${item.data.studentCount}次</span><br/>`
                                            : ""
                                        }`;
                                });
                                return res;
                                // console.log(params);
                                // const str = `${params.marker}<span style="font-size:12px;color:#767985;">${params.name}</span><br/><span style="font-size:14px;color:#333;margin:12px;">${params.seriesName == '金额' ? '营收金额：' : '使用次数：'} &nbsp;&nbsp; ${params.value}${params.seriesName == '金额' ? '元' : '次'}</span>${params.seriesName == '金额' ? '' : `<br/><span style="font-size:12px;color:#333;margin-left:12px;">教师：${params.data.teacherCount}次&nbsp;&nbsp;学生：${params.data.studentCount}次</span>`}`
                                // return str
                            },
                        },
                        legend: {
                            left: "5%",

                            data: ["次数", "金额"],
                            textStyle: {
                                color: "#ffffff",
                            },
                        },

                        grid: {
                            left: "5%",
                            right: "4%",
                            bottom: "3%",
                            containLabel: true,
                        },
                        xAxis: [
                            {
                                type: "category",
                                boundaryGap: false,
                                data: xDataResult,
                                axisLine: {
                                    // 坐标轴轴线相关配置
                                    lineStyle: {
                                        // 坐标轴轴线样式
                                        color: "#2C6CA3", // 坐标轴轴线颜色
                                    },
                                },
                                axisLabel: {
                                    color: "#fff",
                                    fontSize: 12,
                                    interval: 0,
                                    formatter: function (params) {
                                        let newParamsName = "";
                                        let paramsNameNumber = params.length;
                                        let provideNumber = 4;
                                        let rowNumber = Math.ceil(paramsNameNumber / provideNumber);
                                        if (paramsNameNumber > provideNumber) {
                                            for (let p = 0; p < rowNumber; p++) {
                                                let tempStr = "";
                                                let start = p * provideNumber;
                                                let end = start + provideNumber;
                                                if (p === rowNumber - 1) {
                                                    tempStr = params.substring(start, paramsNameNumber);
                                                } else {
                                                    if (p > 2) {
                                                        tempStr = "...";
                                                        newParamsName += tempStr;
                                                        break;
                                                    } else {
                                                        tempStr = params.substring(start, end);
                                                        if (p < 2) {
                                                            tempStr += "\n";
                                                        }
                                                    }
                                                }
                                                newParamsName += tempStr;
                                            }
                                        } else {
                                            newParamsName = params;
                                        }
                                        return newParamsName;
                                    },
                                },
                            },
                        ],
                        yAxis: [
                            {
                                type: "value",

                                axisLabel: {
                                    // 坐标轴刻度标签相关配置
                                    color: "#ffffff",
                                    fontSize: 10,
                                    margin: 20,
                                },
                                splitLine: {
                                    // 坐标轴在 grid 区域中的分隔线

                                    lineStyle: {
                                        // 分割线配置
                                        color: "#2C6CA3", // 分割线颜色
                                        type: "dashed", //虚线
                                    },
                                },
                            },
                        ],
                        series: [
                            {
                                name: "次数",
                                type: "line",
                                stack: "Total",
                                smooth: true,
                                lineStyle: {
                                    width: 0,
                                },
                                itemStyle: {
                                    normal: {
                                        // 设置线条的style等
                                        color: "#0267FE",
                                        lineStyle: {
                                            color: "rgba(59,222,255,1)",
                                        },
                                        label: {
                                            show: false,
                                        },
                                    },
                                },
                                showSymbol: false,
                                areaStyle: {
                                    opacity: 0.8,
                                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                        {
                                            offset: 0,
                                            color: "rgba(59,222,255,1)",
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(59,222,255,0.2)",
                                        },
                                    ]),
                                },
                                emphasis: {
                                    focus: "series",
                                },
                                data: res.data.useList,
                            },
                            {
                                name: "金额",
                                type: "line",
                                stack: "Total",
                                smooth: true,
                                lineStyle: {
                                    width: 0,
                                },

                                itemStyle: {
                                    normal: {
                                        // 设置线条的style等
                                        color: "#0267FE",
                                        lineStyle: {
                                            color: "rgba(204,255,201,1)",
                                        },
                                        label: {
                                            show: false,
                                        },
                                    },
                                },
                                smooth: true,
                                lineStyle: {
                                    width: 0,
                                },
                                showSymbol: false,
                                areaStyle: {
                                    opacity: 0.8,
                                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                        {
                                            offset: 0,
                                            color: "rgba(204,255,201,1)",
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(204,255,201,0.2)",
                                        },
                                    ]),
                                },
                                emphasis: {
                                    focus: "series",
                                },
                                data: res.data.receiptAmountList,
                            },
                        ],
                        dataZoom: [
                            {
                                show: true,
                                height: 6, // 滚动条高度
                                xAxisIndex: [0],
                                bottom: 0, // 距离底部距离
                                showDetail: false,
                                showDataShadow: false,
                                start: 0, //初始化时，滑动条宽度开始标度
                                borderColor: "transparent",
                                textStyle: {
                                    fontSize: 0,
                                },
                                endValue: 5, //从0开始的相当于5个(x轴展示的数量)
                                fillerColor: "#3E599C", // 滚动条颜色
                                borderColor: "rgba(17, 100, 210, 0.12)",
                                backgroundColor: "#142746", //两边未选中的滑动条区域的颜色

                                borderWidth: 0,
                                //                 handleIcon:
                                //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
                                //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
                                //   handleSize: "0%",
                                handleStyle: {
                                    color: "#d3dee5",
                                },
                            },
                            {
                                type: "inside", // 支持内部鼠标滚动平移
                                start: 0,
                                // end: 20,
                                startValue: 0, // 从头开始。
                                endValue: 5, // 最多5个
                                zoomOnMouseWheel: false, // 关闭滚轮缩放
                                moveOnMouseWheel: true, // 开启滚轮平移
                                moveOnMouseMove: true, // 鼠标移动能触发数据窗口平移
                            },
                        ],
                    };
                    myChart.clear();
                    myChart.setOption(laundryData);
                }
            );
        },
        // 售货机
        getVending() {
            let chartDom = document.getElementById("vending");
            let myChart = this.$echarts.init(chartDom);

            let type = "";
            if (this.isuseAc == 4) {
                type = 1;
            } else {
                type = 9;
            }
            let dayOrMonth = this.recList[1].activeDate
            getVendingUseCount({ schoolId: this.recList[1].schoolId, dayOrMonth: dayOrMonth }).then(
                (res) => {
                    console.log(res);
                    let by = new Date().getMonth() + 1; //本月
                    let jr = new Date().getDate(); //今日
                    let xData = [];
                    let xDataResult = [];
                    res.data.dateList.forEach((v) => {
                        if (dayOrMonth == 0) {
                            xData.push(v.substring(5, 10).replace(/-/g, "/"));
                        } else {
                            xData.push(v.length == 2 ? v : v.substring(5) + "月");
                        }
                    });
                    if (dayOrMonth == 0) {
                        xData.forEach((item) => {
                            if (item.substring(2) == jr) {
                                item = "今日";
                            }
                            xDataResult.push(item);
                        });
                    } else {
                        xData.forEach((item) => {
                            if (item.substring(0, 2) == by) {
                                item = "本月";
                            }
                            xDataResult.push(item);
                        });
                    }
                    let laundryData = {
                        color: ["#3BDEFF", "#A0FF9B"],

                        tooltip: {
                            trigger: "axis",
                            extraCssText:
                                "width: 178px;height: 100px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;",
                            axisPointer: {
                                //坐标轴指示器
                                type: "line", //十字准星指示器
                            },
                            formatter: function (params) {
                                console.log(params);
                                let res = `${params[0].marker}<span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name}</span><br/>`;
                                params.forEach((item) => {
                                    if (item.data.studentCount) {
                                    }
                                    res +=
                                        `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName == "金额" ? "营收金额" : "使用次数"
                                        }&nbsp;&nbsp;&nbsp;${item.value}${item.seriesName == "金额" ? "元" : "次"
                                        }</span><br/>` +
                                        `${item.data.studentCount
                                            ? `<span style="font-size:12px;color:#333;margin-left:12px;">教师：${item.data.teacherCount}次&nbsp;&nbsp;学生：${item.data.studentCount}次</span><br/>`
                                            : ""
                                        }`;
                                });
                                return res;
                                // console.log(params);
                                // const str = `${params.marker}<span style="font-size:12px;color:#767985;">${params.name}</span><br/><span style="font-size:14px;color:#333;margin:12px;">${params.seriesName == '金额' ? '营收金额：' : '使用次数：'} &nbsp;&nbsp; ${params.value}${params.seriesName == '金额' ? '元' : '次'}</span>${params.seriesName == '金额' ? '' : `<br/><span style="font-size:12px;color:#333;margin-left:12px;">教师：${params.data.teacherCount}次&nbsp;&nbsp;学生：${params.data.studentCount}次</span>`}`
                                // return str
                            },
                        },
                        legend: {
                            left: "5%",

                            data: ["次数", "金额"],
                            textStyle: {
                                color: "#ffffff",
                            },
                        },

                        grid: {
                            left: "5%",
                            right: "4%",
                            bottom: "3%",
                            containLabel: true,
                        },
                        xAxis: [
                            {
                                type: "category",
                                boundaryGap: false,
                                data: xDataResult,
                                axisLine: {
                                    // 坐标轴轴线相关配置
                                    lineStyle: {
                                        // 坐标轴轴线样式
                                        color: "#2C6CA3", // 坐标轴轴线颜色
                                    },
                                },
                                axisLabel: {
                                    color: "#fff",
                                    fontSize: 12,
                                    interval: 0,
                                    formatter: function (params) {
                                        let newParamsName = "";
                                        let paramsNameNumber = params.length;
                                        let provideNumber = 4;
                                        let rowNumber = Math.ceil(paramsNameNumber / provideNumber);
                                        if (paramsNameNumber > provideNumber) {
                                            for (let p = 0; p < rowNumber; p++) {
                                                let tempStr = "";
                                                let start = p * provideNumber;
                                                let end = start + provideNumber;
                                                if (p === rowNumber - 1) {
                                                    tempStr = params.substring(start, paramsNameNumber);
                                                } else {
                                                    if (p > 2) {
                                                        tempStr = "...";
                                                        newParamsName += tempStr;
                                                        break;
                                                    } else {
                                                        tempStr = params.substring(start, end);
                                                        if (p < 2) {
                                                            tempStr += "\n";
                                                        }
                                                    }
                                                }
                                                newParamsName += tempStr;
                                            }
                                        } else {
                                            newParamsName = params;
                                        }
                                        return newParamsName;
                                    },
                                },
                            },
                        ],
                        yAxis: [
                            {
                                type: "value",

                                axisLabel: {
                                    // 坐标轴刻度标签相关配置
                                    color: "#ffffff",
                                    fontSize: 10,
                                    margin: 20,
                                },
                                splitLine: {
                                    // 坐标轴在 grid 区域中的分隔线

                                    lineStyle: {
                                        // 分割线配置
                                        color: "#2C6CA3", // 分割线颜色
                                        type: "dashed", //虚线
                                    },
                                },
                            },
                        ],
                        series: [
                            {
                                name: "次数",
                                type: "line",
                                stack: "Total",
                                smooth: true,
                                lineStyle: {
                                    width: 0,
                                },
                                itemStyle: {
                                    normal: {
                                        // 设置线条的style等
                                        color: "#0267FE",
                                        lineStyle: {
                                            color: "rgba(59,222,255,1)",
                                        },
                                        label: {
                                            show: false,
                                        },
                                    },
                                },
                                showSymbol: false,
                                areaStyle: {
                                    opacity: 0.8,
                                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                        {
                                            offset: 0,
                                            color: "rgba(59,222,255,1)",
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(59,222,255,0.2)",
                                        },
                                    ]),
                                },
                                emphasis: {
                                    focus: "series",
                                },
                                data: res.data.useList,
                            },
                            {
                                name: "金额",
                                type: "line",
                                stack: "Total",
                                smooth: true,
                                lineStyle: {
                                    width: 0,
                                },

                                itemStyle: {
                                    normal: {
                                        // 设置线条的style等
                                        color: "#0267FE",
                                        lineStyle: {
                                            color: "rgba(204,255,201,1)",
                                        },
                                        label: {
                                            show: false,
                                        },
                                    },
                                },
                                smooth: true,
                                lineStyle: {
                                    width: 0,
                                },
                                showSymbol: false,
                                areaStyle: {
                                    opacity: 0.8,
                                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                        {
                                            offset: 0,
                                            color: "rgba(204,255,201,1)",
                                        },
                                        {
                                            offset: 1,
                                            color: "rgba(204,255,201,0.2)",
                                        },
                                    ]),
                                },
                                emphasis: {
                                    focus: "series",
                                },
                                data: res.data.receiptAmountList,
                            },
                        ],
                        dataZoom: [
                            {
                                show: true,
                                height: 6, // 滚动条高度
                                xAxisIndex: [0],
                                bottom: 0, // 距离底部距离
                                showDetail: false,
                                showDataShadow: false,
                                start: 0, //初始化时，滑动条宽度开始标度
                                borderColor: "transparent",
                                textStyle: {
                                    fontSize: 0,
                                },
                                endValue: 5, //从0开始的相当于5个(x轴展示的数量)
                                fillerColor: "#3E599C", // 滚动条颜色
                                borderColor: "rgba(17, 100, 210, 0.12)",
                                backgroundColor: "#142746", //两边未选中的滑动条区域的颜色

                                borderWidth: 0,
                                //                 handleIcon:
                                //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
                                //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
                                //   handleSize: "0%",
                                handleStyle: {
                                    color: "#d3dee5",
                                },
                            },
                            {
                                type: "inside", // 支持内部鼠标滚动平移
                                start: 0,
                                // end: 20,
                                startValue: 0, // 从头开始。
                                endValue: 5, // 最多5个
                                zoomOnMouseWheel: false, // 关闭滚轮缩放
                                moveOnMouseWheel: true, // 开启滚轮平移
                                moveOnMouseMove: true, // 鼠标移动能触发数据窗口平移
                            },
                        ],
                    };
                    myChart.clear();
                    myChart.setOption(laundryData);
                }
            );
        },
        // 消费记录
        getPayRecord() {

            clearTimeout(this.intervalHandle)
            this.intervalHandle = null
            this.payRecord = []
            getRealTimeConsumption({ schoolId: this.payList[0].schoolId }).then(res => {
                this.payRecord = res.data
                if (this.payRecord.length > 7) {
                    this.countDown()
                }
            })
        },
        // 流水概况
        getPayNum() {
            this.isLoading = true
            let myChart = this.$echarts.init(
                document.getElementById("payNumEchart")
            );
            // let yData = [300, 498, 778, 382, 299, 372];
            let obj = {
                searchType: (this.isPayTypeMouAc - 0) + 1, allDeviceSearch: (this.payList[1].payTypeIndex - 0) == 0 ? '1' : '2', deviceType: (this.payList[1].payTypeIndex - 0) == 0 ? '' : (this.payList[1].payTypeIndex - 0), schoolId: this.payList[1].schoolId
            }
            currentAmountStatement(obj).then(res => {
                this.isLoading = false
                let xData = []
                let yData = res.data.yData
                res.data.xData.forEach(v => {
                    if (this.isPayTypeMouAc == 0) {
                        xData.push(
                            v.length == 2 ? v : v.substring(5, 10).replace(/-/g, "/")
                        )
                    } else {
                        xData.push(
                            v.length == 2 ? v : v.substring(5) + '月'
                        )
                    }
                })
                let option = {
                    grid: {
                        left: '10%',
                        right: '5%',
                        top: '12%',
                        bottom: '15%',
                        // containLabel: true
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {       //坐标轴指示器
                            type: 'line',   //十字准星指示器
                        },
                        extraCssText: 'width:220px;height: 66px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;',
                        formatter: function (params) {
                            return `<span style="font-size:12px;color:#3D3D3D;margin-left:12px;">${params[0].name}</span>` + '<br/>' +
                                `<span style="color:#3D3D3D;font-size:14px;margin-left:12px;">金额流水 &nbsp;&nbsp; ${params[0].value ? params[0].value : 0}元</span>`
                        }
                    },
                    xAxis: {
                        type: 'category', // category(坐标轴类型)
                        data: xData,
                        axisTick: { // 坐标轴刻度相关配置
                            show: false // 是否显示坐标轴刻度
                        },
                        axisLine: { // 坐标轴轴线相关配置
                            lineStyle: { // 坐标轴轴线样式
                                color: '#2C6CA3' // 坐标轴轴线颜色
                            }
                        },
                        axisLabel: { // 坐标轴刻度标签相关配置
                            color: '#ffffff',
                            fontSize: 10,
                            margin: 20
                        }
                    },
                    yAxis: {
                        // name: '人',
                        minInterval: 1, // 最小单位是1
                        nameTextStyle: {
                            color: '#fff'
                        },
                        type: 'value', // value(数值轴,适用于连续数据)
                        axisTick: { // 坐标轴刻度相关配置
                            show: false  // 是否显示坐标轴刻度
                        },
                        axisLine: { // 坐标轴轴线相关配置
                            show: false,
                            lineStyle: { // 坐标轴轴线样式
                                color: '#2C6CA3' // 坐标轴轴线颜色
                            }
                        },
                        axisLabel: { // 坐标轴刻度标签相关配置
                            show: false,
                            color: '#ffffff',
                            fontSize: 10,
                        },
                        splitLine: { // 坐标轴在 grid 区域中的分隔线.
                            show: false,
                            lineStyle: { // 分割线配置
                                color: '#2C6CA3', // 分割线颜色
                                type: 'dashed' //虚线
                            }
                        }
                    },
                    series: [
                        // 中间的长方形柱状图(柱状图):bar
                        {
                            type: 'bar', // 柱状图
                            barWidth: 18, // 柱条的宽度,不设时自适应
                            barGap: '0%', // 柱子与柱子之间的距离
                            itemStyle: { // 图形样式
                                // color支持(rgb(255,255,255)、rgba(255,255,255,1)、#fff,也支持渐变色和纹理填充)
                                // 下面就是使用线性渐变
                                color: {
                                    "x": 0,
                                    "y": 0,
                                    "x2": 0,
                                    "y2": 1,
                                    "type": "linear",
                                    "global": false,
                                    "colorStops": [{
                                        "offset": 0, // 0%处的颜色
                                        "color": "#1B81F7"
                                    }, {
                                        "offset": 1, // 100%处的颜色
                                        "color": "#18AAF8"
                                    }]
                                }
                            },
                            label: { // 图形上的文本标签,可用于说明图像的一些数据信息,比如值,名称等
                                show: false, //是否显示标签
                                position: 'top', // 标签的位置(可以是绝对的像素值或者百分比['50%','50%',也可以是top,left等])
                                color: '#fff',
                                fontSize: 12,
                                formatter: (params) => {
                                    return params.value != 0 ? (params.value + '元') : ''
                                }
                            },
                            data: yData
                        },
                    ],
                    dataZoom: [
                        {
                            show: true,
                            height: 6, // 滚动条高度
                            xAxisIndex: [0],
                            bottom: 0, // 距离底部距离
                            showDetail: false,
                            showDataShadow: false,
                            start: 0, //初始化时，滑动条宽度开始标度
                            borderColor: "transparent",
                            textStyle: {
                                fontSize: 0,
                            },
                            endValue: 5, //从0开始的相当于5个(x轴展示的数量)
                            fillerColor: "#3E599C", // 滚动条颜色
                            borderColor: "rgba(17, 100, 210, 0.12)",
                            backgroundColor: '#142746',//两边未选中的滑动条区域的颜色

                            borderWidth: 0,
                            //                 handleIcon:
                            //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
                            //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
                            //   handleSize: "0%",
                            handleStyle: {
                                color: "#d3dee5",
                            },
                        },
                        {
                            type: "inside",  // 支持内部鼠标滚动平移
                            start: 0,
                            // end: 20,
                            startValue: 0, // 从头开始。
                            endValue: 5,  // 最多5个
                            zoomOnMouseWheel: false,  // 关闭滚轮缩放
                            moveOnMouseWheel: true, // 开启滚轮平移
                            moveOnMouseMove: true  // 鼠标移动能触发数据窗口平移
                        }

                    ],
                }
                // 使用刚指定的配置项和数据显示图表。
                myChart.clear();
                myChart.setOption(option);
            }).catch(err => {
                this.isLoading = false
            })

        },
        // 地图
        getMapEcharts(name) {

            let myChart1 = this.$echarts.init(document.getElementById("mapEchart"));
            let option = {
                series: [
                    {
                        type: "effectScatter",
                        coordinateSystem: "geo",
                        effectType: "ripple",
                        showEffectOn: "render",
                        // 散点样式
                        rippleEffect: {
                            period: 1,
                            scale: 1,
                            brushType: "fill",
                        },
                        // 散点大小
                        symbolSize: [10, 10],

                        hoverAnimation: true,
                        itemStyle: {
                            normal: {
                                color: "#FFBD56",
                                shadowBlur: 3,
                                shadowColor: "#fff",
                            },
                        },
                        zlevel: 1,
                        data: []
                    },
                ],

                // 地图配置
                geo: {
                    zoom: 1.2,
                    roam: true, //支持拖拽缩放
                    scaleLimit: {
                        //滚轮缩放的极限控制
                        min: 0.5, //缩放最小大小
                        max: 100, //缩放最大大小
                    },
                    top: '15%', // 距离顶部
                    map: "china",
                    label: {
                        // 通常状态下的样式
                        normal: {
                            show: true,
                            textStyle: {
                                color: "#fff",
                                fontSize: 6
                            },
                        },
                        // 鼠标放上去的样式
                        emphasis: {
                            textStyle: {
                                color: "#fff",
                                fontSize: 20
                            },
                        },
                    },
                    // 地图区域的样式设置
                    itemStyle: {
                        normal: {
                            borderColor: "#1964FF",
                            borderWidth: 2,
                            areaColor: {
                                type: "radial",
                                x: 0.5,
                                y: 0.5,
                                r: 0.8,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: "#022A6B", // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: "#022A6B", // 100% 处的颜色
                                    },
                                ],
                                globalCoord: false, // 缺省为 false
                            },
                            // shadowColor: "#DEEBFF",
                            // shadowOffsetX: -2,
                            // shadowOffsetY: 2,
                            // shadowBlur: 10,
                        },
                        // 鼠标放上去高亮的样式
                        emphasis: {
                            areaColor: "#389BB7",
                            borderWidth: 0,
                        },
                    },
                    regions: [{ // 点击选中样式
                        name: this.mapName ? this.mapName : '山东省', // 对应的是import "./china"  数据中的名称
                        itemStyle: {
                            normal: {
                                borderColor: "#389BB7", // 省份界线颜色
                                areaColor: '#389BB7', // 整个省份的颜色
                            },
                        },
                    }],
                },
            }
            // 地图注册，第一个参数的名字必须和option.geo.map一致
            this.$echarts.registerMap("china", zhongguo)
            myChart1.clear()
            myChart1.setOption(option);
            myChart1.on('click', e => {
                console.log(e);
                this.mapName = e.name
                // 重新获取地图数据（选中的板块变色）
                this.getMapData()
                this.getMapList(this.mapName)
                // this.getMapEcharts()
            });
            // myChart1.getZr().on('click', e => {
            //     console.log(e);
            //     this.getMapList(this.mapName)
            // });
        },
        // 重新获取地图数据（选中的板块变色）
        getMapData() {

            let myChart1 = this.$echarts.init(document.getElementById("mapEchart"));
            let option = {
                series: [
                    {
                        type: "effectScatter",
                        coordinateSystem: "geo",
                        effectType: "ripple",
                        showEffectOn: "render",
                        // 散点样式
                        rippleEffect: {
                            period: 1,
                            scale: 1,
                            brushType: "fill",
                        },
                        // 散点大小
                        symbolSize: [10, 10],

                        hoverAnimation: true,
                        itemStyle: {
                            normal: {
                                color: "#FFBD56",
                                shadowBlur: 3,
                                shadowColor: "#fff",
                            },
                        },
                        zlevel: 1,
                        data: []
                    },
                ],

                // 地图配置
                geo: {
                    zoom: 1.2,
                    roam: true, //支持拖拽缩放
                    scaleLimit: {
                        //滚轮缩放的极限控制
                        min: 0.5, //缩放最小大小
                        max: 100, //缩放最大大小
                    },
                    top: '15%', // 距离顶部
                    map: "china",
                    label: {
                        // 通常状态下的样式
                        normal: {
                            show: true,
                            textStyle: {
                                color: "#fff",
                                fontSize: 6
                            },
                        },
                        // 鼠标放上去的样式
                        emphasis: {
                            textStyle: {
                                color: "#fff",
                                fontSize: 20
                            },
                        },
                    },
                    // 地图区域的样式设置
                    itemStyle: {
                        normal: {
                            borderColor: "#1964FF",
                            borderWidth: 2,
                            areaColor: {
                                type: "radial",
                                x: 0.5,
                                y: 0.5,
                                r: 0.8,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: "#022A6B", // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: "#022A6B", // 100% 处的颜色
                                    },
                                ],
                                globalCoord: false, // 缺省为 false
                            },
                            // shadowColor: "#DEEBFF",
                            // shadowOffsetX: -2,
                            // shadowOffsetY: 2,
                            // shadowBlur: 10,
                        },
                        // 鼠标放上去高亮的样式
                        emphasis: {
                            areaColor: "#389BB7",
                            borderWidth: 0,
                        },
                    },
                    regions: [{ // 点击选中样式
                        name: this.mapName ? this.mapName : '山东省', // 对应的是import "./china"  数据中的名称
                        itemStyle: {
                            normal: {
                                borderColor: "#389BB7", // 省份界线颜色
                                areaColor: '#389BB7', // 整个省份的颜色
                            },
                        },
                    }],
                },
            }
            // 地图注册，第一个参数的名字必须和option.geo.map一致
            this.$echarts.registerMap("china", zhongguo)
            myChart1.clear()
            myChart1.setOption(option);
        },
        getMapList(name) {
            // 全国学校
            getNationwideSchool({}).then(res => {
                this.allSchool = res.nationwideSchool ? res.nationwideSchool : 0
            })
            this.isLoading = true
            this.cityIndex = []
            getSchoolInfoForMap({}).then(res => {
                this.isLoading = false
                for (let k in res.data) {
                    this.cityIndex.push(k)
                }
                // 能匹配的到
                console.log(name, 'name');
                console.log(this.cityIndex, 'this.cityIndex');
                let flag = this.cityIndex.some(v => name.indexOf(v) != -1)
                console.log(flag, 'flag');

                if (!flag) {
                    this.citySchoolList = []
                    this.cityName = name
                    this.citySchoolName = ''
                    this.mapObj = {}
                    return
                }
                for (let k in res.data) {
                    if (name.indexOf(k) != -1) {
                        this.citySchoolList = res.data[k]
                        this.cityName = k
                    }
                }
                this.citySchoolName = this.citySchoolList[0].schoolName
                this.mapObj = this.citySchoolList[0]

            }).catch(err => {
                this.isLoading = false
                console.log(err);
            })
            // console.log(list, 'list');
        },
    }
}
</script>
<style lang="scss" scoped>
.content-index {
    height: 100vh;
    background-color: #000511;

    .loading-box {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999999;
        background-color: rgba(0, 0, 0, 0.4);
        color: rgb(59, 230, 203);
    }

    .scale-wrap {
        background-image: url('../assets/DataSet/banner.png');
        background-size: cover;
    }

    .top {
        height: 135px;
        background: url(../assets/DataSet/top.png) no-repeat;
        background-size: 100% 100%;
        background-position: center;
        display: flex;
        justify-content: space-between;
        color: white;
        align-items: center;
        position: relative;

        .week {
            position: absolute;
            left: 18.5%;
            bottom: 27px;
            font-size: 12px;
            color: #C5E1FF;
        }

        .top-l {
            width: 20%;
            margin-left: 10%;
            font-size: 14px;
            color: #c5e1ff;
            line-height: 16px;
        }

        .top-cot {

            height: 48px;
            font-size: 30px;
            font-weight: 800;
            color: #FFFFFF;
            line-height: 48px;
            letter-spacing: 9px;
            text-align: center;
            margin-bottom: 20px;
            flex: 1;
        }

        .top-r {
            width: 20%;
            margin-right: 11%;
            font-size: 14px;
            color: #c5e1ff;
            line-height: 16px;
            text-align: right;
            display: flex;
            cursor: pointer;
            justify-content: flex-end;

            .r-box {
                display: flex;
                align-items: center;

                &:active {
                    opacity: .5;
                }

                img {
                    width: 22px;
                    height: 22px;
                    vertical-align: middle;

                }

                span {

                    line-height: 24px;
                }
            }


        }
    }

    .content {
        box-sizing: border-box;
        height: 100vh;
        display: flex;
        padding: 0 40px;

        .content-left {
            width: 24%;
            height: 100%;
            // background-color: red;

            .grow-box {
                width: 440px;
                height: 300px;
                margin-bottom: 20px;
                background: url("../assets/DataSet/pic_bg.png") no-repeat;
                background-size: 100% 100%;
                background-position: center;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;

                // 学校增长title+弹窗
                .school-grow {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    display: flex;
                    height: 30px;
                    margin-top: 3px;
                    align-items: center;
                    font-size: 14px;
                    color: #fff;
                    cursor: pointer;

                    .s-g-bar {
                        width: 0;
                        border: 6px solid;
                        border-color: #fff transparent transparent transparent;
                        margin: 10px 0 0 15px;
                    }

                    .g-content {
                        position: absolute;
                        top: 35px;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 187px;
                        height: 76px;
                        background-color: #11244F;
                        border: 1px solid #3E599C;
                        z-index: 1;

                        .g-c-i {
                            height: 36px;
                            line-height: 36px;
                            color: #32c5ff;
                            font-size: 14px;
                            text-align: center;
                        }

                        .item-ac {
                            background: linear-gradient(270deg, #112F51 0%, #006398 56%, #112F51 100%);
                            color: #fff;
                        }
                    }


                }

                .year-box {
                    position: absolute;
                    top: 9px;
                    right: 29px;
                    display: flex;
                    font-size: 12px;
                    color: #fff;


                    .year-item {
                        width: 24px;
                        height: 24px;
                        border: 2px solid #0D53B7;
                        text-align: center;
                        line-height: 23px;
                        box-sizing: border-box;
                        cursor: pointer;

                        &:nth-child(1) {
                            border-right: 0;
                            border-radius: 4px 0 0 4px;
                        }

                        &:nth-child(3) {
                            border-left: 0;
                            border-radius: 4px 0 0 4px;
                        }
                    }

                    .year-ac {
                        box-sizing: border-box;
                        border-radius: 4px !important;
                        border: 1px solid #41FFFF;
                        background: linear-gradient(113deg, rgba(64, 237, 255, 0.55) 0%, rgba(61, 127, 255, 0.3502) 100%);
                        border-right: 1px solid #41FFFF !important;
                        border-left: 1px solid #41FFFF !important;
                    }
                }

                // 校收收访问统计
                .xss-grow {
                    display: flex;
                    height: 30px;
                    margin-top: 3px;
                    align-items: center;

                    .school-select {
                        position: absolute;
                        top: 9px;
                        left: 10px;
                        text-align: center;
                        width: 102px;
                        height: 24px;
                        line-height: 24px;
                        line-height: 24px;
                        border: 1px solid #3E599C;
                        border-radius: 4px;
                        box-sizing: border-box;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 12px;
                        color: #fff;
                        cursor: pointer;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;

                        .s-g-bar {
                            width: 0;
                            border: 6px solid;
                            border-color: #fff transparent transparent transparent;
                            margin: 10px 0 0 15px;
                        }
                    }

                    .school-alert {
                        position: absolute;
                        top: 36px;
                        left: 0;
                        width: 122px;
                        border: 1px solid #3E599C;
                        max-height: 240px;
                        overflow: auto;

                        &::-webkit-scrollbar {
                            /*滚动条整体样式*/
                            width: 5px;
                            /*高宽分别对应横竖滚动条的尺寸*/
                            height: 1px;
                        }

                        &::-webkit-scrollbar-thumb {
                            /*滚动条里面小方块*/
                            border-radius: 3px;
                            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                            background: #3E599C;
                        }

                        &::-webkit-scrollbar-track {
                            /*滚动条里面轨道*/
                            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                            border-radius: 10px;
                            background: transparent;
                        }

                        .a-item {
                            height: 36px;
                            font-size: 12px;
                            color: #32c5ff;
                            text-align: center;
                            line-height: 36px;
                            background: #11244F;
                            cursor: pointer;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }

                        .a-item-ac {
                            background: linear-gradient(270deg, #112F51 0%, #006398 56%, #112F51 100%);
                            color: #fff;
                        }
                    }

                    .xss-name {
                        font-size: 14px;
                        color: #fff;
                        font-weight: 700;
                        position: absolute;
                        top: 8px;
                        left: 50%;
                        transform: translateX(-50%);
                        display: flex;
                        align-items: center;
                        cursor: pointer;

                        .s-g-bar {
                            width: 0;
                            border: 6px solid;
                            border-color: #fff transparent transparent transparent;
                            margin: 10px 0 0 15px;
                        }

                        .xss-name-tit {
                            position: absolute;
                            top: 32px;
                            left: 50%;
                            transform: translateX(-50%);
                            width: 187px;
                            height: 255px;

                            background-color: #11244F;
                            border: 1px solid #3E599C;

                            z-index: 1;

                            .g-c-i {
                                height: 36px;
                                line-height: 36px;
                                color: #32c5ff;
                                font-size: 14px;
                                text-align: center;
                                cursor: pointer;
                            }

                            .item-ac {
                                background: linear-gradient(270deg, #112F51 0%, #006398 56%, #112F51 100%);
                                color: #fff;
                            }
                        }
                    }

                    .mini-app {
                        position: absolute;
                        top: 9px;
                        right: 10px;
                        display: flex;
                        width: 102px;
                        height: 24px;
                        border-radius: 4px;
                        border: 1px solid #3E599C;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 12px;
                        color: #fff;
                        cursor: pointer;
                        padding: 0 15px 0 5px;
                        box-sizing: border-box;

                        .s-g-bar {
                            width: 0;
                            border: 4px solid;
                            border-color: #fff transparent transparent transparent;
                            margin: 10px 0 4px 0;
                        }

                        .mini-alert {
                            position: absolute;
                            top: 25px;
                            left: 0;
                            width: 120px;
                            height: 144px;
                            border: 1px solid #3E599C;
                            z-index: 99;

                            .a-item {
                                height: 36px;
                                font-size: 12px;
                                color: #32c5ff;
                                text-align: center;
                                line-height: 36px;
                                background: #11244F;
                                cursor: pointer;
                            }

                            .a-item-ac {
                                background: linear-gradient(270deg, #112F51 0%, #006398 56%, #112F51 100%);
                                color: #fff;
                            }
                        }
                    }

                    .year-box {
                        position: absolute;
                        top: 9px;
                        right: 24px;
                        display: flex;
                        font-size: 12px;
                        color: #fff;


                        .year-item {
                            width: 24px;
                            height: 24px;
                            border: 2px solid #0D53B7;
                            text-align: center;
                            line-height: 23px;
                            box-sizing: border-box;
                            cursor: pointer;

                            &:nth-child(1) {
                                border-right: 0;
                                border-radius: 4px 0 0 4px;
                            }

                            &:nth-child(3) {
                                border-left: 0;
                                border-radius: 4px 0 0 4px;
                            }
                        }

                        .year-ac {
                            box-sizing: border-box;
                            border-radius: 4px !important;
                            border: 1px solid #41FFFF;
                            background: linear-gradient(113deg, rgba(64, 237, 255, 0.55) 0%, rgba(61, 127, 255, 0.3502) 100%);
                            border-right: 1px solid #41FFFF !important;
                            border-left: 1px solid #41FFFF !important;
                        }
                    }

                    .year-box-hy {
                        top: 43px;
                    }
                }

                .is-g {
                    z-index: -1;
                }
            }
        }

        .content-center {
            width: 52%;
            height: 100%;
            // background-color: blue;

            .c-top {
                height: 530px;

                // background-color: pink;
                .c-t-box {
                    display: flex;
                    justify-content: space-between;
                    padding: 0 47px;
                    box-sizing: border-box;

                    .c-t-rho {
                        position: relative;
                        width: 130px;
                        height: 130px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .rho-num {
                            color: #fff;
                            font-size: 24px;
                            font-weight: 700;
                            text-align: center;
                        }

                        .rho-name {
                            text-align: center;
                            white-space: nowrap;
                            position: absolute;
                            bottom: -20px;
                            left: 50%;
                            transform: translateX(-50%);
                            color: #fff;
                            font-size: 18px;

                            .rho-tip {
                                cursor: pointer;
                                display: flex;
                                font-size: 12px;
                                position: absolute;
                                bottom: -20px;
                                left: 50%;
                                transform: translateX(-50%);

                                .s-g-bar {
                                    width: 0;
                                    border: 5px solid;
                                    border-color: #fff transparent transparent transparent;
                                    margin: 8px 0 0 5px;
                                }

                                .school-alert {
                                    position: absolute;
                                    top: 25px;
                                    left: 50%;
                                    transform: translate(-50%);
                                    width: 122px;
                                    border: 1px solid #3E599C;
                                    z-index: 10;
                                    max-height: 240px;
                                    overflow: auto;

                                    &::-webkit-scrollbar {
                                        /*滚动条整体样式*/
                                        width: 5px;
                                        /*高宽分别对应横竖滚动条的尺寸*/
                                        height: 1px;
                                    }

                                    &::-webkit-scrollbar-thumb {
                                        /*滚动条里面小方块*/
                                        border-radius: 3px;
                                        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                                        background: #3E599C;
                                    }

                                    &::-webkit-scrollbar-track {
                                        /*滚动条里面轨道*/
                                        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                                        border-radius: 10px;
                                        background: transparent;
                                    }

                                    .a-item {
                                        height: 36px;
                                        font-size: 12px;
                                        color: #32c5ff;
                                        text-align: center;
                                        line-height: 36px;
                                        background: #11244F;
                                        cursor: pointer;
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                    }

                                    .a-item-ac {
                                        background: linear-gradient(270deg, #112F51 0%, #006398 56%, #112F51 100%);
                                        color: #fff;
                                    }
                                }

                            }


                        }
                    }
                }

                .work-list {
                    height: 226px;
                    margin-top: 71px;
                    display: flex;
                    align-items: center;
                    justify-content: space-evenly;

                    .work-item {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        flex: 1;

                        .work-txt {
                            white-space: nowrap;
                            position: absolute;
                            bottom: 60px;
                            left: 50%;
                            transform: translateX(-50%);
                            font-size: 16px;
                            color: #00FFF4;
                            font-weight: 700;
                        }
                    }

                    .work {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 132px;
                        height: 80px;
                        background: url('../assets/DataSet/c-bor.png') no-repeat;
                        background-size: cover;
                        margin: 0 20px 14px 0;
                        font-size: 14px;
                        color: #fff;
                    }

                    // .work-item {
                    //     position: relative;
                    //     flex-shrink: 0;
                    //     width: 98px;
                    //     height: 162px;
                    //     display: flex;
                    //     flex-direction: column;
                    //     justify-content: space-evenly;
                    //     align-items: center;
                    //     z-index: -1;
                    // }
                    .w-center {
                        width: 214px;
                        height: 285px;
                        margin-bottom: 50px;
                    }
                }
            }

            .c-bottom {
                display: flex;
                justify-content: center;
                height: 360px;
                // background-color: purple;

                .bottom-box {
                    position: relative;
                    width: 430px;
                    height: 410px;
                    border: 4px solid #3E599C;
                    border-radius: 14px;

                    &:nth-child(1) {
                        margin-right: 20px;
                    }

                    .pay-box {
                        .pay-top {
                            cursor: pointer;

                            .g-content {
                                position: absolute;
                                top: 57px;
                                left: 50%;
                                transform: translateX(-50%);
                                width: 187px;
                                height: 76px;
                                background-color: #11244F;
                                border: 1px solid #3E599C;
                                z-index: 1;

                                .g-c-i {
                                    height: 36px;
                                    line-height: 36px;
                                    color: #fff;
                                    font-size: 14px;
                                    text-align: center;
                                }

                                .item-ac {
                                    background: linear-gradient(270deg, #112F51 0%, #006398 56%, #112F51 100%);
                                }
                            }

                            .pay-tit {
                                display: flex;
                                align-items: center;
                                position: absolute;
                                top: 24px;
                                left: 50%;
                                transform: translateX(-50%);
                                font-size: 16px;
                                color: #32C5FF;
                                font-weight: 700;

                                .s-g-bar {
                                    width: 0;
                                    border: 6px solid;
                                    border-color: #31C2FB transparent transparent transparent;
                                    margin: 10px 0 0 15px;
                                }
                            }
                        }
                    }

                    .school-select {
                        position: absolute;
                        top: 68px;
                        left: 24px;
                        text-align: center;
                        width: 102px;
                        height: 24px;
                        line-height: 24px;
                        border: 1px solid #3E599C;
                        border-radius: 4px;
                        box-sizing: border-box;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 12px;
                        color: #fff;
                        cursor: pointer;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;

                        .s-g-bar {
                            width: 0;
                            border: 6px solid;
                            border-color: #fff transparent transparent transparent;
                            margin: 10px 0 0 15px;
                        }
                    }

                    .school-alert {
                        position: absolute;
                        top: 95px;
                        left: 15px;
                        width: 122px;
                        border: 1px solid #3E599C;
                        max-height: 240px;
                        overflow: auto;

                        &::-webkit-scrollbar {
                            /*滚动条整体样式*/
                            width: 5px;
                            /*高宽分别对应横竖滚动条的尺寸*/
                            height: 1px;
                        }

                        &::-webkit-scrollbar-thumb {
                            /*滚动条里面小方块*/
                            border-radius: 3px;
                            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                            background: #3E599C;
                        }

                        &::-webkit-scrollbar-track {
                            /*滚动条里面轨道*/
                            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                            border-radius: 10px;
                            background: transparent;
                        }

                        .a-item {
                            height: 36px;
                            font-size: 12px;
                            color: #32c5ff;
                            text-align: center;
                            line-height: 36px;
                            background: #11244F;
                            cursor: pointer;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }

                        .a-item-ac {
                            background: linear-gradient(270deg, #112F51 0%, #006398 56%, #112F51 100%);
                            color: #fff;
                        }
                    }

                    .year-box {
                        position: absolute;
                        top: 68px;
                        right: 24px;
                        display: flex;
                        font-size: 12px;
                        color: #fff;


                        .year-item {
                            width: 24px;
                            height: 24px;
                            border: 2px solid #0D53B7;
                            text-align: center;
                            line-height: 23px;
                            box-sizing: border-box;
                            cursor: pointer;

                            &:nth-child(1) {
                                border-right: 0;
                                border-radius: 4px 0 0 4px;
                            }

                            &:nth-child(3) {
                                border-left: 0;
                                border-radius: 4px 0 0 4px;
                            }
                        }

                        .year-ac {
                            box-sizing: border-box;
                            border-radius: 4px !important;
                            border: 1px solid #41FFFF;
                            background: linear-gradient(113deg, rgba(64, 237, 255, 0.55) 0%, rgba(61, 127, 255, 0.3502) 100%);
                            border-right: 1px solid #41FFFF !important;
                            border-left: 1px solid #41FFFF !important;
                        }
                    }

                    .is-g {
                        z-index: -1;
                    }
                }
            }
        }

        .content-right {
            width: 24%;
            height: 100%;
            // background-color: yellow;

            .map-box {
                position: relative;
                width: 440px;
                height: 531px;
                margin-bottom: 20px;
                background: url("../assets/DataSet/map.png") no-repeat;
                background-size: 100% 100%;
                background-position: center;

                .map-tit {
                    position: absolute;
                    top: 9px;
                    font-size: 14px;
                    color: #fff;
                    font-weight: 700;
                    left: 50%;
                    transform: translateX(-50%);
                }

                .map-info {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 145px;
                    font-size: 16px;
                    // background-color: pink;
                    padding: 0 0 0 22px;
                    box-sizing: border-box;

                    .info-area {
                        // display: flex;
                        color: #fff;
                        font-weight: 700;

                        .school-alert {
                            position: absolute;
                            top: 26px;
                            left: 50px;
                            width: 187px;
                            border: 1px solid #3E599C;
                            z-index: 10;
                            max-height: 240px;
                            overflow: auto;

                            &::-webkit-scrollbar {
                                /*滚动条整体样式*/
                                width: 5px;
                                /*高宽分别对应横竖滚动条的尺寸*/
                                height: 1px;
                            }

                            &::-webkit-scrollbar-thumb {
                                /*滚动条里面小方块*/
                                border-radius: 3px;
                                -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                                background: #3E599C;
                            }

                            &::-webkit-scrollbar-track {
                                /*滚动条里面轨道*/
                                -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                                border-radius: 10px;
                                background: transparent;
                            }

                            .a-item {
                                height: 36px;
                                font-size: 12px;
                                color: #32c5ff;
                                text-align: center;
                                line-height: 36px;
                                background: #11244F;
                                cursor: pointer;
                            }

                            .a-item-ac {
                                background: linear-gradient(270deg, #112F51 0%, #006398 56%, #112F51 100%);
                                color: #fff;
                            }
                        }

                        .s-g-bar {
                            width: 0;
                            border: 6px solid;
                            border-color: #31C2FB transparent transparent transparent;
                            margin: 10px 0 0 -10px;
                        }
                    }
                }
            }

            .ims-box {
                position: relative;
                width: 440px;
                height: 377px;
                margin-top: 20px;
                background: url("../assets/DataSet/ims.png") no-repeat;
                background-size: 100% 100%;
                background-position: center;
                overflow: hidden;

                .ims-tit {
                    position: absolute;
                    top: 9px;
                    font-size: 14px;
                    color: #fff;
                    font-weight: 700;
                    left: 50%;
                    transform: translateX(-50%);
                }

                .ims-wrap {
                    display: flex;
                    justify-content: space-between;
                    margin: 61px 18px 32px;

                    .ims-top-box {
                        display: flex;

                        .ims {
                            font-size: 14px;
                            color: #fff;
                            margin-bottom: 26px;
                            white-space: nowrap;
                        }

                        .ims-num {
                            font-size: 36px;
                            color: #129BFF;
                            white-space: nowrap;
                        }

                        .i-bar {
                            width: 1px;
                            height: 50px;
                            background: #0F1A61;
                            margin: 0 10px;
                        }
                    }


                }

                .ims-bottom {
                    width: 403px;
                    height: 180px;
                    background: url("../assets/DataSet/ims-b.png") no-repeat;
                    background-size: 100% 100%;
                    background-position: center;
                    margin: 0 auto;
                    font-size: 14px;
                    overflow: hidden;
                    padding-bottom: 20px;
                    box-sizing: border-box;

                    .i-b-t {
                        font-size: 16px;
                        color: #fff;
                        font-weight: 700;
                        margin: 15px 0 0 10px;
                    }
                }
            }
        }


    }

    .tip-box {
        height: 280px;
        color: #fff;
        margin: 50px 15px 0 25px;
        padding-top: 12px;
        box-sizing: border-box;
        overflow: hidden;

        &::-webkit-scrollbar {
            /*滚动条整体样式*/
            width: 5px;
            /*高宽分别对应横竖滚动条的尺寸*/
            height: 1px;
        }

        &::-webkit-scrollbar-thumb {
            /*滚动条里面小方块*/
            border-radius: 3px;
            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            background: #3E599C;
        }

        &::-webkit-scrollbar-track {
            /*滚动条里面轨道*/
            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            border-radius: 10px;
            background: transparent;
        }

        .tip {
            width: 60px;
            height: 16px;
            line-height: 16px;
            background: #FFB82E;
            border-radius: 20px;
            text-align: center;
            font-size: 12px;
            margin-right: 10px;
        }

        .tip-ysj {
            width: 46px;
            background: #FF702E;
        }

        .tip-shj {
            width: 46px;
            background: #542EFF;
        }

        .tip-szgh {
            background: #42A3FF;
        }

        .tip-cfj {
            width: 46px;
            background: #00D6CD;
        }

        .tip-xyj {
            width: 46px;
            background: #00D43C;
        }
    }

    .anim {
        transition: all 0.5s ease-in;
        margin-top: -41px;
    }

}
</style>