<template>
  <div class="navbar">
    <!-- <el-button style="position: absolute;top: 50%;transform: translateY(-50%);right:300px;" type="primary"
      @click="dataSetClick" size="mini">可视</el-button> -->
    <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container"
      @toggleClick="toggleSideBar" />

    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" v-if="!topNav" />
    <top-nav id="topmenu-container" class="topmenu-container" v-if="topNav" />

    <div class="right-menu">
      <template v-if="personType == 1">
        <img v-if="$store.state.user.userType != 2" src="../../assets/DataSet/largeIcon.png" title="数据大屏"
          @click="monitors" class="user-avatars">
      </template>
      <template v-if="personType == 3">
        <img v-hasPermi="['school:dataSet:view']" src="../../assets/DataSet/largeIcon.png" title="数据大屏" @click="monitors"
          class="user-avatars">
      </template>
      <!-- <img v-if="$store.state.user.userType != 2" src="../../assets/DataSet/largeIcon.png" title="数据大屏"
          @click="monitors" class="user-avatars"> -->
      <template v-if="device !== 'mobile'">

        <screenfull id="screenfull" class="right-menu-item hover-effect" />

        <!-- <el-tooltip content="布局大小" effect="dark" placement="bottom">
          <size-select id="size-select" class="right-menu-item hover-effect" />
        </el-tooltip> -->

      </template>

      <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
        <div class="avatar-wrapper">

          <img :src="avatar" class="user-avatar">
          <span>{{ name }}</span>
          <i class="el-icon-arrow-down" />
          <el-dropdown-menu slot="dropdown">
            <router-link to="/user/profile">
              <el-dropdown-item>个人中心</el-dropdown-item>
            </router-link>
            <el-dropdown-item @click.native="setting = true">
              <span>布局设置</span>
            </el-dropdown-item>
            <el-dropdown-item divided @click.native="logout">
              <span>退出登录</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </div>

      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import TopNav from '@/components/TopNav'
import Hamburger from '@/components/Hamburger'
import Screenfull from '@/components/Screenfull'
import SizeSelect from '@/components/SizeSelect'
import Search from '@/components/HeaderSearch'
import RuoYiGit from '@/components/RuoYi/Git'
import RuoYiDoc from '@/components/RuoYi/Doc'

export default {
  data() {
    return {
      avatarImg: ''
    }
  },
  components: {
    Breadcrumb,
    TopNav,
    Hamburger,
    Screenfull,
    SizeSelect,
    Search,
    RuoYiGit,
    RuoYiDoc,
    personType: '',
    dataView: '' // 是否收费数据大屏
  },
  created() {
    this.personType = localStorage.getItem('personType')
    this.dataView = localStorage.getItem('dataView')
    this.avatarImg = localStorage.getItem('avatar')
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'avatar',
      'device',
      'name',

    ]),
    setting: {
      get() {
        return this.$store.state.settings.showSettings
      },
      set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'showSettings',
          value: val
        })
      }
    },
    topNav: {
      get() {
        return this.$store.state.settings.topNav
      }
    }
  },
  methods: {
    dataSetClick() {
      let routeData = this.$router.resolve({ path: '/DataSet' });
      window.open(routeData.href, '_blank')
    },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    async logout() {
      this.$confirm('确定注销并退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('LogOut').then(() => {
          // if (this.$store.getters.personType == 3) {
          // if (localStorage.getItem('personType') == 3) {
          //   location.href = '/school';
          // } else {
          location.href = '/';
          // }
        })
      }).catch(() => {
        console.log(this.$store.getters);
      });
    },
    monitors() {
      // if()dataView
      let routeData = this.$router.resolve({ path: '/DataSet' });
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, .08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, .025)
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .topmenu-container {
    position: absolute;
    left: 50px;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .user-avatars {
      width: 32px;
      height: 32px;
      margin: 7px;
      cursor: pointer;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;

        &:hover {
          background: rgba(0, 0, 0, .025)
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;
        display: flex;
        align-items: center;

        .user-avatar {
          cursor: pointer;
          width: 32px;
          height: 32px;
          border-radius: 50%;
        }

        span {
          display: inline-block;
          height: 100%;
          padding: 0 10px;
          text-align: center;
          line-height: 40px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;

        }
      }
    }
  }
}
</style>
