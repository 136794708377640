<template>
  <div class="screen-wrapper" ref="screenWrapper" :style="wrapperStyle">
    <div class="top" color="transparent">
      <div class="week">{{ weeks }}</div>
      <div class="top-l">
        {{ date.year }}-{{ date.month }}-{{ date.date }} {{ date.hours }}:{{
          date.minutes
        }}
      </div>
      <div class="top-cot">{{ schoolName }}</div>
      <div class="top-r" @click="getInit">
        <div class="r-box">
          <img src="../assets/DataSet/refresh.png" alt=""> <span>刷新数据</span>
        </div>
      </div>
    </div>

    <div class="box">
      <!-- 左边数据 -->
      <div class="box-left">
        <!-- <div  v-show="tr.length >= 1"> -->
        <div class="box-left-teacher">
          <div class="box-left-teacher-top">师资数据</div>
          <div id="teacherData" style="
              width: 440px;
              height: 450px;
              padding: 0 5px 44px 0;
              box-sizing: border-box;
            "></div>
        </div>
        <!-- </div> -->
        <!-- <div class="bottom-boxs" v-show="tr.length < 1">


          <img class="imgs" src="../assets/DataSet/noData.png" alt="">
        </div> -->

        <div class="box-left-personnel">
          <div class="box-left-personnel-top">全校请假人数统计</div>
          <div class="box-left-personnel-center">
            <div class="box-img">
              <img src="../assets/DataSet/percentage.png" alt="" class="img-s" />
              <div class="box-left-personnel-center-left" id="percentage"></div>
            </div>
            <div class="box-left-personnel-center-right">
              <div class="box-left-personnel-center-right-one">
                {{ NumberOfLeave ? NumberOfLeave : "0" }} <span>人</span>
              </div>
              <div class="box-left-personnel-center-right-Two">
                <span></span>
                <div>今日请假人数</div>
              </div>
            </div>
          </div>
          <div class="year-box">
            <div @click="dateClick(v, i)" class="year-item" :class="v == activeDate ? 'year-ac' : ''"
              v-for="(v, i) in dateList" :key="i">
              {{ v }}
            </div>
          </div>
          <div id="leave" style="
              width: 98%;
              height: 300px;
              padding: 0 5px 44px 0;
              box-sizing: border-box;
            "></div>
        </div>
      </div>
      <!-- 中间数据 -->
      <div class="box-cont">
        <div class="box-cont-top">
          <div class="box-cont-top-data">
            <div class="box-cont-number">
              <div class="numberBox">
                <div class="numberBoxImg">
                  <div>
                    {{
                      studentCountVo.teacherCount
                      ? studentCountVo.teacherCount
                      : "0"
                    }}
                    <br />
                    <span>人</span>
                  </div>
                </div>
                <div class="numberBoxText">教职工人数</div>
              </div>

              <div class="numberBox">
                <div class="numberBoxImg1">
                  <div>
                    {{
                      studentCountVo.classCount
                      ? studentCountVo.classCount
                      : "0"
                    }}
                    <br />
                    <span>个</span>
                  </div>
                </div>
                <div class="numberBoxText">班级个数</div>
              </div>
              <div class="numberBox">
                <div class="numberBoxImg2">
                  <div>
                    {{
                      studentCountVo.bindFaceCount
                      ? studentCountVo.bindFaceCount
                      : "0"
                    }}
                    <br />
                    <span>人</span>
                  </div>
                </div>
                <div class="numberBoxText">已开通人脸</div>
              </div>
              <div class="numberBox">
                <div class="numberBoxImg3">
                  <div>
                    {{ FaceDevice }} <br />
                    <span>台</span>
                  </div>
                </div>
                <div class="numberBoxText">人脸设备数量</div>
                <div class="equipment">
                  <div @click="equipmentBoxShow">
                    <span>{{
                      equipmentData[equipmentIndex].typeName
                      ? equipmentData[equipmentIndex].typeName
                      : ""
                    }}
                    </span>
                    <img src="../assets/DataSet/down.png" alt="" />
                  </div>

                  <div class="bottom-box-zhe" v-show="equipmentShow" id="equipments">
                    <div class="li" :class="index == equipmentIndex ? 'active' : ''"
                      v-for="(item, index) in equipmentData" :key="index" @click="equipmentAdd(index)">
                      {{ item.typeName }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="box-cont-list">
            <div class="one">
              <div class="one-number">
                {{ studentCountVo.manCount ? studentCountVo.manCount : "0" }}
                <span>人</span>
              </div>
              <div class="one-headline">男生人数</div>
            </div>
            <div class="two">
              <div class="two-number">
                {{
                  studentCountVo.womanCount ? studentCountVo.womanCount : "0"
                }}
                <span>人</span>
              </div>
              <div class="two-headline">女生人数</div>
            </div>

            <div class="cont">
              <div class="cont-number">
                {{
                  studentCountVo.allStudentCount
                  ? studentCountVo.allStudentCount
                  : "0"
                }}
                <br /><span>人</span>
              </div>
              <div class="cont-headline">学生总人数</div>
            </div>

            <div class="one">
              <div class="one-number">
                {{
                  studentCountVo.attendCount ? studentCountVo.attendCount : "0"
                }}
                <span>人</span>
              </div>
              <div class="one-headline">走读生人数</div>
            </div>
            <div class="two">
              <div class="two-number">
                {{
                  studentCountVo.boardCount ? studentCountVo.boardCount : "0"
                }}
                <span>人</span>
              </div>
              <div class="two-headline">住宿生人数</div>
            </div>
          </div>
        </div>
        <div class="box-cont-bottom">
          <!-- 智能公话使用统计 -->
          <div class="bottom-box" :class="flag == -1 ? 'bottom-boxss' : ''">
            <!-- 标题 -->
            <div class="bottom-box-top">
              <div @click="bottomBoxShows">
                <span>{{
                  bottomBoxData[bottomBoxIndex]
                  ? bottomBoxData[bottomBoxIndex].statisticsName
                  : ""
                }}
                </span>
                <img src="../assets/DataSet/down.png" alt="" />
              </div>
              <div class="bottom-box-zhe" v-show="bottomBoxShow" id="smart">
                <div class="li" :class="index == bottomBoxIndex ? 'active' : ''" v-for="(item, index) in bottomBoxData"
                  :key="index" @click="bottomBoxDataAdd(index)">
                  {{ item.statisticsName ? item.statisticsName : "" }}
                </div>
              </div>
            </div>
            <!-- 日月选择 -->
            <div class="year-box">
              <div @click.stop="dateClicks(v, i)" class="year-item" :class="v == bottomBoxDataTime ? 'year-ac' : ''"
                v-for="(v, i) in dateList" :key="i">
                {{ v }}
              </div>
            </div>
            <!--  夙诺
            <div class="year-box" v-show="bottomBoxIndex ==7">
              <el-date-picker style=" color: #18fefe;" v-model="timeVal" type="date"
              placeholder="选择日期"  popper-class="date-picker">
          </el-date-picker>
            </div> -->

            <!-- <span style="color: red">{{ this.flag == -1 }}</span> -->
            <div id="Phone" v-show="bottomBoxData[bottomBoxIndex].type == 1" :class="bottomBoxShow ? 'is-g' : ''" style="
                width: 100% !important;
                height: 300px;
                box-sizing: border-box;
                margin-top: 30px;
              "></div>
            <!-- <div id="Book" style="width: 410px; height: 300px; box-sizing: border-box" ></div> -->
            <div id="Books" v-show="bottomBoxData[bottomBoxIndex].type == 2" :class="bottomBoxShow ? 'is-g' : ''" style="
                width: 100% !important;
                height: 300px;
                box-sizing: border-box;
                margin-top: 30px;
              "></div>
            <div id="laundry" :class="bottomBoxShow ? 'is-g' : ''" v-show="bottomBoxData[bottomBoxIndex].type == 5 ||
              bottomBoxData[bottomBoxIndex].type == 6
              " style="
                width: 100% !important;
                height: 300px;
                box-sizing: border-box;
                margin-top: 30px;
              "></div>
            <div id="entrance" :class="bottomBoxShow ? 'is-g' : ''" v-show="bottomBoxData[bottomBoxIndex].type == 4" style="
                width: 100% !important;
                height: 300px;
                box-sizing: border-box;
                margin-top: 30px;
              "></div>

            <div id="dinner" :class="bottomBoxShow ? 'is-g' : ''" v-show="bottomBoxData[bottomBoxIndex].type == 3" style="
                width: 100% !important;
                height: 300px;
                box-sizing: border-box;
                margin-top: 30px;
              "></div>
          </div>

          <!-- 宿舍考勤统计 -->
          <div class="bottom-box-right" v-if="flag != -1">
            <div class="bottom-box-top">
              <div @click="checkBoxShow">
                <span>{{ checkData[checkIndex].statisticsName }} </span>
                <img src="../assets/DataSet/down.png" alt="" />
              </div>
              <div class="bottom-box-zhe" v-show="checkShow" id="smarts">
                <div class="li" :class="index == checkIndex ? 'active' : ''" v-for="(item, index) in checkData"
                  :key="index" @click="checkIndexAdd(index)">
                  {{ item.statisticsName }}
                </div>
              </div>
            </div>
            <div class="year-boxs">
              <el-date-picker style="color: #18fefe" v-model="timeVal" type="date" placeholder="选择日期" :clearable="false"
                popper-class="dark_form" @change="changeTime">
              </el-date-picker>
            </div>
            <div id="leavings" :class="bottomBoxShow ? 'is-g' : ''" style="
                width: 410px;
                height: 305px;
                box-sizing: border-box;
                margin-top: 30px;
              "></div>
          </div>
        </div>
      </div>
      <!-- 右边数据 -->
      <div class="box-right">
        <div class="map-box">
          <div class="map-box-top">学生人数汇总</div>

          <div id="student" style="width: 410px; height: 400px; box-sizing: border-box"></div>
        </div>
        <!-- <div class="map-box">
          <div class="map-box-top">
            学生数据增长分析
          </div>
          <div class="bottom-box-left">
            <span>{{ studentAllData[studentAllIndex] }} </span>
            <img
              src="../assets/DataSet/down.png"
              alt=""
              @click="studentAllBoxShow"
            />
            <div class="bottom-box-zhe" v-show="studentAllShow" id="studentss">
              <div
                class="li"
                :class="index == studentAllIndex ? 'active' : ''"
                v-for="(item, index) in studentAllData"
                :key="index"
                @click="studentAllAdd(index)"
              >
                {{ item }}
              </div>
            </div>


          </div>
          <div
          id="studentAll"

          style="width: 410px; height: 280px; box-sizing: border-box"
        ></div>

        </div> -->
        <div class="map-box">
          <!-- 头部 -->
          <div class="bottom-box-top">
            <div @click="informBoxShow">
              <span>{{ informData[informIndex] }} </span>
              <img src="../assets/DataSet/down.png" alt="" />
            </div>
            <div class="bottom-box-zhe" v-show="informShow" id="smartss">
              <div class="li" :class="index == informIndex ? 'active' : ''" v-for="(item, index) in informData"
                :key="index" @click="informAdd(index)">
                {{ item }}
              </div>
            </div>
          </div>

          <!-- 列表 -->
<!--          <div class="inform-content">-->
<!--            <div class="i-list" v-for="(item, index) in tabList" :key="index">-->
<!--              <div class="i-item" @click="openDetail(item)">-->
<!--                <div class="title">{{ item.title ? item.title : "" }}</div>-->
<!--                <div class="time">-->
<!--                  {{ item.createTime ? item.createTime : "" }}-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
          <div  class="tip-box">
            <div :class="{ anim: animate == true }" @mouseenter="handleMouseEnter"
                 @mouseleave="handleMouseLeave">
              <div v-for="(item, index) in tabList" class="i-list" @click="openDetail(item)"
                   style="display: flex;align-items: center;justify-content: space-between;margin-bottom: 22px;">

                  <div style="display: flex;font-size: 14px;">
                    <div class="title">{{ item.title ? item.title : "" }}</div>
                  </div>
                  <div class="time">{{ item.createTime ? item.createTime : "" }}</div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="dialog" v-show="open">
      <div class="dialog-box">
        <div class="close" @click="close">
          <img src="../assets/DataSet/close.png" alt="" />
        </div>
        <div class="dialog-box-top">
          {{ form.title }}
        </div>
        <div class="dialog-box-subheading">
          <div class="dialog-box-subheading-type">
            {{ form.type }}
          </div>
          <div class="dialog-box-subheading-issuer">
            发布人: {{ form.createByName }}
          </div>
          <div class="dialog-box-subheading-time">
            时间:{{ form.createTime }}
          </div>
        </div>

        <div class="dialog-box-cont" v-html="form.content"></div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * 防抖函数
 * @param {T} fn
 * @param {number} delay
 * @return
 */
function debounce(fn, delay) {
  let timer = null;
  return function (...args) {
    timer = setTimeout(
      () => {
        typeof fn === "function" && fn.apply(null, args);
        clearTimeout(timer);
      },
      delay > 0 ? delay : 100
    );
  };
}
import * as echarts from "echarts";
import {
  getSchoolRelease,
  getStudentCount,
  getStudentAttend,
  getBusinessType,
  getFaceDeviceCount,
  schoolLargeScreen,
  getMonthLeaveStatis,
  getSchoolMealCount,
  getAccessCount,
  getDormitoryType
} from "@/api/schoolIndex";
import {
  getVideoPhoneUseCount,
  getAccessDormitoryCount,
  getBookSchoolBorrowCount,
  getWashingUseCount,
  getAccessSchoolCount,
} from "@/api/index";
export default {
  name: "VScaleScreen",
  props: {
    width: {
      type: [String, Number],
      default: 1920,
    },
    height: {
      type: [String, Number],
      default: 1080,
    },
    fullScreen: {
      type: Boolean,
      default: false,
    },
    autoScale: {
      type: [Object, Boolean],
      default: true,
    },
    selfAdaption: {
      type: Boolean,
      default: true,
    },
    delay: {
      type: Number,
      default: 500,
    },
    boxStyle: {
      type: Object,
      default: () => ({}),
    },
    wrapperStyle: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      timers: null, //定时器
      animate:false,
      intervalHandle: null,
      setTimeHandle: null,
      schoolName: "",
      echartsTimer: null,
      tr: [],
      date: {
        year: null,
        month: null,
        date: null,
        day: null,
        hours: null,
        minutes: null,
        seconds: null,
      },
      weeks: "",
      open: false,
      flag: null,
      form: {
        content: "",
        type: "",
        title: "",
        createTime: "",
        createByName: "",
      },

      timeVal: "",
      currentWidth: 0,
      currentHeight: 0,
      originalWidth: 0,
      originalHeight: 0,
      onResize: null,
      observer: null,
      dateList: ["日", "月"],
      activeDate: "日",
      bottomBoxDataTime: "日",
      studentCountVo: {},
      FaceDevice: 0,
      NumberOfLeave: 0,
      bottomBoxShow: false,
      bottomBoxIndex: 0,
      bottomBoxData: [
        { name: "智能公话使用统计", val: 0 },
        { name: "校园图书借阅统计", val: 1 },
        { name: "校园团餐营收统计", val: 2 },
        { name: "智能门禁进出统计", val: 3 },
        { name: "共享吹风机使用统计", val: 4 },
        { name: "智能公话使用统计", val: 5 },
        { name: "校园团餐使用统计", val: 6 },
        // { name: "入离校考勤统计", val: 7 },
      ],
      // 考勤
      checkShow: false,
      checkIndex: 0,
      checkData: [
        { name: "入离校考勤统计", val: 1 },
        { name: "宿舍考勤统计", val: 2 },
      ],
      informShow: false,
      informIndex: 0,
      informData: ["学校通知", "新闻通知"],
      tabList: [],
      // studentAllIndex:0,
      // studentAllShow:false,
      // studentAllData:['全部学校', '鱼尾中学', '爆顶三中', '大专', '大专魂','全部学校', '鱼尾中学', '爆顶三中', '大专', '大专魂'],
      equipmentIndex: 0,
      equipmentShow: false,
      equipmentData: ["全部设备", "设备一", "设备一", "设备一", "设备一"],
    };
  },
  watch: {
    selfAdaption(val) {
      if (val) {
        this.resize();
        this.addListener();
      } else {
        this.clearListener();
        this.clearStyle();
      }
    },
  },
  computed: {
    screenWrapper() {
      return this.$refs["screenWrapper"];
    },
  },
  created() {
    // 考勤统计 默认当前日期
    this.initHandle()
    this.getData();
    // this.timers = setInterval(() => {
    //   let now = new Date();
    //   this.date.year = now.getFullYear(); //  年份
    //   this.date.month =
    //     now.getMonth() + 1 < 10
    //       ? "0" + (now.getMonth() + 1)
    //       : now.getMonth() + 1; //  月份，注意月份范围是0~11，5表示六月
    //   this.date.date = now.getDate() < 10 ? "0" + now.getDate() : now.getDate(); //  表示24号
    //   this.date.hours =
    //     now.getHours() < 10 ? "0" + now.getHours() : now.getHours(); // 19, 24小时制
    //   this.date.minutes =
    //     now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes(); // 49, 分钟
    //   this.date.seconds =
    //     now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds(); // 22, 秒
    //   // console.log(this.date);
    // }, 1000);

    // let week = new Date().getDay();
    // if (week == 0) {
    //   this.weeks = "星期日";
    // } else if (week == 1) {
    //   this.weeks = "星期一";
    // } else if (week == 2) {
    //   this.weeks = "星期二";
    // } else if (week == 3) {
    //   this.weeks = "星期三";
    // } else if (week == 4) {
    //   this.weeks = "星期四";
    // } else if (week == 5) {
    //   this.weeks = "星期五";
    // } else if (week == 6) {
    //   this.weeks = "星期六";
    // }
    // setInterval(() => {


    // }, 600000);
  },
  methods: {

    initHandle() {
            this.timer = setInterval(() => {
                let now = new Date();
                this.date.year = now.getFullYear(); //  年份
                this.date.month = (now.getMonth() + 1) < 10 ? "0" + (now.getMonth() + 1) : (now.getMonth() + 1); //  月份，注意月份范围是0~11，5表示六月
                this.date.date = now.getDate() < 10 ? "0" + now.getDate() : now.getDate(); //  表示24号
                this.date.hours = now.getHours() < 10 ? "0" + now.getHours() : now.getHours(); // 19, 24小时制
                this.date.minutes = now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes(); // 49, 分钟
                this.date.seconds = now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds(); // 22, 秒
            }, 1000)
            let week = new Date().getDay();
            if (week == 0) {
                this.weeks = "星期日";
            } else if (week == 1) {
                this.weeks = "星期一";
            } else if (week == 2) {
                this.weeks = "星期二";
            } else if (week == 3) {
                this.weeks = "星期三";
            } else if (week == 4) {
                this.weeks = "星期四";
            } else if (week == 5) {
                this.weeks = "星期五";
            } else if (week == 6) {
                this.weeks = "星期六";
            }
        },

        getInit() {
      this.GetBusinessType();
      this.GetDormitoryType()
      this.GetSchoolLargeScreen();
      // this.getFaceDeviceCount()
      this.EqDistrEcharts();
      this.percentage();
      this.statistics();
      this.getPhone();
      this.getDinner();
      this.getEntrance();
      this.getLaundry();
      this.getStudent();
      this.getSchoolInform();
      this.$nextTick(() => {
        this.gteStudentAll();
        this.getLeaving();
      });
        },
    initSize() {
      return new Promise((resolve, reject) => {
        // console.log("初始化样式");
        //给父元素设置 overflow:hidden
        this.screenWrapper.parentNode.style.overflow = "hidden";
        this.screenWrapper.parentNode.scrollLeft = 0;
        this.screenWrapper.parentNode.scrollTop = 0;

        this.$nextTick(() => {
          // region 获取大屏真实尺寸
          if (this.width && this.height) {
            this.currentWidth = this.width;
            this.currentHeight = this.height;
          } else {
            this.currentWidth = this.screenWrapper.clientWidth;
            this.currentHeight = this.screenWrapper.clientHeight;
          }
          // endregion
          // region 获取画布尺寸
          if (!this.originalHeight || !this.originalWidth) {
            this.originalWidth = window.screen.width;
            this.originalHeight = window.screen.height;
          }
          // endregion
          resolve();
        });
      });
    },
    // 滚动
    scroll() {
      this.animate = true;    // 因为在消息向上滚动的时候需要添加css3过渡动画，所以这里需要设置true
      this.setTimeHandle = setTimeout(() => {      //  这里直接使用了es6的箭头函数，省去了处理this指向偏移问题，代码也比之前简化了很多
        this.tabList.push(this.tabList[0]);  // 将数组的第一个元素添加到数组的
        this.tabList.shift();               //删除数组的第一个元素
        this.animate = false;  // margin-top 为0 的时候取消过渡动画，实现无缝滚动
      }, 500)
    },
    // 移入
    handleMouseEnter() {
      clearTimeout(this.intervalHandle)
      clearTimeout(this.setTimeHandle)
      this.intervalHandle = null
      this.setTimeHandle = null
      console.log('来了');
    },
    // 移出
    handleMouseLeave() {
      console.log('走了');
      if (this.tabList.length > 10) {
        this.countDown()
      }
    },
    countDown() {
      clearTimeout(this.intervalHandle)
      this.intervalHandle = null
      this.scroll()
      this.intervalHandle = setTimeout(this.countDown, 1500)
    },
    updateSize() {
      if (this.currentWidth && this.currentHeight) {
        this.screenWrapper.style.width = `${this.currentWidth}px`;
        this.screenWrapper.style.height = `${this.currentHeight}px`;
      } else {
        this.screenWrapper.style.width = `${this.originalWidth}px`;
        this.screenWrapper.style.height = `${this.originalHeight}px`;
      }
    },
    handleAutoScale(scale) {
      if (!this.autoScale) return;
      const screenWrapper = this.screenWrapper;
      const domWidth = screenWrapper.clientWidth;
      const domHeight = screenWrapper.clientHeight;
      const currentWidth = document.body.clientWidth;
      const currentHeight = document.body.clientHeight;
      screenWrapper.style.transform = `scale(${scale},${scale}) `;
      let mx = Math.max((currentWidth - domWidth * scale) / 2, 0);
      let my = Math.max((currentHeight - domHeight * scale) / 2, 0);
      if (typeof this.autoScale === "object") {
        // @ts-ignore
        !this.autoScale.x && (mx = 0);
        // @ts-ignore
        !this.autoScale.y && (my = 0);
      }
      // console.log({
      //   mx,
      //   my,
      //   currentWidth,
      //   currentHeight,
      //   domWidth,
      //   domHeight,
      //   scale,
      // });
      this.screenWrapper.style.margin = `${my}px ${mx}px`;
    },
    updateScale() {
      const screenWrapper = this.screenWrapper;
      // 获取真实视口尺寸
      const currentWidth = document.body.clientWidth;
      const currentHeight = document.body.clientHeight;
      // 获取大屏最终的宽高onResize
      const realWidth = this.currentWidth || this.originalWidth;
      const realHeight = this.currentHeight || this.originalHeight;
      // 计算缩放比例
      const widthScale = currentWidth / realWidth;
      const heightScale = currentHeight / realHeight;
      // console.log({currentWidth, currentHeight,realWidth,realHeight});

      // 若要铺满全屏，则按照各自比例缩放
      if (this.fullScreen) {
        screenWrapper.style.transform = `scale(${widthScale},${heightScale})`;
        return false;
      }
      // 按照宽高最小比例进行缩放
      const scale = Math.min(widthScale, heightScale);
      this.handleAutoScale(scale);
    },
    initMutationObserver() {
      const screenWrapper = this.screenWrapper;
      const observer = (this.observer = new MutationObserver(() => {
        this.onResize();
      }));

      observer.observe(screenWrapper, {
        attributes: true,
        attributeFilter: ["style"],
        attributeOldValue: true,
      });
    },
    clearListener() {
      window.removeEventListener("resize", this.onResize);
    },
    addListener() {
      window.addEventListener("resize", this.onResize);
    },
    clearStyle() {
      // console.log("清除");
      const screenWrapper = this.screenWrapper;
      screenWrapper.parentNode.style.overflow = "auto";

      screenWrapper.style = "";
    },
    async resize() {
      if (!this.selfAdaption) {
        return;
      }
      await this.initSize();
      this.updateSize();
      this.updateScale();
    },
    dateClick(item, index) {
      this.activeDate = item;
      this.percentage();
    },
    dateClicks(item, index) {
      console.log(item, index);
      this.bottomBoxDataTime = item;
      this.$nextTick(() => {
        if (this.bottomBoxData[this.bottomBoxIndex].type == 1) {
          this.statistics();
        }
        if (this.bottomBoxData[this.bottomBoxIndex].type == 2) {
          this.getPhone();
        }
        if (this.bottomBoxData[this.bottomBoxIndex].type == 3) {
          this.getDinner();
        }
        if (this.bottomBoxData[this.bottomBoxIndex].type == 4) {
          this.getEntrance();
        }
        if (
          this.bottomBoxData[this.bottomBoxIndex].type == 5 ||
          this.bottomBoxData[this.bottomBoxIndex].type == 6
        ) {
          this.getLaundry();
        }
      });
    },
    bottomBoxDataAdd(index) {
      console.log(index);
      this.bottomBoxDataTime = "日";
      this.bottomBoxIndex = index;
      this.$nextTick(() => {
        if (this.bottomBoxData[this.bottomBoxIndex].type == 1) {
          this.statistics();
        }
        if (this.bottomBoxData[this.bottomBoxIndex].type == 2) {
          this.getPhone();
        }
        if (this.bottomBoxData[this.bottomBoxIndex].type == 3) {
          this.getDinner();
        }
        if (this.bottomBoxData[this.bottomBoxIndex].type == 4) {
          this.getEntrance();
        }
        if (
          this.bottomBoxData[this.bottomBoxIndex].type == 5 ||
          this.bottomBoxData[this.bottomBoxIndex].type == 6
        ) {
          this.getLaundry();
        }
      });
      this.bottomBoxShow = false;
    },
    bottomBoxShows() {
      console.log("1111");
      this.bottomBoxShow = !this.bottomBoxShow;
    },
    checkBoxShow() {
      this.checkShow = !this.checkShow;
    },
    checkIndexAdd(index) {
      this.checkIndex = index;
      this.getData();
      this.getLeaving();
      this.checkShow = false;
    },
    informBoxShow() {
      this.informShow = !this.informShow;
    },
    informAdd(index) {
      this.informShow = false;
      this.informIndex = index;
      this.getSchoolInform();
    },
    // 教资数据
    EqDistrEcharts(subjectNameList, subjectCountList) {
      let myChart = this.$echarts.init(document.getElementById("teacherData"));
      let yData = subjectCountList;
      let option = {
        grid: {
          left: "10%",
          right: "5%",
          top: "12%",
          bottom: "15%",
          // containLabel: true
        },
        tooltip: {
          trigger: "item",
          extraCssText:
            "background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px; color:#000",
          formatter: function (parms) {
            return parms.name + "：" + parms.value + "人";
          },
        },
        xAxis: {
          type: "category", // category(坐标轴类型)
          data: subjectNameList,
          axisTick: {
            // 坐标轴刻度相关配置
            show: false, // 是否显示坐标轴刻度
          },

          axisLine: {
            // 坐标轴轴线相关配置
            lineStyle: {
              // 坐标轴轴线样式
              color: "#2C6CA3", // 坐标轴轴线颜色
            },
          },
          axisLabel: {
            // 坐标轴刻度标签相关配置
            color: "#ffffff",
            fontSize: 10,
            margin: 20,
          },
        },
        yAxis: {
          name: '人',
          minInterval: 1, // 最小单位是1
          nameTextStyle: {
            color: '#fff'
          },
          type: 'value', // value(数值轴,适用于连续数据)
          axisTick: { // 坐标轴刻度相关配置
            show: false  // 是否显示坐标轴刻度
          },
          axisLine: { // 坐标轴轴线相关配置
            show: true,
            lineStyle: { // 坐标轴轴线样式
              color: '#2C6CA3' // 坐标轴轴线颜色
            }
          },
          axisLabel: { // 坐标轴刻度标签相关配置
            color: '#ffffff',
            fontSize: 10,
          },
          splitLine: { // 坐标轴在 grid 区域中的分隔线
            lineStyle: { // 分割线配置
              color: '#2C6CA3', // 分割线颜色
              type: 'dashed' //虚线
            }
          }
        },
        series: [
          // 底部的椭圆形(象形柱图):pictorialBar
          {
            type: "pictorialBar", // pictorialBar(象形柱图)
            // label: {
            //   // 图形上的文本标签,可用于说明图像的一些数据信息,比如值,名称等
            //   show: true, //是否显示标签
            //   position: ["17", "-30"], // 标签的位置(可以是绝对的像素值或者百分比['50%','50%',也可以是top,left等])
            //   color: "#fff",
            //   fontSize: 14,
            // },
            symbolSize: [18, 9], // 图形的大小用数组分别比表示宽和高,也乐意设置成10相当于[10,10]
            symbolOffset: [0, 5], // 图形相对于原本位置的偏移
            z: 12, // 象形柱状图组件的所有图形的 z 值.控制图形的前后顺序.z 值小的图形会被 z 值大的图形覆盖.
            itemStyle: {
              // 图形样式
              // echarts.graphic.LinearGradient(echarts内置的渐变色生成器)
              // 4个参数用于配置渐变色的起止位置,这4个参数依次对应右 下 左 上
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                // 这里 offset: 0 1 ,表示从下往上的渐变色
                {
                  offset: 0, // 0%处的颜色
                  color: "#18AAF8",
                },
                {
                  offset: 1, // 100%处的颜色
                  color: "#18AAF8",
                },
              ]),
            },
            data: yData,
          },
          // 中间的长方形柱状图(柱状图):bar
          {
            type: "bar", // 柱状图
            barWidth: 18, // 柱条的宽度,不设时自适应
            barGap: "0%", // 柱子与柱子之间的距离
            itemStyle: {
              // 图形样式
              // color支持(rgb(255,255,255)、rgba(255,255,255,1)、#fff,也支持渐变色和纹理填充)
              // 下面就是使用线性渐变
              color: {
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                type: "linear",
                global: false,
                colorStops: [
                  {
                    offset: 0, // 0%处的颜色
                    color: "#1B81F7",
                  },
                  {
                    offset: 1, // 100%处的颜色
                    color: "#18AAF8",
                  },
                ],
              },
            },
            data: yData,
          },
          // 顶部的椭圆形(象形柱图):pictorialBar
          {
            type: "pictorialBar",
            symbolSize: [18, 9],
            symbolOffset: [0, -5],
            z: 12,
            symbolPosition: "end",
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: "#02E6EB",
                  },
                  {
                    offset: 1,
                    color: "#02E6EB",
                  },
                ],
                false
              ),
            },
            data: yData,
          },
        ],
        dataZoom: [
          {
            show: true,
            height: 6, // 滚动条高度
            xAxisIndex: [0],
            bottom: 0, // 距离底部距离
            showDetail: false,
            showDataShadow: false,
            start: 0, //初始化时，滑动条宽度开始标度
            borderColor: "transparent",
            textStyle: {
              fontSize: 0,
            },
            endValue: 5, //从0开始的相当于5个(x轴展示的数量)
            fillerColor: "#3E599C", // 滚动条颜色
            borderColor: "rgba(17, 100, 210, 0.12)",
            backgroundColor: "#142746", //两边未选中的滑动条区域的颜色

            borderWidth: 0,
            //                 handleIcon:
            //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
            //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
            //   handleSize: "0%",
            handleStyle: {
              color: "#d3dee5",
            },
          },
          {
            type: "inside", // 支持内部鼠标滚动平移
            start: 0,
            // end: 20,
            startValue: 0, // 从头开始。
            endValue: 5, // 最多5个
            zoomOnMouseWheel: false, // 关闭滚轮缩放
            moveOnMouseWheel: true, // 开启滚轮平移
            moveOnMouseMove: true, // 鼠标移动能触发数据窗口平移
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    // 请假统计
    percentage() {
      let myChart = this.$echarts.init(document.getElementById("percentage"));
      let myCharts = this.$echarts.init(document.getElementById("leave"));
      let type = this.activeDate == "日" ? 1 : 2;
      getMonthLeaveStatis(type).then((res) => {
        this.NumberOfLeave = res.data.sumLeaveCount;
        let datas = [
          { value: res.data.sumLeaveCount, name: "请假人数" },
          { value: res.data.totalCount, name: "全部人员" },
        ];

        let dataset;
        dataset = {
          tooltip: {
            trigger: "item",
            extraCssText:
              "background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px; color:#000",
            formatter: function (parms) {
              return parms.name + "：" + parms.value + "人";
            },
          },

          graphic: {
            type: "text",
            left: "center",
            top: "center",
            style: {
              // text: '总数',
              text: `${res.data.leaveRatio} `,
              textAlign: "center",
              fill: "#fff",
              width: 20,
              height: 20,
              fontSize: 12,
            },
          },

          series: [
            {
              type: "pie",
              radius: ["75%", "85%"],
              color: ["#00D33F ", "#0E5FFF"],
              avoidLabelOverlap: false,

              label: {
                show: false,
                position: "center",
              },
              emphasis: {
                label: {
                  show: false,
                  fontSize: "10",
                  fontWeight: "bold",
                },
              },
              labelLine: {
                show: false,
              },
              data: datas,
            },
          ],
        };

        let leaveTime = [];
        // let leaveCount = [];
        res.data.leaveStatisticList.forEach((item) => {
          leaveTime.push(item.leaveTime);
          // leaveCount.push(item.leaveCount);
        });
        let by = new Date().getMonth() + 1; //本月
        let jr = new Date().getDate(); //今日
        let xData = [];
        let xDataResult = [];
        leaveTime.forEach((v) => {
          if (type == 1) {
            xData.push(v.substring(5, 10).replace(/-/g, "/"));
          } else {
            xData.push(v.length == 2 ? v : v.substring(5) + "月");
          }
        });
        if (type == 1) {
          xData.forEach((item) => {
            if (item.substring(2) == jr) {
              item = "今日";
            }
            xDataResult.push(item);
          });
        } else {
          xData.forEach((item) => {
            if (item.substring(0, 2) == by) {
              item = "本月";
            }
            xDataResult.push(item);
          });
        }


        let yData = res.data.leaveStatisticList;
        let option = {
          grid: {
            left: "40",
            right: "10",
            bottom: "50",
            top: "40",
          },
          tooltip: {
            trigger: "item",
            extraCssText:
              "background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px; color:#000",
            // formatter: function (parms) {
            //   return parms.name + "：" + "请假" + parms.value + "人";
            // },
            formatter: function (params) {
              console.log(params);
              let res = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background: linear-gradient(180deg, rgba(2,102,253,0.65) 0%, rgba(2,102,253,0.3) 100%);" ></span ><span style='display:inline-block;font-size:12px;color:#767985;'>${params.name}</span><br/>
              <span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>请假人数&nbsp;&nbsp;&nbsp;${params.value}人</span><br/>
              <span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>请假率&nbsp;&nbsp;&nbsp;${params.data.leaveRatio}</span><br/>
              `
              // params.forEach(item => {
              //   res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>请假人数&nbsp;&nbsp;&nbsp;${item.value}人</span> <span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>请假率 $${item.leaveRatio}</span><br/>`;
              // });
              return res;
            },
          },

          xAxis: {
            type: "category", // category(坐标轴类型)
            data: xDataResult,
            axisTick: {
              // 坐标轴刻度相关配置
              show: false, // 是否显示坐标轴刻度
            },
            axisLine: {
              // 坐标轴轴线相关配置
              lineStyle: {
                // 坐标轴轴线样式
                color: "#2C6CA3", // 坐标轴轴线颜色
              },
            },
            axisLabel: {
              color: "#fff",
              fontSize: 12,
              interval: 0,
              formatter: function (params) {
                let newParamsName = "";
                let paramsNameNumber = params.length;
                let provideNumber = 5;
                let rowNumber = Math.ceil(paramsNameNumber / provideNumber);
                if (paramsNameNumber > provideNumber) {
                  for (let p = 0; p < rowNumber; p++) {
                    let tempStr = "";
                    let start = p * provideNumber;
                    let end = start + provideNumber;
                    if (p === rowNumber - 1) {
                      tempStr = params.substring(start, paramsNameNumber);
                    } else {
                      if (p > 2) {
                        tempStr = "...";
                        newParamsName += tempStr;
                        break;
                      } else {
                        tempStr = params.substring(start, end);
                        if (p < 2) {
                          tempStr += "\n";
                        }
                      }
                    }
                    newParamsName += tempStr;
                  }
                } else {
                  newParamsName = params;
                }
                return newParamsName;
              },
            },
          },
          yAxis: {
            name: "人",
            nameTextStyle: {
              color: "#fff",
            },
            type: "value", // value(数值轴,适用于连续数据)
            axisTick: {
              // 坐标轴刻度相关配置
              show: false, // 是否显示坐标轴刻度
            },
            axisLine: {
              // 坐标轴轴线相关配置
              lineStyle: {
                // 坐标轴轴线样式
                color: "#2C6CA3", // 坐标轴轴线颜色
              },
            },
            axisLabel: {
              // 坐标轴刻度标签相关配置
              color: "#ffffff",
              fontSize: 10,
            },
            splitLine: {
              // 坐标轴在 grid 区域中的分隔线
              lineStyle: {
                // 分割线配置
                color: "#2C6CA3", // 分割线颜色
                type: "dashed", //虚线
              },
            },
          },
          series: [
            // 中间的长方形柱状图(柱状图):bar
            {
              type: "bar", // 柱状图
              barWidth: 18, // 柱条的宽度,不设时自适应
              barGap: "0%", // 柱子与柱子之间的距离
              // label: {
              //   // 图形上的文本标签,可用于说明图像的一些数据信息,比如值,名称等
              //   show: true, //是否显示标签
              //   position: ["-5", "-30"], // 标签的位置(可以是绝对的像素值或者百分比['50%','50%',也可以是top,left等])
              //   color: "#fff",
              //   fontSize: 14,
              // },
              itemStyle: {
                // 图形样式
                // color支持(rgb(255,255,255)、rgba(255,255,255,1)、#fff,也支持渐变色和纹理填充)
                // 下面就是使用线性渐变
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  type: "linear",
                  global: false,
                  colorStops: [
                    {
                      offset: 0, // 0%处的颜色
                      color: "#E1B343",
                    },
                    {
                      offset: 1, // 100%处的颜色
                      color: "#E1B343",
                    },
                  ],
                },
              },
              data: yData,
            },
          ],
          dataZoom: [
            {
              show: true,
              height: 6, // 滚动条高度
              xAxisIndex: [0],
              bottom: 0, // 距离底部距离
              showDetail: false,
              showDataShadow: false,
              start: 0, //初始化时，滑动条宽度开始标度
              borderColor: "transparent",
              textStyle: {
                fontSize: 0,
              },
              endValue: 5, //从0开始的相当于5个(x轴展示的数量)
              fillerColor: "#3E599C", // 滚动条颜色
              borderColor: "rgba(17, 100, 210, 0.12)",
              backgroundColor: "#142746", //两边未选中的滑动条区域的颜色

              borderWidth: 0,
              //                 handleIcon:
              //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
              //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
              //   handleSize: "0%",
              handleStyle: {
                color: "#d3dee5",
              },
            },
            {
              type: "inside", // 支持内部鼠标滚动平移
              start: 0,
              // end: 20,
              startValue: 0, // 从头开始。
              endValue: 5, // 最多5个
              zoomOnMouseWheel: false, // 关闭滚轮缩放
              moveOnMouseWheel: true, // 开启滚轮平移
              moveOnMouseMove: true, // 鼠标移动能触发数据窗口平移
            },
          ],
        };
        dataset && myChart.setOption(dataset);
        myCharts.setOption(option);
      });
    },
    // 智能公话使用统计
    statistics() {
      let myChart = this.$echarts.init(document.getElementById("Phone"));

      let dayOrMonth = this.bottomBoxDataTime == "日" ? 0 : 1;
      getVideoPhoneUseCount({ dayOrMonth: dayOrMonth }).then((res) => {
        console.log(res);
        let by = new Date().getMonth() + 1; //本月
        let jr = new Date().getDate(); //今日
        let xData = [];
        let xDataResult = [];
        res.data.dateList.forEach((v) => {
          if (dayOrMonth == 0) {
            xData.push(v.substring(5, 10).replace(/-/g, "/"));
          } else {
            xData.push(v.length == 2 ? v : v.substring(5) + "月");
          }
        });
        if (dayOrMonth == 0) {
          xData.forEach((item) => {
            if (item.substring(2) == jr) {
              item = "今日";
            }
            xDataResult.push(item);
          });
        } else {
          xData.forEach((item) => {
            if (item.substring(0, 2) == by) {
              item = "本月";
            }
            xDataResult.push(item);
          });
        }

        let PhoneData = {
          color: ["#A0FF9B", "#3BDEFF"],

          // tooltip: {
          //   trigger: "axis",
          //   axisPointer: {
          //     type: "cross",
          //     label: {
          //       backgroundColor: "#6a7985",
          //     },
          //   },
          // },
          tooltip: {
            trigger: "axis",
            extraCssText:
              "background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;color:#000",
            axisPointer: {
              //坐标轴指示器
              type: "cross", //十字准星指示器
            },
          },
          legend: {
            left: "5%",

            data: ["次数", "分钟"],
            textStyle: {
              color: "#ffffff",
            },
          },

          grid: {
            left: "50",
            right: "10",
            bottom: "50",
            top: "40",
          },
          xAxis: [
            {
              type: "category",
              boundaryGap: false,
              data: xDataResult,
              axisLine: {
                // 坐标轴轴线相关配置

                lineStyle: {
                  // 坐标轴轴线样式
                  color: "#2C6CA3", // 坐标轴轴线颜色
                },
              },
              axisLabel: {
                color: "#fff",
                fontSize: 12,
                interval: 0,
                formatter: function (params) {
                  let newParamsName = "";
                  let paramsNameNumber = params.length;
                  let provideNumber = 5;
                  let rowNumber = Math.ceil(paramsNameNumber / provideNumber);
                  if (paramsNameNumber > provideNumber) {
                    for (let p = 0; p < rowNumber; p++) {
                      let tempStr = "";
                      let start = p * provideNumber;
                      let end = start + provideNumber;
                      if (p === rowNumber - 1) {
                        tempStr = params.substring(start, paramsNameNumber);
                      } else {
                        if (p > 2) {
                          tempStr = "...";
                          newParamsName += tempStr;
                          break;
                        } else {
                          tempStr = params.substring(start, end);
                          if (p < 2) {
                            tempStr += "\n";
                          }
                        }
                      }
                      newParamsName += tempStr;
                    }
                  } else {
                    newParamsName = params;
                  }
                  return newParamsName;
                },
              },
            },
          ],
          yAxis: [
            {
              type: "value",

              axisLabel: {
                // 坐标轴刻度标签相关配置
                color: "#ffffff",
                fontSize: 10,
                margin: 20,
              },
              splitLine: {
                // 坐标轴在 grid 区域中的分隔线

                lineStyle: {
                  // 分割线配置
                  color: "#2C6CA3", // 分割线颜色
                  type: "dashed", //虚线
                },
              },
            },
            {
              type: "value",

              axisLabel: {
                // 坐标轴刻度标签相关配置
                color: "#ffffff",
                fontSize: 10,
                margin: 20,
              },
              splitLine: {
                // 坐标轴在 grid 区域中的分隔线

                lineStyle: {
                  // 分割线配置
                  color: "#2C6CA3", // 分割线颜色
                  type: "dashed", //虚线
                },
              },
            },
          ],
          series: [
            {
              name: "次数",
              type: "line",
              stack: "Total",
              smooth: true,
              lineStyle: {
                width: 0,
              },
              itemStyle: {
                normal: {
                  // 设置线条的style等
                  color: "#0267FE",
                  lineStyle: {
                    color: "rgba(59,222,255,1)",
                  },
                  label: {
                    show: false,
                  },
                },
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "rgba(59,222,255,1)",
                  },
                  {
                    offset: 1,
                    color: "rgba(59,222,255,0.2)",
                  },
                ]),
              },
              emphasis: {
                focus: "series",
              },
              data: res.data.numList,
            },
            {
              name: "分钟",
              type: "line",
              stack: "Total",

              itemStyle: {
                normal: {
                  // 设置线条的style等
                  color: "#0267FE",
                  lineStyle: {
                    color: "rgba(204,255,201,1)",
                  },
                  label: {
                    show: false,
                  },
                },
              },
              smooth: true,
              lineStyle: {
                width: 0,
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "rgba(204,255,201,1)",
                  },
                  {
                    offset: 1,
                    color: "rgba(204,255,201,0.2)",
                  },
                ]),
              },
              emphasis: {
                focus: "series",
              },
              data: res.data.durationList,
            },
          ],
          dataZoom: [
            {
              show: true,
              height: 6, // 滚动条高度
              xAxisIndex: [0],
              bottom: 0, // 距离底部距离
              showDetail: false,
              showDataShadow: false,
              start: 0, //初始化时，滑动条宽度开始标度
              borderColor: "transparent",
              textStyle: {
                fontSize: 0,
              },
              endValue: 5, //从0开始的相当于5个(x轴展示的数量)
              fillerColor: "#3E599C", // 滚动条颜色
              borderColor: "rgba(17, 100, 210, 0.12)",
              backgroundColor: "#142746", //两边未选中的滑动条区域的颜色

              borderWidth: 0,
              //                 handleIcon:
              //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
              //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
              //   handleSize: "0%",
              handleStyle: {
                color: "#d3dee5",
              },
            },
            {
              type: "inside", // 支持内部鼠标滚动平移
              start: 0,
              // end: 20,
              startValue: 0, // 从头开始。
              endValue: 5, // 最多5个
              zoomOnMouseWheel: false, // 关闭滚轮缩放
              moveOnMouseWheel: true, // 开启滚轮平移
              moveOnMouseMove: true, // 鼠标移动能触发数据窗口平移
            },
          ],
        };
        myChart.resize();
        PhoneData && myChart.setOption(PhoneData);
      });
    },
    // 校园图书借阅统计
    getPhone() {
      let myChart = this.$echarts.init(document.getElementById("Books"));

      let dayOrMonth = this.bottomBoxDataTime == "日" ? 0 : 1;
      getBookSchoolBorrowCount({ dayOrMonth: dayOrMonth }).then((res) => {
        //     let xData = []

        //       res.data.dateList.forEach(v => {
        //         if (dayOrMonth == 0) {
        //           xData.push(
        //           v.substring(5, 10).replace(/-/g, "/")
        //         )
        //         } else {
        //         xData.push(
        //           v.length == 2 ? v : v.substring(5) + '月'
        //         )
        //       }
        // })
        let by = new Date().getMonth() + 1; //本月
        let jr = new Date().getDate(); //今日
        let xData = [];
        let xDataResult = [];
        res.data.dateList.forEach((v) => {
          if (dayOrMonth == 0) {
            xData.push(v.substring(5, 10).replace(/-/g, "/"));
          } else {
            xData.push(v.length == 2 ? v : v.substring(5) + "月");
          }
        });
        if (dayOrMonth == 0) {
          xData.forEach((item) => {
            if (item.substring(2) == jr) {
              item = "今日";
            }
            xDataResult.push(item);
          });
        } else {
          xData.forEach((item) => {
            if (item.substring(0, 2) == by) {
              item = "本月";
            }
            xDataResult.push(item);
          });
        }

        let option = {
          grid: {
            left: "50",
            right: "10",
            bottom: "50",
            top: "40",
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              //坐标轴指示器
              type: "line", //十字准星指示器
            },
            extraCssText:
              "width: 165px;height: 66px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;",
            formatter: function (params) {
              return (
                `<span style="font-size:12px;color:#3D3D3D;">${params[0].name}</span>` +
                "<br/>" +
                `<span style="color:#3D3D3D;font-size:14px;margin-left:12px;">借阅图书 &nbsp;&nbsp; ${params[0].value}本</span>`
              );
            },

            // formatter: function (parms) {
            //     return parms.marker + " " + parms.name + "：" + parms.value + "次";
            // }
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: xDataResult,
            splitLine: {
              show: true,
              lineStyle: {
                // 坐标轴轴线样式
                color: "#2C6CA3", // 坐标轴轴线颜色
              },
            },
            axisLine: {
              lineStyle: {
                // 坐标轴轴线样式
                color: "#2C6CA3", // 坐标轴轴线颜色
              },
            }, //坐标轴轴线
            axisLabel: {
              color: "#fff",
              fontSize: 12,
              interval: 0,
              formatter: function (params) {
                let newParamsName = "";
                let paramsNameNumber = params.length;
                let provideNumber = 5;
                let rowNumber = Math.ceil(paramsNameNumber / provideNumber);
                if (paramsNameNumber > provideNumber) {
                  for (let p = 0; p < rowNumber; p++) {
                    let tempStr = "";
                    let start = p * provideNumber;
                    let end = start + provideNumber;
                    if (p === rowNumber - 1) {
                      tempStr = params.substring(start, paramsNameNumber);
                    } else {
                      if (p > 2) {
                        tempStr = "...";
                        newParamsName += tempStr;
                        break;
                      } else {
                        tempStr = params.substring(start, end);
                        if (p < 2) {
                          tempStr += "\n";
                        }
                      }
                    }
                    newParamsName += tempStr;
                  }
                } else {
                  newParamsName = params;
                }
                return newParamsName;
              },
            },
            axisTick: { show: false }, //坐标轴刻度
          },

          yAxis: {
            name: "本",
            nameTextStyle: {
              color: "#fff",
            },
            type: "value",
            splitLine: { show: false }, //坐标轴在 grid 区域中的分隔线
            axisTick: { show: false }, //坐标轴刻度
            axisLine: {
              // 坐标轴轴线相关配置
              lineStyle: {
                // 坐标轴轴线样式
                color: "#2C6CA3", // 坐标轴轴线颜色
              },
            },
            axisLabel: {
              // 坐标轴刻度标签相关配置
              color: "#ffffff",
              fontSize: 10,
            },
          },
          series: [
            {
              type: "line",
              stack: "Total",
              smooth: true,
              lineStyle: {
                width: 0,
              },
              itemStyle: {
                normal: {
                  // 设置线条的style等
                  color: "#0267FE",
                  lineStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: "#FFBF60",
                      },
                      {
                        offset: 1,
                        color: "#FFBF60",
                      },
                    ]),
                  },
                  label: {
                    show: false,
                  },
                },
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "rgba(255,191,96,1) ",
                  },
                  {
                    offset: 1,
                    color: "rgba(107,91,60,0.2) ",
                  },
                ]),
              },
              emphasis: {
                focus: "series",
              },
              data: res.data.borrowList,
            },
          ],
          dataZoom: [
            {
              show: true,
              height: 6, // 滚动条高度
              xAxisIndex: [0],
              bottom: 0, // 距离底部距离
              showDetail: false,
              showDataShadow: false,
              start: 0, //初始化时，滑动条宽度开始标度
              borderColor: "transparent",
              textStyle: {
                fontSize: 0,
              },
              endValue: 5, //从0开始的相当于5个(x轴展示的数量)
              fillerColor: "#3E599C", // 滚动条颜色
              borderColor: "rgba(17, 100, 210, 0.12)",
              backgroundColor: "#142746", //两边未选中的滑动条区域的颜色

              borderWidth: 0,
              //                 handleIcon:
              //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
              //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
              //   handleSize: "0%",
              handleStyle: {
                color: "#d3dee5",
              },
            },
            {
              type: "inside", // 支持内部鼠标滚动平移
              start: 0,
              // end: 20,
              startValue: 0, // 从头开始。
              endValue: 5, // 最多5个
              zoomOnMouseWheel: false, // 关闭滚轮缩放
              moveOnMouseWheel: true, // 开启滚轮平移
              moveOnMouseMove: true, // 鼠标移动能触发数据窗口平移
            },
          ],
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.resize();
        myChart.setOption(option);
      });
    },
    // 团餐管理
    getDinner() {
      // 基于准备好的dom，初始化echarts实例

      let chartDom = document.getElementById("dinner");
      let myChart = echarts.init(chartDom);

      let dayOrMonth = this.bottomBoxDataTime == "日" ? 0 : 1;
      getSchoolMealCount({ dayOrMonth: dayOrMonth }).then((res) => {
        let by = new Date().getMonth() + 1; //本月
        let jr = new Date().getDate(); //今日
        let xData = [];
        let xDataResult = [];
        res.data.dateList.forEach((v) => {
          if (dayOrMonth == 0) {
            xData.push(v.substring(5, 10).replace(/-/g, "/"));
          } else {
            xData.push(v.length == 2 ? v : v.substring(5) + "月");
          }
        });
        if (dayOrMonth == 0) {
          xData.forEach((item) => {
            if (item.substring(2) == jr) {
              item = "今日";
            }
            xDataResult.push(item);
          });
        } else {
          xData.forEach((item) => {
            if (item.substring(0, 2) == by) {
              item = "本月";
            }
            xDataResult.push(item);
          });
        }
        let dinnerData = {
          //  backgroundColor: '#00265f',

          legend: {
            left: "5%",

            data: ["早", "中", "晚"],
            textStyle: {
              color: "#ffffff",
            },
          },
          tooltip: {
            trigger: "axis",
            extraCssText:
              "background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;",
            axisPointer: {
              //坐标轴指示器
              type: "line", //十字准星指示器
            },
            formatter: function (params) {
              console.log(params);
              let res = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#4B93FF;" ></span ><span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name
                } &nbsp;&nbsp;总营收&nbsp;${params[0].data.allUsePrice ? params[0].data.allUsePrice : 0.0
                }元  &nbsp;&nbsp;刷脸${params[0].data.allUseCount ? params[0].data.allUseCount : 0
                }次 </span><br/>`;
              params.reverse().forEach((item) => {
                res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName}&nbsp;&nbsp;&nbsp;${item.value}元</span><br/>`;
              });
              return res;
            },
          },
          grid: {
            left: "5%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: [
            {
              type: "category",
              // data: data.titleList,
              data: xDataResult,
              axisLine: {
                show: true,
                lineStyle: {
                  color: "rgba(255,255,255,.1)",
                  width: 1,
                  type: "solid",
                },
              },

              axisTick: {
                show: false,
              },
              axisLabel: {
                color: "#fff",
                fontSize: 12,
                interval: 0,
                formatter: function (params) {
                  let newParamsName = "";
                  let paramsNameNumber = params.length;
                  let provideNumber = 4;
                  let rowNumber = Math.ceil(paramsNameNumber / provideNumber);
                  if (paramsNameNumber > provideNumber) {
                    for (let p = 0; p < rowNumber; p++) {
                      let tempStr = "";
                      let start = p * provideNumber;
                      let end = start + provideNumber;
                      if (p === rowNumber - 1) {
                        tempStr = params.substring(start, paramsNameNumber);
                      } else {
                        if (p > 2) {
                          tempStr = "...";
                          newParamsName += tempStr;
                          break;
                        } else {
                          tempStr = params.substring(start, end);
                          if (p < 2) {
                            tempStr += "\n";
                          }
                        }
                      }
                      newParamsName += tempStr;
                    }
                  } else {
                    newParamsName = params;
                  }
                  return newParamsName;
                },
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              axisLabel: {
                //formatter: '{value} %'
                show: true,
                textStyle: {
                  color: "rgba(255,255,255,.6)",
                  fontSize: "12",
                },
              },
              axisTick: {
                show: false,
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "rgba(255,255,255,.1	)",
                  width: 1,
                  type: "solid",
                },
              },
              splitLine: {
                lineStyle: {
                  color: "rgba(255,255,255,.1)",
                },
              },
            },
          ],
          series: [
            {
              type: "bar",
              name: "早",
              stack: "堆叠", //设置堆叠柱状图的属性，需堆叠的柱子名称需相同
              // data: data.oneList,
              data: res.data.listEarly,
              barWidth: "20%", //柱子宽度
              // barGap: 1, //柱子之间间距
              itemStyle: {
                normal: {
                  color: "#4CD6A6", //柱子颜色
                  opacity: 1,
                  barBorderRadius: 1,
                },
              },
            },
            {
              type: "bar",
              name: "中",
              stack: "堆叠", //设置堆叠柱状图的属性，需堆叠的柱子名称需相同
              // data: data.twoList,
              data: res.data.listMiddle,
              barWidth: "20%", //柱子宽度
              // barGap: 1, //柱子之间间距
              itemStyle: {
                normal: {
                  color: "#469DFA", //柱子颜色
                  opacity: 1,
                  barBorderRadius: 1, //柱子菱角
                },
              },
            },
            {
              type: "bar",
              name: "晚",
              stack: "堆叠", //设置堆叠柱状图的属性，需堆叠的柱子名称需相同
              // data: data.twoList,
              data: res.data.listLate,
              barWidth: "20%", //柱子宽度
              // barGap: 1, //柱子之间间距
              itemStyle: {
                normal: {
                  color: "#F8BC5B", //柱子颜色
                  opacity: 1,
                  barBorderRadius: 1, //柱子菱角
                },
              },
            },
          ],
          dataZoom: [
            {
              show: true,
              height: 6, // 滚动条高度
              xAxisIndex: [0],
              bottom: 0, // 距离底部距离
              showDetail: false,
              showDataShadow: false,
              start: 0, //初始化时，滑动条宽度开始标度
              borderColor: "transparent",
              textStyle: {
                fontSize: 0,
              },
              endValue: 5, //从0开始的相当于5个(x轴展示的数量)
              fillerColor: "#3E599C", // 滚动条颜色
              borderColor: "rgba(17, 100, 210, 0.12)",
              backgroundColor: "#142746", //两边未选中的滑动条区域的颜色

              borderWidth: 0,
              //                 handleIcon:
              //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
              //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
              //   handleSize: "0%",
              handleStyle: {
                color: "#d3dee5",
              },
            },
            {
              type: "inside", // 支持内部鼠标滚动平移
              start: 0,
              // end: 20,
              startValue: 0, // 从头开始。
              endValue: 5, // 最多5个
              zoomOnMouseWheel: false, // 关闭滚轮缩放
              moveOnMouseWheel: true, // 开启滚轮平移
              moveOnMouseMove: true, // 鼠标移动能触发数据窗口平移
            },
          ],
        };
        myChart.resize();
        // 使用刚指定的配置项和数据显示图表。
        dinnerData && myChart.setOption(dinnerData);
      });
    },
    //  门禁
    getEntrance() {
      let chartDom = document.getElementById("entrance");
      let myChart = echarts.init(chartDom);

      let dayOrMonth = this.bottomBoxDataTime == "日" ? 0 : 1;
      getAccessSchoolCount({ dayOrMonth: dayOrMonth, deviceType: "" }).then((res) => {
        let by = new Date().getMonth() + 1; //本月
        let jr = new Date().getDate(); //今日
        let xData = [];
        let xDataResult = [];
        res.data.dateList.forEach((v) => {
          if (dayOrMonth == 0) {
            xData.push(v.substring(5, 10).replace(/-/g, "/"));
          } else {
            xData.push(v.length == 2 ? v : v.substring(5) + "月");
          }
        });
        if (dayOrMonth == 0) {
          xData.forEach((item) => {
            if (item.substring(2) == jr) {
              item = "今日";
            }
            xDataResult.push(item);
          });
        } else {
          xData.forEach((item) => {
            if (item.substring(0, 2) == by) {
              item = "本月";
            }
            xDataResult.push(item);
          });
        }
        let entranceData = {
          backgroundColor: "transparent",
          tooltip: {
            trigger: "axis",
            extraCssText:
              "background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;color:#000",
            formatter: function (params) {
              console.log(params);
              let res = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background: linear-gradient(180deg, rgba(2,102,253,0.65) 0%, rgba(2,102,253,0.3) 100%);" ></span ><span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name}</span><br/>`
              params.forEach(item => {
                res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName}人次&nbsp;&nbsp;&nbsp;${item.value}人</span> <span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.data.passCount}次</span><br/>`;
              });
              return res;
            },
            axisPointer: {
              //坐标轴指示器
              type: "shadow", //十字准星指示器
            },
          },
          legend: {
            left: "5%",
            data: ["进入", "外出"],
            textStyle: {
              color: "#fff",
            },
            itemWidth: 15,
            itemHeight: 11,
            itemGap: 35,
          },
          grid: {
            left: "50",
            right: "10",
            bottom: "50",
            top: "40",
          },
          xAxis: [
            {
              type: "category",
              axisLine: {
                lineStyle: {
                  color: "#2C6CA3",
                },
              },
              axisLabel: {
                color: "#fff",
                fontSize: 14,
                interval: 0,
                formatter: function (params) {
                  let newParamsName = "";
                  let paramsNameNumber = params.length;
                  let provideNumber = 4;
                  let rowNumber = Math.ceil(paramsNameNumber / provideNumber);
                  if (paramsNameNumber > provideNumber) {
                    for (let p = 0; p < rowNumber; p++) {
                      let tempStr = "";
                      let start = p * provideNumber;
                      let end = start + provideNumber;
                      if (p === rowNumber - 1) {
                        tempStr = params.substring(start, paramsNameNumber);
                      } else {
                        if (p > 2) {
                          tempStr = "...";
                          newParamsName += tempStr;
                          break;
                        } else {
                          tempStr = params.substring(start, end);
                          if (p < 2) {
                            tempStr += "\n";
                          }
                        }
                      }
                      newParamsName += tempStr;
                    }
                  } else {
                    newParamsName = params;
                  }
                  return newParamsName;
                },
              },
              splitLine: {
                lineStyle: {
                  color: "#00314a",
                },
              },
              // axisTick: {
              //   show: false
              // },

              data: xDataResult,
            },
          ],
          yAxis: [
            {
              type: "value",

              nameTextStyle: {
                color: "#fff",
                fontSize: 14,
              },
              axisLine: {
                lineStyle: {
                  color: "#2C6CA3",
                },
              },
              axisTick: {
                show: false,
              },

              splitLine: {
                // 坐标轴在 grid 区域中的分隔线

                lineStyle: {
                  // 分割线配置
                  color: "#2C6CA3", // 分割线颜色
                  type: "dashed", //虚线
                },
              },
              axisLabel: {
                color: "#fff",
                fontSize: 14,
                formatter: "{value}",
              },
            },
          ],
          series: [
            {
              name: "进入",
              type: "bar",
              data: res.data.personCountJrList,
              barWidth: 10,
              barGap: "0%" /*多个并排柱子设置柱子之间的间距*/,
              barCategoryGap: "100%" /*多个并排柱子设置柱子之间的间距*/,

              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "rgba(244,94,94,1)" },
                  { offset: 1, color: "rgba(255,205,205,1)" },
                ]),
              },
            },
            {
              name: "外出",
              type: "bar",
              data: res.data.personCountWcList,
              barWidth: 10,
              barGap: "0%" /*多个并排柱子设置柱子之间的间距*/,
              barCategoryGap: "100%" /*多个并排柱子设置柱子之间的间距*/,

              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "rgba(70,157,250,1)" },
                  { offset: 1, color: "rgba(0,31,64,1)" },
                ]),
              },
            },
          ],
          dataZoom: [
            {
              show: true,
              height: 6, // 滚动条高度
              xAxisIndex: [0],
              bottom: 0, // 距离底部距离
              showDetail: false,
              showDataShadow: false,
              start: 0, //初始化时，滑动条宽度开始标度
              borderColor: "transparent",
              textStyle: {
                fontSize: 0,
              },
              endValue: 5, //从0开始的相当于5个(x轴展示的数量)
              fillerColor: "#3E599C", // 滚动条颜色
              borderColor: "rgba(17, 100, 210, 0.12)",
              backgroundColor: "#142746", //两边未选中的滑动条区域的颜色

              borderWidth: 0,
              //                 handleIcon:
              //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
              //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
              //   handleSize: "0%",
              handleStyle: {
                color: "#d3dee5",
              },
            },
            {
              type: "inside", // 支持内部鼠标滚动平移
              start: 0,
              // end: 20,
              startValue: 0, // 从头开始。
              endValue: 5, // 最多5个
              zoomOnMouseWheel: false, // 关闭滚轮缩放
              moveOnMouseWheel: true, // 开启滚轮平移
              moveOnMouseMove: true, // 鼠标移动能触发数据窗口平移
            },
          ],
        };
        myChart.resize();
        entranceData && myChart.setOption(entranceData);
      });
    },
    // 共享洗衣使用统计
    getLaundry() {
      let chartDom = document.getElementById("laundry");
      let myChart = echarts.init(chartDom);

      // getWashingUseCount
      let type = "";
      if (this.bottomBoxData[this.bottomBoxIndex].type == 5) {
        type = 1;
      } else if (this.bottomBoxData[this.bottomBoxIndex].type == 6) {
        type = 9;
      }
      let dayOrMonth = this.bottomBoxDataTime == "日" ? 0 : 1;
      getWashingUseCount({ dayOrMonth: dayOrMonth, orderType: type }).then(
        (res) => {
          console.log(res);
          let by = new Date().getMonth() + 1; //本月
          let jr = new Date().getDate(); //今日
          let xData = [];
          let xDataResult = [];
          res.data.dateList.forEach((v) => {
            if (dayOrMonth == 0) {
              xData.push(v.substring(5, 10).replace(/-/g, "/"));
            } else {
              xData.push(v.length == 2 ? v : v.substring(5) + "月");
            }
          });
          if (dayOrMonth == 0) {
            xData.forEach((item) => {
              if (item.substring(2) == jr) {
                item = "今日";
              }
              xDataResult.push(item);
            });
          } else {
            xData.forEach((item) => {
              if (item.substring(0, 2) == by) {
                item = "本月";
              }
              xDataResult.push(item);
            });
          }
          let laundryData = {
            color: ["#3BDEFF", "#A0FF9B"],

            tooltip: {
              trigger: "axis",
              extraCssText:
                "width: 178px;height: 100px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;",
              axisPointer: {
                //坐标轴指示器
                type: "line", //十字准星指示器
              },
              formatter: function (params) {
                console.log(params);
                let res = `${params[0].marker}<span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name}</span><br/>`;
                params.forEach((item) => {
                  if (item.data.studentCount) {
                  }
                  res +=
                    `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName == "金额" ? "营收金额" : "使用次数"
                    }&nbsp;&nbsp;&nbsp;${item.value}${item.seriesName == "金额" ? "元" : "次"
                    }</span><br/>` +
                    `${item.data.studentCount
                      ? `<span style="font-size:12px;color:#333;margin-left:12px;">教师：${item.data.teacherCount}次&nbsp;&nbsp;学生：${item.data.studentCount}次</span><br/>`
                      : ""
                    }`;
                });
                return res;
                // console.log(params);
                // const str = `${params.marker}<span style="font-size:12px;color:#767985;">${params.name}</span><br/><span style="font-size:14px;color:#333;margin:12px;">${params.seriesName == '金额' ? '营收金额：' : '使用次数：'} &nbsp;&nbsp; ${params.value}${params.seriesName == '金额' ? '元' : '次'}</span>${params.seriesName == '金额' ? '' : `<br/><span style="font-size:12px;color:#333;margin-left:12px;">教师：${params.data.teacherCount}次&nbsp;&nbsp;学生：${params.data.studentCount}次</span>`}`
                // return str
              },
            },
            legend: {
              left: "5%",

              data: ["次数", "金额"],
              textStyle: {
                color: "#ffffff",
              },
            },

            grid: {
              left: "5%",
              right: "4%",
              bottom: "3%",
              containLabel: true,
            },
            xAxis: [
              {
                type: "category",
                boundaryGap: false,
                data: xDataResult,
                axisLine: {
                  // 坐标轴轴线相关配置
                  lineStyle: {
                    // 坐标轴轴线样式
                    color: "#2C6CA3", // 坐标轴轴线颜色
                  },
                },
                axisLabel: {
                  color: "#fff",
                  fontSize: 12,
                  interval: 0,
                  formatter: function (params) {
                    let newParamsName = "";
                    let paramsNameNumber = params.length;
                    let provideNumber = 4;
                    let rowNumber = Math.ceil(paramsNameNumber / provideNumber);
                    if (paramsNameNumber > provideNumber) {
                      for (let p = 0; p < rowNumber; p++) {
                        let tempStr = "";
                        let start = p * provideNumber;
                        let end = start + provideNumber;
                        if (p === rowNumber - 1) {
                          tempStr = params.substring(start, paramsNameNumber);
                        } else {
                          if (p > 2) {
                            tempStr = "...";
                            newParamsName += tempStr;
                            break;
                          } else {
                            tempStr = params.substring(start, end);
                            if (p < 2) {
                              tempStr += "\n";
                            }
                          }
                        }
                        newParamsName += tempStr;
                      }
                    } else {
                      newParamsName = params;
                    }
                    return newParamsName;
                  },
                },
              },
            ],
            yAxis: [
              {
                type: "value",

                axisLabel: {
                  // 坐标轴刻度标签相关配置
                  color: "#ffffff",
                  fontSize: 10,
                  margin: 20,
                },
                splitLine: {
                  // 坐标轴在 grid 区域中的分隔线

                  lineStyle: {
                    // 分割线配置
                    color: "#2C6CA3", // 分割线颜色
                    type: "dashed", //虚线
                  },
                },
              },
            ],
            series: [
              {
                name: "次数",
                type: "line",
                stack: "Total",
                smooth: true,
                lineStyle: {
                  width: 0,
                },
                itemStyle: {
                  normal: {
                    // 设置线条的style等
                    color: "#0267FE",
                    lineStyle: {
                      color: "rgba(59,222,255,1)",
                    },
                    label: {
                      show: false,
                    },
                  },
                },
                showSymbol: false,
                areaStyle: {
                  opacity: 0.8,
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "rgba(59,222,255,1)",
                    },
                    {
                      offset: 1,
                      color: "rgba(59,222,255,0.2)",
                    },
                  ]),
                },
                emphasis: {
                  focus: "series",
                },
                data: res.data.useList,
              },
              {
                name: "金额",
                type: "line",
                stack: "Total",
                smooth: true,
                lineStyle: {
                  width: 0,
                },

                itemStyle: {
                  normal: {
                    // 设置线条的style等
                    color: "#0267FE",
                    lineStyle: {
                      color: "rgba(204,255,201,1)",
                    },
                    label: {
                      show: false,
                    },
                  },
                },
                smooth: true,
                lineStyle: {
                  width: 0,
                },
                showSymbol: false,
                areaStyle: {
                  opacity: 0.8,
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "rgba(204,255,201,1)",
                    },
                    {
                      offset: 1,
                      color: "rgba(204,255,201,0.2)",
                    },
                  ]),
                },
                emphasis: {
                  focus: "series",
                },
                data: res.data.receiptAmountList,
              },
            ],
            dataZoom: [
              {
                show: true,
                height: 6, // 滚动条高度
                xAxisIndex: [0],
                bottom: 0, // 距离底部距离
                showDetail: false,
                showDataShadow: false,
                start: 0, //初始化时，滑动条宽度开始标度
                borderColor: "transparent",
                textStyle: {
                  fontSize: 0,
                },
                endValue: 5, //从0开始的相当于5个(x轴展示的数量)
                fillerColor: "#3E599C", // 滚动条颜色
                borderColor: "rgba(17, 100, 210, 0.12)",
                backgroundColor: "#142746", //两边未选中的滑动条区域的颜色

                borderWidth: 0,
                //                 handleIcon:
                //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
                //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
                //   handleSize: "0%",
                handleStyle: {
                  color: "#d3dee5",
                },
              },
              {
                type: "inside", // 支持内部鼠标滚动平移
                start: 0,
                // end: 20,
                startValue: 0, // 从头开始。
                endValue: 5, // 最多5个
                zoomOnMouseWheel: false, // 关闭滚轮缩放
                moveOnMouseWheel: true, // 开启滚轮平移
                moveOnMouseMove: true, // 鼠标移动能触发数据窗口平移
              },
            ],
          };
          myChart.resize();
          laundryData && myChart.setOption(laundryData);
        }
      );
    },

    getLeaving() {
      let chartDom = document.getElementById("leavings");
      let myChart = echarts.init(chartDom);

      let data = {
        deviceType: this.checkData[this.checkIndex].type,
        searchTime: this.timeVal,
      };
      getStudentAttend(data).then((res) => {
        let leavingData = {
          backgroundColor: "transparent",
          tooltip: {
            trigger: "axis",
            extraCssText:
              "background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;color:#000",
            axisPointer: {
              //坐标轴指示器
              type: "shadow", //十字准星指示器
            },
          },

          legend: {
            left: "5%",
            data: ["异常", "正常", "请假"],
            textStyle: {
              color: "#fff",
            },
            itemWidth: 15,
            itemHeight: 11,
            itemGap: 35,
          },
          grid: {
            left: "40",
            right: "10",
            bottom: "60",
            top: "40",
          },
          xAxis: [
            {
              type: "category",
              axisLine: {
                lineStyle: {
                  color: "#2C6CA3",
                },
              },
              axisLabel: {
                color: "#fff",
                fontSize: 12,
                interval: 0,
                formatter: function (params) {
                  let newParamsName = "";
                  let paramsNameNumber = params.length;
                  let provideNumber = 4;
                  let rowNumber = Math.ceil(paramsNameNumber / provideNumber);
                  if (paramsNameNumber > provideNumber) {
                    for (let p = 0; p < rowNumber; p++) {
                      let tempStr = "";
                      let start = p * provideNumber;
                      let end = start + provideNumber;
                      if (p === rowNumber - 1) {
                        tempStr = params.substring(start, paramsNameNumber);
                      } else {
                        if (p > 2) {
                          tempStr = "...";
                          newParamsName += tempStr;
                          break;
                        } else {
                          tempStr = params.substring(start, end);
                          if (p < 2) {
                            tempStr += "\n";
                          }
                        }
                      }
                      newParamsName += tempStr;
                    }
                  } else {
                    newParamsName = params;
                  }
                  return newParamsName;
                },
              },
              splitLine: {
                lineStyle: {
                  color: "#00314a",
                },
              },
              // axisTick: {
              //   show: false
              // },

              data: res.data.listOrg,
            },
          ],
          yAxis: [
            {
              type: "value",

              nameTextStyle: {
                color: "#fff",
                fontSize: 12,
              },
              axisLine: {
                lineStyle: {
                  color: "#2C6CA3",
                },
              },
              axisTick: {
                show: false,
              },
              splitLine: {
                // 坐标轴在 grid 区域中的分隔线

                lineStyle: {
                  // 分割线配置
                  color: "#2C6CA3", // 分割线颜色
                  type: "dashed", //虚线
                },
              },
              axisLabel: {
                color: "#fff",
                fontSize: 12,
                formatter: "{value}",
              },
            },
          ],
          series: [
            {
              name: "异常",
              type: "bar",
              data: res.data.ycPerson,
              barWidth: 10,
              barGap: "0%" /*多个并排柱子设置柱子之间的间距*/,
              barCategoryGap: "100%" /*多个并排柱子设置柱子之间的间距*/,

              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "rgba(244,94,94,1)" },
                  { offset: 1, color: "rgba(255,205,205,1)" },
                ]),
              },
            },

            {
              name: "正常",
              type: "bar",
              data: res.data.listAll,
              barWidth: 10,
              barGap: "0%" /*多个并排柱子设置柱子之间的间距*/,
              barCategoryGap: "100%" /*多个并排柱子设置柱子之间的间距*/,

              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "rgba(70,157,250,1)" },
                  { offset: 1, color: "rgba(0,31,64,1)" },
                ]),
              },
            },
            {
              name: "请假",
              type: "bar",
              data: res.data.qjPerson,
              barWidth: 10,
              barGap: "0%" /*多个并排柱子设置柱子之间的间距*/,
              barCategoryGap: "100%" /*多个并排柱子设置柱子之间的间距*/,

              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "rgba(225,179,67,1)" },
                  { offset: 1, color: "rgba(225,179,67,0.64)" },
                ]),
              },
            },
          ],
          dataZoom: [
            {
              show: true,
              height: 1, // 滚动条高度
              xAxisIndex: [0],
              bottom: 10, // 距离底部距离
              showDetail: false,
              showDataShadow: false,
              start: 0, //初始化时，滑动条宽度开始标度
              borderColor: "transparent",
              textStyle: {
                fontSize: 0,
              },
              endValue: 5, //从0开始的相当于5个(x轴展示的数量)
              fillerColor: "#3E599C", // 滚动条颜色
              borderColor: "rgba(17, 100, 210, 0.12)",
              backgroundColor: "#142746", //两边未选中的滑动条区域的颜色

              borderWidth: 0,
              //                 handleIcon:
              //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
              //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
              //   handleSize: "0%",
              handleStyle: {
                color: "#d3dee5",
              },
            },
            {
              type: "inside", // 支持内部鼠标滚动平移
              start: 0,
              // end: 20,
              startValue: 0, // 从头开始。
              endValue: 5, // 最多5个
              zoomOnMouseWheel: false, // 关闭滚轮缩放
              moveOnMouseWheel: true, // 开启滚轮平移
              moveOnMouseMove: true, // 鼠标移动能触发数据窗口平移
            },
          ],
        };
        leavingData && myChart.setOption(leavingData);
      });
    },
    // 学生人数汇总
    getStudent() {
      let chartDom = document.getElementById("student");
      let myChart = echarts.init(chartDom);
      getStudentCount({ studentSearchType: 0 }).then((res) => {
        let studentData = {
          backgroundColor: "transparent",
          // tooltip: {
          //   trigger: "axis",
          //   axisPointer: {
          //     type: "shadow",
          //   },
          // },
          tooltip: {
            trigger: "axis",
            extraCssText:
              "background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;color:#000",
            axisPointer: {
              //坐标轴指示器
              type: "shadow", //十字准星指示器
            },
            formatter: function (params) {
              let res = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background: linear-gradient(180deg, rgba(2,102,253,0.65) 0%, rgba(2,102,253,0.3) 100%);" ></span ><span style='display:inline-block;font-size:12px;color:#767985;'>${params[0].name} &nbsp;&nbsp;共${params[0].data.allStudentCount}人</span><br/>`;
              params.forEach((item) => {
                res += `<span style='display:inline-block;font-size:14px;color:#333;margin-left:12px;'>${item.seriesName == "男"
                  ? "男生"
                  : item.seriesName == "女"
                    ? "女生"
                    : item.seriesName == "走读生"
                      ? "走读生"
                      : item.seriesName == "住宿生"
                        ? "住宿生"
                        : ""
                  }&nbsp;&nbsp;&nbsp;${item.value}人</span><br/>`;
              });
              return res;
            },
          },
          legend: {
            right: "0",
            top: "3%",
            data: ["男", "女"],
            textStyle: {
              color: "#fff",
            },
            itemWidth: 15,
            itemHeight: 11,
            itemGap: 35,
          },
          grid: {
            left: "40",
            right: "10",
            bottom: "60",
            top: "40",
          },
          xAxis: [
            {
              type: "category",
              axisLine: {
                lineStyle: {
                  color: "#2C6CA3",
                },
              },

              axisLabel: {
                color: "#fff",
                fontSize: 12,
                interval: 0,
                formatter: function (params) {
                  let newParamsName = "";
                  let paramsNameNumber = params.length;
                  let provideNumber = 4;
                  let rowNumber = Math.ceil(paramsNameNumber / provideNumber);
                  if (paramsNameNumber > provideNumber) {
                    for (let p = 0; p < rowNumber; p++) {
                      let tempStr = "";
                      let start = p * provideNumber;
                      let end = start + provideNumber;
                      if (p === rowNumber - 1) {
                        tempStr = params.substring(start, paramsNameNumber);
                      } else {
                        if (p > 2) {
                          tempStr = "...";
                          newParamsName += tempStr;
                          break;
                        } else {
                          tempStr = params.substring(start, end);
                          if (p < 2) {
                            tempStr += "\n";
                          }
                        }
                      }
                      newParamsName += tempStr;
                    }
                  } else {
                    newParamsName = params;
                  }
                  return newParamsName;
                },
              },
              splitLine: {
                // 坐标轴在 grid 区域中的分隔线

                lineStyle: {
                  // 分割线配置
                  color: "#2C6CA3", // 分割线颜色
                },
              },
              // axisTick: {
              //   show: false
              // },

              data: res.data.orgList,
            },
          ],
          yAxis: [
            {
              type: "value",

              nameTextStyle: {
                color: "#fff",
                fontSize: 12,
              },
              axisLine: {
                lineStyle: {
                  color: "#2C6CA3",
                },
              },
              axisTick: {
                show: false,
              },
              // splitLine: {
              //   lineStyle: {
              //     color: "#355b68",
              //   },
              // },
              splitLine: {
                // 坐标轴在 grid 区域中的分隔线

                lineStyle: {
                  // 分割线配置
                  color: "#2C6CA3", // 分割线颜色
                  type: "dashed", //虚线
                },
              },
              axisLabel: {
                color: "#fff",
                fontSize: 12,
                formatter: "{value}",
              },
            },
          ],
          series: [
            {
              name: "男",
              type: "bar",
              data: res.data.manList,
              barWidth: 10,
              barGap: "0%" /*多个并排柱子设置柱子之间的间距*/,
              barCategoryGap: "100%" /*多个并排柱子设置柱子之间的间距*/,

              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "rgba(70,157,250,1)" },
                  { offset: 1, color: "rgba(0,31,64,1)" },
                ]),
              },
            },
            {
              name: "女",
              type: "bar",
              data: res.data.womanList,
              barWidth: 10,
              barGap: "0%" /*多个并排柱子设置柱子之间的间距*/,
              barCategoryGap: "100%" /*多个并排柱子设置柱子之间的间距*/,

              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "rgba(244,94,94,1)" },
                  { offset: 1, color: "rgba(255,205,205,1)" },
                ]),
              },
            },
          ],
          dataZoom: [
            {
              show: true,
              height: 1, // 滚动条高度
              xAxisIndex: [0],
              bottom: 10, // 距离底部距离
              showDetail: false,
              showDataShadow: false,
              start: 0, //初始化时，滑动条宽度开始标度
              borderColor: "transparent",
              textStyle: {
                fontSize: 0,
              },
              endValue: 5, //从0开始的相当于5个(x轴展示的数量)
              fillerColor: "#3E599C", // 滚动条颜色
              borderColor: "rgba(17, 100, 210, 0.12)",
              backgroundColor: "#142746", //两边未选中的滑动条区域的颜色

              borderWidth: 0,
              //                 handleIcon:
              //                     "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.
              //     8c0 - 2.2, 1.8-4, 4-4h59.8c2.2, 0, 4, 1.8, 4, 4V413z",
              //   handleSize: "0%",
              handleStyle: {
                color: "#d3dee5",
              },
            },
            {
              type: "inside", // 支持内部鼠标滚动平移
              start: 0,
              // end: 20,
              startValue: 0, // 从头开始。
              endValue: 5, // 最多5个
              zoomOnMouseWheel: false, // 关闭滚轮缩放
              moveOnMouseWheel: true, // 开启滚轮平移
              moveOnMouseMove: true, // 鼠标移动能触发数据窗口平移
            },
          ],
        };
        studentData && myChart.setOption(studentData);
      });
    },
    getSchoolInform() {
      // this.loading = this.$loading({
      //   lock: true,
      //   text: "Loading",
      //   spinner: "el-icon-loading",
      //   background: "rgba(255, 255, 255, 0.8)",
      // });
      let data = {
        functionType: this.informIndex + 1,
      };
      getSchoolRelease(data)
        .then((res) => {
          this.tabList = res.data;
          if (this.tabList.length > 10) {
            this.countDown()
          }
          // this.loading.close();
        })
      // .catch((err) => {
      //   this.loading.close();
      // });
    },
    openDetail(val) {
      console.log(val);
      this.open = true;

      this.form = {
        content: val.content.replace(
          /<img/g,
          "<img style='max-width:100%;height:auto;'"
        ),
        type: this.informIndex == 0 ? "学校通知" : "学校新闻",
        title: val.title,
        createTime: val.createTime,
        createByName: val.createByName,
      };
    },
    // 关闭
    close() {
      this.open = false;
    },
    studentAllBoxShow() {
      this.studentAllShow = !this.studentAllShow;
    },
    studentAllAdd(index) {
      this.studentAllIndex = index;
      this.studentAllShow = false;
      this.gteStudentAll();
    },

    gteStudentAll() {
      // let myChart = this.$echarts.init(document.getElementById("studentAll"));
      let chartDom = document.getElementById("studentAll");
      let myChart = echarts.init(chartDom);
      let xx = this.studentAllData[this.studentAllIndex];
      let option = {
        grid: {
          left: "10%",
          right: "5%",
          top: "12%",
          bottom: "15%",
          // containLabel: true
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            //坐标轴指示器
            type: "line", //十字准星指示器
          },
          extraCssText:
            "width: 300px;height: 66px;background: #FFFFFF;box-shadow: 0px 0px 20px 0px rgba(93,90,121,0.2);padding:11px;",
          formatter: function (params) {
            return (
              `<span style="font-size:12px;color:#3D3D3D;">${params[0].name}年</span>` +
              "<br/>" +
              `<span style="color:#3D3D3D;font-size:12px;margin-left:12px;">${xx}最多在校学生 &nbsp;&nbsp; ${params[0].data.one ? params[0].data.one : 0.0
              }人  ${params[0].data.value ? params[0].data.value : 0.0
              }人</span>`
            );
          },

          // formatter: function (parms) {
          //     return parms.marker + " " + parms.name + "：" + parms.value + "次";
          // }
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["一年级", "一年级", "一年级", "一年级", "一年级", "一年级"],
          splitLine: {
            show: true,
            lineStyle: {
              // 坐标轴轴线样式
              color: "#2C6CA3", // 坐标轴轴线颜色
            },
          },
          axisLine: {
            lineStyle: {
              // 坐标轴轴线样式
              color: "#2C6CA3", // 坐标轴轴线颜色
            },
          }, //坐标轴轴线
          axisTick: { show: false }, //坐标轴刻度
          axisLabel: {
            // 坐标轴标签
            // show: true,  // 是否显示
            // inside: false, // 是否朝内
            // rotate: 0, // 旋转角度
            // margin: 5, // 刻度标签与轴线之间的距离
            lineStyle: {
              // 坐标轴轴线样式
              color: "#2C6CA3", // 坐标轴轴线颜色
            },
            color: "#ffffff",
            fontSize: 10,
          },
        },
        yAxis: {
          name: "人",
          nameTextStyle: {
            color: "#fff",
          },
          type: "value",
          splitLine: { show: false }, //坐标轴在 grid 区域中的分隔线
          axisTick: { show: false }, //坐标轴刻度
          axisLine: {
            // 坐标轴轴线相关配置
            lineStyle: {
              // 坐标轴轴线样式
              color: "#2C6CA3", // 坐标轴轴线颜色
            },
          },
          axisLabel: {
            // 坐标轴刻度标签相关配置
            color: "#ffffff",
            fontSize: 10,
          },
        },
        series: [
          {
            itemStyle: {
              normal: {
                // 设置线条的style等
                color: "#0267FE",
                lineStyle: {
                  color: "#0267FE", // 折线线条颜色
                },
                label: {
                  show: false,
                },
              },
            },
            // data: [1000, 120, 530, 123, 150, 160, 130, 121, 150, 160],
            data: [
              { one: 1500, value: 300 },
              { one: 1150, value: 400 },
              { one: 1250, value: 500 },
              { one: 1350, value: 300 },
              { one: 1450, value: 300 },
              { one: 1550, value: 300 },
              { one: 1650, value: 300 },
            ],
            type: "line",
            // symbolSize: 8,   //设定中心点的大小
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#00348F", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(0,52,143,0)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            smooth: true,
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    equipmentBoxShow() {
      this.equipmentShow = !this.equipmentShow;
    },
    equipmentAdd(index) {
      this.equipmentIndex = index;
      this.getFaceDeviceCount();
      this.equipmentShow = false;
    },
    // 时间格式化
    getData() {
      let nowDay = this.$options.filters.formatData(new Date());
      this.timeVal = nowDay;
    },
    // 日期选择
    changeTime(e) {
      console.log("99999");
      this.timeVal = this.$options.filters.formatData(new Date(e));
      this.getLeaving();
    },
    GetBusinessType() {
      getBusinessType().then((res) => {
        console.log(res);

        if (res.data.length!=0){
          this.bottomBoxData = res.data;
          this.bottomBoxData.push({type:6,statisticsName:"智慧饮水机使用统计"})
          this.bottomBoxData.push({type:6,statisticsName:"自助售货机使用统计"})
          this.bottomBoxData.push({type:6,statisticsName:"智慧淋浴使用统计"})
          this.bottomBoxData.push({type:6,statisticsName:"校园教育缴费"})
          this.equipmentData = [{ type: 9, typeName: "全部设备" },...res.data];
        }else {
          this.equipmentData = [{ type: 9, typeName: "全部设备" }];
        }

        console.log("设备列表222",this.equipmentData)
        this.$nextTick(() => {
          let flag = this.bottomBoxData.findIndex((item) => {
            return item.type == "4";
          });
          console.log(flag, "flag");
          this.flag = flag;
          // if(this.flag != -1) {
          //   this.getLeaving();
          //   }
          this.getFaceDeviceCount();
          this.statistics();
          this.getStudent();
          this.getSchoolInform();
          this.$nextTick(() => {
            this.getLeaving();
          });
        });
      });
    },
    GetDormitoryType() {
      getDormitoryType().then(res => {
        console.log(res);
        this.checkData = res.data
        this.$nextTick(() => {
            this.getLeaving();
          });
      })
    },
    getFaceDeviceCount() {
      let type = this.equipmentData[this.equipmentIndex].type;
      getFaceDeviceCount(type).then((res) => {
        console.log(res);
        this.FaceDevice = res.data;
      });
    },
    GetSchoolLargeScreen() {
      schoolLargeScreen().then((res) => {
        this.schoolName = res.data.schoolName;
        this.tr = res.data.subjectNameList;
        this.EqDistrEcharts(
          res.data.subjectNameList,
          res.data.subjectCountList
        );
        this.studentCountVo = res.data.studentCountVo;
      });
    },
    refresh() {
      this.GetBusinessType();
      this.GetDormitoryType()
      this.GetSchoolLargeScreen();
      // this.getFaceDeviceCount()
      this.EqDistrEcharts();
      this.percentage();
      this.statistics();
      this.getPhone();
      this.getDinner();
      this.getEntrance();
      this.getLaundry();
      this.getStudent();
      this.getSchoolInform();
      this.$nextTick(() => {
        this.gteStudentAll();
        this.getLeaving();
      });
    }
  },
  beforeDestroy() {
        if (this.timer || this.echartsTimer) {
            clearInterval(this.timer);
            clearInterval(this.echartsTimer);
            this.timer = null;
            this.echartsTimer = null;
        }
    },
  mounted() {
    this.onResize = debounce(() => {
      this.resize();
    }, this.delay);
    this.$nextTick(() => {
      if (this.selfAdaption) {
        this.resize();
        this.addListener();
      }
    });
    this.getInit()
        this.echartsTimer = setInterval(() => {
            this.getInit()
      }, 600000)
    // this.GetBusinessType();
    // this.GetDormitoryType()
    // this.GetSchoolLargeScreen();
    // // this.getFaceDeviceCount()
    // this.EqDistrEcharts();
    // this.percentage();
    // this.statistics();
    // this.getPhone();
    // this.getDinner();
    // this.getEntrance();
    // this.getLaundry();
    // this.getStudent();
    // this.getSchoolInform();
    // this.$nextTick(() => {
    //   // this.gteStudentAll();
    //   this.getLeaving();
    // });



    // this.getPhone()
    //给页面添加鼠标抬起事件
    document.addEventListener("mouseup", (e) => {
      console.log("抬起");
      //获取弹窗对象
      console.log(e);
      const userCon = document.getElementById("smart");

      if (userCon && !userCon.contains(e.target)) {
        //有dom 并 没点击弹窗
        this.bottomBoxShow = false;
      }
    });
    document.addEventListener("mouseup", (e) => {
      //获取弹窗对象
      console.log(e);
      const userCon = document.getElementById("smarts");

      if (userCon && !userCon.contains(e.target)) {
        //有dom 并 没点击弹窗
        this.checkShow = false;
      }
    });
    document.addEventListener("mouseup", (e) => {
      //获取弹窗对象
      console.log(e);
      const userCon = document.getElementById("smartss");

      if (userCon && !userCon.contains(e.target)) {
        //有dom 并 没点击弹窗
        this.informShow = false;
      }
    });
    document.addEventListener("mouseup", (e) => {
      //获取弹窗对象
      console.log(e);
      const userCon = document.getElementById("studentss");

      if (userCon && !userCon.contains(e.target)) {
        //有dom 并 没点击弹窗
        this.studentAllShow = false;
      }
    });
    document.addEventListener("mouseup", (e) => {
      //获取弹窗对象
      console.log(e);
      const userCon = document.getElementById("equipments");

      if (userCon && !userCon.contains(e.target)) {
        //有dom 并 没点击弹窗
        this.equipmentShow = false;
      }
    });
  },
  beforeDestroy() {
    this.clearListener();
    // this.observer.disconnect()
    if (this.timers) {
      clearInterval(this.timers);
      this.timers = null;
    }
  },
};
//
</script>

<style lang="scss" scoped>
.screen-wrapper {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  position: relative;
  overflow: hidden;
  z-index: 100;
  transform-origin: left top;
}

.top {
  height: 12%;
  background: url(../assets/DataSet/top.png) no-repeat;
  background-size: 100% 100%;
  background-position: center;

  display: flex;
  justify-content: space-between;
  color: white;
  align-items: center;
  position: relative;

  .week {
    position: absolute;
    left: 18.5%;
    bottom: 22%;
    font-size: 12px;
    color: #c5e1ff;
  }

  .top-l {
    width: 20%;
    margin-left: 10%;
    font-size: 14px;
    color: #c5e1ff;
    line-height: 16px;
  }

  .top-cot {
    height: 48px;
    font-size: 30px;
    font-weight: 600;
    color: #ffffff;
    line-height: 48px;
    letter-spacing: 9px;
    text-align: center;
    margin-bottom: 20px;
    flex: 1;
  }

  .top-r {
    width: 20%;
    margin-right: 11%;
    font-size: 14px;
    color: #c5e1ff;
    line-height: 16px;
    text-align: right;
    display: flex;
    cursor: pointer;

    justify-content: flex-end;

    .r-box {
      display: flex;
      align-items: center;
      &:active {
        opacity: .5;
      }

      img {
        width: 22px;
        height: 22px;
        vertical-align: middle;

      }

      span {

        line-height: 24px;
      }
    }
  }
}

.box {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  padding: 0 2%;

  .box-left {
    width: 24%;
    height: 100%;

    .bottom-boxs {
      width: 440px;
      height: 455px;
      border: 4px solid #3e599c;
      border-radius: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 32px;

      .imgs {
        width: 190px;
        height: 121px;
      }

      &:nth-child(1) {
        margin-right: 20px;
      }
    }

    .box-left-teacher {
      width: 440px;
      height: 455px;
      background: url(../assets/DataSet/teacher.png) no-repeat;
      background-size: 100% 100%;
      background-position: center;
      margin-bottom: 30px;

      .box-left-teacher-top {
        height: 40px;
        text-align: center;
        line-height: 40px;
        color: #c5e1ff;
        font-size: 14px;
        font-weight: 700;
      }
    }

    .box-left-personnel {
      width: 440px !important;
      height: 455px !important;
      background: url(../assets/DataSet/teacher.png) no-repeat;
      background-size: 100% 100%;
      background-position: center;
      margin-bottom: 20px;

      .year-box {
        display: flex;
        font-size: 12px;
        color: #fff;
        justify-content: flex-end;
        margin-right: 24px;
        margin-top: 15px;
      }

      .year-item {
        width: 24px;
        height: 24px;
        border: 2px solid #0d53b7;
        text-align: center;
        line-height: 23px;
        box-sizing: border-box;
        cursor: pointer;

        &:nth-child(1) {
          border-right: 0;
          border-radius: 4px 0 0 4px;
        }

        &:nth-child(3) {
          border-left: 0;
          border-radius: 4px 0 0 4px;
        }
      }

      .year-ac {
        box-sizing: border-box;
        border-radius: 4px !important;
        border: 1px solid #41ffff;
        background: linear-gradient(113deg,
            rgba(64, 237, 255, 0.55) 0%,
            rgba(61, 127, 255, 0.3502) 100%);
        border-right: 1px solid #41ffff !important;
        border-left: 1px solid #41ffff !important;
      }

      .box-left-personnel-top {
        height: 40px;
        text-align: center;
        line-height: 40px;
        color: #c5e1ff;
        font-size: 14px;
        font-weight: 700;
      }

      .box-left-personnel-center {
        width: 400px;
        height: 104px;
        // background: #2F558F;
        border-radius: 0px 0px 0px 0px;

        border: 2px solid #2f558f;
        margin: 7px auto;
        display: flex;
        align-items: center;

        .box-img {
          width: 120px;
          height: 100px;
          margin-left: 90px;
          position: relative;
          // background-color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;

          .img-s {
            width: 90px !important;
            height: 90px !important;
            position: absolute;
          }
        }

        .box-left-personnel-center-left {
          position: absolute;
          width: 110px !important;
          height: 100px !important;
          z-index: 9999999999999999999;
          // background-color: red;
          //background: url(../assets/DataSet/percentage.png) no-repeat;
          // background-size: 100% 100%;
        }

        .box-left-personnel-center-right {
          margin-left: 25px;
          width: 88px !important;
          height: 88px !important;
          margin-top: 19px;

          .box-left-personnel-center-right-one {
            width: 127px;
            height: 34px;
            font-size: 32px;
            font-weight: 350;
            color: #18fefe;
            line-height: 34px;
            margin-left: 15px;

            span {
              font-size: 14px;
            }
          }

          .box-left-personnel-center-right-Two {
            display: flex;
            align-items: center;

            height: 45px;
            width: 180px;

            span {
              width: 10px;
              height: 10px;
              border-radius: 5px;
              background: linear-gradient(180deg, #0281ff 0%, #65fffe 100%);
              opacity: 1;
              margin-right: 8px;
            }

            div {
              flex: 1;
              height: 18px;
              font-size: 15px;
              font-weight: 500;
              color: #ffffff;
              line-height: 18px;
            }
          }
        }
      }
    }
  }

  .box-cont {
    width: 52%;
    height: 100%;
    //background-color: red;
    padding: 0 2%;
    box-sizing: border-box;

    .box-cont-top {
      height: 490px;
      // background-color: pink;
      margin-bottom: 40px;

      .box-cont-top-data {
        height: 208px;
        //background-color:blue;
        padding: 21px;
        padding-top: 40px;
        box-sizing: border-box;

        .box-cont-number {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .numberBox {
          width: 126px;
          height: 146px;

          .numberBoxImg {
            width: 126px;
            height: 126px;
            background: url(../assets/DataSet/teaching.png) no-repeat;
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            div {
              height: 50px;
              font-size: 32px;
              //: PangMenZhengDao-Regular, PangMenZhengDao;
              font-weight: 600;
              color: #42a3ff;
              line-height: 26px;
              margin: auto;
              text-align: center;

              span {
                font-size: 20px;
                font-weight: 400;
              }
            }
          }

          .numberBoxImg1 {
            width: 126px;
            height: 126px;
            background: url(../assets/DataSet/class.png) no-repeat;
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            div {
              height: 50px;
              font-size: 32px;
             // font-family: PangMenZhengDao-Regular, PangMenZhengDao;
              font-weight: 600;
              color: #8ab2ff;
              line-height: 26px;
              margin: auto;
              text-align: center;

              span {
                font-size: 20px;
                font-weight: 400;
              }
            }
          }

          .numberBoxImg2 {
            width: 126px;
            height: 126px;
            background: url(../assets/DataSet/patriarch.png) no-repeat;
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            div {
              height: 50px;
              font-size: 32px;
              //font-family: PangMenZhengDao-Regular, PangMenZhengDao;
              font-weight: 600;
              color: #94ffb2;
              line-height: 26px;
              margin: auto;
              text-align: center;

              span {
                font-size: 20px;
                font-weight: 400;
              }
            }
          }

          .numberBoxImg3 {
            width: 126px;
            height: 126px;
            background: url(../assets/DataSet/human.png) no-repeat;
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            div {
              height: 50px;
              font-size: 32px;
             // font-family: PangMenZhengDao-Regular, PangMenZhengDao;
              font-weight: 600;
              color: #ffb82e;
              line-height: 26px;
              margin: auto;
              text-align: center;

              span {
                font-size: 20px;
                font-weight: 400;
              }
            }
          }

          .numberBoxText {
            height: 20px;
            color: #ffffff;
            text-align: center;
            size: 18px;
            font-weight: 400;
            color: #ffffff;
            line-height: 16px;
          }

          .equipment {
            margin: 5px auto;
            width: 102px;
            height: 24px;
            border-radius: 4px 4px 4px 4px;
            opacity: 1;
            border: 1px solid #3e599c;
            font-size: 12px;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            img {
              width: 10px;
              height: 6px;
              vertical-align: middle;
              margin-left: 10px;
            }

            .bottom-box-zhe {
              width: 102px;
              //height: 150px;
              border: 1px solid #3e599c;
              background: #11234f;
              position: absolute;
              top: 26px;
              overflow-y: auto;
              z-index: 99999999999999999999999999999;

              .li {
                width: 100%;
                text-align: center;
                line-height: 35px;
                color: #32c5ff;
              }

              .active {
                color: #fff;
                background: linear-gradient(270deg,
                    rgba(0, 99, 152, 0) 0%,
                    #006398 56%,
                    rgba(0, 99, 152, 0) 100%);
              }
            }

            .bottom-box-zhe::-webkit-scrollbar {
              width: 5px;
            }

            .bottom-box-zhe::-webkit-scrollbar-track {
              background-color: #142746;
              border-radius: 5px;
            }

            .bottom-box-zhe::-webkit-scrollbar-thumb {
              border-radius: 5px;
              background: #3e599c;
            }
          }
        }
      }

      .box-cont-list {
        height: 208px;
        // background-color:blue;
        padding: 21px;
        box-sizing: border-box;
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        margin-top: 50px;

        .one {
          width: 132px;
          height: 162px;
          background: url(../assets/DataSet/BG.png) no-repeat;
          background-size: 100% 100%;
          border-radius: 0px 0px 0px 0px;

          // border: 1px solid #3E599C;
          .one-number {
            font-size: 28px;
           // font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 600;
            color: #01e0d9;
            line-height: 26px;
            text-align: center;
            margin-top: 48px;

            span {
              height: 12px;
              font-size: 14px;
              font-weight: 400;
              color: #01e0d9;
              line-height: 12px;
            }
          }

          .one-headline {
            margin-top: 35px;
            height: 14px;
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
            line-height: 14px;
            text-align: center;
          }
        }

        .two {
          width: 132px;
          height: 162px;
          background: url(../assets/DataSet/BG.png) no-repeat;
          background-size: 100% 100%;
          border-radius: 0px 0px 0px 0px;

          //border: 1px solid #3E599C;
          .two-number {
            font-size: 28px;
            //font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 600;
            color: #fedb65;
            line-height: 26px;
            text-align: center;
            margin-top: 48px;

            span {
              height: 12px;
              font-size: 14px;
              font-weight: 400;
              color: #fedb65;
              line-height: 12px;
            }
          }

          .two-headline {
            margin-top: 35px;
            height: 14px;
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
            line-height: 14px;
            text-align: center;
          }
        }

        .cont {
          width: 205.39px;
          height: 260px;
          background: url(../assets/DataSet/statistics.png) no-repeat;
          background-size: 100% 100%;
          border-radius: 0px 0px 0px 0px;
          //border: 1px solid #3E599C;
          margin-top: -64px;

          .cont-number {
            font-size: 22px;
            font-weight: 700;
            color: #ffffff;
            margin-top: 150px;
            text-align: center;

            span {
              font-size: 12px;
              color: #ffffff;
            }
          }

          .cont-headline {
            font-size: 16px;
            font-weight: 700;
            color: #00fff4;
            line-height: 19px;
            margin-top: 20px;
            text-align: center;
          }
        }
      }
    }

    .box-cont-bottom {
      display: flex;
      // justify-content: center;
      //height: 410px;
      //background-color: purple;
      margin-bottom: 20px;

      .bottom-boxss {
        width: 100% !important;
        margin-right: 0;
        flex-shrink: 0;
        height: 410px;
        border: 4px solid #3e599c;
        border-radius: 14px;
        position: relative;
        box-sizing: border-box;
      }

      .bottom-box {
        width: 48.3%;
        flex-shrink: 0;
        height: 410px;
        border: 4px solid #3e599c;
        border-radius: 14px;
        position: relative;
        box-sizing: border-box;
        margin-right: 20px;

        .year-box {
          display: flex;
          font-size: 12px;
          color: #fff;
          justify-content: flex-end;
          margin-right: 24px;
          margin-top: 15px;
          top: 20%;
          right: 0;
          position: absolute; //夙诺

          z-index: 9;
          .year-item {
            width: 24px;
            height: 24px;
            border: 2px solid #0d53b7;
            text-align: center;
            line-height: 23px;
            box-sizing: border-box;
            cursor: pointer;
            z-index: 9;

            &:nth-child(1) {
              border-right: 0;
              border-radius: 4px 0 0 4px;
            }

            &:nth-child(3) {
              border-left: 0;
              border-radius: 4px 0 0 4px;
            }
          }
        }

        .year-boxs {
          display: flex;
          font-size: 12px;
          color: #fff;
          justify-content: flex-end;
          margin-right: 24px;
          margin-top: 15px;
          top: 18%;
          right: -10px;
          position: absolute; //夙诺

          z-index: 9;
        }

        //.year-item {
        //  width: 24px;
        //  height: 24px;
        //  border: 2px solid #0d53b7;
        //  text-align: center;
        //  line-height: 23px;
        //  box-sizing: border-box;
        //  cursor: pointer;
        //
        //  &:nth-child(1) {
        //    border-right: 0;
        //    border-radius: 4px 0 0 4px;
        //  }
        //
        //  &:nth-child(3) {
        //    border-left: 0;
        //    border-radius: 4px 0 0 4px;
        //  }
        //}

        .year-ac {
          box-sizing: border-box;
          border-radius: 4px !important;
          border: 1px solid #41ffff;
          background: linear-gradient(113deg,
              rgba(64, 237, 255, 0.55) 0%,
              rgba(61, 127, 255, 0.3502) 100%);
          border-right: 1px solid #41ffff !important;
          border-left: 1px solid #41ffff !important;
        }

        .bottom-box-top {
          width: 323px;
          height: 55px;
          margin: 12px auto 0;
          display: flex;
          justify-content: center;
          align-items: center;
          background: url(..//assets/DataSet/pay-top.png);
          background-size: 100% 100%;
          color: #32c5ff;
          position: relative;

          img {
            width: 10px;
            height: 6px;
            vertical-align: middle;
            margin-left: 10px;
          }

          .bottom-box-zhe {
            width: 187px;
            //height: 219px;
            // background: url(..//assets/DataSet/down-bg.png);
            // background-size: 100% 100%;
            background-color: #11234f;
            position: absolute;
            top: 46px;
            overflow-y: auto;
            z-index: 99999999999999999 ;

            .li {
              width: 100%;
              text-align: center;
              line-height: 35px;
            }

            .active {
              color: #fff;
              background: linear-gradient(270deg,
                  rgba(0, 99, 152, 0) 0%,
                  #006398 56%,
                  rgba(0, 99, 152, 0) 100%);
            }
          }

          .bottom-box-zhe::-webkit-scrollbar {
            display: none;
          }
        }
      }

      .bottom-box-right {
        width: 420px;
        flex-shrink: 0;
        height: 410px;
        border: 4px solid #3e599c;
        border-radius: 14px;
        position: relative;
        box-sizing: border-box;

        .year-box {
          display: flex;
          font-size: 12px;
          color: #fff;
          justify-content: flex-end;
          margin-right: 24px;
          margin-top: 15px;
          top: 20%;
          right: 0;
          position: absolute; //夙诺

          z-index: 9999999999999999999999;
        }

        .year-boxs {
          display: flex;
          font-size: 12px;
          color: #fff;
          justify-content: flex-end;
          margin-right: 24px;
          margin-top: 15px;
          top: 18%;
          right: -10px;
          position: absolute; //夙诺

          z-index: 9;
        }

        .year-item {
          width: 24px;
          height: 24px;
          border: 2px solid #0d53b7;
          text-align: center;
          line-height: 23px;
          box-sizing: border-box;
          cursor: pointer;

          &:nth-child(1) {
            border-right: 0;
            border-radius: 4px 0 0 4px;
          }

          &:nth-child(3) {
            border-left: 0;
            border-radius: 4px 0 0 4px;
          }
        }

        .year-ac {
          box-sizing: border-box;
          border-radius: 4px !important;
          border: 1px solid #41ffff;
          background: linear-gradient(113deg,
              rgba(64, 237, 255, 0.55) 0%,
              rgba(61, 127, 255, 0.3502) 100%);
          border-right: 1px solid #41ffff !important;
          border-left: 1px solid #41ffff !important;
        }

        .bottom-box-top {
          width: 323px;
          height: 55px;
          margin: 12px auto 0;
          display: flex;
          justify-content: center;
          align-items: center;
          background: url(..//assets/DataSet/pay-top.png);
          background-size: 100% 100%;
          color: #32c5ff;
          position: relative;

          img {
            width: 10px;
            height: 6px;
            vertical-align: middle;
            margin-left: 10px;
          }

          .bottom-box-zhe {
            width: 187px;
            //height: 219px;
            // background: url(..//assets/DataSet/down-bg.png);
            //background-size: 100% 100%;
            background-color: #11234f;
            position: absolute;
            top: 46px;
            overflow-y: auto;
           z-index: 999;

            .li {
              width: 100%;
              text-align: center;
              line-height: 35px;
            }

            .active {
              color: #fff;
              background: linear-gradient(270deg,
                  rgba(0, 99, 152, 0) 0%,
                  #006398 56%,
                  rgba(0, 99, 152, 0) 100%);
            }
          }

          .bottom-box-zhe::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }
  }

  .box-right {
    width: 24%;
    height: 100%;

    //background-color: blue;
    .map-box {
      width: 440px;
      height: 455px;
      margin-bottom: 30px;
      background: url("../assets/DataSet/teacher.png") no-repeat;
      background-size: 100% 100%;
      background-position: center;
      position: relative;

      .bottom-box-left {
        width: 102px;
        height: 24px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #3e599c;
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 12px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 10px;
          height: 6px;
          vertical-align: middle;
          margin-left: 10px;
        }

        .bottom-box-zhe {
          width: 102px;
          height: 150px;
          background-color: #000;
          position: absolute;
          top: 46px;
          overflow-y: auto;
          z-index: 9999999;

          .li {
            width: 100%;
            text-align: center;
            line-height: 35px;
          }

          .active {
            background: linear-gradient(270deg,
                rgba(0, 99, 152, 0) 0%,
                #006398 56%,
                rgba(0, 99, 152, 0) 100%);
          }
        }

        .bottom-box-zhe::-webkit-scrollbar {
          display: none;
        }
      }

      .map-box-top {
        text-align: center;
        line-height: 40px;
        color: #c5e1ff;
      }

      .bottom-box-top {
        width: 323px;
        height: 40px;
        margin: 12px auto 0;
        display: flex;
        justify-content: center;
        align-items: center;
        //background: url(..//assets/DataSet/pay-top.png);
        background-size: 100% 100%;
        color: #c5e1ff;
        position: relative;

        img {
          width: 10px;
          height: 6px;
          vertical-align: middle;
          margin-left: 10px;
        }

        .bottom-box-zhe {
          width: 187px;
          //height: 219px;
          // background: url(..//assets/DataSet/down-bg.png);
          //background-size: 100% 100%;
          background-color: #11234f;
          position: absolute;
          top: 46px;
          overflow-y: auto;
          z-index: 9999999;

          .li {
            width: 100%;
            text-align: center;
            line-height: 35px;
            height: 35px;
          }

          .active {
            color: #fff;
            background: linear-gradient(270deg,
                rgba(0, 99, 152, 0) 0%,
                #006398 56%,
                rgba(0, 99, 152, 0) 100%);
          }
        }

        .bottom-box-zhe::-webkit-scrollbar {
          display: none;
        }
      }

      .inform-content {
        height: 350px;
        box-sizing: border-box;
        overflow-y: auto;
        //background-color: #fff;
        margin-top: 28px;
        color: #fff;
        margin-right: 5px;

        .i-list {
          margin: 0 28px;

          .i-item {
            height: 45px;
            line-height: 45px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            &:hover {
              color: rgba(24, 254, 254, 1);
            }

            .title {
              /*强制文本在一行内显示*/
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .time {
              width: 25%;

              font-size: 12px;
              color: #86909c;
              text-align: right;
            }
          }
        }
      }

      .inform-content::-webkit-scrollbar {
        width: 5px;
      }

      .inform-content::-webkit-scrollbar-track {
        background-color: #142746;
        border-radius: 5px;
      }

      .inform-content::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: #3e599c;
      }
    }
  }
}

.dialog {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.309);
  position: fixed;
  z-index: 999999999999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .dialog-box {
    width: 750px;
    height: 854px;
    background: #11234f;
    box-shadow: inset 0px 0px 34px 0px rgba(16, 66, 87, 0.66);
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 4px solid #3e599c;
    padding: 40px;
    box-sizing: border-box;
    position: relative;

    .close {
      position: absolute;
      right: 0;
      top: 0;
      width: 40px;
      height: 40px;
      // background: #BBC6E5;
      opacity: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
      }
    }

    .dialog-box-top {
      font-size: 32px;
      font-weight: 700;
      color: #00fff4;
      line-height: 34px;
      box-sizing: border-box;
    }

    .dialog-box-subheading {
      display: flex;
      align-items: center;
      height: 70px;

      .dialog-box-subheading-type {
        width: 90px;
        height: 30px;
        line-height: 30px;
        background: rgba(201, 255, 238, 0.1);
        border-radius: 82px 82px 82px 82px;
        opacity: 1;
        border: 2px solid #3e599c;
        text-align: center;
        color: #00fff4;
        margin-right: 40px;
      }

      .dialog-box-subheading-issuer {
        width: 126px;
        height: 20px;
        font-size: 14px;
        font-weight: 350;
        color: rgba(255, 255, 255, 0.6);
        line-height: 20px;
        margin-right: 40px;
      }

      .dialog-box-subheading-time {
        width: 153px;
        height: 20px;
        font-size: 14px;
        font-weight: 350;
        color: rgba(255, 255, 255, 0.6);
        line-height: 20px;
      }
    }

    .dialog-box-cont {
      width: 100%;
      height: 600px;
      color: #fff;
      overflow-y: scroll;
      overflow-y: auto;
    }
    .dialog-box-cont::-webkit-scrollbar {
      width: 5px;
    }

    .dialog-box-cont::-webkit-scrollbar-track {
      background-color: #142746;
      border-radius: 5px;
    }

    .dialog-box-cont::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: #3e599c;
    }
    .departmentName::-webkit-scrollbar {
      display: none;
    }
  }
}

.anim {
  transition: all 0.5s ease-in;
  margin-top: -41px;
}
.tip-box {
  height: 400px;
  color: #fff;
  margin: 0px 0 0 0px;
  padding-top: 12px;
  box-sizing: border-box;
  overflow: hidden;

  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 5px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }

  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #3E599C;
  }

  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: transparent;
  }

  .i-list {
    margin: 0 18px;

    .i-item {
      height: 45px;
      line-height: 45px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      &:hover {
        color: rgba(24, 254, 254, 1);
      }

      .title {
        /*强制文本在一行内显示*/
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #FFFFFF;
      }

      .time {
        width: 25%;
        color: #FFFFFF;
        font-size: 12px;
        color: #86909c;
        text-align: right;
      }
    }
    .i-item:hover{
      cursor: pointer;
    }
  }
}

::v-deep .el-input--medium .el-input__inner {
  background: rgba(0, 0, 0, 0);
  color: #fff;
  width: 135px;
  border: 1px solid #3e599c;
}

::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 135px;
  border: 1px solid #3e599c;
}

::v-deep .echarts_instance div {
  width: 100%;
}
.is-g {
  z-index: -1;
}
</style>
